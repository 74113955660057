import React from "react";
import RemixIcon from "react-native-remix-icon";
import { View } from "react-native";
import colors from "../../../colors";
import { A, Font } from "../../components/font";
import { StackActions } from "@react-navigation/native";

export const Unauthorized = () => {
  return (
    <View className="flex-1 items-center justify-center">
      <View className="items-center">
        <RemixIcon name="lock-fill" size={60} color={colors.app.c}></RemixIcon>
        <Font className="my-4">Please sign in first</Font>
        <A
          href="signin"
          action={StackActions.replace("signin")}
          className="text-app-e font-bold"
          label="Sign in"
        ></A>
      </View>
    </View>
  );
};
