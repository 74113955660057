import React, { useEffect, useState } from "react";
import { Pressable, View } from "react-native";
import { getStats, getRecentActivites } from "../../services/api";
import { Font } from "../../components/font";
import { Card } from "../../components/general/cards/card";
import { Footer } from "../../components/footer";
import { ScrollView } from "react-native";
import { Input } from "../../components/input";
import { TextInput } from "react-native-gesture-handler";

import SVGLoader from "../../utils/SVGLoader";
import search from "../../../assets/search.svg";
import verticalMenu from "../../../assets/menu-vertical.svg";
import correctCheckbox from "../../../assets/green-fill-checkbox.svg";
import cancelCheckbox from "../../../assets/gray-fill-cross.svg";
import {
  AddToGroup,
  getAllCustomers,
  getGroupNonMembers,
  getGroupNonMembersInvites,
  sendInvite,
  sendInviteToEmail,
} from "../../actions/auth-actions";
import RemixIcon from "react-native-remix-icon";
import colors from "../../../colors";
import { PleaseWaitModal } from "../../components/modal";
import ResponseModal from "../../components/general/modals/ResponseModal";

export default function AddInviteMember({ navigation, route }) {
  const [isSelected, setIsSelected] = useState(false);

  useEffect(() => {
    if (route.params) {
      const { id, groupId } = route.params;
      setGroupId(groupId);
      setId(id);
      getMembersToAdd(id, groupId);
      getMembersToInvite(id, groupId);
    } else {
      getCustomersToInvite();
    }
    getActivities();
  }, [route.params]);
  const { checkRefreshListforMembers } = route?.params;
  // Dependency array ensures this runs only when route.params changes

  const [memberList, setMemberList] = useState([]);
  const [groupId, setGroupId] = useState();
  const [id, setId] = useState();
  const [memberList1, setMemberList1] = useState([]);
  const [emailText, setEmailText] = useState("");
  const [searchText, setSearchText] = useState("");
  const [isPleaseWaitModal, setIsPleaseWaitModal] = React.useState(false);
  const [responseModal, setResponseModal] = React.useState({
    visible: false,
    message: "",
    DismissText: "",
  });
  // const [memberList, setMemberList] = useState([
  //   {
  //     id: 1,
  //     first_name: "Stephen 1",
  //     email: "stephen@email.com",
  //     phone: "9080706050",
  //     // isSelected: true,
  //   },
  //   {
  //     id: 2,
  //     first_name: "Stella",
  //     email: "stella@email.com",
  //     phone: "9080706050",
  //     // isSelected: false,
  //   },
  //   {
  //     id: 3,
  //     first_name: "Robert",
  //     email: "robert@email.com",
  //     phone: "9080706050",
  //     // isSelected: true,
  //   },

  // ]);
  // const [memberList1, setMemberList1] = useState([
  //   {
  //     id: 7,
  //     first_name: "Stephen 1",
  //     email: "stephen@email.com",
  //     phone: "9080706050",
  //     // isSelected: true,
  //   },
  //   {
  //     id: 8,
  //     first_name: "Stella",
  //     email: "stella@email.com",
  //     phone: "9080706050",
  //     // isSelected: false,
  //   },
  //   {
  //     id: 9,
  //     first_name: "Robert",
  //     email: "robert@email.com",
  //     phone: "9080706050",
  //     // isSelected: true,
  //   },

  // ]);
  const [stats, setStats] = React.useState({
    total_listings: 0,
    total_products: 0,
    bookings_completed: 0,
    orders_fulfilled: 0,
  });
  const [activities, setActivities] = React.useState([]);

  const getActivities = async () => {
    try {
      let res = await getStats();
      if (res.status === 200) setStats(res.data.data);
      else
        setStats({
          total_listings: 0,
          total_products: 0,
          bookings_completed: 0,
          orders_fulfilled: 0,
        });
      let activities = await getRecentActivites();
      if (activities.status === 200) setActivities(activities.data.data || []);
    } catch (error) {
      console.log("Error Getting Stats : ", error.response);
    }
  };
  const getMembersToAdd = async (id, groupId) => {
    try {
      let res = await getGroupNonMembers(id, groupId);
      if (res.status === 200) {
        console.log(res.data, "members to add");
        console.log(res, "members to add res");
        setMemberList(res.data.data);
      } else {
        console.log(res.data, "members to add failed");
      }
    } catch (error) {
      console.log("Error Getting Stats : ", error);
    }
  };
  const getMembersToInvite = async (id, groupId) => {
    try {
      let res = await getGroupNonMembersInvites(id, groupId);
      if (res.status === 200) {
        console.log(res.data, "members to invite");
        setMemberList1(res.data.data);
      } else {
        console.log(res.data, "members to add failed");
      }
    } catch (error) {
      console.log("Error Getting Stats : ", error);
    }
  };
  const getCustomersToInvite = async () => {
    try {
      let res = await getAllCustomers(searchText);
      console.log(res.status, "getCustomersToInvite status");
      console.log(res.data, "getCustomersToInvite data");
      if (res.status === 200) {
        console.log(res, "getCustomersToInvite");
        setMemberList1(res.data.data);
      } else {
        console.log(res, "getCustomersToInvite failed");
      }
    } catch (error) {
      console.log("Error Getting Stats : ", error);
    }
  };

  const sendInvitesToNonMembers = async () => {
    try {
      console.log(" cyheck res status for invites");
      setIsPleaseWaitModal(true);
      const res = await sendInvite(groupId, selectedMembers);
      console.log(res.data, "res status for invites");

      if (res.status == 207) {
        setIsPleaseWaitModal(false);
        setResponseModal({
          visible: true,
          message: "Invitation sent Successfully !!!",
          DismissText: "Dismiss",
        });
        const updatedMemberList = memberList1.filter(
          (member) => !selectedMembers.includes(member.id)
        );
        setMemberList1(updatedMemberList);

        checkRefreshListforMembers();
      } else {
        setIsPleaseWaitModal(false);
        setResponseModal({
          visible: true,
          message: "Something went wrong !!!",
          DismissText: "Dismiss",
        });
      }
    } catch (error) {
      console.log(error, "invite error");

      setIsPleaseWaitModal(false);
    }
  };
  const AddNonMembersToGroup = async () => {
    try {
      console.log(" cyheck res status for invites");
      setIsPleaseWaitModal(true);
      const res = await AddToGroup(groupId, selectedMembersToAdd);
      console.log(res.data, "res status for invites");

      if (res.status == 207) {
        setIsPleaseWaitModal(false);
        setResponseModal({
          visible: true,
          message: "Member added Successfully !!!",
          DismissText: "Dismiss",
        });
        const updatedMemberList = memberList.filter(
          (member) => !selectedMembersToAdd.includes(member.id)
        );
        setMemberList(updatedMemberList);
        checkRefreshListforMembers();
      } else {
        setIsPleaseWaitModal(false);
        setResponseModal({
          visible: true,
          message: "Something went wrong !!!",
          DismissText: "Dismiss",
        });
      }
    } catch (error) {
      console.log(error, "invite error");

      setIsPleaseWaitModal(false);
    }
  };
  const sendInviteEmail = async () => {
    try {
      setIsPleaseWaitModal(true);
      const res = await sendInviteToEmail(emailText);
      console.log(res.status, "res status for email invites");
      console.log(res, "res status for email invites");

      if (res.status == 200) {
        setIsPleaseWaitModal(false);
        setResponseModal({
          visible: true,
          message: "Invitation sent Successfully !!!",
          DismissText: "Dismiss",
        });
        checkRefreshListforMembers();
      } else {
        setIsPleaseWaitModal(false);
        setResponseModal({
          visible: true,
          message: "Something went wrong !!!",
          DismissText: "Dismiss",
        });
      }
    } catch (error) {
      setIsPleaseWaitModal(false);
    }
  };
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [selectedMembersToAdd, setSelectedMembersToAdd] = useState([]);

  // Function to handle checkbox toggle
  const handleCheckboxToggle = (memberId) => {
    console.log(memberId, "memberId");

    setSelectedMembers((prevSelected) => {
      if (prevSelected.includes(memberId)) {
        return prevSelected.filter((id) => id !== memberId);
      } else {
        return [...prevSelected, memberId];
      }
    });
  };
  const handleCheckboxToggleToAdd = (memberId) => {
    console.log(memberId, "memberId");

    setSelectedMembersToAdd((prevSelected) => {
      if (prevSelected.includes(memberId)) {
        return prevSelected.filter((id) => id !== memberId);
      } else {
        return [...prevSelected, memberId];
      }
    });
  };

  const closeResponseModal = () => {
    setResponseModal((prev) => ({ ...prev, visible: false }));
  };

  console.log(searchText, "searchText");
  console.log(selectedMembersToAdd, "selectedMembersToAdd");
  console.log(selectedMembers, "selectedMembers");

  return (
    <View className="flex-1 bg-app-e lg:bg-gray-50">
      {isPleaseWaitModal && <PleaseWaitModal showLoader={true} />}
      <ResponseModal
        visible={responseModal.visible}
        bodyText={responseModal.message}
        onRequestClose={closeResponseModal}
      ></ResponseModal>
      <ScrollView
        className="p-4 rounded-t-2xl bg-gray-50"
        contentContainerStyle={{
          flexGrow: 1,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <View className=" max-w-screen-lg w-full mx-auto h-full">
          <View className="my-5 hidden lg:flex px-4">
            <Font className="text-lg font-semibold text-app-d">
              Add/Invite Members
            </Font>
          </View>
          <View className="flex-col justify-center item-center max-w-screen-lg w-full p-2">
            <View className="flex justify-center items-center">
              <View className=" w-full lg:w-3/5 mb-4 px-4 lg:px-0 lg:py-1 flex-row bg-white rounded-full shadow-sm">
                <View className="w-full flex-row justify-between items-center">
                  <TextInput
                    className="w-full lg:w-1/2 ml-2"
                    placeholder="...Search by Name, e mail or phone"
                    placeholderTextColor="#94a3b8"
                    type="text"
                    value={searchText}
                    onChangeText={(e) => setSearchText(e)}
                    onSubmitEditing={getCustomersToInvite}
                    returnKeyType="search"
                  />
                  <View className="w-8 h-8 -ml-6 lg:mr-2 lg:ml-0 justify-center items-center">
                    <SVGLoader
                      src={search}
                      height={25}
                      width={25}
                      webHeight={"20em"}
                      webWidth={"auto"}
                    />
                  </View>
                </View>
              </View>
            </View>
            <View className="flex-col lg:flex-row max-w-screen-lg w-full">
              <Card className="flex-1">
                <View className="w-full p-4 flex-col">
                  <View className="w-full mb-3 pb-2 flex-row justify-between item-center border-b-2 border-b-gray-200">
                    <Font className="lg:text-lg text-base font font-bold self-center">
                      Add
                    </Font>
                    <View className="flex-row justify-center items-center">
                      <Font className="lg:text-sm text-base text-app-e self-center">
                        {memberList.length > 3 ? "See all" : ""}
                      </Font>
                      <View className="w-8 h-4 mt-1 flex justify-center items-center">
                        {/* <SVGLoader
                          src={verticalMenu}
                          height={30}
                          width={30}
                          webHeight={"20em"}
                          webWidth={"auto"}
                        /> */}
                      </View>
                    </View>
                  </View>

                  <View className="min-h-[16rem] max-h-64 p-3 pt-0 px-6 overflow-scroll">
                    {memberList.length == 0 ? (
                      <View className=" w-full flex justify-center items-center">
                        <Font
                          className="text-app-d1 text-xs items-center"
                          weight={500}
                        >
                          No Members to Add
                        </Font>
                      </View>
                    ) : (
                      memberList?.map((member) => {
                        const isSelected = selectedMembersToAdd.includes(
                          member?.id
                        );
                        return (
                          <View
                            className="w-full mb-3 flex-row justify-between"
                            key={member?.id}
                          >
                            <View>
                              <Font className="font-bold text-base">
                                {member?.first_name + " " + member?.last_name}
                              </Font>
                              <Font>{member?.email}</Font>
                              <Font>{member?.phone}</Font>
                            </View>
                            <View className="mt-2">
                              {/* <Input
                            type="checkbox"
                            value={member?.isSelected}
                            setValue={() => {
                              setIsSelected(!isSelected);
                            }}
                          /> */}
                              <Pressable
                                style={{
                                  width: 17,
                                  height: 17,
                                  borderRadius: 2,
                                  alignItems: "center",
                                  justifyContent: "center",
                                  backgroundColor: isSelected
                                    ? "red"
                                    : "#f3f3f3",
                                  borderColor: isSelected ? "red" : "#ccc",
                                  borderWidth: 1,
                                }}
                                onPress={() =>
                                  handleCheckboxToggleToAdd(member?.id)
                                }
                              >
                                {isSelected ? (
                                  <RemixIcon
                                    name="check-fill"
                                    color={"#ffffff"}
                                    size={20}
                                  />
                                ) : null}
                              </Pressable>
                            </View>
                          </View>
                        );
                      })
                    )}
                  </View>
                </View>
              </Card>
              <Pressable
                onPress={() =>
                  memberList.length == 0 ? null : AddNonMembersToGroup()
                }
                className="flex-row justify-center  item-center w-full px-4"
              >
                <View className="w-16 h-16">
                  <SVGLoader
                    src={correctCheckbox}
                    height={40}
                    width={40}
                    webHeight={"20em"}
                    webWidth={"auto"}
                  />
                </View>
              </Pressable>
              <View className="w-4 h-4" />
              <Card className="flex-1">
                <View className="w-full p-4 flex-col">
                  <View className="w-full mb-3 pb-2 flex-row justify-between item-center border-b-2 border-b-gray-200">
                    <Font className="lg:text-lg text-base font font-bold self-center">
                      Invite
                    </Font>
                    <View className="flex-row justify-center items-center">
                      <Font className="lg:text-sm text-base text-app-e self-center">
                        {memberList1.length > 3 ? "See all" : ""}
                      </Font>
                      <View className="w-8 h-4 mt-1 flex justify-center items-center">
                        {/* <SVGLoader
                          src={verticalMenu}
                          height={30}
                          width={30}
                          webHeight={"20em"}
                          webWidth={"auto"}
                        /> */}
                      </View>
                    </View>
                  </View>

                  <View className="min-h-[16rem] max-h-64 p-3 pt-0 px-6 overflow-scroll">
                    {memberList1.length == 0 ? (
                      <View className=" w-full flex justify-center items-center">
                        <Font
                          className="text-app-d1 text-xs items-center"
                          weight={500}
                        >
                          No Members to Invite
                        </Font>
                      </View>
                    ) : (
                      memberList1?.map((member) => {
                        const isSelected = selectedMembers.includes(member?.id);
                        return (
                          <View
                            className="w-full mb-3 flex-row justify-between"
                            key={member?.id}
                          >
                            <View>
                              <Font className="font-bold text-base">
                                {member?.first_name + " " + member?.last_name}
                              </Font>
                              <Font>{member?.email}</Font>
                              <Font>{member?.phone}</Font>
                            </View>
                            <View className="mt-2">
                              <Pressable
                                style={{
                                  width: 17,
                                  height: 17,
                                  borderRadius: 2,
                                  alignItems: "center",
                                  justifyContent: "center",
                                  backgroundColor: isSelected
                                    ? "red"
                                    : "#f3f3f3",
                                  borderColor: isSelected ? "red" : "#ccc",
                                  borderWidth: 1,
                                }}
                                onPress={() => handleCheckboxToggle(member?.id)}
                              >
                                {isSelected ? (
                                  <RemixIcon
                                    name="check-fill"
                                    color={"#ffffff"}
                                    size={20}
                                  />
                                ) : null}
                              </Pressable>
                            </View>
                          </View>
                        );
                      })
                    )}
                  </View>
                </View>
              </Card>
            </View>

            <View className="mb-4 flex-row justify-center lg:justify-end item-center w-full">
              <View className="hidden lg:flex my-3 w-10 h-10">
                <SVGLoader
                  src={cancelCheckbox}
                  height={40}
                  width={40}
                  webHeight={"20em"}
                  webWidth={"auto"}
                />
              </View>

              <Pressable
                onPress={() =>
                  memberList1.length == 0 ? null : sendInvitesToNonMembers()
                }
                className=" px-4"
              >
                <View className="w-16 h-16">
                  <SVGLoader
                    src={correctCheckbox}
                    height={40}
                    width={40}
                    webHeight={"20em"}
                    webWidth={"auto"}
                  />
                </View>
              </Pressable>
            </View>
          </View>
          <Footer navigation={navigation} />
        </View>
      </ScrollView>
    </View>
  );
}
