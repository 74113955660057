import React from "react";
import { Image, Pressable, ScrollView, Text, View } from "react-native";
import RemixIcon from "react-native-remix-icon";
import colors from "../../../colors";
import { Card } from "../../components/general/cards/card";
import { Font } from "../../components/font";
import { Footer } from "../../components/footer";
import { Widget } from "../../components/widget";
import { Switcher } from "../../components/input";
import { UserContext } from "../../services/api";
import { Unauthorized } from "./unauthorized";
export default function Wishlist({ navigation, route }) {
  const { user } = React.useContext(AuthContext);
  const [type, setType] = React.useState("Listing");
  React.useEffect(() => {
    console.log(type);
  }, [type]);
  return user ? (
    <View className="flex-1 bg-app-e lg:bg-gray-50">
      <View className="rounded-t-2xl lg:rounded-none overflow-hidden flex-1 bg-gray-50">
        <View className="px-4 py-2 pb-2 flex lg:hidden">
          <Switcher
            options={[
              { label: "Listing", value: false },
              { label: "Product", value: true },
            ]}
            value={type == "Product"}
            setValue={(e) => setType(e ? "Product" : "Listing")}
          ></Switcher>
        </View>
        <ScrollView
          className="px-4 rounded-t-2xl "
          contentContainerStyle={{
            flexGrow: 1,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <View className=" max-w-screen-lg w-full mx-auto">
            <View className="flex-col lg:flex-row max-w-screen-lg w-full">
              <View className=" w-full lg:w-4/6 p-0 lg:pr-4">
                <View className="my-5 hidden lg:flex flex-row">
                  <Font className="text-lg font-semibold text-app-d flex-1">
                    Wishlist
                  </Font>
                  <View className="w-52">
                    <Switcher
                      options={[
                        { label: "Listing", value: false },
                        { label: "Product", value: true },
                      ]}
                      value={type == "Product"}
                      setValue={(e) => setType(e ? "Product" : "Listing")}
                    ></Switcher>
                  </View>
                </View>
                {type == "Listing" ? (
                  <View className="flex-1 rounded-2xl">
                    {[...Array(5).keys()].map((e, i) => (
                      <View
                        className="w-full bg-app-a rounded-lg p-0 border border-gray-200 flex-col lg:flex-row mb-4"
                        key={i}
                      >
                        <Pressable className="overflow-hidden rounded-none  rounded-t-lg lg:rounded-l-lg">
                          <Image
                            source={require("../../../assets/bg.jpg")}
                            className="w-full h-40 lg:w-40 lg:h-40 "
                          ></Image>
                          <View className="absolute top-0 left-0 w-full h-full p-1 flex-row">
                            <Pressable className="h-8 w-8 bg-app-a rounded-full items-center justify-center flex lg:hidden">
                              <RemixIcon
                                name="bookmark-fill"
                                color={colors.app.e}
                                size="18"
                              ></RemixIcon>
                            </Pressable>
                            <View className="flex-1"></View>
                          </View>
                        </Pressable>
                        <Pressable
                          className="justify-center p-4 flex-1"
                          onPress={() => navigation.navigate("listing")}
                        >
                          <View
                            className="flex-row items-center"
                            style={{
                              marginVertical: 2,
                            }}
                          >
                            <Font
                              className="text-xs lg:text-md text-app-f"
                              numberOfLines={1}
                              weight={500}
                            >
                              Accomodation
                            </Font>
                          </View>
                          <Font
                            className="text-[15px] text-app-d leading-4"
                            numberOfLines={2}
                            weight={600}
                          >
                            Tommy&rsquo;s Italian Restaurant asd asd ad asd asd
                            asd adss
                          </Font>
                          <View
                            className="flex-row items-center mb-1 lg:mb-1"
                            style={{
                              marginVertical: 2,
                            }}
                          >
                            <Font
                              className=" text-app-d1"
                              weight={500}
                              numberOfLines={1}
                            >
                              Washington, DC 20008, United States
                            </Font>
                          </View>
                          <View
                            className="flex-row items-center"
                            style={{
                              marginVertical: 2,
                            }}
                          >
                            <Font
                              className="text-[15px] text-app-d"
                              numberOfLines={1}
                              weight={600}
                            >
                              {" "}
                              $ 99.00{" -  "}$ 150.00{" "}
                            </Font>
                          </View>
                          <View
                            className="flex-row items-center"
                            style={{
                              marginVertical: 2,
                            }}
                          >
                            <RemixIcon
                              name="star-fill"
                              size={16}
                              color={colors.app.i}
                              className="mr-1 flex-shrink-0"
                            ></RemixIcon>
                            <Font
                              className="text-app-d1"
                              weight={500}
                              numberOfLines={1}
                            >
                              {" "}
                              4.8 | 100 reviews
                            </Font>
                          </View>
                        </Pressable>
                        <View className="hidden lg:flex p-4">
                          <Pressable>
                            <RemixIcon
                              name="bookmark-fill"
                              color={colors.app.e}
                              size="24"
                            ></RemixIcon>
                          </Pressable>
                        </View>
                      </View>
                    ))}
                  </View>
                ) : (
                  <View className=" w-[104%]  p-0 lg:pr-4 flex-row flex-wrap -ml-2">
                    {[...Array(10).keys()].map((e, i) => (
                      <View className="w-1/2 lg:w-1/3  p-1 lg:p-2" key={i}>
                        <Pressable className="w-full h-full rounded-lg overflow-hidden border border-gray-200 bg-app-a">
                          <View className="h-44 lg:h-64 w-full">
                            <Image
                              source={require("../../../assets/bip3.jpg")}
                              className="w-full h-full"
                            ></Image>
                            <View className="absolute top-0 left-0 w-full h-full p-1 flex-row">
                              <Pressable className="h-8 w-8 bg-app-a rounded-full items-center justify-center flex lg:hidden border border-gray-200">
                                <RemixIcon
                                  name="bookmark-fill"
                                  color={colors.app.e}
                                  size="18"
                                ></RemixIcon>
                              </Pressable>
                              <View className="flex-1"></View>
                            </View>
                          </View>
                          <View className="p-2">
                            <Font
                              className="text-md text-app-d mb-1 leading-5"
                              weight={600}
                              numberOfLines={2}
                            >
                              Amazfit Bip 3 Pro Smartwatch 1.69&ldquo; Touch
                              Screen, GPS jam tangan SpO2 - Merah Muda
                            </Font>

                            <View
                              className="flex-row items-center"
                              style={{
                                marginVertical: 2,
                              }}
                            >
                              <Font
                                className="text-[15px] text-app-d"
                                numberOfLines={1}
                                weight={700}
                              >
                                {" "}
                                $ 99.00{" "}
                              </Font>
                              <Font
                                className="text-md text-app-e1 line-through ml-2"
                                weight={600}
                                numberOfLines={1}
                              >
                                {" "}
                                $ 150.00
                              </Font>
                            </View>
                            <View
                              className="flex-row items-center"
                              style={{
                                marginVertical: 2,
                              }}
                            >
                              <RemixIcon
                                name="star-fill"
                                size={16}
                                color={colors.app.i}
                                className="mr-1 flex-shrink-0"
                              ></RemixIcon>
                              <Font
                                className="text-md text-app-d1"
                                weight={500}
                                numberOfLines={1}
                              >
                                {" "}
                                4.8 | 100 reviews
                              </Font>
                            </View>
                          </View>
                        </Pressable>
                      </View>
                    ))}
                  </View>
                )}
              </View>
              <View className=" w-full lg:w-2/6 p-0 lg:pl-4 pt-20">
                <Widget></Widget>
              </View>
            </View>
          </View>
          <Footer navigation={navigation}></Footer>
        </ScrollView>
      </View>
    </View>
  ) : (
    <Unauthorized />
  );
}
