import React, { useState } from "react";
import {
  Image,
  View,
  Pressable,
  Platform,
  ImageBackground,
} from "react-native";
import { Font } from "../../components/font";
import { Button, Input } from "../../components/input";
import { ScrollView } from "react-native";
import axiosInstance from "../../constants/axios-config";
import { apiURIV2 } from "../../constants";
// import ResponseModal from "../../components/general/modals/ResponseModal";
import useInputHandler from "../../utils/useInputHandler";
import { email_format } from "../../constants/regex";

export default function ForgotPassword({ navigation }) {
  const {
    currentValue: emailValue,
    onBlurHandler: onEmailBlurHandler,
    onValueChangeHandler: onEmailChangeHandler,
    hasError: emailHasError,
  } = useInputHandler({
    validateFunction: (value) => {
      if (value.match(email_format)) {
        return "";
      }
      return "Please enter a valid email";
    },
  });

  // const [modal, setModal] = useState({
  //   isVisible: false,
  //   message: "Test Modal",
  // });
  const resetPassword = async () => {
    if (!(emailValue && !emailHasError)) {
      return;
    }
    try {
      const res = await axiosInstance.post(apiURIV2 + "/auth/forgot-password", {
        email: emailValue,
      });
      if (res.status === 200) {
        navigation.navigate("email-sent-successful");
      } else {
        console.log("forgot-password, reset pass res error : ", res.data);
      }
    } catch (error) {
      console.log("forgot-password, reset pass error : ", error);
    }
  };
  return (
    <View className="w-full h-full bg-gray-50 flex-row">
      <View className="h-full w-full lg:w-1/2 items-center justify-center">
        <ScrollView
          className="w-full"
          contentContainerStyle={{
            flexGrow: 1,
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <View
            className={
              "w-80 items-center py-16 " +
              (Platform.OS === "web"
                ? "py-8 px-10 md:px-0 w-full md:w-80"
                : "basis-0")
            }
          >
            <Pressable className="mb-8 items-center">
              <Image
                source={require("../../../assets/smbex-lo.png")}
                className="w-36 h-12 my-2"
              ></Image>
              <Font className="text-app-e">Vendor</Font>
            </Pressable>
            <View className="w-full my-2">
              <Font className="text-xl text-app-d" weight={500}>
                Forgot password
              </Font>
            </View>
            <View className="w-full mb-4">
              <Input
                type="email"
                label="Email"
                placeholder="user@example.com"
                value={emailValue}
                setValue={onEmailChangeHandler}
                onBlur={onEmailBlurHandler}
                note={emailHasError}
                invalid={!!emailHasError}
              />
            </View>
            {/* {emailValue !== "" && (
              <View className="flex-1">
                <Font className="text-app-e">Please enter a valid email.</Font>
              </View>
            )} */}
            <View className="w-full my-2">
              <Button
                type="primary"
                label="Send reset password email"
                onPress={resetPassword}
                disabled={!emailValue}
                // onPress={() => {
                //   {
                //     resetPassword;
                //   }
                //   navigation.navigate("signin");
                // }}
              ></Button>
            </View>
          </View>
        </ScrollView>
        {/* {modal.isVisible && (
          <View>
            <ResponseModal
              bodyText={modal.message}
              // modalVisible={modal.isVisible}
              visible={(e) => setModal({ ...modal, isVisible: e })}
              // primaryButtonLabel="Dismiss"
              onRequestClose={() => {
                setModal({ isVisible: false, message: null });
                navigation.navigate("signin", {});
              }}
            ></ResponseModal>
          </View>
        )} */}
      </View>
      <View className="h-full w-1/2 hidden lg:flex bg-app-d">
        <ImageBackground
          className="flex-1"
          source={require("../../../assets/side-background-image.jpg")}
          resizeMode="stretch"
          alt="side-background-home"
        />
      </View>
    </View>
  );
}
