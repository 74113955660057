import React from "react";
import { Pressable, ScrollView, Text, View } from "react-native";
import RemixIcon from "react-native-remix-icon";
import colors from "../../../colors";
import { Card } from "../../components/general/cards/card";
import { FaqItem } from "../../components/faq";
import { Font } from "../../components/font";
import { Footer } from "../../components/footer";
import { Widget } from "../../components/widget";
import { Button, Input } from "../../components/input";
import { getPublicData } from "../../services/api";

export default function ContactUs({ navigation, route }) {
  return (
    <View className="flex-1 bg-app-e lg:bg-gray-50">
      <ScrollView
        className="p-4 rounded-t-2xl bg-gray-50"
        contentContainerStyle={{
          flexGrow: 1,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <View className=" max-w-screen-lg w-full mx-auto">
          <View className="my-5 hidden lg:flex">
            <Font className="text-lg font-semibold text-app-d">Contact Us</Font>
          </View>
          <View className="flex-col lg:flex-row max-w-screen-lg w-full">
            <View className=" w-full lg:w-4/6 p-0 lg:pr-4">
              <Card title="Message">
                <View className="w-full p-4 pt-0">
                  <View className="flex-col lg:flex-row mb-4">
                    <Input
                      type="text"
                      label={"Name"}
                      placeholder="Full name"
                      className="w-full lg:w-1/2 mb-4 lg:mb-0"
                    ></Input>
                    <Input
                      type="email"
                      label={"Email"}
                      placeholder="Email address"
                      className="w-full lg:w-1/2 lg:ml-4"
                    ></Input>
                  </View>
                  <View className="flex-row mb-4">
                    <Input
                      type="text"
                      label={"Subject"}
                      placeholder="Subject"
                    ></Input>
                  </View>
                  <View className="flex-row mb-4 w-full">
                    <Input
                      type="longtext"
                      label={"Message"}
                      placeholder="Message"
                      className="w-full"
                    ></Input>
                  </View>
                  <Button
                    type="primary"
                    label="Send"
                    className="w-1/2"
                  ></Button>
                </View>
              </Card>
            </View>
            <View className=" w-full lg:w-2/6 p-0 lg:pl-4">
              <Widget></Widget>
            </View>
          </View>
          <Footer navigation={navigation}></Footer>
        </View>
      </ScrollView>
    </View>
  );
}
