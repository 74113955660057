import React from "react";
import {
  Image,
  LogBox,
  Pressable,
  RefreshControl,
  ScrollView,
  Text,
  View,
} from "react-native";
import { ActivityIndicator } from "react-native";
import RemixIcon from "react-native-remix-icon";
import colors from "../../../colors";
import { Card } from "../../components/general/cards/card";
import { Font } from "../../components/font";
import { Footer } from "../../components/footer";
import { Widget } from "../../components/widget";
import { Button, Switcher } from "../../components/input";
import { Unauthorized } from "./unauthorized";
import { AuthContext } from "../../contexts/auth-context";
import ResponseModal from "../../components/general/modals/ResponseModal";
import { isSignedIn } from "../../actions/auth-actions";
import axiosInstance from "../../constants/axios-config";
import { apiURIV2 } from "../../constants";
import { useOrdersContext } from "../../contexts/orders-context";
import { Platform } from "react-native";
import { FlatList } from "react-native";
import moment from "moment";
import { getFullDateTime } from "../../utils/date-time-util";
import { orderStatusBadgeData } from "../../utils/statusBadgeValues";
import { catchAPIErrorText } from "../../utils/error-handler";
import { parseProfileName } from "../../utils";

const initial_from = 1,
  load_records = 5;

export default function Orders({ navigation, route }) {
  const [showResponseModal, setShowResponseModal] = React.useState({
    visible: false,
    onRequestClose: () => null,
    bodyText: "",
  });
  const { user } = React.useContext(AuthContext);

  const [isOrdersLoading, setIsOrdersLoading] = React.useState();
  const [isOrdersFailed, setIsOrdersFailed] = React.useState();
  const [ordersData, setOrdersData] = React.useState();

  const [isFirstCame, setIsFirstCame] = React.useState(true);

  const {
    tabStatus,
    setTabStatus,
    orders,
    setOrders,
    pagination,
    setPagination,
  } = useOrdersContext();

  React.useEffect(() => {
    if (isFirstCame) {
      setIsFirstCame((isFirstCame) => !isFirstCame);
      setPagination({
        from: 1,
        to: load_records,
        total: pagination?.total,
      });
    }
  }, [isFirstCame, setPagination, pagination?.total]);

  const getVendorOrders = async (offset, limit, statuses, push) => {
    setIsOrdersLoading(true);
    let data,
      total_count = 0;
    try {
      const token = await isSignedIn();
      console.log("----->>", token);
      const res = await axiosInstance.get(apiURIV2 + "/orders/product", {
        params: {
          offset,
          limit: limit || load_records,
          statuses: statuses ? statuses.split(",") : undefined,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      // console.log("orders res.status: ", res.status, res.data);
      if (res.status === 200) {
        total_count = res.data.total_count;
        data = res.data.data;
      }
    } catch (e) {
      console.log("orders e.res", e?.response, e);
      if (!offset && !limit) {
        setTabStatus({ curr: tabStatus.curr, prev: undefined });
      }
      setShowResponseModal({
        visible: true,
        onRequestClose: () => {
          setShowResponseModal({
            visible: false,
            onRequestClose: () => null,
            bodyText: "",
          });
        },
        bodyText: catchAPIErrorText(e, "Unknown Error while Getting Orders"),
      });
      setIsOrdersFailed(true);
    } finally {
      if (data) {
        const pagObj = {
          from: (offset || -1) + 1 || initial_from,
          to:
            (offset || -1) + 1
              ? offset + (data?.length || load_records)
              : data?.length || load_records,
          total: total_count,
        };
        setOrders({ data: data, push });
        setPagination(pagObj);
        setOrdersData(data);
      }
      setIsOrdersLoading(false);
    }
  };

  if (!orders && !isOrdersLoading && !isOrdersFailed) {
    getVendorOrders();
  } else if (orders && !ordersData) {
    setOrdersData(orders.slice(0, load_records));
  }

  React.useEffect(() => {
    LogBox.ignoreLogs(["VirtualizedLists should never be nested"]);
  }, []);

  if (!user) {
    return <Unauthorized />;
  } else if (isOrdersFailed) {
    return (
      <View className="flex-1 justify-center items-center">
        {showResponseModal.visible && (
          <ResponseModal
            visible={showResponseModal.visible}
            onRequestClose={showResponseModal.onRequestClose}
            bodyText={showResponseModal.bodyText}
          />
        )}
        <View className="p-4">
          <Font className="text-app-d font-semibold text-base" weight={700}>
            Failed to Load the Data
          </Font>
          <Font className="text-app-d1 py-2">Unable to Connect the Server</Font>
          <Button
            label="Retry"
            type="primary"
            onPress={() => {
              if (!isOrdersLoading) {
                getVendorOrders();
                setIsOrdersFailed(false);
              }
            }}
          />
        </View>
      </View>
    );
  } else if (!orders || !ordersData) {
    return (
      <View className="flex-1 justify-center items-center">
        {showResponseModal.visible && (
          <ResponseModal
            visible={showResponseModal.visible}
            onRequestClose={showResponseModal.onRequestClose}
            bodyText={showResponseModal.bodyText}
          />
        )}
        <ActivityIndicator
          animating={!orders && !ordersData}
          size={Platform.OS === "web" ? "small" : "large"}
          color={colors.app.e}
          className="mb-4 shadow-md rounded-full p-2"
        />
      </View>
    );
  }

  const statusBadgeData = (status) => {
    let orderStatus = orderStatusBadgeData(status);

    return (
      <View
        className={`flex-row items-center px-2 py-1 rounded bg-app-h3 ${
          orderStatus.borderColor ? `border ${orderStatus.borderColor}` : ""
        } ${orderStatus.bgColor}`}
        style={{
          marginVertical: 2,
        }}
      >
        <Font
          className={`uppercase text-xs text-app-h ${orderStatus.textColor}`}
          weight={600}
          numberOfLines={1}
        >
          {orderStatus.text}
        </Font>
      </View>
    );
  };

  return user ? (
    <View className="flex-1 bg-app-e lg:bg-gray-50">
      {showResponseModal.visible && (
        <ResponseModal
          visible={showResponseModal.visible}
          onRequestClose={showResponseModal.onRequestClose}
          bodyText={showResponseModal.bodyText}
        />
      )}
      <View className="rounded-t-2xl lg:rounded-none overflow-hidden flex-1 bg-gray-50">
        <ScrollView
          className="px-4 pt-1 sm:pt-2 lg:pt-4 pb-4 rounded-t-2xl "
          contentContainerStyle={{
            flexGrow: 1,
            justifyContent: "center",
            alignItems: "center",
          }}
          nestedScrollEnabled={true}
          refreshControl={
            Platform.OS === "web" ? null : (
              <RefreshControl
                refreshing={isOrdersLoading}
                onRefresh={() => {
                  getVendorOrders(undefined, undefined, tabStatus.curr);
                }}
              />
            )
          }
        >
          <View className=" max-w-screen-lg w-full mx-auto h-full">
            <View className="mt-3 mb-4 lg:my-4 p-0 lg:px-4 flex-col w-full">
              <View className="flex flex-row items-center">
                <View className="hidden lg:flex flex-1 mr-4">
                  <Font className="text-lg font-semibold text-app-d">
                    Orders
                  </Font>
                </View>
                <View className="self-center justify-self-center flex-1">
                  <Switcher
                    options={[
                      { label: "All", value: undefined },
                      {
                        label: "In Process",
                        value: "processing,ready_for_pickup,picked",
                      },
                      {
                        label: "Dispatched",
                        value: "shipped,out_for_delivery,delivered",
                      },
                      {
                        label: "Canceled",
                        value:
                          "failed,cancelled_by_vendor,cancelled_by_customer",
                      },
                    ]}
                    value={tabStatus.curr}
                    setValue={(e) => {
                      if (e !== tabStatus.curr) {
                        getVendorOrders(undefined, undefined, e);
                        setTabStatus({ curr: e, prev: tabStatus.curr });
                      }
                    }}
                  />
                </View>
              </View>
            </View>
            <View className="flex-1 rounded-lg relative bg-app-a border border-gray-200 overflow-hidden mb-4 lg:mx-4">
              {
                <FlatList
                  className="w-full h-full"
                  initialNumToRender={load_records}
                  contentContainerStyle={{ width: "100%", height: "100%" }}
                  data={ordersData}
                  renderItem={({ item: order, index: i }) => (
                    <View
                      className="w-full p-0 border-b border-gray-200 flex-row "
                      key={i}
                    >
                      {/* <Pressable className="overflow-hidden rounded-none rounded-l-lg" onPress={()=>navigation.navigate("listing")}>
                            <Image
                                source={require("../../../assets/bg.jpg")}
                                className="w-full h-full w-24 lg:w-44 lg:h-44 "
                            ></Image>
                          </Pressable> */}
                      <Pressable
                        className="justify-center p-4 flex-1"
                        onPress={() =>
                          navigation.navigate("order-details", {
                            oid: order.id,
                          })
                        }
                      >
                        {/* <View className="items-start">
                            </View> */}
                        <View
                          className="flex-row items-center"
                          style={{
                            marginVertical: 2,
                          }}
                        >
                          {statusBadgeData(order.status)}
                          <Font className="text-app-d1"> | </Font>
                          <Font
                            className=" text-app-d1"
                            weight={500}
                            numberOfLines={1}
                          >
                            {getFullDateTime(order.created_at)}
                          </Font>
                        </View>
                        <Font
                          className="text-md lg:text-[15px] text-app-d leading-4 lg:leading-6 my-2"
                          weight={600}
                          numberOfLines={2}
                        >
                          # {order.id}
                        </Font>
                        <View
                          className="flex-row items-center"
                          style={{
                            marginVertical: 2,
                          }}
                        >
                          <RemixIcon
                            name="ri-user-3-fill"
                            size={16}
                            color={colors.app.f1}
                            className="mr-1 flex-shrink-0"
                          ></RemixIcon>
                          <Font
                            className=" text-app-d1"
                            weight={500}
                            numberOfLines={1}
                          >
                            {parseProfileName(
                              order?.master_order?.user.first_name,
                              order?.master_order?.user.last_name
                            ) || "Unknown Customer"}
                          </Font>
                        </View>
                        <View
                          className="flex-row items-center"
                          style={{
                            marginVertical: 2,
                          }}
                        >
                          <RemixIcon
                            name="ri-shopping-bag-3-fill"
                            size={16}
                            color={colors.app.f1}
                            className="mr-1 flex-shrink-0"
                          ></RemixIcon>
                          <Font
                            className=" text-app-d1"
                            weight={500}
                            numberOfLines={1}
                          >
                            {(() => {
                              let items = 0;
                              order?.product_order_items.forEach((pr_item) => {
                                items += pr_item?.quantity || 0;
                              });
                              return items;
                            })()}{" "}
                            Items
                          </Font>
                          <View className="w-4"></View>
                          <RemixIcon
                            name="price-tag-3-fill"
                            size={16}
                            color={colors.app.f1}
                            className="mr-1 flex-shrink-0"
                          ></RemixIcon>
                          <Font
                            className=" text-app-d1"
                            weight={500}
                            numberOfLines={1}
                          >
                            {order?.master_order?.currency_symbol || ""}{" "}
                            {[undefined, null, ""].includes(
                              order?.master_order?.net_payable
                            )
                              ? "-"
                              : order.master_order.net_payable / 100}
                          </Font>
                        </View>
                      </Pressable>
                    </View>
                  )}
                  ListEmptyComponent={() => (
                    <View className="flex-1 justify-center items-center">
                      <Font className="text-app-d">
                        {isOrdersLoading ? "Loading..." : "No Orders Found"}
                      </Font>
                    </View>
                  )}
                />
              }

              {load_records < pagination?.total && (
                <View className="flex-row w-full">
                  {/* {page > 0 && ( */}
                  {pagination.from > initial_from && (
                    <Pressable
                      className="p-4"
                      onPress={() => {
                        if (pagination.from > initial_from) {
                          const from =
                            pagination.from === 1
                              ? 1
                              : pagination.from - load_records;
                          const toModulus = pagination.to % load_records;
                          const to =
                            pagination.to -
                            (toModulus > 0
                              ? toModulus
                              : pagination.to > load_records
                              ? load_records
                              : 0);
                          const pagObj = {
                            from: from,
                            to: to,
                            total: pagination.total,
                          };
                          setPagination(pagObj);
                          // getListingData(from - 1, load_records, true);
                          setOrdersData(orders.slice(from - 1, to));
                        }
                      }}
                    >
                      <Font className="text-app-e" weight={600}>
                        &lt; Newer
                      </Font>
                    </Pressable>
                  )}
                  {/* )} */}
                  <View className="flex-1"></View>
                  {/* {postsLimit <= posts.length && ( */}
                  {pagination.to < pagination.total && (
                    <Pressable
                      className="p-4"
                      onPress={() => {
                        if (pagination.to < pagination.total) {
                          const remList = pagination.total - pagination.to;
                          const from =
                            pagination.from + (remList > 0 ? load_records : 0);
                          const to =
                            pagination.to +
                            (remList < load_records ? remList : load_records);
                          const pagObj = {
                            from: from,
                            to: to,
                            total: pagination.total,
                          };
                          const listData = orders.slice(from - 1, to);
                          if (listData?.length) {
                            setPagination(pagObj);
                            setOrdersData(listData);
                          } else {
                            getVendorOrders(
                              from - 1,
                              load_records,
                              tabStatus.curr,
                              true
                            );
                          }
                        }
                      }}
                    >
                      <Font className="text-app-e" weight={600}>
                        Older &gt;
                      </Font>
                    </Pressable>
                  )}
                  {/* )} */}
                </View>
              )}
            </View>
            {/* <View className=" w-full lg:w-2/6 p-0 lg:pl-4">
                <Widget></Widget>
              </View> */}
          </View>
        </ScrollView>
        {Platform.OS === "web" && isOrdersLoading && (
          <ActivityIndicator
            animating={isOrdersLoading}
            size="small"
            color={colors.app.d}
            className={`mb-4 shadow-md rounded-full p-2 absolute self-center bg-white top-8 transform transition-all ease-in-out delay-100 duration-1000 opacity-0 ${
              isOrdersLoading && "opacity-100"
            }`}
          />
        )}
      </View>
    </View>
  ) : (
    <Unauthorized />
  );
}
