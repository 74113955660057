import React from "react";
import { Pressable, TextInput, ScrollView } from "react-native";
import { Text, View } from "react-native";
import { Font } from "./font";
export function Card({
  children,
  title = null,
  seeAll = null,
  style,
  Widget = null,
  className,
  navigation
}) {
  let styl = style
    ? style.reduce(function (acc, x) {
        for (var key in x) acc[key] = x[key];
        return acc;
      }, {})
    : [];
  return (
    <View
      className={`w-full mb-4 lg:mb-8 justify-center rounded-lg ${className}`}
      style={{
        ...styl,
      }}
    >
      <View className={`bg-white w-full items-center border border-gray-200 rounded-lg pb-4 shadow-lg shadow-gray-300 ${!title && "pt-4"}`}>
        {title && (
          <View className="p-4 w-full">
            <View className="flex-row pb-4 border-b border-b-gray-200 w-full">
              <Font className="flex-1 text-app-d" weight={700}>
              
                  {title}
                  {/* {title==='Contact'?<Text style={{marginStart:110}}>|</Text>:null} */}

              </Font>
            {/* {title==='Contact'?:null} */}
              {seeAll !== null ? (
                <Pressable onPress={seeAll}>
                  <Font className="text-app-e text-sm">See all</Font>
                </Pressable>
              ) : null}
              {Widget !== null ? <Widget></Widget> : null}
            </View>
          </View>
        )}
        
        {children}
      </View>
    </View>
  );
}
export function CardDark({ children, title = "", seeAll = null }) {
  return (
    <View className="w-full mb-4 lg:mb-8 justify-center ">
      <View className="bg-app-d w-full items-center border border-gray-200 rounded-lg">
        <View className="p-4 w-full">
          <View className="flex-row pb-4 border-b border-b-gray-600 w-full">
            <Font className="flex-1 text-app-a" weight={700}>
              {title}
            </Font>
            {seeAll !== null ? (
              <Pressable>
                <Font className="text-app-a text-sm">See all</Font>
              </Pressable>                   
            ) : null}
          </View>
        </View>
        {children}
      </View>
    </View>
  );
}
