import React from "react";
import { Pressable, Text, View } from "react-native";
import RemixIcon from "react-native-remix-icon";
import WebView from "react-native-webview";
import colors from "../../colors";
import { Font } from "./font";

export const FaqItem = (props) => {
    let [open, setOpen] = React.useState(false);
    return (
        <View
            className="w-full bg-app-a rounded-lg p-0 border border-gray-200 mb-4"
            {...props}
        >
            <Pressable
                className="justify-center p-4 flex-1 flex-row"
                onPress={() => setOpen((e) => !e)}
            >
                <Font
                    className="text-app-d leading-5 flex-1 font-semibold"
                    numberOfLines={3}
                >
                    Faq title will be here
                </Font>
                <RemixIcon
                    className="flex-shrink-0"
                    name={`arrow-${open ? "up" : "down"}-s-fill`}
                    size={24}
                    color={colors.app.d}
                ></RemixIcon>
            </Pressable>
            {open && (
                <View className="flex-1 p-4 border-t border-gray-200">
                    <Font>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s,
                        when an unknown printer took a galley of type and
                        scrambled it to make a type specimen book. It has
                        survived not only five centuries, but also the leap into
                        electronic typesetting, remaining essentially unchanged.
                        It was popularised in the 1960s with the release of
                        Letraset sheets containing Lorem Ipsum passages, and
                        more recently with desktop publishing software like
                        Aldus PageMaker including versions of Lorem Ipsum.
                    </Font>
                </View>
            )}
        </View>
    );
};
