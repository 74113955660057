import axios from "axios";
import { replace } from "../utils/navigation-util";

export const controller = new AbortController();

const _axiosConfiguredInstance = axios.create({
  // timeout: 30000,
  signal: controller.signal,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

_axiosConfiguredInstance.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return Promise.resolve(response);
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    // return handleErrors(error, undefined, () => Promise.reject(error));
    return Promise.reject(error);
  }
);

export const handleErrors = (error, callingFunction, cb) => {
  try {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      // if (error.response.status === 400) {
      // } else
      if (error.response.status === 401) {
        replace("/signin");
      }
      // else if (error.response.status === 402) {
      // } else if (error.response.status === 403) {
      // } else if (error.response.status === 404) {
      // } else if (error.response.status === 413) {
      // } else if (error.response.status === 500) {
      // }
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      console.log("Oops! Network Error Occured...");
    } else {
      // Something happened in setting up the request that triggered an Error (those browser shows as unhandled error text)y
      console.log("Error Config: ", error.config);
    }
    // console.log(`API Name: ${callingFunction?.name || error?.config?.url}`);
    console.log(`Error Response: `, error);
  } catch (e) {
    console.log("handle error:", e);
  }
  cb();
};

const axiosInstance = _axiosConfiguredInstance;
export default axiosInstance;
