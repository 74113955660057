import { Platform } from "react-native";
import React, { useCallback, useRef } from "react";
import { WebView } from "react-native-webview";

export function Maps({ onLocate = () => null, ...props }) {
  const {
    latitude,
    longitude,
    disabled,
    url,
    image,
    title,
    location,
    rating,
    priceMin,
    priceMax,
    style,
  } = props;
  const [key, setKey] = React.useState(Date.now());
  React.useEffect(() => {
    setKey(Date.now());
  }, [
    latitude,
    longitude,
    disabled,
    url,
    image,
    title,
    location,
    rating,
    priceMin,
    priceMax,
    style,
  ]);
  const isNativeWebView = Platform.OS === "web" ? false : true;
  const webviewRef = useRef();
  const onLoad = useCallback(() => {
    function inject(message) {
      return `(()=>document.dispatchEvent(new MessageEvent('message', {data: ${JSON.stringify(
        message
      )}})))();`;
    }
    let data = {
      latitude: props.latitude || 51.505,
      longitude: props.longitude || -0.09,
      locate: true,
      disabled: props.disabled,
      items: [
        {
          url: props.url || "http://",
          image:
            props.image ||
            "https://s3-media0.fl.yelpcdn.com/bphoto/fFDWdGcMqmlbooVsLswvlg/l.jpg",
          title: props.title ?? "Unknown Listing",
          location: props.location ?? "Unknown Address",
          latitude: props.latitude || 51.505,
          longitude: props.longitude || -0.09,
          rating: props.rating ?? 4.8,
          priceMin: props.priceMin ?? 150.0,
          priceMax: props.priceMax ?? 200.0,
        },
      ],
    };
    Platform.OS === "web"
      ? webviewRef.current?.postMessage(data)
      : webviewRef.current?.injectJavaScript(inject(data));
  }, [props]);

  return (
    <WebView
      key={key}
      style={[props.style]}
      ref={webviewRef}
      scalesPageToFit={false}
      geolocationEnabled={true}
      nestedScrollEnabled={true}
      mixedContentMode="compatibility"
      originWhitelist={["*"]}
      onMessage={(e) => {
        let nativeEventData;
        if (isNativeWebView) nativeEventData = JSON.parse(e.nativeEvent.data);
        else nativeEventData = e.nativeEvent.data;
        if (nativeEventData.type === "locate") return onLocate(nativeEventData);
      }}
      onLoad={onLoad}
      useWebKit={true}
      onError={(...d) => console.log("WebView onError: ", d)}
      source={{
        html: `<meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"/> 
          <link rel="stylesheet" href="https://unpkg.com/leaflet@1.8.0/dist/leaflet.css" integrity="sha512-hoalWLoI8r4UszCkZ5kL8vayOGVae1oxXe/2A4AO6J9+580uKHDO3JdHb7NzwwzK5xr/Fs0W40kiNHxM9vyTtQ==" crossorigin=""/> 
          <link rel="stylesheet" href="https://unpkg.com/leaflet.markercluster@1.4.1/dist/MarkerCluster.Default.css" />
          <style>body{padding: 0; margin: 0;}html, body, #map{height: 100%; width: 100vw;}.leaflet-popup-content-wrapper{overflow:hidden}.leaflet-popup-content{margin:0}p{margin:0 !important;}</style> 
          <div id="map"></div>
          <script src="https://unpkg.com/leaflet@1.8.0/dist/leaflet.js" integrity="sha512-BB3hKbKWOc9Ez/TAwyWxNXeoV9c1v6FIeYiBieIWkpLjauysF18NzgR1MBNBXf8/KABdlkX68nAhlwcDFLGPCQ==" crossorigin=""></script> 
          <script src="https://unpkg.com/leaflet.markercluster@1.4.1/dist/leaflet.markercluster.js"></script>
          <script>
            const openItem = (e) => ${isNativeWebView} ? window.ReactNativeWebView.postMessage(JSON.stringify({type:'open',data:e})) : window.parent.postMessage({type:'open',data:e});
            function showMap(data) {
              let latlng = [data.latitude, data.longitude];
              var map = L.map('map').setView(latlng, 13); 
              L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', { maxZoom: 19, attribution: '' }).addTo(map);
              var markers = L.markerClusterGroup({
                spiderfyShapePositions: function(count, centerPt) {
                  var distanceFromCenter = 35,
                      markerDistance = 45,
                      lineLength = markerDistance * (count - 1),
                      lineStart = centerPt.y - lineLength / 2,
                      res = [],
                      i;
                  res.length = count;
                  for (i = count - 1; i >= 0; i--) {
                    res[i] = new Point(centerPt.x + distanceFromCenter, lineStart + markerDistance * i);
                  }
                  return res;
                }
              });
              data.items.forEach((e,i)=>{
                let marker = L.marker([e.latitude, e.longitude], { draggable: data.locate })
                  .addTo(map)
                  .bindPopup(\`
                    <div style="width:220px;height:100%;cursor:pointer" onclick="openItem('\${e.url}')">
                      <img src="\${e.image}" style="width:224px;height:126px;object-fit:cover; margin: -1px auto auto -1px;">
                      <div style="padding:4px 12px">
                        <div title="\${e.title}" style="font-size:15px;font-weight:700;white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">\${e.title}</div>
                        <div title="\${e.location}" style="color:#888888; margin-top:2px; font-size: 12px; max-height: 46px; overflow: hidden; text-overflow: ellipsis;">\${e.location}</div>
                        <div style="margin-tonbspp:4px;font-weight:600;">
                          <span style="color:orange;font-size:1rem;">&starf;</span>
                           \${e.rating} &nbsp;&#183;&nbsp;  
                          <span style="color:green">\${(e.priceMin||e.priceMax)&&"$"}</span>
                           \${e.priceMin}\${e.priceMin&&e.priceMax&&" - "}\${e.priceMax}
                        </div>
                      </div>
                    </div>
                  \`)
                markers.addLayer(marker);
                if(data.locate) {
                  marker.on('dragend', function (e) {
                    if(${isNativeWebView}) 
                      window.ReactNativeWebView.postMessage(JSON.stringify({type:'locate',data:{latitude:marker.getLatLng().lat,longitude:marker.getLatLng().lng}}));
                    else
                      window.parent.postMessage({type:'locate',data:{latitude:marker.getLatLng().lat,longitude:marker.getLatLng().lng}});
                  });
                }
                if(data.disabled) {
                  marker.dragging.disable();
                }
              })
              map.addLayer(markers);
            }
            if(${isNativeWebView}){
              document.addEventListener("message", message => {
                  let data = message.data;
                  showMap(data)
              });
            } else {
              window.addEventListener("message", message => {
                  let data = message.data;
                  showMap(data)
              });
            }
          </script>`,
      }}
    />
  );
}
