import { View, Pressable, ActivityIndicator } from "react-native";
import React from "react";
import { Font } from "../../font";
import RemixIcon from "react-native-remix-icon";
import { FlatList } from "react-native";
import { Platform } from "react-native";
import { cloneDeep } from "lodash";
import colors from "../../../../colors";
import {
  monthsWithDateIndex,
  weekDaysForUI,
} from "../../../constants/calendar-constant";

const Calendar1 = ({ slots, onCalendarDateSelect = () => null } = {}) => {
  const todayDate = new Date(),
    today = {
      year: todayDate.getFullYear(),
      month: todayDate.getMonth(),
      date: todayDate.getDate(),
      day: todayDate.getDay(),
      firstDateWeekDay: new Date(todayDate.setDate(1)).getDay(),
    },
    [{ year, month, date, day, firstDateWeekDay }, setCalendarData] =
      React.useState(cloneDeep(today));

  const offsetDaysFromFirstWeekDay =
    firstDateWeekDay === 0 ? 6 : firstDateWeekDay - 1;
  return (
    <View className="flex-1 px-4 pb-4">
      <View className="flex-1 px-4 pb-4 border border-app-c rounded">
        <View className="flex justify-between flex-row py-4">
          <View className="flex-1 flex-row items-center">
            <Font
              className="capitalize"
              selectable={false}
            >{`${monthsWithDateIndex[month]}, ${year}`}</Font>
          </View>
          <View className="flex-row w-max">
            <Pressable
              className="mr-2"
              onPress={() => {
                if (month > 0) {
                  setCalendarData(({ year, month }) => {
                    month = month - 1;
                    const isCurrMon = month === today.month;
                    return {
                      year,
                      month: month,
                      date: isCurrMon ? today.date : undefined,
                      day: isCurrMon ? today.day : undefined,
                      firstDateWeekDay: new Date(year, month, 1).getDay(),
                    };
                  });
                }
              }}
            >
              <RemixIcon
                name="ri-arrow-left-s-line"
                className="p-1"
                size={Platform.OS === "web" ? 26 : 20}
              />
            </Pressable>
            <Pressable
              className="ml-2"
              onPress={() => {
                month < 11 &&
                  setCalendarData(({ year, month, date, day }) => ({
                    year,
                    month: month + 1,
                    date,
                    day,
                    firstDateWeekDay: new Date(year, month + 1, 1).getDay(),
                  }));
              }}
            >
              <RemixIcon
                name="ri-arrow-right-s-line"
                className="p-1"
                size={Platform.OS === "web" ? 26 : 20}
              />
            </Pressable>
          </View>
        </View>
        <View className="flex-row justify-center items-center">
          <FlatList
            data={weekDaysForUI}
            renderItem={({ item }) => (
              <View className="flex-1 flex-row items-center justify-center p-2 md:p-4">
                <Font
                  className={`capitalize ${
                    item === "sunday" ? "text-app-e" : ""
                  }`}
                  selectable={false}
                >
                  {item.substring(0, Platform.OS === "web" ? 3 : 1)}
                </Font>
              </View>
            )}
            numColumns={7}
          />
        </View>
        <View className="flex-1 flex-row justify-center items-center relative">
          <FlatList
            refreshing={!slots}
            data={[
              ...Array(
                ([3, 5, 8, 10].includes(month)
                  ? 30
                  : month === 1
                  ? !(year % 4)
                    ? 29
                    : 28
                  : 31) + offsetDaysFromFirstWeekDay
              ).keys(),
            ]}
            renderItem={({ item }) => {
              const calcDate = item - offsetDaysFromFirstWeekDay + 1;
              const isDate = calcDate > 0;
              const isPastDate =
                month < today.month ||
                (month == today.month && calcDate < today.date);
              const availableSlots =
                slots?.[year]?.[monthsWithDateIndex[month]]?.[calcDate];
              const areSlotsAvailable = availableSlots?.length;

              return (
                <View
                  className={`basis-1/7 max-h-10 md:max-h-14 grow-0 shrink-0 flex-row items-center justify-center p-[2px] md:p-[4px] ${
                    isDate ? "border border-app-c box-border rounded" : ""
                  }`}
                >
                  <Pressable
                    disabled={!isDate}
                    className={`p-[25%] lg:py-[29%] flex-1 rounded ${
                      isDate
                        ? `${
                            areSlotsAvailable
                              ? isPastDate
                                ? "bg-app-e3 active:bg-app-e2"
                                : "bg-app-g3 active:bg-app-g2"
                              : "bg-app-c/50"
                          }`
                        : ""
                    }`}
                    onPress={() => {
                      onCalendarDateSelect({
                        isPastDate,
                        year,
                        month: monthsWithDateIndex[month],
                        date: calcDate,
                        weekday: day,
                        slots: cloneDeep(availableSlots),
                      });
                    }}
                  >
                    <View>
                      <Font
                        className={`capitalize m-0 self-center p-0 leading-4 ${
                          areSlotsAvailable || !isPastDate
                            ? ""
                            : "text-app-d/40"
                        }`}
                        selectable={false}
                      >
                        {isDate ? calcDate : ""}
                      </Font>
                    </View>
                  </Pressable>
                </View>
              );
            }}
            numColumns={7}
          />
          {!slots && (
            <ActivityIndicator
              animating={!slots}
              size="large"
              color={colors.app.e}
              style={{ position: "absolute" }}
              className="rounded flex-1 w-full h-full"
            />
          )}
        </View>
      </View>
    </View>
  );
};

export default Calendar1;
