import React, { useEffect, useState } from "react";
import {
  Pressable,
  View,
  Image,
  Text,
  TouchableWithoutFeedback,
  Modal,
  Share,
  FlatList,
  RefreshControl,
  ActivityIndicator,
} from "react-native";
import { getStats, getRecentActivites } from "../../services/api";
import { Font } from "../../components/font";
import { Footer } from "../../components/footer";

import { ScrollView } from "react-native";
import { TextInput } from "react-native-gesture-handler";
import RemixIcon from "react-native-remix-icon";
import colors from "../../../colors";
import * as Linking from "expo-linking";
import SVGLoader from "../../utils/SVGLoader";
import verticalmenu from "../../../assets/white-menu-vertical.svg";
import edit from "../../../assets/pencil.svg";
import profilelock from "../../../assets/Lock.svg";
import horizontalMenu from "../../../assets/horizontal-menu.svg";
import CreateGroupPost from "./create-group-post";
import GroupMembers from "./group-members";
import GroupSettings from "./groups-settings";
import {
  deleteAPost,
  getAPost,
  getGroupMembers,
  LikePostApi,
  ShareAPost,
} from "../../actions/auth-actions";
import { useAuthContext } from "../../contexts/auth-context";
import EditModal from "../../components/general/modals/EditModal";
import moment from "moment";
import { useVendorFeedContext } from "../../contexts/vendor-feed-context";
import CommentSection from "../../components/general/commentSection";
import ConfirmModal from "../../components/general/modals/ConfirmModal";
import { PleaseWaitModal } from "../../components/modal";
import ResponseModal from "../../components/general/modals/ResponseModal";
import CreateGroupPostModal from "./create-post-modal";
import { cloneDeep } from "lodash";

export default function ManageGroups({
  navigation,
  route,
  // Id,
  // Name,
  // Description,
  // IsPrivate,
  // GroupStatus,
  // checkRefreshList,
}) {
  const { tabStatus, setTabStatus } = useVendorFeedContext();
  const [isRefreshList, setRefreshList] = React.useState(true);
  const checkRefreshListforPosts = () => {
    setRefreshList(!isRefreshList);
  };
  // console.log(
  //   document.decodeURIComponent(
  //     "group%2Fpost%2Fattachments%2Fsz1bD2D1j2rvm34THZEaw3WXi8ocyMhGTs0D9Cwwh4U.pdf"
  //   ),
  //   "decoded uri"
  // );
  // useEffect(() => {
  // if (route?.params) {
  const { id, name, description, isPrivate, groupStatus, checkRefreshList } =
    route?.params;
  // setId(id);
  // setName(name);
  // setDescription(description);
  // setIsPrivate(isPrivate);
  // setGroupStatus(groupStatus);
  // // setCheckRefreshList(checkRefreshList);
  // checkRefreshList(checkRefreshList);
  // } else {
  //   setId(Id);
  //   setName(Name);
  //   setDescription(Description);
  //   setIsPrivate(IsPrivate);
  //   setGroupStatus(GroupStatus);
  //   // setCheckRefreshList(checkRefreshList);
  //   checkRefreshList(checkRefreshList);
  // }
  // }, [route?.params]);

  // const { id, name, description, isPrivate, groupStatus, checkRefreshList } =
  // //   route?.params;
  // const [id, setId] = React.useState();
  // const [name, setName] = React.useState();
  // const [description, setDescription] = React.useState();
  // const [isPrivate, setIsPrivate] = React.useState();
  // const [groupStatus, setGroupStatus] = React.useState();
  //   const checkRefreshList  = (func) =>{
  // func();
  //   }
  // const [checkRefreshList,setCheckRefreshList ] = React.useState();

  // const { id, name, description, isPrivate, groupStatus, checkRefreshList } =
  //   route.params;
  const { user } = useAuthContext();
  // console.log(id, " group id check");
  // console.log(user.id, "id check");
  const [isGroupHandlingTab, setIsGroupHandlingTab] = useState("home");
  const [refreshing, setRefreshing] = React.useState(false);
  const [isActiveTab, setIsActiveTab] = useState("Public");
  const [isEditModal, setIsEditModal] = useState(false);
  const [isEditPostModal, setIsEditPostModal] = useState(false);
  const [isCommentSectionVisible, setIsCommentSectionVisible] = useState(false);
  const [isFetchingMore, setIsFetchingMore] = React.useState(false);
  const [isPostId, setIsPostId] = useState();
  const [isVisible, setIsVisible] = useState(false);
  const [loading, setIsLoading] = React.useState(false);
  const [getPostsLoading, setGetPostsLoading] = React.useState(false);
  const [currentPage, setCurrentPage] = React.useState(1);
  const closeEditModal = () => setIsEditModal(false);
  const closeEditPostModal = () => setIsEditPostModal(false);
  const [MembersList, setMembersList] = useState([]);
  const [groupPost, setGroupPost] = useState([]);
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const [isPleaseWaitModal, setIsPleaseWaitModal] = useState(false);
  const scrollViewRef = React.useRef(null);
  const [responseModal, setResponseModal] = useState({
    visible: false,
    message: "",
    DismissText: "",
  });
  const [deleteModal, setIsDeleteModal] = useState(false);
  const closeMenu = () => {
    setIsMenuVisible(false);
  };
  const closeDeleteModal = () => {
    setIsDeleteModal(false);
  };

  const onRefresh = async () => {
    setRefreshing(true);
    setGroupPost([]);
    await getAPost();
    setRefreshing(false);
  };
  const closeResponseModal = () => {
    setResponseModal((prev) => ({ ...prev, visible: false }));
  };
  const [stats, setStats] = React.useState({
    total_listings: 0,
    total_products: 0,
    bookings_completed: 0,
    orders_fulfilled: 0,
  });
  const [activities, setActivities] = React.useState([]);
  const timeAgo = (updatedAt) => moment(updatedAt).fromNow();
  // React.useEffect(() => {
  //   getGroupMembers(id);
  // }, []);
  const GetMembers = async () => {
    try {
      let res = await getGroupMembers(id);
      if (res.status === 200) {
        // console.log(res.data.data, "res for get members 200");
        setMembersList(res.data.data);
        // console.log(res.data.data[0].user, "res for get members 200");
      } else console.log(res.data.data.user, "res for get members");
    } catch (error) {
      console.log("Error Getting Stats : ", error.response);
    }
  };

  const getActivities = async () => {
    try {
      let res = await getStats();
      if (res.status === 200) setStats(res.data.data);
      else
        setStats({
          total_listings: 0,
          total_products: 0,
          bookings_completed: 0,
          orders_fulfilled: 0,
        });
      let activities = await getRecentActivites();
      if (activities.status === 200) setActivities(activities.data.data || []);
    } catch (error) {
      console.log("Error Getting Stats : ", error.response);
    }
  };
  const getGroupPosts = async (page = 1) => {
    console.log(page, "page check");
    try {
      if (page === 1) {
        setGetPostsLoading(true);
        setIsLoading(true);
      } else {
        setIsFetchingMore(true);
      }
      let res = await getAPost(id, page);
      // console.log(res, "res check for get posts");

      if (res.status === 200) {
        setGetPostsLoading(false);
        setIsFetchingMore(false);
        setIsLoading(false);
        console.log(res.data.posts.length, "res.data.posts.length;");

        setGroupPost((prevData) =>
          page === 1 ? res.data.posts : [...prevData, ...res.data.posts]
        );
        // (res.data.posts);
        console.log(res.data, "response data check success for get posts");
        console.log(res, "response check success for get posts");
      } else {
        setGetPostsLoading(false);
        console.log(res.data, "response check failed for get posts");
        console.log(res, "response check failed for get posts");
      }
    } catch (error) {
      setGetPostsLoading(false);
      setIsFetchingMore(false);
      setIsLoading(false);
      console.log("Error Getting Stats : ", error.response);
    }
  };

  const sharePostApi = async (postId, index) => {
    try {
      const result = await Share.share({
        message: `https://smbexvendor.wisdomtooth.tech/groups/${id}/posts/${postId}`,
      });
      console.log("result:", result, new Share());

      console.log(
        result?.action === Share.sharedAction,
        "result?.action === Share.sharedAction"
      );
      console.log(result?.action, "result?.action");
      console.log(Share.sharedAction, "Share.sharedAction");
      console.log(result.activityType, "result.activityType");

      if (result?.action === Share.sharedAction) {
        if (result.activityType) {
          // shared with activity type of result.activityType
        } else {
          // shared
          try {
            let res = await ShareAPost(id, postId);

            if (res.status === 201) {
              setGroupPost((data) => {
                data[index].shares++;

                return cloneDeep(data);
              });
              // setGroupPost(res.data.posts);
              // console.log(res.data, "response check success for share posts");
            } else {
              console.log(res.data, "response check failed for share posts");
            }
          } catch (error) {
            console.log("Error Getting share : ", error.response);
          }
        }
      } else if (result?.action === Share.dismissedAction) {
        // dismissed
        console.log("Share action was dismissed.");
      }
    } catch (error) {
      alert(error.message);
    }
  };
  const LikeDisLikePost = async (postId, isLiked, index) => {
    try {
      let res = await LikePostApi(id, postId, isLiked);
      console.log(res, "res check for Like posts");

      if (res.status === 201) {
        setGroupPost((data) => {
          const is_liked = isLiked;
          data[index].is_liked = is_liked ? false : true;
          if (is_liked) {
            data[index].likes--;
          } else {
            data[index].likes++;
          }
          return cloneDeep(data);
        });
        // setGroupPost(res.data.posts);
        // console.log(res.data, "response check success for Like posts");
      } else {
        console.log(res, "response check failed for Like posts");
        console.log(res.data, "response check failed for Like posts data");
      }
    } catch (error) {
      console.log("Error Getting share : ", error.response);
    }
  };
  const deletePost = async () => {
    try {
      setIsDeleteModal(false);
      setIsPleaseWaitModal(true);
      let res = await deleteAPost(id, isPostId);
      // console.log(res, "res check for delete posts");
      // console.log(isPostId, "isPostId");

      if (res.status === 204) {
        setGroupPost((prevList) =>
          prevList.filter((post) => post.id !== isPostId)
        );
        setIsPleaseWaitModal(false);
        setResponseModal({
          visible: true,
          message: "Post Deleted Successfully !!!",
          DismissText: "Dismiss",
        });
        // setGroupPost(res.data.posts);
        // console.log(res.data, "response check success for Like posts");
      } else {
        setResponseModal({
          visible: true,
          message: "Something went wrong !!!",
          DismissText: "Dismiss",
        });
        setIsPleaseWaitModal(false);
        console.log(res.data, "response check failed for delete posts");
      }
    } catch (error) {
      console.log("Error Getting share : ", error.response);
    }
  };

  const loadMoreData = () => {
    console.log("on end reached ?", loading, isFetchingMore);

    // if (!isFetchingMore && !loading) {
    //   setCurrentPage((prevPage) => prevPage + 1);
    // }
  };

  const openMenu = () => {
    // if (buttonRef.current) {
    //   buttonRef.current.measure((x, y, width, height, pageX, pageY) => {
    //     setButtonPosition({ left: pageX - 100, top: pageY + height - 30 });
    //   });
    // }
    setIsMenuVisible(true);
  };

  const buttonRef = React.useRef(null);
  const [buttonPosition, setButtonPosition] = React.useState({});
  // console.log("navigation-manage grp:", navigation);
  useEffect(() => {
    getActivities();
    getGroupPosts(currentPage);
    GetMembers();
  }, [isRefreshList, currentPage]);

  console.log(currentPage, "currentPage");

  const renderItem = ({ item, i }) => {
    return (
      <View
        key={i}
        className="p-2 w-full flex justify-center items-center bg-app-a border-t border-t-gray-300"
      >
        <View className="w-full flex-row justify-between items-center">
          <View className="flex-row justify-start items-center">
            <View className="mr-2">
              <Image
                source={require("../../../assets/user.jpg")}
                className="w-10 h-10 rounded-full"
              />
            </View>
            <View className="mt-2 flex justify-start item-center">
              <Font className="text-sm text-app-d font-bold">{name}</Font>
              <View className="flex-row justify-start items-center">
                {/* <Font className="text-xs text-gray-500">
                                Owner
                              </Font> */}
                {/* <Font className="mr-2 ml-2 flex items-center text-xs font-extrabold self-center">
                                .
                                </Font> */}
                <Font className="text-xs text-gray-500">
                  {timeAgo(item?.created_at)}
                </Font>
              </View>
            </View>
          </View>
          <Pressable
            onPress={() => {
              setIsPostId(item?.id);
              openMenu();
            }}
            ref={buttonRef}
            className="mr-2 w-4 h-4 lg:w-10 lg:-h-10"
          >
            <SVGLoader
              src={horizontalMenu}
              height={25}
              width={20}
              webHeight={"20em"}
              webWidth={"auto"}
            />
          </Pressable>
        </View>
        <View className="py-2 w-full text-sm">
          <Font>
            {/* Lorem ipsum dolor sit amet. Ea velit reprehenderit est
                          magni sequi id similique libero sit ipsam cumque... */}
            {item?.description}
          </Font>
        </View>
        {/* {console.log(
                        "https://smbexapi.wisdomtooth.tech/api/v2/media/" +
                        e?.cover_image_url,
                        "Check url"
                        )} */}
        {item?.cover_image_url !== null && (
          <View className="w-full h-56">
            <Image
              resizeMode="cover"
              source={{
                uri:
                  "https://smbexapi.wisdomtooth.tech/api/v2/media/" +
                  item?.cover_image_url,
              }}
              className="w-auto h-full rounded-lg"
              // style={{ resizeMode: "contain" }}
            />
          </View>
        )}
        {item?.external_link_url ? (
          <Pressable
            className="p-2 mb-0 w-full flex-row item-center justify-between"
            onPress={() =>
              item?.external_link_url
                ? Linking.openURL(item?.external_link_url)
                : null
            }
          >
            <View className="flex justify-center items-center">
              <View className="flex item-center justify-between">
                <Font className="text-lg text-app-d">
                  {item?.external_link_url}
                </Font>
                <Font className="text-sm text-app-d">
                  {/* Headline copy here */}Click on this link to visit
                </Font>
              </View>
            </View>
            <View className="flex justify-center items-center">
              <View className="p-2 py-1 flex justify-center items-center bg-app-c border-2 border-gray-300 rounded-lg">
                <Font className="text-xs">Learn More</Font>
              </View>
            </View>
          </Pressable>
        ) : (
          <></>
        )}
        {item?.attached_file_url ? (
          <Pressable
            className="p-2 mb-5 w-full flex-row item-center justify-between"
            onPress={() =>
              item?.attached_file_url
                ? Linking.openURL(
                    "https://smbexapi.wisdomtooth.tech/api/v2/media/" +
                      item?.attached_file_url
                  )
                : null
            }
          >
            <View className="flex justify-center items-center">
              <View className="flex item-center justify-between">
                <Font className="text-lg text-app-d">
                  {/* {item?.external_link_url} */}
                  Attached File
                </Font>
                <Font className="text-sm text-app-d">
                  {/* Headline copy here */}Click to Download file
                </Font>
              </View>
            </View>
            <View className="flex justify-center items-center">
              <View className="p-2 py-1 flex justify-center items-center bg-app-c border-2 border-gray-300 rounded-lg">
                <Font className="text-xs">Download</Font>
              </View>
            </View>
          </Pressable>
        ) : (
          <></>
        )}
        <View className="p-4 w-full flex-row justify-between item-center border-b border-t border-b-gray-300">
          <View className="flex-row justify-center item-center">
            <View className="z-10 h-8 w-8 bg-indigo-600 border-4 border-gray-300 rounded-full items-center justify-center">
              <RemixIcon
                name="ri-thumb-up-fill"
                color={colors.app.a}
                size="18"
              />
            </View>
            <View className="-ml-2 h-8 w-8 bg-red-400 border-4 border-gray-300 rounded-full items-center justify-center">
              <RemixIcon name="ri-heart-fill" color={colors.app.a} size="18" />
            </View>
            <View className="flex justify-center items-center">
              <Font className="text-base text-gray-500">{item?.likes}</Font>
            </View>
          </View>
          <View className="w-full flex-row justify-center item-center">
            <Font className="text-base text-gray-500 mr-2">
              {item?.comments} Comments
            </Font>
            <Font className="text-base text-gray-500">
              {item?.shares} Shares
            </Font>
          </View>
        </View>
        <View className="py-2 w-full flex-row justify-around item-center">
          <View>
            <View className="w-full flex-row justify-around item-center">
              <Pressable
                onPress={() => LikeDisLikePost(item?.id, item?.is_liked, i)}
                className="flex-row item-center"
              >
                <View className="h-8 w-8 mt-1">
                  <RemixIcon
                    name="ri-thumb-up-line"
                    color={item?.is_liked ? colors.app.f : colors.app.d1}
                    size="22"
                  />
                </View>
                <Font className="text-lg text-gray-600">Like</Font>
              </Pressable>
              <Pressable
                onPress={() => {
                  setIsPostId(item?.id);
                  setIsCommentSectionVisible((prev) => !prev);
                }}
                // onPress={() =>
                //   navigation.navigate("vendor-feed-comments", {
                //     slug: id,
                //   })
                // navigation.goBack()
                // }
                className="flex-row item-center"
              >
                <View className="h-8 w-8 mt-1">
                  <RemixIcon
                    name="ri-chat-4-line"
                    color={colors.app.f4}
                    size="22"
                  />
                </View>
                <Font className="text-lg text-gray-600">Comment</Font>
              </Pressable>

              <Pressable
                onPress={() => sharePostApi(item?.id, i)}
                className="flex-row item-center"
              >
                <View className="h-8 w-8 mt-1">
                  <RemixIcon
                    name="ri-share-forward-line"
                    color={colors.app.f4}
                    size="22"
                  />
                </View>
                <Font className="text-lg text-gray-600">Share</Font>
              </Pressable>
              {isPostId == item?.id ? (
                <CreateGroupPostModal
                  visible={isEditPostModal}
                  onRequestClose={closeEditPostModal}
                  postImage={
                    item?.cover_image_url
                      ? "https://smbexapi.wisdomtooth.tech/api/v2/media/" +
                        item?.cover_image_url
                      : null
                  }
                  Description={item?.description}
                  attachedFileURL={item?.attached_file_url}
                  externalLinkURL={item?.external_link_url}
                  id={item?.id}
                  groupId={id}
                  getGroupPosts={getGroupPosts}
                  // selectedImgObj={selectedImageObj}
                />
              ) : (
                <></>
              )}
            </View>
            {isCommentSectionVisible && isPostId == item?.id ? (
              <CommentSection
                // route={{ params: { slug: id } }}
                id={item?.id}
                groupId={id}
                useId={true}
                navigation={navigation}
                setTabStatus={setTabStatus}
                tabStatus={tabStatus}
              />
            ) : (
              <></>
            )}
          </View>
        </View>
      </View>
    );
  };
  // let MembersLength = MembersList?.length;
  // let MembersLength =2
  return (
    <View className="flex-1 bg-app-e lg:bg-gray-50">
      {/* --------------------------mobile-screen Group Navbar--------------------------- */}
      {isMenuVisible ? (
        <Modal
          visible={isMenuVisible}
          transparent={true}
          onRequestClose={closeMenu}
        >
          <TouchableWithoutFeedback onPress={closeMenu}>
            <View
              style={{
                flex: 1,
                justifyContent: "center",
                alignItems: "center",
              }}
              className="w-full h-full justify-center items-center bg-black/50"
            >
              <TouchableWithoutFeedback>
                <View
                  style={{
                    // position: "absolute",
                    // left: buttonPosition.left,
                    // top: buttonPosition.top,
                    backgroundColor: "white",
                    padding: 10,
                    borderRadius: 10,
                    width: 120,
                    shadowColor: "#000",
                    shadowOffset: {
                      width: 0,
                      height: 2,
                    },
                    shadowOpacity: 0.25,
                    shadowRadius: 3.84,
                    elevation: 5,
                  }}
                >
                  <ScrollView>
                    <Pressable
                      onPress={() => {
                        setIsEditPostModal(true);
                        closeMenu();
                      }}
                      className=" pr-0 flex-1 item center bg-app-a rounded-lg shadow-sm"
                    >
                      <Font className="">Edit Post</Font>
                    </Pressable>
                    <View
                      style={{
                        borderWidth: 0.4,
                        marginVertical: 4,
                      }}
                    ></View>
                    <Pressable
                      onPress={() => {
                        setIsDeleteModal(true);
                        closeMenu();
                      }}
                      className=" pr-0 flex-1 item center bg-app-a rounded-lg shadow-sm"
                    >
                      <Font className="text-app-e">Delete Post</Font>
                    </Pressable>
                  </ScrollView>
                </View>
              </TouchableWithoutFeedback>
            </View>
          </TouchableWithoutFeedback>
        </Modal>
      ) : (
        <></>
      )}
      <ConfirmModal
        visible={deleteModal}
        onCancel={closeDeleteModal}
        onConfirm={deletePost}
        confirmText={"Delete"}
        bodyText={"Are you sure you want to delete Post ?"}
        BodyComponent={false}
      ></ConfirmModal>
      {isPleaseWaitModal && <PleaseWaitModal showLoader={true} />}
      <ResponseModal
        visible={responseModal.visible}
        bodyText={responseModal.message}
        onRequestClose={closeResponseModal}
      ></ResponseModal>
      <View className="p-4 pt-2 w-full flex lg:hidden bg-app-e">
        <View className="w-full flex-col">
          {/* <View className="w-3 h-3">
              <SVGLoader
              src={leftArrow}
              height={40}
              width={40}
                webHeight={"20em"}
                webWidth={"auto"}
                />
            </View> */}
          <View className="flex-row justify-end items-center">
            {/* <View className="w-5 h-5">
                <SVGLoader
                  src={bell}
                  height={40}
                  width={40}
                  webHeight={"20em"}
                  webWidth={"auto"}
                />
              </View> */}
            <View className="w-5 h-5 mb-2 mr-2">
              {/* <SVGLoader
                src={verticalmenu}
                height={30}
                width={25}
                webHeight={"20em"}
                webWidth={"auto"}
              /> */}
            </View>
          </View>
          <EditModal
            navigation={navigation}
            visible={isEditModal}
            onRequestClose={closeEditModal}
            userId={user.id}
            groupId={id}
            name={name}
            description={description}
            activetab={isPrivate == true ? "Private" : "Public"}
            checkRefreshList={checkRefreshList}
          ></EditModal>
          <View className="w-full justify-center items-center">
            <View
              className="mb-2 flex-col rounded-lg"
              style={{ backgroundColor: "rgba(255, 255, 255, 0.5)" }}
            >
              <View className="px-6 py-1 flex-col justify-center items-center">
                <Font className="text-lg text-app-a font-bold">
                  {/* Relax Baby sitting */}
                  {name}
                </Font>
                <Font className="text-sm text-app-a">{description}</Font>
                {/* <Font className="text-sm text-app-a">just relax</Font> */}
              </View>
              <View className="flex-row justify-end">
                <Pressable
                  onPress={() => setIsEditModal(true)}
                  className="px-2 flex-row justify-end items-center rounded-full"
                  style={{ backgroundColor: "rgba(255, 255, 255, 0.5)" }}
                >
                  <Font className="text-xs text-app-a mr-2">Edit</Font>
                  <View className="w-3 h-3 flex justify-center items-center">
                    <SVGLoader
                      src={edit}
                      height={10}
                      width={10}
                      webHeight={"20em"}
                      webWidth={"auto"}
                    />
                  </View>
                </Pressable>
              </View>
            </View>
            <View className="flex-row justify-center item-center">
              <View className="mr-2 w-3 h-3 flex justify-center item-center">
                {/* <SVGLoader
                  src={profilelock}
                  height={20}
                  width={20}
                  webHeight={"20em"}
                  webWidth={"auto"}
                /> */}
                <RemixIcon
                  className=" mt-1 "
                  name={isPrivate == true ? `ri-lock-fill` : `ri-earth-fill`}
                  size={12} // or use Tailwind text-size class
                  color="#ffffff"
                />
              </View>
              <Font className="text-xs text-app-a">
                {isPrivate == true ? "Private" : "Public"} group .{" "}
                {MembersList?.length} members
              </Font>
            </View>
          </View>
        </View>
      </View>

      <ScrollView
        className="rounded-t-2xl bg-gray-50"
        contentContainerStyle={{
          flexGrow: 1,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <View className="max-w-screen-lg w-full h-full">
          {/* ------------------------------------------------lg-screen header--------------------------------------------- */}
          <View className="max-w-screen-lg w-full flex-col justify-center items-center">
            <View className="p-4 pt-2 w-full hidden lg:flex bg-app-e rounded-lg">
              <View className="w-full flex-col">
                <View className="w-full flex-row justify-end items-center">
                  <View className="w-5 h-5 mb-2">
                    <SVGLoader
                      src={verticalmenu}
                      height={40}
                      width={40}
                      webHeight={"20em"}
                      webWidth={"auto"}
                    />
                  </View>
                </View>
                <View className="w-full justify-center items-center">
                  <View
                    className="mb-2 flex-col rounded-lg"
                    style={{ backgroundColor: "rgba(255, 255, 255, 0.5)" }}
                  >
                    <View className="px-10 py-1 flex-col justify-center items-center">
                      <Font className="text-lg text-app-a font-bold">
                        Relax Baby sitting
                      </Font>
                      <Font className="text-sm text-app-a">
                        We care your baby...
                      </Font>
                      <Font className="text-sm text-app-a">just relax</Font>
                    </View>
                    <View className="flex-row justify-end">
                      <View
                        className="px-2 flex-row justify-end items-center rounded-full"
                        style={{ backgroundColor: "rgba(255, 255, 255, 0.5)" }}
                      >
                        <Font className="text-xs text-app-a mr-2">Edit</Font>
                        <View className="w-3 h-3">
                          <SVGLoader
                            src={edit}
                            height={40}
                            width={40}
                            webHeight={"20em"}
                            webWidth={"auto"}
                          />
                        </View>
                      </View>
                    </View>
                  </View>
                  <View className="flex-row justify-center item-center">
                    <View className="mr-2 w-3 h-3 flex justify-center item-center">
                      <SVGLoader
                        src={profilelock}
                        height={40}
                        width={40}
                        webHeight={"20em"}
                        webWidth={"auto"}
                      />
                    </View>
                    <Font className="text-xs text-app-a">
                      Private group . 35 members
                    </Font>
                  </View>
                </View>
              </View>
            </View>

            {/* ------------------------Group-Tabs----------------------------------- */}
            <View className="w-full flex-col rounded-lg">
              <View className="py-4 px-4 w-full flex-row justify-center overflow-scroll">
                <Pressable
                  className={`py-2 px-2 m-1 mr-0.5 lg:m-2 min-w-max  border border-gray-200 rounded-tl-lg rounded-tr-lg rounded-bl-lg rounded-br-lg ${
                    isGroupHandlingTab === "home" ? "bg-app-e" : "bg-none"
                  }`}
                  onPress={() => {
                    setIsGroupHandlingTab("home");
                  }}
                >
                  <Font
                    className={`text font-medium self-center justify-self-center ${
                      isGroupHandlingTab === "home"
                        ? "text-app-a"
                        : "text-app-d1"
                    }`}
                    weight={600}
                  >
                    Home
                  </Font>
                </Pressable>
                <Pressable
                  className={`py-2 px-3 m-1 mx-0.5 lg:m-2 min-w-max flex border border-gray-200  rounded-tl-lg rounded-tr-lg rounded-bl-lg rounded-br-lg ${
                    isGroupHandlingTab === "create-post"
                      ? "bg-app-e"
                      : "bg-none"
                  }`}
                  onPress={() => {
                    setIsGroupHandlingTab("create-post");
                  }}
                >
                  <Font
                    className={`text font-medium self-center justify-self-center ${
                      isGroupHandlingTab === "create-post"
                        ? "text-app-a "
                        : "text-app-d1"
                    }`}
                    weight={600}
                  >
                    Create a Post
                  </Font>
                </Pressable>
                <Pressable
                  className={`py-2 px-3 m-1 mx-0.5 lg:m-2 min-w-max flex border border-gray-200  rounded-tl-lg rounded-tr-lg rounded-bl-lg rounded-br-lg ${
                    isGroupHandlingTab === "members" ? "bg-app-e" : "bg-none"
                  }`}
                  onPress={() => {
                    setIsGroupHandlingTab("members");
                  }}
                >
                  <Font
                    className={`text font-medium self-center justify-self-center ${
                      isGroupHandlingTab === "members"
                        ? "text-app-a "
                        : "text-app-d1"
                    }`}
                    weight={600}
                  >
                    Members
                  </Font>
                </Pressable>
                <Pressable
                  className={`py-2 px-2 m-1 mx-0.5 lg:m-2 min-w-max flex border border-gray-200 rounded-tl-lg rounded-tr-lg rounded-bl-lg rounded-br-lg ${
                    isGroupHandlingTab === "settings" ? "bg-app-e" : "bg-none"
                  }`}
                  onPress={() => {
                    setIsGroupHandlingTab("settings");
                  }}
                >
                  <Font
                    className={`text font-medium self-center justify-self-center ${
                      isGroupHandlingTab === "settings"
                        ? "text-app-a "
                        : "text-app-d1"
                    }`}
                    weight={600}
                  >
                    Settings
                  </Font>
                </Pressable>
              </View>

              {/* ------------------------------Home Tab Section------------------------------ */}

              {isGroupHandlingTab === "home" && (
                <>
                  {/* <View className="p-4 w-full flex-row item-center bg-app-a">
                    <View className="p-2 w-full flex-row item-center">
                      <View className="-ml-4 mr-1 lg:mr-2">
                      <Image
                      source={require("../../../assets/user.jpg")}
                      className="w-8 h-8 lg:w-10 lg:h-10 rounded-full"
                      />
                      </View>
                      <TextInput
                        className="p-1 -mr-1 w-full border border-gray-300 rounded-2xl"
                        placeholder="Write something..."
                        placeholderTextColor="#94a3b8"
                        numberOfLines={4}
                        />
                    </View>
                    </View> */}

                  {/* <View className="p-2 w-full flex-row justify-center item-center border-t border-t-gray-300">
                    <Pressable className="py-1 px-2 lg:px-10 flex-row justify-center item-center bg-app-a rounded-full border border-gray-300">
                    <View className="lg:w-full flex-row lg:justify-between lg:items-center">
                    <View className="flex justify-center items-center">
                    <RemixIcon
                            name="ri-file-text-fill"
                            size={16}
                            color={colors.app.a4}
                            className="mr-2 lg:mr-4 w-4 h-4 lg:w-6 lg:h-6 flex-shrink-0"
                          />
                        </View>
                        <Font className="lg:ml-4 text-sm">File</Font>
                      </View>
                    </Pressable>
                    <Pressable className="py-1 px-2 ml-2 mr-2 lg:px-10 flex-row justify-center item-center bg-app-a rounded-full border border-gray-300">
                      <View className="lg:w-full flex-row lg:justify-between lg:items-center">
                      <View className="mr-2 lg:mr-4 flex justify-center items-center">
                          <RemixIcon
                          name="ri-image-fill"
                            size={16}
                            color={colors.app.b4}
                            className="mr-1 w-4 h-4 lg:w-6 lg:h-6 flex-shrink-0"
                            />
                        </View>
                        <Font className="lg:ml-4 text-sm">Gallery</Font>
                        </View>
                    </Pressable>
                    <Pressable className="py-1 px-2 lg:px-10 flex-row justify-center item-center bg-app-a rounded-full border border-gray-300">
                      <View className="lg:w-full flex-row lg:justify-between lg:items-center">
                        <View className="mr-2 lg:mr-4 flex justify-center items-center">
                        <RemixIcon
                        name="ri-camera-fill"
                        size={16}
                        color={colors.app.c4}
                        className="mr-1 w-4 h-4 lg:w-6 lg:h-6 flex-shrink-0"
                        />
                        </View>
                        <Font className="lg:ml-4 text-sm">Camera</Font>
                        </View>
                    </Pressable>
                  </View> */}

                  {getPostsLoading ? (
                    <ActivityIndicator
                      animating={getPostsLoading}
                      className="self-center justify-self-center flex-1 w-full h-full"
                      color={colors.app.e}
                      size="large"
                    />
                  ) : groupPost?.length == 0 ? (
                    <View className=" w-full flex justify-center items-center">
                      <Font
                        className="text-app-d1 text-xs items-center"
                        weight={500}
                      >
                        No Post
                      </Font>
                    </View>
                  ) : (
                    // groupPost?.map((e, i) => (

                    // ))
                    <FlatList
                      ref={scrollViewRef}
                      data={groupPost}
                      renderItem={renderItem}
                      keyExtractor={(item) => item.id.toString()}
                      // contentContainerStyle={styles.flatListContent}
                      refreshControl={
                        <RefreshControl
                          refreshing={refreshing}
                          onRefresh={onRefresh}
                        />
                      }
                      onEndReached={loadMoreData}
                      onEndReachedThreshold={0.5}
                      ListFooterComponent={() => {
                        console.log(isFetchingMore, "isFetchingMore");
                        // return (
                        //   <ActivityIndicator
                        //     animating={isFetchingMore}
                        //     className="self-center justify-self-center flex-1 w-full h-full"
                        //     color={colors.app.e}
                        //     size="small"
                        //   />
                        // );
                      }}
                      // onScroll={handleScroll}
                    />
                  )}
                </>
              )}
              {/* ---------------------Create Post tab Section -------------------------- */}
              {isGroupHandlingTab === "create-post" && (
                <CreateGroupPost
                  navigation={navigation}
                  groupId={id}
                  checkRefreshListforPosts={checkRefreshListforPosts}
                />
              )}

              {/* -----------------------Group-members Tab Section----------------------------- */}
              {isGroupHandlingTab === "members" && (
                <GroupMembers
                  navigation={navigation}
                  MembersLists={MembersList}
                  id={user.id}
                  groupId={id}
                />
              )}

              {/* -----------------------Group settings tab section------------------------------ */}
              {isGroupHandlingTab === "settings" && (
                <GroupSettings
                  navigation={navigation}
                  groupId={id}
                  checkRefreshList={checkRefreshListforPosts}
                  userId={user.id}
                  isPrivate={isPrivate}
                  groupStatus={groupStatus}
                />
              )}
            </View>
          </View>
          <Footer navigation={navigation} />
        </View>
      </ScrollView>
    </View>
  );
}
