import { Platform, View } from "react-native";
import React from "react";
import { Font } from "../../font";
import { Pressable } from "react-native";
import RemixIcon from "react-native-remix-icon";
import colors from "../../../../colors";

const ServiceCard = ({
  title,
  subtitle,
  price,
  special_price,
  weekend_price,
  onEditButtonPress,
  onDeleteButtonPress,
}) => {
  return (
    <View className="md:min-w-[140rem] md:max-w-[160rem] border border-gray-200 rounded-lg py-4 pr-4 relative">
      <View className="items-center flex-row">
        <View className="h-full absolute w-1 bg-app-e rounded-r"></View>
        <Font
          className="ml-4 text-app-d font-semibold leading-5 text-base mb-px flex-1"
          numberOfLines={2}
          weight={700}
        >
          {title}
        </Font>
        {onEditButtonPress && (
          <Pressable className="mr-2" onPress={onEditButtonPress}>
            <RemixIcon
              name="ri-pencil-fill"
              className="self-start mt-1 ml-2"
              size={20}
              color={colors.app.d2}
            />
          </Pressable>
        )}
        {onDeleteButtonPress && (
          <Pressable onPress={onDeleteButtonPress}>
            <RemixIcon
              name="ri-delete-bin-5-fill"
              className="self-start mt-1 ml-1"
              size={20}
              color={colors.app.d2}
            />
          </Pressable>
        )}
      </View>
      {!!subtitle && (
        <View className="items-center flex-row my-1">
          <Font className="px-4 text-app-d1" numberOfLines={2}>
            {subtitle}
          </Font>
        </View>
      )}
      <View className="items-center flex-row py-1">
        <Font className="ml-4 text-app-d font-semibold" weight={700}>
          Price: {price}
        </Font>
      </View>
      {special_price && (
        <View className="items-center flex-row py-1">
          <Font className="ml-4 text-app-f font-semibold" weight={700}>
            Special Price: {special_price}
          </Font>
        </View>
      )}
      {!!weekend_price && (
        <View className="items-center flex-row py-1">
          <Font className="ml-4 text-app-h font-semibold" weight={700}>
            Weekend Price: {weekend_price}
          </Font>
        </View>
      )}
    </View>
  );
};

export default ServiceCard;

{
  /* <View
  className={`rounded-md bg-app-c3 border border-app-c1 p-4 mb-4 ${
    Platform.OS === "web"
      ? "min-w-[208px] max-w-[328px]"
      : "min-w-[60vw] w-fit max-w-[83vw]"
  }`}
>
  <View className="flex-row items-start relative">
    {/* <View className="h-6 absolute w-1 bg-app-e  rounded-r"></View> *
    <Font
      weight={700}
      className="font-semibold text-base flex-1"
      numberOfLines={2}
    >
      {title}
    </Font>
    {!!onEditButtonPress && (
      <Pressable className="mr-2" onPress={onEditButtonPress}>
        <RemixIcon
          name="ri-pencil-fill"
          className="self-start mt-1 ml-2"
          size={20}
          color={colors.app.d2}
        />
      </Pressable>
    )}
    {!!onDeleteButtonPress && (
      <Pressable onPress={onDeleteButtonPress}>
        <RemixIcon
          name="ri-delete-bin-5-fill"
          className="self-start mt-1 ml-1"
          size={20}
          color={colors.app.d2}
        />
      </Pressable>
    )}
  </View>
  <Font className="py-1">{subtitle}</Font>
  <Font weight={600} className="font-semibold">
    {price}
  </Font>
</View>; */
}
