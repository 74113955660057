import AsyncStorage from "@react-native-async-storage/async-storage";
import React, { createContext, useState } from "react";

export const AuthContext = createContext({
  isLoading: false,
  setIsLoading: () => null,
  token: null,
  user: null,
  setUser: () => null,
  error: null,
  reset: () => null,
});

export const AuthProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [user, setUser] = useState(null);
  const [token, setToken] = useState();
  const [error, setError] = useState(null);

  React.useLayoutEffect(() => {
    (async () =>
      await AsyncStorage.getItem("ut", (error, result) => {
        if (error) {
          console.log("error", error);
        } else {
          setToken(result);
        }
      }))();
  }, []);

  return (
    <AuthContext.Provider
      value={{
        isLoading: isLoading,
        setIsLoading: setIsLoading,
        token,
        user,
        setUser,
        error,
        reset: () => {
          setUser(null);
          setToken();
          setError(null);
        },
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => React.useContext(AuthContext);
