import React from "react";
import { Image, Pressable, View } from "react-native";
import { ScrollView } from "react-native";
import { Font } from "./font";

export const Gallery = ({ className, style, images, resizeMode }) => {
  const [imageIndex, setImageIndex] = React.useState(0);
  const [imageList, setImageList] = React.useState([]);

  React.useMemo(() => {
    setImageList(
      images
        ? images
        : [
            require("../../assets/bg.jpg"),
            require("../../assets/bip3.jpg"),
            require("../../assets/bg.jpg"),
            require("../../assets/bip3.jpg"),
            require("../../assets/bg.jpg"),
            require("../../assets/bip3.jpg"),
          ]
    );
  }, [images]);
  React.useEffect(() => {
    let ci = 0;
    let i = setInterval(() => {
      ci = ci < imageList.length - 1 ? ci + 1 : 0;
      setImageIndex(ci);
    }, 5000);
    return () => clearInterval(i);
  }, []);

  console.log("images:", images);

  return (
    <>
      {images?.length ? (
        <View className={`flex-col lg:flex-row ${className}`} style={style}>
          <View className="flex-1 h-auto lg:h-full rounded-lg overflow-hidden">
            <Image
              source={imageList[imageIndex]}
              className="w-full h-full"
              resizeMode={resizeMode}
            ></Image>
          </View>
          <View className="w-full lg:w-28 h-20 lg:h-full p-1">
            <ScrollView
              className="w-full h-full  hidden lg:flex"
              showsHorizontalScrollIndicator={false}
            >
              {imageList.map((e, i) => (
                <Pressable
                  className="p-1 w-full h-24"
                  onPress={() => setImageIndex(i)}
                  key={i}
                >
                  <View className="flex-1 overflow-hidden rounded">
                    <Image
                      source={e}
                      className="w-full h-full object-cover"
                    ></Image>
                    {imageIndex === i ? (
                      <View className="absolute w-full h-full left-0 right-0 bg-app-d1"></View>
                    ) : null}
                  </View>
                </Pressable>
              ))}
            </ScrollView>
            <ScrollView
              className="w-full h-full flex lg:hidden p-1"
              horizontal
              showsHorizontalScrollIndicator={false}
            >
              {imageList.map((e, i) => (
                <Pressable
                  className="p-1 w-16 h-full "
                  onPress={() => setImageIndex(i)}
                  key={i}
                >
                  <View className="flex-1 overflow-hidden rounded">
                    <Image source={e} className="w-full h-full"></Image>

                    {imageIndex === i ? (
                      <View className="absolute w-full h-full left-0 right-0 bg-app-d1"></View>
                    ) : null}
                  </View>
                </Pressable>
              ))}
            </ScrollView>
          </View>
        </View>
      ) : (
        <View className="w-full h-full justify-center items-center border rounded-lg border-app-c bg-app-a">
          <Font className="text-app-d2">No Image(s) Found</Font>
        </View>
      )}
    </>
  );
};
