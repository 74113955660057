import React from "react";
import { isSignedIn, validateStripeStatus } from "../actions/auth-actions";

const INITIAL_STATE = {
  stripeStatus: {
    isUserStripeValidated: true,
    isLoading: false,
    stripeUrl: "",
    stripeMessage: "",
    isConfirmToValidate: false,
  },
  allowAddStripeBankAccount: true,
  setAllowAddStripeBankAccount: () => null,
  validateStripeUserBankAccountStatus: () => null,
  reset: () => null,
};

const StripeReducer = (state, action) => {
  switch (action.type) {
    case "SET_ADD_STRIPE_BANK_ACCOUNT_STATUS":
      return { ...state, allowAddStripeBankAccount: action.payload };
    case "RESET_STRIPE_STATES":
      return INITIAL_STATE;
    default:
      return state;
  }
};

export const StripeContext = React.createContext(INITIAL_STATE);

export const StripeContextProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(StripeReducer, INITIAL_STATE);
  const [stripeStatus, setStripeStatus] = React.useState(
    INITIAL_STATE.stripeStatus
  );

  const validateStripeUserBankAccountStatus = React.useCallback(
    async (allowAddStripeBankAccount) => {
      if (!(allowAddStripeBankAccount || state.allowAddStripeBankAccount)) {
        return;
      }
      setStripeStatus({
        ...stripeStatus,
        isLoading: false,
        stripeUrl: "",
        isUserStripeValidated: true,
        isConfirmToValidate: false,
      });
      const checkStripeRes = await validateStripeStatus();
      if (checkStripeRes?.status) {
        if (checkStripeRes.status == 200 && checkStripeRes.data) {
          const { data } = checkStripeRes.data;
          if (data && data?.account_reference) {
            const { onboarding_status } = data.account_reference;
            if (onboarding_status == "completed") {
              return data;
            }
            if (onboarding_status == "initiated") {
              setStripeStatus({
                ...stripeStatus,
                isUserStripeValidated: false,
                isConfirmToValidate: false,
                stripeMessage:
                  "Continue where you left off with your Bank Account Setup?",
              });
            }
          }
        }
        if (checkStripeRes.status == 404) {
          setStripeStatus({
            ...stripeStatus,
            isUserStripeValidated: false,
            isConfirmToValidate: false,
            stripeMessage: `${checkStripeRes.status}: ${checkStripeRes.data?.message}\nPlease provide your Bank Account details to start accepting payouts from us`,
          });
        }
      }
    },
    [state.allowAddStripeBankAccount]
  );

  return (
    <StripeContext.Provider
      value={{
        stripeStatus,
        setStripeStatus,
        validateStripeUserBankAccountStatus,
        allowAddStripeBankAccount: state.allowAddStripeBankAccount,
        setAllowAddStripeBankAccount: (allow) => {
          console.log("setAllowAddStripeBankAccount:", allow);
          dispatch({
            type: "SET_ADD_STRIPE_BANK_ACCOUNT_STATUS",
            payload: allow,
          });
        },
        reset: () => {
          setStripeStatus(INITIAL_STATE.stripeStatus);
          dispatch({ type: "RESET_STRIPE_STATES" });
        },
      }}
    >
      {children}
    </StripeContext.Provider>
  );
};

export const useStripeContext = () => React.useContext(StripeContext);
