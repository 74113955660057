export const appURI =
  process.env.APP_URI || "https://smbexvendor.wisdomtooth.tech";

export const apiURI =
  process.env.API_URI || "https://smbexapi.wisdomtooth.tech/api";

export const apiStripeURI =
  process.env.STRIPE_API_URI_V2 || "https://smbexapi.wisdomtooth.tech/api";

export const apiURIV1 = (process.env.API_URI_V1 || apiURI) + "/v1";

export const apiURIV2 = (process.env.API_URI_V2 || apiURI) + "/v2";

export const stripeAPIURIV2 = (apiStripeURI) + "/v2";
export const mediaURI = (process.env.MEDIA_URI || apiURIV2) + "/media/";

export const socketIOURI = process.env.SOCKET_IO_URI;

export const stripePublishableKey =
  "pk_test_51Na0h4KR0bxm2aiIo55yTzjU25gHtevCfVpPNtOUa8R368nxsZxJt8Bbr2iGqprTabsONFKTgS7u1buGsid9AhhY00NbQgpbtK";

export const merchantIdentifier = "merchant.com.Vendor";
