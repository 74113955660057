import React from "react";
import { View, Pressable, Image, FlatList } from "react-native";
import RemixIcon from "react-native-remix-icon";
import * as ImagePicker from "expo-image-picker";
import { Font } from "./font";
import { Platform } from "react-native";
import { fileURLtoBlob } from "../utils/encDec";
import colors from "../../colors";

export const Galleryupload = ({
  images,
  onAddImage = () => null,
  onRemoveImage = () => null,
  reset,
  onReset,
}) => {
  let [imagesList, setImagesList] = React.useState();
  const removeImage = (i) => {
    let ar = imagesList;
    const [removedImage] = ar.splice(i, 1);
    setImagesList([...ar]);
    onRemoveImage(removedImage);
  };
  const pickImage = async () => {
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.All,
      allowsEditing: true,
      aspect: [1, 1],
      quality: 1,
      // base64: true,
    });

    console.log(result);

    if (!result.canceled) {
      const id = Date.now() + "-local";
      setImagesList((e) => [...(e || []), { id: id, ...result.assets[0] }]);
      let imageObject = result.assets[0];
      imageObject.id = id;
      if (Platform.OS === "ios") {
        imageObject.uri = decodeURIComponent(
          imageObject.uri.replace("file://", "")
        );
      }
      fileURLtoBlob(imageObject.uri).then((data) => {
        console.log("blob", data);
        imageObject.blob = data;
        console.log("imageObject", imageObject);
        onAddImage(imageObject);
      });
    }
  };

  if (!imagesList && images) {
    // after first init imageList will always be [] (empty array)
    setImagesList(images);
  }

  const resetGalleryHandler = React.useCallback(() => {
    onReset();
  }, [onReset]);

  React.useEffect(() => {
    if (reset) {
      resetGalleryHandler();
      setImagesList(images);
    }
  }, [reset, images, resetGalleryHandler]);

  return (
    <View className="w-full flex-row">
      <FlatList
        bounces={true}
        data={imagesList}
        ListFooterComponent={() => (
          <View>
            <Pressable
              className="w-28 lg:w-36 h-32 lg:h-44 rounded-lg border border-gray-200 overflow-hidden bg-app-a"
              onPress={pickImage}
            >
              <View className="w-full h-28 flex-1 justify-center items-center">
                <RemixIcon name="add-line" size={28} color={colors.app.d1} />
              </View>
              <View className="justify-center items-center p-1 lg:p-2 border-t border-gray-200">
                <Font className="text-app-d" weight={500}>Upload</Font>
              </View>
            </Pressable>
          </View>
        )}
        ListFooterComponentStyle={{ marginStart: 10 }}
        ItemSeparatorComponent={() => <View style={{ marginEnd: 10 }} />}
        contentContainerStyle={{ paddingEnd: 10 }}
        horizontal
        showsHorizontalScrollIndicator={false}
        renderItem={({ item: e, index: i }) => (
          <View key={i}>
            <View className="w-28 lg:w-36 h-32 lg:h-44 rounded-lg border border-gray-200 overflow-hidden bg-app-a">
              <Image source={e} className="w-full h-28 object-cover flex-1" />
              <Pressable
                onPress={() => removeImage(i)}
                className="justify-center items-center p-1 lg:p-2 border-t border-gray-200"
              >
                <Font weight={500} className="text-app-e">
                  Remove
                </Font>
              </Pressable>
            </View>
          </View>
        )}
      />
    </View>
  );
};
