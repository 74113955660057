import React, { useState } from "react";
import { ScrollView, View } from "react-native";
import { Button, Input, Select } from "../../components/input";
import { Card } from "../../components/general/cards/card";
import useInputHandler from "../../utils/useInputHandler";
import { useCouponsContext } from "../../contexts/coupons-context";
import { isSignedIn } from "../../actions/auth-actions";
import axiosInstance from "../../constants/axios-config";
import { apiURIV2 } from "../../constants";
import ResponseModal from "../../components/general/modals/ResponseModal";
import { catchAPIErrorText } from "../../utils/error-handler";
// import DatePicker from "react-native-date-picker";

export default function AddCoupons({ navigation, editing }) {
  const [selectType, setSelectType] = useState();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [date, setDate] = useState(new Date());
  const [open, setOpen] = useState(false);
  const [showResponseModal, setShowResponseModal] = useState({
    visible: false,
    onRequestClose: () => null,
    bodyText: "",
  });

  const { coupons, setCoupons, pagination, setPagination } =
    useCouponsContext();

  // const { screen } = useSharedContext();

  // const [dateTimeDown, setDateTimeDown] = useState({
  //   from: new Date(),
  //   to: new Date(),
  // });

  // const handleDateChange = (date, field) => {
  //   setDateTimeDown((prev) => ({
  //     ...prev,
  //     [field]: date,
  //   }));
  // };

  // const [dateTime, setDateTime] = useState(new Date());

  // const handleDateTimeChange = (event, newDateTime) => {
  //   setDateTime(newDateTime);
  // };

  const {
    inputValue: codeInputValue,
    currentValue: codeValue,
    onValueChangeHandler: onChangeCodeText,
    onBlurHandler: onBlurCode,
    hasError: codeHasError,
    reset: resetCode,
  } = useInputHandler({ required: true });

  const {
    inputValue: minimumOrderAmountRequiredInputValue,
    currentValue: minimumOrderAmountRequiredValue,
    onValueChangeHandler: onChangeMinimumOrderAmountRequiredText,
    onBlurHandler: onBlurMinimumOrderAmountRequired,
    hasError: minimumOrderAmountRequiredHasError,
    reset: resetMinimumOrderAmountRequired,
  } = useInputHandler({ required: true });

  const {
    inputValue: typeInputValue,
    currentValue: typeValue,
    onValueChangeHandler: onChangeType,
    onBlurHandler: onBlurType,
    hasError: typeHasError,
    reset: resetType,
  } = useInputHandler({ required: true });

  const {
    inputValue: flatDiscountInputValue,
    currentValue: flatDiscountValue,
    onValueChangeHandler: onChangeFlatDiscountText,
    onBlurHandler: onBlurFlatDiscount,
    hasError: flatDiscountHasError,
    reset: resetFlatDiscount,
  } = useInputHandler({ required: true });

  const {
    inputValue: discountPercentageMultiplierInputValue,
    currentValue: discountPercentageMultiplierValue,
    onValueChangeHandler: onChangeDiscountPercentageMultiplierText,
    onBlurHandler: onBlurDiscountPercentageMultiplier,
    hasError: discountPercentageMultiplierHasError,
    reset: resetDiscountPercentageMultiplier,
  } = useInputHandler({ required: true });

  const {
    inputValue: maxDiscountInputValue,
    currentValue: maxDiscountValue,
    onValueChangeHandler: onChangeMaxDiscountText,
    onBlurHandler: onBlurMaxDiscount,
    hasError: MaxDiscountHasError,
    reset: resetMaxDiscount,
  } = useInputHandler({ required: true });

  const {
    inputValue: expiresAtInputValue,
    currentValue: expiresAtValue,
    onValueChangeHandler: onChangeExpiresAtText,
    onBlurHandler: onBlurExpiresAt,
    hasError: expiresAtHasError,
    reset: resetExpiresAt,
  } = useInputHandler({ required: true });

  const resetFormData = React.useCallback(
    ({ resetOtherFormData }) => {
      if (resetOtherFormData) {
        resetCode();
        resetMinimumOrderAmountRequired();
        resetType();
        resetFlatDiscount();
        resetDiscountPercentageMultiplier();
        resetMaxDiscount();
        resetExpiresAt();
      }
    },
    [
      resetCode,
      resetMinimumOrderAmountRequired,
      resetType,
      resetFlatDiscount,
      resetDiscountPercentageMultiplier,
      resetMaxDiscount,
      resetExpiresAt,
    ]
  );

  const onSubmitListingData = () => {
    let updateObj = {};
    updateObj.code = codeInputValue;
    updateObj.minimum_order_amount_required =
      minimumOrderAmountRequiredInputValue;
    updateObj.type = typeInputValue;
    if (typeValue === "percent_discount") {
      updateObj.discount_percentage_multiplier =
        discountPercentageMultiplierInputValue / 100;
      updateObj.max_discount = maxDiscountInputValue;
      updateObj.flat_discount = null;
    }
    if (typeValue === "flat_discount") {
      updateObj.flat_discount = flatDiscountInputValue;
      updateObj.discount_percentage_multiplier = null;
      updateObj.max_discount = null;
    }
    updateObj.expires_at = expiresAtInputValue;

    const addCoupons = async () => {
      try {
        setIsSubmitting(true);
        const token = await isSignedIn();
        const res = await axiosInstance.post(
          apiURIV2 + "/coupons/",
          updateObj,
          {
            headers: {
              Authorization: "Bearer " + token,
              "Content-Type": "application/json",
            },
          }
        );
        console.log("res.status: ", res.status, res.data);
        if (res.status === 201) {
          if (coupons?.length) {
            setCoupons({ data: res.data.data, push: true });
          }
          setShowResponseModal({
            visible: true,
            onRequestClose: () => {
              setShowResponseModal({
                visible: false,
                onRequestClose: () => null,
                bodyText: "",
              });
              navigation.replace("coupons");
            },
            bodyText: `${updateObj.code} Coupon Code has been Successfully Added`,
          });
        }
        resetFormData({
          resetOtherFormData: true,
        });
      } catch (e) {
        console.log("Value of 'e':", e);
        console.log(
          "e.res",
          e?.response,
          "\n\n",
          e?.response?.status,
          "\n\n",
          e?.response?.data,
          "\n\n",
          e.toJSON()
        );

        setShowResponseModal({
          visible: true,
          onRequestClose: () => {
            setShowResponseModal({
              visible: false,
              onRequestClose: () => null,
              bodyText: "",
            });
          },
          bodyText: catchAPIErrorText(e, "Unknown Error while Adding coupon"),
        });
      } finally {
        setIsSubmitting(false);
      }
    };
    addCoupons();
  };

  return (
    <ScrollView
      className="rounded-t-2xl"
      overScrollMode="never"
      contentContainerStyle={{
        flexGrow: 1,
        paddingHorizontal: 16,
        paddingBottom: 16,
        justifyContent: "flex-start",
        alignItems: "center",
      }}
    >
      {showResponseModal.visible ? (
        <ResponseModal
          visible={showResponseModal.visible}
          onRequestClose={showResponseModal.onRequestClose}
          bodyText={showResponseModal.bodyText}
        />
      ) : (
        <></>
      )}
      <Card title="Add Coupon" className="mt-4">
        <View className="flex-row flex-wrap px-2 w-full">
          <View className="px-2 w-full lg:w-1/3 pb-4">
            <Input
              type="text"
              label="Code"
              value={codeValue}
              onChangeText={onChangeCodeText}
              onBlur={onBlurCode}
              note={codeHasError}
            />
          </View>
          <View className="px-2 w-full lg:w-1/3 pb-4">
            <Input
              type="number"
              label="Minimum Order Amount For Discount"
              value={minimumOrderAmountRequiredValue}
              onChangeText={onChangeMinimumOrderAmountRequiredText}
              onBlur={onBlurMinimumOrderAmountRequired}
              leftText={"$"}
              note={minimumOrderAmountRequiredHasError}
            />
          </View>
          <View className="px-2 w-full lg:w-1/3 pb-4">
            <Select
              label="Type"
              options={[
                { label: "Flat Discount", value: "flat_discount" },
                { label: "Percent Discount", value: "percent_discount" },
              ]}
              placeholder="Select type"
              value={typeValue}
              setValue={onChangeType}
              note={typeHasError}
            />
          </View>
          {typeValue === "flat_discount" && (
            <View className="px-2 w-full lg:w-1/3 pb-4">
              <Input
                type="number"
                label="Flat Discount"
                value={flatDiscountValue}
                onChangeText={onChangeFlatDiscountText}
                onBlur={onBlurFlatDiscount}
                leftText={"$"}
                note={flatDiscountHasError}
              />
            </View>
          )}
          {typeValue === "percent_discount" && (
            <View className="px-2 w-full lg:w-1/3 pb-4">
              <Input
                type="number"
                label="Discount Percentage"
                value={discountPercentageMultiplierValue}
                onChangeText={onChangeDiscountPercentageMultiplierText}
                onBlur={onBlurDiscountPercentageMultiplier}
                note={discountPercentageMultiplierHasError}
                rightText={"%"}
                infoButtonText="Decimal multiplier value for Discount in Percentage. For Example - 1% => 1 x '0.1' = 0.1"
              />
            </View>
          )}
          {typeValue === "percent_discount" && (
            <View className="px-2 w-full lg:w-1/3 pb-4">
              <Input
                type="number"
                label="Max Discount"
                value={maxDiscountValue}
                onChangeText={onChangeMaxDiscountText}
                onBlur={onBlurMaxDiscount}
                note={MaxDiscountHasError}
                leftText={"$"}
                infoButtonText="will not be there when its flat discount, might needed in discount_percentage_multiplier"
              />
            </View>
          )}
          <View className="px-2 w-full lg:w-1/3 pb-4">
            <Input
              label="Expires on Date and Time"
              type="datetime"
              value={expiresAtValue}
              setValue={onChangeExpiresAtText}
              onBlur={onBlurExpiresAt}
              note={expiresAtHasError}
            />
          </View>
        </View>
      </Card>
      <View className="w-full flex-row justify-center lg:justify-end y-4">
        <View className="w-full lg:w-60">
          <Button
            label={isSubmitting ? "Please Wait" : "Create Coupon"}
            type="primary"
            onPress={onSubmitListingData}
          ></Button>
        </View>
      </View>
    </ScrollView>
  );
}
