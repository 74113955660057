import React from "react";
import moment from "moment";
import { ScrollView, View, Text, Image, Pressable } from "react-native";
import RemixIcon from "react-native-remix-icon";
import colors from "../../colors";
import { Font } from "./font";
import { navigate } from "../utils/navigation-util";
import { DefaultImages } from "../constants/predefined-constants";

// MVP
export function LatestArticles({ data }) {
  return (
    <ScrollView
      horizontal
      className="flex-1 w-full p-2 pb-4 pt-0 gap-4"
      // showsHorizontalScrollIndicator={false}
    >
      {[...Array(5).keys()].map((e, i) => (
        <Pressable
          className="w-80 h-88 rounded-lg overflow-hidden border border-gray-200"
          key={i}
          onPress={() => navigate({ name: "post", params: { slug: "sample" } })}
        >
          <View className="h-32 w-full">
            <Image
              source={require("../../assets/banner.jpg")}
              className="w-full h-full"
            ></Image>
          </View>
          <View className="px-2 py-4">
            <View
              className="flex-row items-center  mb-1 "
              style={{ marginVertical: 2 }}
            >
              <Font className="text-sm text-app-d1 leading-4" numberOfLines={2}>
                Tips and trick
              </Font>
            </View>
            <Font
              className="text-md text-app-d mb-1 leading-5"
              weight={500}
              numberOfLines={2}
            >
              Ancol Attractions FAQ: Find answers to your questions about Ancol
              attractions tickets
            </Font>
          </View>
        </Pressable>
      ))}
    </ScrollView>
  );
}
export function FeaturedProducts() {
  return (
    <ScrollView
      horizontal
      className="flex-1 w-full p-2 pb-4 pt-0 gap-4"
      // showsHorizontalScrollIndicator={false}
    >
      {[...Array(10).keys()].map((e, i) => (
        <Pressable
          className="w-56 h-88 rounded-lg overflow-hidden border border-gray-200"
          key={i}
          onPress={() => navigate("product")}
        >
          <View className="h-56 w-full">
            <Image
              source={require("../../assets/bip3.jpg")}
              className="w-full h-full"
            ></Image>
          </View>
          <View className="px-2 py-4">
            <Font
              className="text-md text-app-d mb-1 leading-5"
              weight={500}
              numberOfLines={2}
            >
              Amazfit Bip 3 Pro Smartwatch 1.69&quot; Touch Screen, GPS jam
              tangan SpO2 - Merah Muda
            </Font>

            <View
              className="flex-row items-center"
              style={{ marginVertical: 2 }}
            >
              <Font
                className="text-[15px] text-app-d"
                weight={700}
                numberOfLines={1}
              >
                {" "}
                $ 99.00{" "}
              </Font>
              <Font
                className="text-md text-app-e1 line-through ml-2"
                numberOfLines={1}
              >
                {" "}
                $ 150.00
              </Font>
            </View>
            <View
              className="flex-row items-center"
              style={{ marginVertical: 2 }}
            >
              <RemixIcon
                name="star-fill"
                size={16}
                color={colors.app.i}
                className="mr-1 flex-shrink-0"
              ></RemixIcon>
              <Font className="text-md text-app-d1" numberOfLines={1}>
                {" "}
                4.8 | 100 reviews
              </Font>
            </View>
          </View>
        </Pressable>
      ))}
    </ScrollView>
  );
}
export function FeaturedListing() {
  return (
    <ScrollView
      horizontal
      className="flex-1 w-full p-2 pb-4 pt-0 gap-4"
      // showsHorizontalScrollIndicator={false}
    >
      {[...Array(10).keys()].map((e, i) => (
        <Pressable
          className="w-56 h-88 rounded-lg overflow-hidden border border-gray-200"
          key={i}
          onPress={() => navigate("listing")}
        >
          <View className="h-56 w-full">
            <Image
              source={require("../../assets/water.jpg")}
              className="w-full h-full"
            ></Image>
            <View className="p-1 px-4 w-min rounded-full bg-blue-500 items-center justify-center self-start absolute top-2 left-2">
              <Font className="text-app-a text-xs">Stay</Font>
            </View>
          </View>
          <View className="px-2 py-4">
            <Font
              className="text-md text-app-d  leading-5"
              weight={500}
              numberOfLines={2}
            >
              Tommy&rsquo;s Italian Restaurant
            </Font>
            <View
              className="flex-row items-center  mb-1 "
              style={{ marginVertical: 2 }}
            >
              <Font className="text-sm text-app-d1 leading-4" numberOfLines={2}>
                Washington, DC 20008, United States
              </Font>
            </View>

            <View
              className="flex-row items-center"
              style={{ marginVertical: 2 }}
            >
              <Font
                className="text-[15px] text-app-d"
                weight={700}
                numberOfLines={1}
              >
                {" "}
                $ 99.00{" -  "}$ 150.00{" "}
              </Font>
            </View>
            <View
              className="flex-row items-center"
              style={{ marginVertical: 2 }}
            >
              <RemixIcon
                name="star-fill"
                size={16}
                color={colors.app.i}
                className="mr-1 flex-shrink-0"
              ></RemixIcon>
              <Font className="text-md text-app-d1  ml-1" numberOfLines={1}>
                4.8 | 100 reviews
              </Font>
            </View>
          </View>
        </Pressable>
      ))}
    </ScrollView>
  );
}
export function UserReviews() {
  return (
    <ScrollView
      horizontal
      className="flex-1 w-full p-2"
      // showsHorizontalScrollIndicator={false}
    >
      {[...Array(10).keys()].map((e, i) => (
        <View className="p-2" key={i}>
          <Pressable
            className="w-56 rounded-lg overflow-hidden border border-gray-200 p-4"
            key={i}
            onPress={() => navigate("listing")}
          >
            <View className="flex-row">
              <Image
                source={DefaultImages.DUMMY_USER_DP}
                className="w-10 h-10 rounded-full"
              ></Image>
              <View className="px-2 justify-center">
                <Font weight={500} className="text-app-d">
                  John Doe
                </Font>
                <Font weight={500} className="text-app-d1 text-xs">
                  3 months ago
                </Font>
              </View>
            </View>
            <View className="flex-row py-2">
              <RemixIcon
                name="star-fill"
                size={18}
                color={colors.app.h}
              ></RemixIcon>
              <RemixIcon
                name="star-fill"
                size={18}
                color={colors.app.h}
              ></RemixIcon>
              <RemixIcon
                name="star-fill"
                size={18}
                color={colors.app.h}
              ></RemixIcon>
              <RemixIcon
                name="star-fill"
                size={18}
                color={colors.app.h}
              ></RemixIcon>
              <RemixIcon
                name="star-fill"
                size={18}
                color={colors.app.d3}
              ></RemixIcon>
            </View>
            <View>
              <Font className="text-app-d">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry&rsquo;s standard
                dummy text ever since the 1500s,{" "}
              </Font>
            </View>
          </Pressable>
        </View>
      ))}
    </ScrollView>
  );
}
