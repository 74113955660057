import React from "react";
import { TouchableOpacity } from "react-native";
import { View } from "react-native";
import { Modal } from "react-native";
import { Font } from "../../font";

const ResponseModal = ({
  visible,
  onRequestClose,
  animationType = "fade",
  bodyText,
  dismissText,
}) => {
  return (
    <Modal
      animationType={animationType}
      transparent={true}
      visible={visible}
      onRequestClose={onRequestClose}
    >
      <View className="flex-1 justify-center items-center bg-black/50">
        <View className="w-80 bg-app-a border border-app-c min-h-[160px] max-h-full rounded-lg justify-center items-center -mt-12">
          <View className="grow justify-center items-center py-4 px-4">
            <Font className="text-base font-medium" weight={600}>
              {bodyText}
            </Font>
          </View>
          <View className="w-full h-[1px] bg-gray-200"></View>
          <TouchableOpacity
            className="py-3 w-full rounded-bl-lg rounded-br-lg"
            onPress={onRequestClose}
            style={{ outline: "none" }}
          >
            <Font
              className="text-base text-center leading-4 text-app-d font-medium self-center justify-self-center"
              weight={600}
            >
              {dismissText || "Dismiss"}
            </Font>
          </TouchableOpacity>
        </View>
      </View>
    </Modal>
  );
};

export default ResponseModal;
