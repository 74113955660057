import React, { useContext, useEffect, useState } from "react";
import {
  ActivityIndicator,
  Pressable,
  ScrollView,
  StyleSheet,
  Text,
  View,
} from "react-native";
import RemixIcon from "react-native-remix-icon";
import colors from "../../../colors";
import { Card } from "../../components/card";
import { Font } from "../../components/font";
import { Footer } from "../../components/footer";
import { Button, Input } from "../../components/input";
import { DEV_API, getPublicData } from "../../services/api";
import { TouchableOpacity } from "react-native";
import { RectButton } from "react-native-gesture-handler";
import * as Contacts from "expo-contacts";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { AuthContext } from "../../contexts/auth-context";
import axiosInstance from "../../constants/axios-config";
import { apiURIV2 } from "../../constants";
import { isSignedIn } from "../../actions/auth-actions";
import ResponseModal from "../../components/general/modals/ResponseModal";
import { catchAPIErrorText } from "../../utils/error-handler";

export const InviteContact = ({ navigation, route }) => {
  const [contacts, setContacts] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const { user } = useContext(AuthContext);
  const [yourContactsToggleButton, setYourcontactToggleButton] =
    useState(false);

  const [showResponseModal, setShowResponseModal] = React.useState({
    visible: false,
    onRequestClose: () => null,
    bodyText: "",
  });

  // const [inValue ,setInValue] = useState(contact.selected)

  useEffect(() => {
    fetchContacts();
  }, []);

  useEffect(() => {
    fetchInvitedContacts();
  }, []);

  const fetchContacts = async () => {
    const { status } = await Contacts.requestPermissionsAsync();
    if (status === "granted") {
      const { data } = await Contacts.getContactsAsync();
      if (data.length > 0) {
        setContacts(
          data.map((contact) => ({
            ...contact,
            selected: false,
          }))
        );
      }
    }
    setLoaded(true);
    setYourcontactToggleButton(!yourContactsToggleButton);
  };

  const fetchInvitedContacts = async () => {
    try {
      const invitedContactIds = await AsyncStorage.getItem("invitedContacts");
      if (invitedContactIds) {
        const updatedContacts = contacts.filter(
          (contact) => !invitedContactIds.includes(contact.id)
        );
        setContacts(updatedContacts);
      }
    } catch (error) {
      console.error("Error fetching invited contacts", error);
    }
  };

  const handleCheckboxChange = (contactId) => {
    setContacts((prevContacts) =>
      prevContacts.map((contact) => {
        if (contact.id === contactId) {
          return {
            ...contact,
            selected: !contact.selected,
          };
        }
        return contact;
      })
    );
  };

  const handleInviteButtonPress = () => {
    const selectedContacts = contacts.filter((contact) => contact.selected);
    sendInvitation(selectedContacts);
  };

  const sendInvitation = async (selectedContacts) => {
    const formattedContacts = selectedContacts.map((contact) => ({
      name: contact.name,
      mobile:
        contact.phoneNumbers && contact.phoneNumbers.length > 0
          ? contact.phoneNumbers[0].number
          : "",
    }));
    console.log(formattedContacts);
    const apiEndpoint = apiURIV2 + "/invites";
    const userToken = await isSignedIn();
    const requestOptions = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
      },
    };

    try {
      const response = await axiosInstance.post(
        apiEndpoint,
        { contacts: formattedContacts },
        requestOptions
      );
      // console.log(response);
      // console.log(response.ok)
      if (response.status === 200) {
        const invitedContactIds = selectedContacts.map((contact) => contact.id);
        await AsyncStorage.setItem(
          "invitedContacts",
          JSON.stringify(invitedContactIds)
        );
        const updatedContacts = contacts.filter(
          (contact) => !invitedContactIds.includes(contact.id)
        );
        setContacts(updatedContacts);
        console.log("Invitation sent successfully");
        // console.warn("success");
        //
        // const updatedContacts = contacts.filter
      }
      // else {
      //   console.error("Error sending invitation 1:", response.status);
      // }
    } catch (error) {
      console.error("Error sending invitation 2:", error);
      setShowResponseModal({
        visible: true,
        onRequestClose: () => {
          setShowResponseModal({
            visible: false,
            onRequestClose: () => null,
            bodyText: "",
          });
        },
        bodyText: catchAPIErrorText(
          e,
          "Unknown Error while Sending Invitation"
        ),
      });
    }
  };

  // if(contacts.length === 0){
  //   return <Text>No Contacts</Text>
  // }

  const [showAll, setShowAll] = useState(false);

  const toggleShowAll = () => {
    setShowAll(!showAll);
  };

  const renderContacts = () => {
    if (showAll) {
      return contacts.map((contact) => (
        <View
          key={contact.id}
          className="py-4 border-b border-gray-200 flex-row items-center w-full"
        >
          {console.log(contact)}
          <View className="flex-1">
            <Font>{contact.name}</Font>
            <Font>{contact?.phoneNumbers?.[0]?.number}</Font>
          </View>
          {/* {contact.registered ? null : <Text style={styles.warning} >Pending Registration</Text>} */}
          <View className="w-8">
            <Input
              value={contact.selected}
              setValue={(e) => handleCheckboxChange(contact.id)}
              // tintColors={{true:'red'}}
              // style={styles.checkbox}
              type="checkbox"
              // onValueChange={() => {console.log("eddsdsd") ; handleCheckboxChange(contact.id)}}

              // value={contact.checked}
              // onValueChange={() => handleCheckBoxToggle(contact.id)}
            />
          </View>
        </View>
      ));
    } else if (!loaded) {
      return (
        <Text style={{ padding: 10, textAlign: "center", fontSize: 16 }}>
          Loading...
        </Text>
      );
    } else if (contacts.length === 0) {
      return (
        <Text style={{ padding: 10, textAlign: "center" }}>No Contacts</Text>
      );
    } else {
      const limitedContacts = contacts.slice(0, 10);
      return limitedContacts.map((contact) => (
        <View
          key={contact.id}
          className="py-4 border-b border-gray-200 flex-row items-center w-full"
        >
          <View className="flex-1">
            <Font>{contact.name}</Font>
            <Font>{contact?.phoneNumbers?.[0]?.number}</Font>
          </View>
          <View className="w-8">
            <Input
              value={contact.selected}
              setValue={(e) => handleCheckboxChange(contact.id)}
              // tintColors={{true:'red'}}
              // style={styles.checkbox}
              type="checkbox"
              // onValueChange={() => handleCheckboxChange(contact.id)}
              // value={contact.checked}
              // onValueChange={() => handleCheckBoxToggle(contact.id)}
            />
          </View>
        </View>
      ));
    }
  };

  return (
    <View className="relative flex-1 bg-app-e lg:bg-gray-50">
      <ScrollView
        className="p-4 rounded-t-2xl bg-gray-50"
        contentContainerStyle={{
          flexGrow: 1,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <View className=" max-w-screen-lg w-full mx-auto">
          <View className="my-5 hidden lg:flex">
            <Font className="text-lg font-semibold text-app-d">
              Your Contacts
            </Font>
          </View>
          <View className="flex-col lg:flex-row max-w-screen-lg w-full">
            <View className="w-full lg:w-4/6 p-0 lg:pr-4">
              <View className={`w-full mb-4 lg:mb-8 justify-center rounded-lg`}>
                <View
                  className={`bg-white w-full items-center border border-gray-200 rounded-lg pb-4 shadow-lg shadow-gray-300 px-3`}
                >
                  <View className="flex-row  pb-4 border-b border-b-gray-200 w-full justify-between p-3">
                    <Button
                      label="Invited Contacts"
                      type="primary"
                      className="min-w-max px-8"
                      style={{ flex: 1 }}
                      onPress={() => navigation.navigate("contacted")}
                    />
                    <View className="w-1/2 mx-12" />
                  </View>
                  <View className=" w-full lg:w-4/6 p-0 lg:pr-4">
                    <View style={styles.content}>
                      {/* <Text>Contacted List</Text> */}

                      <View>{renderContacts()}</View>

                      {contacts.length === 0 ? null : (
                        <TouchableOpacity onPress={toggleShowAll}>
                          <Text style={{ padding: 10, textAlign: "center" }}>
                            {showAll ? "Show Less " : "Show More "}
                          </Text>
                        </TouchableOpacity>
                      )}

                      {/*<Text>
                    Selected Contacts:{" "}
                    {selectedContacts
                      .map((contact) => contact.name)
                      .join(", ")}
                    </Text> */}
                    </View>
                  </View>
                </View>
              </View>
            </View>
            <View className=" flex-1 w-full  lg:w-2/6 p-0 lg:pl-4">
              <Card title="SMBEx">
                <View className="w-full flex-1 p-4 pt-0">
                  <Font>
                    We collaboratively administrate turnkey channels where
                    kingdom businesses can become virtual retailers. We provide
                    scalable metrics, proactive e-services and objective metrics
                    for small businesses to thrive in any economic environment.
                  </Font>
                </View>
              </Card>
              <Card title="Find us there">
                <View className="flex-1 w-full p-4 pt-0">
                  <View className=" w-full flex-1 flex-row items-center">
                    <View className="h-16 w-16 items-center justify-center">
                      <RemixIcon
                        name="phone-fill"
                        size={40}
                        color={colors.app.d1}
                      ></RemixIcon>
                    </View>
                    <View>
                      <Font>Phone number</Font>
                      <Font className="text-app-e">1 877 963-5797</Font>
                    </View>
                  </View>
                  <View className="flex-row items-center">
                    <View className="h-16 w-16 items-center justify-center">
                      <RemixIcon
                        name="global-fill"
                        size={40}
                        color={colors.app.d1}
                      ></RemixIcon>
                    </View>
                    <View>
                      <Font>Website</Font>
                      <Font className="text-app-e">www.SMBEx.com</Font>
                    </View>
                  </View>
                  <View className="flex-row items-center">
                    <View className="h-16 w-16 items-center justify-center">
                      <RemixIcon
                        name="mail-fill"
                        size={40}
                        color={colors.app.d1}
                      ></RemixIcon>
                    </View>
                    <View>
                      <Font>Email Address</Font>
                      <Font className="text-app-e">office@SMBEx.com</Font>
                    </View>
                  </View>
                </View>
              </Card>
            </View>
          </View>
          <Footer navigation={navigation}></Footer>
        </View>
      </ScrollView>
      <View className="m-4 mx-10 absolute bottom-0 left-0 right-0">
        {contacts.length === 0 ? null : (
          <TouchableOpacity
            className="p-2 bg-app-e rounded shadow-sm"
            onPress={handleInviteButtonPress}
            disabled={!contacts.some((contact) => contact.selected)}
          >
            <Text className="text-center text-white">Invite Contact</Text>
          </TouchableOpacity>
        )}
      </View>
      {showResponseModal?.visible && (
        <ResponseModal
          visible={showResponseModal?.visible}
          onRequestClose={showResponseModal?.onRequestClose}
          bodyText={showResponseModal?.bodyText}
          dismissText={showResponseModal?.dismissText}
        />
      )}
      {/* {loading && (
        <ActivityIndicator
          color={colors.app.e}
          size={"large"}
          style={{ position: "absolute", alignSelf: "center", top: 50 }}
        />
      )} */}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 20,
  },
  title: {
    fontSize: 18,
    // fontWeight: 700,
    marginBottom: 10,
  },
  contactContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 10,
  },
  checkbox: {
    marginLeft: 10,
    display: "block",
    borderColor: "red",
    // flex:1
  },
  contactName: {
    fontSize: 16,
  },
  contactNumber: {
    marginLeft: 10,
  },
  content: {
    marginLeft: 20,
  },
  warning: {
    color: "orange",
    textAlign: "center",
    marginRight: 20,
    fontSize: 13,
  },
  space: {
    marginHorizontal: 200,
  },
});
