import _ from "lodash";
import React from "react";

const INITIAL_STATE = {
  listings: null,
  setListings: () => [],
  pagination: null,
  setPagination: () => ({ from: null, to: null, total: null }),
  tabStatus: { curr: undefined, prev: undefined },
  setTabStatus: () => ({ curr: undefined, prev: undefined }),
  listingDetailsData: null,
  setListingDetailsData: () => ({}),
  listingCategories: null,
  setListingCategories: () => [],
  listingCoupons: null,
  setListingCoupons: () => null,
  listingFeatures: null,
  setListingFeatures: () => [],
  listingPackages: null,
  setListingPackages: () => [],
  listingReviews: null,
  setListingReviews: () => [],
  listingReviewsPagination: { from: null, to: null, total: null },
  setListingReviewsPagination: () => ({ from: null, to: null, total: null }),
  listingReviewDetails: null,
  setListingReviewDetails: () => null,
  resetListingContext: () => null,
};

export const ListingContext = React.createContext(INITIAL_STATE);

const ListingReducer = (state, action) => {
  switch (action.type) {
    case "SET_LISTINGS":
      return { ...state, listings: action.payload };
    case "PAGINATION":
      return { ...state, pagination: action.payload };
    case "SET_TAB_STATUS":
      return { ...state, tabStatus: action.payload };
    case "SET_LISTING_DATA":
      return { ...state, listingDetailsData: action.payload };
    case "SET_LISTING_CATEGORIES":
      return { ...state, listingCategories: action.payload };
    case "SET_LISTING_COUPONS":
      return { ...state, listingCoupons: action.payload };
    case "SET_LISTING_FEATURES":
      return { ...state, listingFeatures: action.payload };
    case "SET_LISTING_PACKAGES":
      return { ...state, listingPackages: action.payload };
    case "ADD_LISTING_REVIEWS":
      return { ...state, listingReviews: action.payload };
    case "SET_LISTING_REVIEW_DATA":
      return { ...state, listingReviewDetails: action.payload };
    case "SET_LISTING_REVIEWS_PAGINATION":
      return { ...state, listingReviewsPagination: action.payload };
    case "RESET_LISTING_STATES":
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default function ListingProvider({ children }) {
  const [state, dispatch] = React.useReducer(ListingReducer, INITIAL_STATE);

  return (
    <ListingContext.Provider
      value={{
        listings: state.listings,
        setListings: ({ data, async = true, push = false }) => {
          dispatch({
            type: "SET_LISTINGS",
            payload: push ? [...(state.listings || []), data] : data,
          });
        },
        pagination: state.pagination,
        setPagination: ({ from, to, total }) => {
          dispatch({ type: "PAGINATION", payload: { from, to, total } });
        },
        tabStatus: state.tabStatus,
        setTabStatus: ({ curr, prev }) => {
          dispatch({
            type: "SET_TAB_STATUS",
            payload: { curr: curr, prev: prev },
          });
        },
        listingCategories: state.listingCategories,
        setListingCategories: ({ data }) => {
          dispatch({ type: "SET_LISTING_CATEGORIES", payload: data || [] });
        },
        listingCoupons: state.listingCoupons,
        setListingCoupons: (data) => {
          dispatch({ type: "SET_LISTING_COUPONS", payload: data || [] });
        },
        listingFeatures: state.listingFeatures,
        setListingFeatures: ({ data }) => {
          dispatch({ type: "SET_LISTING_FEATURES", payload: data || [] });
        },
        listingPackages: state.listingPackages,
        setListingPackages: ({ data }) => {
          dispatch({ type: "SET_LISTING_PACKAGES", payload: data || [] });
        },
        listingDetailsData: state.listingDetailsData,
        setListingDetailsData: ({ data, id, push = true }) => {
          const parseSlots = (parseObj) => {
            const getMinutes = (time_in_min) => {
              let m = time_in_min % 60;
              if (m < 10) {
                m = "0" + m;
              }
              return m.toString();
            };
            const getHours = (time_in_min) => {
              let h = parseInt(time_in_min / 60);
              if (h < 10) {
                h = "0" + h;
              }
              return h.toString();
            };
            let data = _.cloneDeep(parseObj);
            for (const key in data) {
              data[key] = data[key].map((v) => {
                v.start_in_min = {
                  h: getHours(v.start_in_min),
                  m: getMinutes(v.start_in_min),
                  tim: v.start_in_min,
                };
                v.end_in_min = {
                  h: getHours(v.end_in_min),
                  m: getMinutes(v.end_in_min),
                  tim: v.end_in_min,
                };
                return v;
              });
            }
            return data;
          };
          data.opening_hours = parseSlots(data.opening_hours);
          data.available_slots = parseSlots(data.available_slots);
          dispatch({
            type: "SET_LISTING_DATA",
            payload: push
              ? { ...state.listingDetailsData, [id]: data || {} }
              : { [id]: data || {} },
          });
        },
        updateListingDetailsData: (data) => {
          let newData;
          if (typeof data === "function") {
            newData = data(state.listingDetailsData);
          }
          dispatch({
            type: "SET_LISTING_DATA",
            payload: { ...(newData ? newData : data) },
          });
        },
        listingReviews: state.listingReviews,
        setListingReviews: ({ data, lid, push = false }) => {
          dispatch({
            type: "ADD_LISTING_REVIEWS",
            payload: {
              ...(state.listingReviews || {}),
              [lid]: push
                ? { ...data, ...(state.listingReviews?.[lid] || {}) }
                : data,
            },
          });
        },
        listingReviewDetails: state.listingReviewDetails,
        setListingReviewDetails: ({ data, lrid }) => {
          console.log("2...data:", data, lrid);
          dispatch({
            type: "SET_LISTING_REVIEW_DATA",
            payload: { ...state.listingReviewDetails, [lrid]: data },
          });
        },
        listingReviewsPagination: state.listingReviewsPagination,
        setListingReviewsPagination: ({ from, to, total }) => {
          dispatch({
            type: "SET_LISTING_REVIEWS_PAGINATION",
            payload: { from, to, total },
          });
        },
        resetListingContext: ({ data } = {}) => {
          dispatch({ type: "RESET_LISTING_STATES", payload: data || [] });
        },
      }}
    >
      {children}
    </ListingContext.Provider>
  );
}

export const useListingContext = () => React.useContext(ListingContext);
