import React from "react";
import {
  Image,
  View,
  Pressable,
  Platform,
  Modal,
  ImageBackground,
} from "react-native";
import { Font, A } from "../../components/font";
import { Button, Input } from "../../components/input";
import colors from "../../../colors";
import { ScrollView } from "react-native";

// import {
//   GoogleSignin,
//   GoogleSigninButton,
//   statusCodes,
// } from "@react-native-google-signin/google-signin";

// import * as WebBrowser from "expo-web-browser";
// import * as AuthSession from "expo-auth-session";
import { AuthContext } from "../../contexts/auth-context";
import { logIn } from "../../actions/auth-actions";
import ResponseModal from "../../components/general/modals/ResponseModal";
import { useNotifications } from "../../utils/useNotifications";
import * as WebBrowser from "expo-web-browser";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { json } from "react-router-dom";
import axiosInstance from "../../constants/axios-config";

import GoogleSigninFunctionWeb from "./googleSignin";
import { GoogleOAuthProvider } from "@react-oauth/google";

// WebBrowser.maybeCompleteAuthSession();
// const redirectUri = AuthSession.makeRedirectUri();

export default function Signin({ navigation }) {
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [remember, setRemember] = React.useState(false);
  const [isSigningIn, setIsSigningIn] = React.useState(false);
  const [isGoogleSigningIn, setIsGoogleSigningIn] = React.useState();
  const [showAPIResponse, setShowAPIResponse] = React.useState({
    visible: false,
    onRequestClose: () => null,
    bodyText: "",
  });
  const { user, setUser } = React.useContext(AuthContext);
  const [userInfo, setUserInfo] = React.useState();
  const { registerForPushNotificationsAsync } = useNotifications();

  const [error, setError] = React.useState();

  // const configureGoogleSignIn = () => {
  //   try {
  //     GoogleSignin.configure({
  //       // expoClientId:
  //       // "1049029176568-9m667iv9lb1fokt3h6vb0urnfg9m9r6q.apps.googleusercontent.com",
  //       webClientId:
  //         "1049029176568-9m667iv9lb1fokt3h6vb0urnfg9m9r6q.apps.googleusercontent.com",
  //       iosClientId:
  //         "1049029176568-a6nbpgue9pchdcd5ub4245hthht6n3ua.apps.googleusercontent.com",
  //       // redirectUri:"http://localhost:19007",
  //       androidClientId:
  //         "1049029176568-nc3es5c5g3fpvrbcpqu26uuqf5305pgj.apps.googleusercontent.com",
  //     });
  //   } catch (e) {
  //     console.error("Google Sign-In configuration error:", e);
  //   }
  // };

  // React.useEffect(() => {
  //   configureGoogleSignIn();
  // });

  // const signInWithGoogle = async () => {
  //   console.log("Pressed sign in");

  //   try {
  //     await GoogleSignin.hasPlayServices();
  //     const userInfo = await GoogleSignin.signIn();
  //     setUser(userInfo);
  //     // navigation.replace("app");
  //     // registerForPushNotificationsAsync();
  //     setUserInfo(userInfo);
  //     // setIsSigningIn(true);
  //     setError(null);
  //   } catch (e) {
  //     alert(e)
  //     console.log(e, "error mssgs");
  //     setError(e);
  //   }

  // };
  // const GoogleSigninFunction_Platform_wise = Platform.select({
  //   web: React.lazy(async ()=> await import("./googleSignin")),
  //   default: require("./googleSignin.native").default,
  //   // default: require("./googleSignin").default,
  // });

  //   let GoogleSigninFunction_Platform_wise;

  // if (Platform.OS === 'web') {
  //   GoogleSigninFunction_Platform_wise = React.lazy(async() => await import("./googleSignin"));
  // }
  // else {
  //   GoogleSigninFunction_Platform_wise = React.lazy(async() => await import("./googleSignin"));
  // }
  let GoogleSigninFunction;
  if (Platform.OS !== "web") {
    GoogleSigninFunction = require("./googleSignin.native").default;
  }

  const signIn = async () => {
    try {
      if (!(email && password)) return;
      setIsSigningIn(true);
      const res = await logIn({
        email: email,
        password: password,
        remember: remember,
      });

      console.log("res?.data: ", res?.data);
      if (!res?.data?.data) {
        throw new Error(
          "User profile not received!\n" +
            JSON.stringify({ status: res.status, data: res.data })
        );
      }

      if (res?.status === 200) {
        setUser(res?.data?.data);
        navigation.replace("app");
        registerForPushNotificationsAsync();
      } else if (res?.status) {
        setShowAPIResponse({
          visible: true,
          onRequestClose: () => {
            setShowAPIResponse({
              visible: false,
              onRequestClose: () => null,
              bodyText: "",
            });
          },
          bodyText: `${res?.status}: ${res?.data?.message}`,
          dismissText: "Okay",
        });
      }
    } catch (error) {
      console.log("signin error:", error);
      setShowAPIResponse({
        visible: true,
        onRequestClose: () => {
          setShowAPIResponse({
            visible: false,
            onRequestClose: () => null,
            bodyText: "",
          });
        },
        bodyText: error?.message,
      });
    } finally {
      setIsSigningIn(false);
    }
  };

  return (
    <View className="w-full h-full bg-gray-50 flex-row">
      <View className="h-full w-full lg:w-1/2 items-center justify-center">
        <ScrollView
          className="w-full"
          contentContainerStyle={{
            flexGrow: 1,
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <View
            className={
              "w-80 items-center py-16 " +
              (Platform.OS === "web"
                ? "py-8 px-10 md:px-0 w-full md:w-80"
                : "basis-0")
            }
          >
            <Pressable
              // onPress={() => navigation.replace("app")}
              className="mb-8 items-center"
            >
              <Image
                source={require("../../../assets/smbex-lo.png")}
                className="w-36 h-12 my-2"
              />
              <Font className="text-app-e">Vendor</Font>
            </Pressable>
            <View className="w-full my-2">
              <Font className="text-xl text-app-d" weight={500}>
                Sign in
              </Font>
            </View>
            {Platform.OS == "web" ? (
              <GoogleOAuthProvider clientId="1049029176568-9m667iv9lb1fokt3h6vb0urnfg9m9r6q.apps.googleusercontent.com">
                <GoogleSigninFunctionWeb
                  setUser={setUser}
                  setUserInfo={setUserInfo}
                  navigation={navigation}
                />
              </GoogleOAuthProvider>
            ) : (
              <GoogleSigninFunction
                setUser={setUser}
                setUserInfo={setUserInfo}
                navigation={navigation}
              />
            )}
            <View className="w-full h-4 items-center justify-center mb-2">
              <View className="w-full h-[1px] bg-gray-200 my-2"></View>
              <View className="absolute bg-gray-50 py-1 px-2">
                <Font>or</Font>
              </View>
            </View>
            <View className="w-full mb-2">
              <Input
                type="email"
                value={email}
                setValue={setEmail}
                label="Email"
                placeholder="user@example.com"
                validation={true}
              ></Input>
            </View>
            <View className="w-full mb-2">
              <Input
                type="password"
                value={password}
                setValue={setPassword}
                label="Password"
                placeholder="************"
                validation={true}
              ></Input>
            </View>

            <View className="w-full mb-2">
              <Input
                type="checkbox"
                value={remember}
                setValue={setRemember}
                label="Remember me"
              ></Input>
            </View>
            <View className="w-full mb-2 my-2">
              <Button
                type="primary"
                label="Sign in"
                onPress={signIn}
                waitState={isSigningIn}
                loaderWithText="Please Wait"
              ></Button>
            </View>
            <View className="w-full my-4">
              <A
                href="forgot-password"
                label="Forgot password?"
                className="font-semibold text-app-e text-sm text-center"
              />
            </View>
            <View className="w-full h-[1px] bg-gray-200 my-2"></View>
            <View className="w-full my-2">
              <Font className="text-center mb-2">
                Don&rsquo;t have an account?
              </Font>
              <A
                href="signup"
                label="Sign up"
                className="font-semibold text-app-e text-sm text-center"
              />
            </View>
          </View>
        </ScrollView>
      </View>
      <View className="h-full w-1/2 hidden lg:flex bg-app-d">
        <ImageBackground
          className="flex-1"
          source={require("../../../assets/side-background-image.jpg")}
          resizeMode="stretch"
          alt="side-background-home"
        />
      </View>
      <ResponseModal
        visible={showAPIResponse.visible}
        onRequestClose={showAPIResponse.onRequestClose}
        bodyText={showAPIResponse.bodyText}
        dismissText={showAPIResponse?.dismissText}
      />
    </View>
  );
}

// const [userInfo, setUserInfo] = React.useState(null);
// const [request , response , promptAsync] = Google.useAuthRequest({
//   expoClientId:
//   "1049029176568-9m667iv9lb1fokt3h6vb0urnfg9m9r6q.apps.googleusercontent.com",
//   webClientId:
//   "1049029176568-9m667iv9lb1fokt3h6vb0urnfg9m9r6q.apps.googleusercontent.com",
//   iosClientId:
//   "1049029176568-a6nbpgue9pchdcd5ub4245hthht6n3ua.apps.googleusercontent.com",
//   redirectUri:"http://localhost:19007",
//   androidClientId:
//   "1049029176568-nc3es5c5g3fpvrbcpqu26uuqf5305pgj.apps.googleusercontent.com",
// });
// console.log("Response of google" ,response)

// React.useEffect(()=>{
// handleSignInWithGoogle();
// },[response])

// const handleSignInWithGoogle = async () => {
//   if(!user){
//     if(response?.type==="success"){
//       await getUserInfo(response.authentication.accessToken)
//     }
//   }
//   else{
//     setUser(JSON.parse(user));
//   }
// }

// const getUserInfo = async (token) => {
//   if(!token) return;
//   try {
//     const response = await axiosInstance("https://www.googleapis.com/userinfo/v2/me",{
//       headers:{
//         Authorization:`Bearer ${token}`,
//       }
//     });

//     const user = await response.json();
//     setUser(user);
//     navigation.replace("app");
//     registerForPushNotificationsAsync();
//   } catch (error) {

//   }
// }
