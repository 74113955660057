import React from "react";
import { Image, Pressable, ScrollView, Text, View } from "react-native";
import { useIsFocused } from "@react-navigation/native";
import { Input, Select } from "../../components/input";
import { Font } from "../../components/font";
import { Card, CardDark } from "../../components/general/cards/card";
import { Honeycomb, HoneycombSmall } from "../../components/honeycomb";
import {
  FeaturedListing,
  FeaturedProducts,
  LatestArticles,
} from "../../components/listing";
import { Footer } from "../../components/footer";
import RemixIcon from "react-native-remix-icon";
import { Maps } from "../../components/maps";
import colors from "../../../colors";
import { SharedContext } from "../../contexts/shared-context";

export default function Search({ navigation, route }) {
  const [searchParams, setSearchParams] = React.useState({
    itemType: "Listing",
    keyword: "",
    category: "",
    priceRange: [],
    location: "",
  });
  const { setSearchParams: setSearch } = React.useContext(SharedContext);
  React.useEffect(() => {
    console.log(route.params);
    setSearchParams((e) => ({ ...e, ...route.params }));
  }, []);
  React.useEffect(() => {
    setSearch(searchParams);
  }, [searchParams, setSearch]);
  return (
    <View className="flex-1 bg-app-e lg:bg-gray-50">
      {searchParams.itemType === "Listing" ? (
        <View className="flex-1 flex-col lg:flex-row-reverse">
          {/* <Animated.View
                    className="w-full lg:w-1/2 h-full flex lg:hidden"
                    style={{
                        height: heightAnim.interpolate({
                            inputRange: [0, 1],
                            outputRange: ["0%", "100%"],
                        }),
                    }}
                >
                    <Maps className="flex-1"></Maps>
                </Animated.View> */}

          <View className="w-full lg:w-1/2 h-full hidden lg:flex">
            <Maps className="flex-1"></Maps>
          </View>
          <View className="w-full lg:w-1/2 flex-1 lg:h-full x-mt-12 lg:mt-0 bg-app-b rounded-t-2xl lg:rounded-none ">
            {/* <View className="w-full h-10 flex lg:hidden">
                        <Pressable
                            className="flex-1 items-center justify-center"
                            onPress={toggleMap}
                        >
                            <View className="h-1 w-16 bg-app-d3 rounded"></View>
                        </Pressable>
            </View> */}
            <ScrollView
              className="flex-1 h-4"
              contentContainerStyle={{
                flexGrow: 1,
                alignItems: "center",
              }}
            >
              <View className="flex-1 h-full w-full p-4 lg:p-8 items-center">
                <View className="flex-row w-full mb-4 hidden lg:flex">
                  {/* <Input
                                        type="text"
                                        placeholder="What are you looking for?"
                                        className="flex-1"
                                    ></Input>
                                    <Input
                                        placeholder="Location"
                                        className="flex-1"
                                    ></Input> */}

                  <Pressable
                    className="bg-app-a rounded flex-1 p-4 border border-gray-200 flex-row items-center"
                    onPress={() => navigation.navigate("search-modal")}
                  >
                    <View className="mr-4">
                      <RemixIcon
                        name="search-line"
                        size={24}
                        color={colors.app.d1}
                      ></RemixIcon>
                    </View>
                    <View>
                      <Font className="text-app-d1" weight={500}>
                        Product
                      </Font>
                      <Font weight={600} className=" text-app-d text-[15px]">
                        Geen sytlist hoodie
                      </Font>
                    </View>
                  </Pressable>
                </View>
                <Select
                  options={[{ label: "test", value: "test" }]}
                  placeholder="Select"
                  className="w-full mb-4"
                  mode="simple"
                ></Select>
                {[...Array(5).keys()].map((e, i) => (
                  <View
                    className="w-full bg-app-a rounded-lg p-0 border border-gray-200 flex-col lg:flex-row mb-4"
                    key={i}
                  >
                    <Pressable className="overflow-hidden rounded-none  rounded-t-lg lg:rounded-l-lg">
                      <Image
                        source={require("../../../assets/bg.jpg")}
                        className="w-full h-40 lg:w-40 lg:h-40 "
                      ></Image>
                      <View className="absolute top-0 left-0 w-full h-full p-1 flex-row">
                        <Pressable className="h-8 w-8 bg-app-a rounded-full items-center justify-center flex lg:hidden">
                          <RemixIcon
                            name="bookmark-fill"
                            color={colors.app.e}
                            size="18"
                          ></RemixIcon>
                        </Pressable>
                        <View className="flex-1"></View>
                      </View>
                    </Pressable>
                    <Pressable
                      className="justify-center p-4 flex-1"
                      onPress={() => navigation.navigate("listing")}
                    >
                      <View
                        className="flex-row items-center"
                        style={{
                          marginVertical: 2,
                        }}
                      >
                        <Font
                          className="text-xs lg:text-md text-app-f"
                          numberOfLines={1}
                          weight={500}
                        >
                          Accomodation
                        </Font>
                      </View>
                      <Font
                        className="text-[15px] text-app-d leading-4"
                        numberOfLines={2}
                        weight={600}
                      >
                        Tommy&rsquo;s Italian Restaurant asd asd ad asd asd asd
                        adss
                      </Font>
                      <View
                        className="flex-row items-center mb-1 lg:mb-1"
                        style={{
                          marginVertical: 2,
                        }}
                      >
                        <Font
                          className=" text-app-d1"
                          weight={500}
                          numberOfLines={1}
                        >
                          Washington, DC 20008, United States
                        </Font>
                      </View>
                      <View
                        className="flex-row items-center"
                        style={{
                          marginVertical: 2,
                        }}
                      >
                        <Font
                          className="text-[15px] text-app-d"
                          numberOfLines={1}
                          weight={600}
                        >
                          {" "}
                          $ 99.00{" -  "}$ 150.00{" "}
                        </Font>
                      </View>
                      <View
                        className="flex-row items-center"
                        style={{
                          marginVertical: 2,
                        }}
                      >
                        <RemixIcon
                          name="star-fill"
                          size={16}
                          color={colors.app.i}
                          className="mr-1 flex-shrink-0"
                        ></RemixIcon>
                        <Font
                          className="text-app-d1"
                          weight={500}
                          numberOfLines={1}
                        >
                          {" "}
                          4.8 | 100 reviews
                        </Font>
                      </View>
                    </Pressable>
                    <View className="hidden lg:flex p-4">
                      <Pressable>
                        <RemixIcon
                          name="bookmark-fill"
                          color={colors.app.e}
                          size="24"
                        ></RemixIcon>
                      </Pressable>
                    </View>
                  </View>
                ))}
              </View>
              <Footer navigation={navigation}></Footer>
            </ScrollView>
          </View>
        </View>
      ) : (
        <ScrollView
          className="p-4 rounded-t-2xl bg-gray-50"
          contentContainerStyle={{
            flexGrow: 1,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <View className=" max-w-screen-lg w-full mx-auto">
            <View className="my-5 hidden lg:flex">
              {/* <Font className="text-lg font-semibold text-app-d ">
                                Search
                            </Font> */}
              <Pressable
                className="bg-app-a rounded flex-1 p-4 border border-gray-200 flex-row items-center"
                onPress={() => navigation.navigate("search-modal")}
              >
                <View className="mr-4">
                  <RemixIcon
                    name="search-line"
                    size={24}
                    color={colors.app.d1}
                  ></RemixIcon>
                </View>
                <View>
                  <Font className="text-app-d1" weight={500}>
                    Product
                  </Font>
                  <Font weight={600} className=" text-app-d text-[15px]">
                    Geen sytlist hoodie
                  </Font>
                </View>
              </Pressable>
            </View>
            <View className="flex-col lg:flex-row max-w-screen-lg w-full">
              <View className=" w-[104%]  p-0 lg:pr-4 flex-row flex-wrap -ml-2">
                {[...Array(10).keys()].map((e, i) => (
                  <View className="w-1/2 lg:w-1/4 p-1 lg:p-2" key={i}>
                    <Pressable
                      className="w-full h-full rounded-lg overflow-hidden border border-gray-200 bg-app-a"
                      onPress={() => navigation.navigate("product")}
                    >
                      <View className="h-44 lg:h-64 w-full">
                        <Image
                          source={require("../../../assets/bip3.jpg")}
                          className="w-full h-full"
                        ></Image>
                      </View>
                      <View className="p-2">
                        <Font
                          className="text-md text-app-d mb-1 leading-5"
                          weight={600}
                          numberOfLines={3}
                        >
                          Amazfit Bip 3 Pro Smartwatch 1.69&rdquo; Touch Screen,
                          GPS jam tangan SpO2 - Merah Muda
                        </Font>

                        <View
                          className="flex-row items-center"
                          style={{
                            marginVertical: 2,
                          }}
                        >
                          <Font
                            className="text-[15px] text-app-d"
                            numberOfLines={1}
                            weight={700}
                          >
                            {" "}
                            $ 99.00{" "}
                          </Font>
                          <Font
                            className="text-md text-app-e1 line-through ml-2"
                            weight={600}
                            numberOfLines={1}
                          >
                            {" "}
                            $ 150.00
                          </Font>
                        </View>
                        <View
                          className="flex-row items-center"
                          style={{
                            marginVertical: 2,
                          }}
                        >
                          <RemixIcon
                            name="star-fill"
                            size={16}
                            color={colors.app.i}
                            className="mr-1 flex-shrink-0"
                          ></RemixIcon>
                          <Font
                            className="text-md text-app-d1"
                            weight={500}
                            numberOfLines={1}
                          >
                            {" "}
                            4.8 | 100 reviews
                          </Font>
                        </View>
                      </View>
                    </Pressable>
                  </View>
                ))}
              </View>
              {/* <View className=" w-full lg:w-2/6 p-0 lg:pl-4">
                                <Card title="SMBEx">
                                    <View className="flex-1 w-full p-4 pt-0">
                                        <Font>
                                            We collaboratively administrate
                                            turnkey channels where kingdom
                                            businesses can become virtual
                                            retailers. We provide scalable
                                            metrics, proactive e-services and
                                            objective metrics for small
                                            businesses to thrive in any economic
                                            environment.
                                        </Font>
                                    </View>
                                </Card>
                                <Card title="Find us there">
                                    <View className="flex-1 w-full p-4 pt-0">
                                        <View className="flex-row items-center">
                                            <View className="h-16 w-16 items-center justify-center">
                                                <RemixIcon
                                                    name="phone-fill"
                                                    size={40}
                                                    color={colors.app.d1}
                                                ></RemixIcon>
                                            </View>
                                            <View>
                                                <Font>Phone number</Font>
                                                <Font className="text-app-e">
                                                    1 877 963-5797
                                                </Font>
                                            </View>
                                        </View>
                                        <View className="flex-row items-center">
                                            <View className="h-16 w-16 items-center justify-center">
                                                <RemixIcon
                                                    name="global-fill"
                                                    size={40}
                                                    color={colors.app.d1}
                                                ></RemixIcon>
                                            </View>
                                            <View>
                                                <Font>Website</Font>
                                                <Font className="text-app-e">
                                                    www.SMBEx.com
                                                </Font>
                                            </View>
                                        </View>
                                        <View className="flex-row items-center">
                                            <View className="h-16 w-16 items-center justify-center">
                                                <RemixIcon
                                                    name="mail-fill"
                                                    size={40}
                                                    color={colors.app.d1}
                                                ></RemixIcon>
                                            </View>
                                            <View>
                                                <Font>Email Address</Font>
                                                <Font className="text-app-e">
                                                    office@SMBEx.com
                                                </Font>
                                            </View>
                                        </View>
                                    </View>
                                </Card>
                            </View> */}
            </View>
            <Footer navigation={navigation}></Footer>
          </View>
        </ScrollView>
      )}
    </View>
  );
}
