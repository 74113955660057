export const bookingStatusBadgeData = (status) => {
  let bookingStatus = {
    bgcolor: "",
    borderColor: "",
    textColor: "",
    text: "",
  };
  if (["completed", "confirmed", "verified"].includes(status)) {
    bookingStatus = {
      bgcolor: "bg-app-g3",
      borderColor: "border-app-g",
      textColor: "text-app-g",
    };
  } else if (["created", "pending_confirmation"].includes(status)) {
    bookingStatus = {
      bgcolor: "bg-app-h3",
      borderColor: "border-app-h",
      textColor: "text-app-h",
    };
  } else if (
    [
      "cancelled_by_customer",
      "cancelled_by_vendor",
      "failed",
      "rejected",
    ].includes(status)
  ) {
    bookingStatus = {
      bgcolor: "bg-app-e3",
      borderColor: "border-app-e",
      textColor: "text-app-e",
    };
  } else {
    bookingStatus = {
      bgcolor: "bg-app-d3",
      borderColor: "border-app-d1",
      textColor: "text-app-d1",
    };
  }
  bookingStatus.text = status.split("_").join(" ");
  return bookingStatus;
};

export const orderStatusBadgeData = (status) => {
  let orderStatus = {
    bgColor: "bg-app-d3",
    borderColor: "border-app-d1",
    textColor: "text-app-d1",
    text: "Unknown Status",
  };

  if (status === "created")
    orderStatus = {
      bgColor: "bg-app-a",
      borderColor: "border-app-e1",
      textColor: "text-app-e1",
    };
  else if (status === "confirmed")
    orderStatus = {
      bgColor: "bg-app-a",
      borderColor: "border-app-h",
      textColor: "text-app-h",
    };
  else if (status === "processing")
    orderStatus = {
      bgColor: "bg-app-h3",
      borderColor: "border-app-h",
      textColor: "text-app-h",
    };
  else if (status === "ready_for_pickup")
    orderStatus = {
      bgColor: "bg-app-h1",
      borderColor: "border-app-h",
      textColor: "text-app-a",
    };
  else if (status === "picked")
    orderStatus = {
      bgColor: "bg-app-h",
      borderColor: "border-app-h",
      textColor: "text-app-a",
    };
  else if (status === "shipped")
    orderStatus = {
      bgColor: "bg-app-a",
      borderColor: "border-app-g",
      textColor: "text-app-g",
    };
  else if (status === "out_for_delivery")
    orderStatus = {
      bgColor: "bg-app-g3",
      borderColor: "border-app-g",
      textColor: "text-app-g",
    };
  else if (status === "delivered")
    orderStatus = {
      bgColor: "bg-app-g",
      borderColor: "border-app-g",
      textColor: "text-app-a",
    };
  else if (["cancelled_by_customer", "cancelled_by_vendor"].includes(status))
    orderStatus = {
      bgColor: "bg-app-e1",
      borderColor: "border-app-e1",
      textColor: "text-app-a",
    };
  else if (status === "failed")
    orderStatus = {
      bgColor: "bg-app-e3",
      borderColor: "border-app-e",
      textColor: "text-app-e",
    };
  orderStatus.text = status?.split("_")?.join(" ") || orderStatus.text;
  return orderStatus;
};
