module.exports = {
  app: {
    a: "#FFFFFFFF",
    b: "#FAFAFAFF",
    c: "#E5E8EEFF",
    d: "#0C3954FF",
    e: "#EF1211FF",
    f: "#1677FFFF",
    g: "#00B578FF",
    h: "#FF8F1FFF",
    i: "#FFC01FFF",
    a1: "#FFFFFFAA",
    b1: "#FAFAFAAA",
    c1: "#E5E8EEAA",
    d1: "#0C3954AA",
    e1: "#EF1211AA",
    f1: "#1677FFAA",
    g1: "#00B578AA",
    h1: "#FF8F1FAA",
    i1: "#FFC01FAA",
    a2: "#FFFFFF55",
    b2: "#FAFAFA55",
    c2: "#E5E8EE55",
    d2: "#0C395455",
    e2: "#EF121155",
    f2: "#1677FF55",
    g2: "#00B57855",
    h2: "#FF8F1F55",
    i2: "#FFC01F55",
    a3: "#FFFFFF33",
    b3: "#FAFAFA33",
    c3: "#E5E8EE33",
    d3: "#0C395433",
    e3: "#EF121133",
    f3: "#1677FF33",
    g3: "#00B57833",
    h3: "#FF8F1F33",
    i3: "#FFC01F33",
    a4: "#EA3EF7",
    b4: "#75F94C",
    c4: "#0021F5",
    d4: "#CCCCCC",
    e4: "#E72D46",
    f4: "#606770",
    g4: "#666666",
    h4: "#75F94C",
    i4: "#000000",
  },
};
