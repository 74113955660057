import React from "react";
import { Pressable, View } from "react-native";
import RemixIcon from "react-native-remix-icon";
import colors from "../../colors";
import { Card } from "./general/cards/card";
import { Font } from "./font";

export const Widget = () => {
  return (
    <>
      <Card title="SMBEx">
        <View className="flex-1 w-full p-4 pt-0">
          <Font className="text-app-d1" weight={500}>
            We collaboratively administrate turnkey channels where kingdom
            businesses can become virtual retailers. We provide scalable
            metrics, proactive e-services and objective metrics for small
            businesses to thrive in any economic environment.
          </Font>
        </View>
      </Card>
      <Card title="Find us there">
        <View className="flex-1 w-full p-4 pt-0">
          <View className="flex-row items-center">
            <View className="h-16 w-16 items-center justify-center">
              <RemixIcon
                name="phone-fill"
                size={40}
                color={colors.app.d1}
              ></RemixIcon>
            </View>
            <View>
              <Font className="text-app-d" weight={500}>
                Phone number
              </Font>
              <Font className="text-app-e">1 877 963-5797</Font>
            </View>
          </View>
          <View className="flex-row items-center">
            <View className="h-16 w-16 items-center justify-center">
              <RemixIcon
                name="global-fill"
                size={40}
                color={colors.app.d1}
              ></RemixIcon>
            </View>
            <View>
              <Font className="text-app-d" weight={500}>
                Website
              </Font>
              <Font className="text-app-e">www.SMBEx.com</Font>
            </View>
          </View>
          <View className="flex-row items-center">
            <View className="h-16 w-16 items-center justify-center">
              <RemixIcon
                name="mail-fill"
                size={40}
                color={colors.app.d1}
              ></RemixIcon>
            </View>
            <View>
              <Font className="text-app-d" weight={500}>
                Email Address
              </Font>
              <Font className="text-app-e">office@SMBEx.com</Font>
            </View>
          </View>
        </View>
      </Card>
    </>
  );
};
