import React from "react";
import { Image, View } from "react-native";
import { Font } from "../../components/font";
import { Switcher } from "../../components/input";
import { Unauthorized } from "./unauthorized";
import { AuthContext } from "../../contexts/auth-context";
import { useVendorFeedContext } from "../../contexts/vendor-feed-context";
import { pageTitle, parseProfileName } from "../../utils";
import { mediaURI } from "../../constants";
import VendorReviews from "./vendor-reviews";
import VendorReviewDetails from "./vendor-review-details";
import VendorFeed from "./vendor-feed";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import VendorFeedComments from "./vendor-feed-comments";
import { DefaultImages } from "../../constants/predefined-constants";

const Stack = createNativeStackNavigator();

export default function Vendor({ navigation }) {
  const { tabStatus, setTabStatus } = useVendorFeedContext();
  const { user = {} } = React.useContext(AuthContext);

  React.useEffect(() => {
    setTabStatus({ curr: "My Feed", prev: tabStatus.curr });
  }, []);

  if (!user) {
    <Unauthorized />;
  }

  return (
    <View className="flex-1 bg-app-e lg:bg-gray-50">
      <View className="rounded-t-2xl lg:rounded-none flex-1 bg-gray-50">
        <View className="flex-row gap-4 px-4 lg:px-8 py-8 lg:pb-4">
          <View className="rounded-full w-12 h-12 overflow-hidden">
            <Image
              source={
                user?.profile_image_url
                  ? { uri: mediaURI + user.profile_image_url }
                  : DefaultImages.DP_PLACEHOLDER
              }
              className="w-full h-full"
            ></Image>
          </View>
          <View className="justify-center">
            <Font className="font-bold text-[16px] mb-1 text-app-d">
              {parseProfileName(user?.first_name, user?.last_name)}
            </Font>
            <Font className="text-[12px] text-app-d1">
              {user?.about || "No about here"}
            </Font>
          </View>
        </View>
        {/* <View className="flex lg:hidden">
          <View className="w-full h-10 rounded-lg flex-row overflow-hidden border border-gray-200 divide-x divide-gray-200">
            {options?.map((e, i) => (
              <Pressable
                className={`h-full flex-1 items-center justify-center ${
                  tabStatus.curr == e.value
                    ? "bg-app-e active:bg-red-600"
                    : "bg-white active:bg-gray-100  "
                }`}
                onPress={() => setTab(e)}
                key={i}
              >
                <Font
                  weight={600}
                  className={`${
                    tabStatus.curr === e ? "text-app-a" : "text-app-e"
                  }`}
                >
                  {e.label}
                </Font>
              </Pressable>
            ))}
          </View>
        </View> */}
        <View className="w-full mx-auto flex-1">
          <View className="flex-col h-full max-w-screen w-full relative">
            <View className="flex-row items-center px-4 lg:px-8">
              <Font className="text-lg font-semibold text-app-d flex-1">
                {tabStatus.curr}
              </Font>
              <View className="w-52">
                <Switcher
                  options={[
                    {
                      label: "Wall",
                      value: "My Feed",
                    },
                    {
                      label: "Reviews",
                      value: "My Reviews",
                    },
                  ]}
                  value={tabStatus.curr}
                  setValue={(e) => {
                    if (e !== tabStatus.curr) {
                      //   getVendorBookings(undefined, undefined, e);
                      navigation.push(
                        e === "My Feed"
                          ? "vendor-feed"
                          : e === "My Reviews"
                          ? "vendor-reviews"
                          : ""
                      );
                      setTabStatus({ curr: e, prev: tabStatus.curr });
                    }
                  }}
                ></Switcher>
              </View>
            </View>
            <View className="flex-1 h-full w-full bg-gray-50 py-4">
              <Stack.Navigator
                screenOptions={{ animation: "fade_from_bottom" }}
              >
                <Stack.Screen
                  name="vendor-feed"
                  options={{
                    title: pageTitle("Vendor Feed"),
                    // header: (props) => <Navbar {...props} user={user}></Navbar>,
                    headerShown: false,
                  }}
                >
                  {(props) => (
                    <VendorFeed
                      {...props}
                      setTabStatus={setTabStatus}
                      tabStatus={tabStatus}
                    />
                  )}
                </Stack.Screen>
                <Stack.Screen
                  name="vendor-feed-comments"
                  getId={({ params }) => params.slug}
                  options={{
                    title: pageTitle("Vendor Feed Comments"),
                    // header: (props) => <Navbar {...props} user={user}></Navbar>,
                    headerShown: false,
                  }}
                >
                  {(props) => (
                    <VendorFeedComments
                      {...props}
                      setTabStatus={setTabStatus}
                      tabStatus={tabStatus}
                    />
                  )}
                </Stack.Screen>
                <Stack.Screen
                  name="vendor-reviews"
                  options={{
                    title: pageTitle("Vendor Reviews"),
                    // header: (props) => <Navbar {...props} user={user}></Navbar>,
                    headerShown: false,
                  }}
                >
                  {(props) => (
                    <VendorReviews
                      {...props}
                      setTabStatus={setTabStatus}
                      tabStatus={tabStatus}
                    />
                  )}
                </Stack.Screen>
                <Stack.Screen
                  name="vendor-review"
                  getId={({ params }) => params.slug}
                  options={{
                    title: pageTitle("Vendor Review"),
                    // header: (props) => <Navbar {...props} user={user}></Navbar>,
                    headerShown: false,
                  }}
                >
                  {(props) => (
                    <VendorReviewDetails
                      {...props}
                      setTabStatus={setTabStatus}
                      tabStatus={tabStatus}
                    />
                  )}
                </Stack.Screen>
              </Stack.Navigator>
            </View>
          </View>
        </View>
        {/* <Footer></Footer> */}
      </View>
    </View>
  );
}
