import React, { useCallback, useEffect, useState } from "react";
import { Pressable, ScrollView, View } from "react-native";
import {
  MaterialIcons,
  MaterialCommunityIcons,
  Foundation,
} from "@expo/vector-icons";
import colors from "../../../colors";
import { Card } from "../../components/card";
import { Font } from "../../components/font";
import { Footer } from "../../components/footer";
import { ActivityIndicator } from "react-native";
import axiosInstance from "../../constants/axios-config";
import { apiURIV2 } from "../../constants";
import { isSignedIn } from "../../actions/auth-actions";
import { Switcher } from "../../components/input";
import ResponseModal from "../../components/general/modals/ResponseModal";
import { Platform } from "react-native";
import { RefreshControl } from "react-native";
import { catchAPIErrorText } from "../../utils/error-handler";
import { getFullDateTime } from "../../utils/date-time-util";

export const Transactions = ({ navigation, route }) => {
  const [transactions, setTransactions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [refreshing, setRefreshing] = useState(false);
  const [tabStatus, setTabStatus] = useState({ curr: undefined, prev: "" });
  const [showResponseModal, setShowResponseModal] = React.useState({
    visible: false,
    onRequestClose: () => null,
    bodyText: "",
  });

  const getTransactions = useCallback(async (mode) => {
    let data;
    setIsLoading(true);
    const token = await isSignedIn();
    axiosInstance
      .get(
        apiURIV2 + `${mode === "wallet" ? "/wallets/mine" : ""}/transactions`,
        // {
        //   limit: null,
        //   offset: null,
        // },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        data = res.data.data;
      })
      .catch((e) => {
        setIsLoading(false);

        setShowResponseModal({
          visible: true,
          onRequestClose: () => {
            setShowResponseModal({
              visible: false,
              onRequestClose: () => null,
              bodyText: "",
            });
          },
          bodyText: catchAPIErrorText(
            e,
            "Unknown Error while Getting Transactions"
          ),
        });
      })
      .finally(() => {
        setTransactions(data || []);
        setRefreshing(false);
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    setTabStatus({ curr: undefined, prev: "" });
    getTransactions();
  }, []);

  const onRefresh = useCallback(() => {
    setTransactions([]);
    setRefreshing(true);
    getTransactions(tabStatus.curr);
  }, [getTransactions]);

  return (
    <View className="flex-1 bg-app-e lg:bg-gray-50">
      <ScrollView
        className="py-4 px-4 lg:px-8 rounded-t-2xl bg-app-a"
        refreshControl={
          Platform.OS === "web" ? null : (
            <RefreshControl refreshing={refreshing} onRefresh={onRefresh} />
          )
        }
      >
        <View className="flex-1 justify-start max-w-screen-lg w-full mx-auto">
          <View className="flex-row mt-2 lg:mt-5 mb-5">
            <View className="hidden lg:flex lg:flex-1">
              <Font className="text-lg font-semibold text-app-d">
                Transactions
              </Font>
            </View>
            <View className="self-center justify-self-center flex-1">
              <Switcher
                options={[
                  { label: "All", value: undefined },
                  {
                    label: "Wallet",
                    value: "wallet",
                  },
                ]}
                value={tabStatus.curr}
                setValue={(e) => {
                  if (e !== tabStatus.curr && !isLoading) {
                    setTransactions([]);
                    getTransactions(e);
                    setTabStatus({ curr: e, prev: tabStatus.curr });
                  }
                }}
              />
            </View>
          </View>
          <View className="flex-col lg:flex-row max-w-screen-lg w-full">
            <View className="w-full p-0">
              {transactions.length > 0 && !isLoading ? (
                <Card title="All Transactions">
                  <View className="px-2 pt-0 w-full divide-y divide-app-c">
                    {transactions.map((e, i) => (
                      <Pressable
                        className="py-4 flex-row justify-between active:bg-app-c1 px-2 rounded-lg"
                        // onPress={() => navigation.navigate("invoice")}
                        key={`transaction_${i}`}
                      >
                        <View className="w-1/6 justify-center flex-col items-center">
                          {["top_up", "withdraw"].includes(
                            e.transaction_intent
                          ) ? (
                            <Foundation
                              name="dollar"
                              size={32}
                              color={
                                e.transaction_intent === "top_up"
                                  ? colors.app.g
                                  : e.transaction_intent === "withdraw"
                                  ? colors.app.e
                                  : colors.app.d
                              }
                            />
                          ) : [e.transaction_intent, e.type].includes(
                              "refund"
                            ) ? (
                            <MaterialCommunityIcons
                              name="cash-refund"
                              size={28}
                              color={colors.app.d}
                            />
                          ) : ["redeem", "add_rewards"].includes(
                              e.transaction_intent
                            ) ? (
                            <MaterialCommunityIcons
                              name={
                                e.transaction_intent === "add_rewards"
                                  ? "gift"
                                  : e.transaction_intent === "redeem"
                                  ? "gift-open"
                                  : ""
                              }
                              size={24}
                              color={colors.app.d}
                            />
                          ) : (
                            <MaterialIcons
                              name={"payments"}
                              size={28}
                              color={colors.app.d}
                            />
                          )}
                        </View>
                        <View className="flex-1 justify-center">
                          <Font className="text-app-d capitalize" weight={700}>
                            {(e.transaction_intent || e.type || "")
                              .split("_")
                              .join(" ")
                              .trim()}
                          </Font>
                          <Font
                            className="text-app-d leading-4 py-2"
                            weight={500}
                          >
                            {getFullDateTime(e.updated_at)}
                          </Font>
                          <Font
                            className={`${
                              e.transaction_type === "credit"
                                ? "text-app-g"
                                : e.transaction_type === "debit"
                                ? "text-app-e"
                                : "text-app-d"
                            }`}
                            weight={700}
                          >
                            {`${
                              e.transaction_type === "credit"
                                ? "+"
                                : e.transaction_type === "debit"
                                ? "-"
                                : ""
                            } ${e.currency_symbol ?? "$"} ${
                              +(e.amount || 0) / 100
                            }`}
                          </Font>
                        </View>
                        {/* {e.type ? (
                          <View className="justify-start">
                            <Font
                              className="text-app-g pb-2 capitalize leading-4 bg-app-g3 px-2 py-1 rounded-md"
                              weight={500}
                            >
                              {e.type.split("_")[0]}{" "}
                              {e.type.split("_")[2] ?? ""}
                            </Font>
                          </View>
                        ) : (
                          <></>
                        )} */}
                      </Pressable>
                    ))}
                  </View>
                </Card>
              ) : transactions.length === 0 && !isLoading ? (
                <Card className="flex">
                  <View className="w-full flex-row h-80 justify-center items-center">
                    <Font className="text-app-d1">No transactions found.</Font>
                  </View>
                </Card>
              ) : (
                <Card className="w-full flex-1">
                  <View className="flex-1 w-full justify-center h-40">
                    <ActivityIndicator
                      size={"large"}
                      color={colors.app.e}
                    ></ActivityIndicator>
                  </View>
                </Card>
              )}
            </View>
          </View>
          <Footer></Footer>
        </View>
      </ScrollView>
      {showResponseModal.visible && (
        <ResponseModal
          visible={showResponseModal.visible}
          onRequestClose={showResponseModal.onRequestClose}
          bodyText={showResponseModal.bodyText}
          dismissText={showResponseModal?.dismissText}
        />
      )}
    </View>
  );
};

export default Transactions;
