import React from "react";
import { Pressable, ScrollView } from "react-native";
import { View } from "react-native";
import { Font } from "./font";
import moment from "moment";
export function Calendar() {
    const [month, setMonth] = React.useState(null);
    let min = new Date(moment().subtract(60, "days"));
    let max = new Date(moment().add(40, "days"));
    const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    const prevMonth = () => {
        const date = new Date(month.date);
        updateMonth(new Date(date.getFullYear(), date.getMonth() - 1, 1));
    };
    const nextMonth = () => {
        const date = new Date(month.date);
        updateMonth(new Date(date.getFullYear(), date.getMonth() + 1, 1));
    };
    const currentMonth = () => {
        updateMonth(new Date());
    };
    const updateMonth = (dt) => {
        var month = dt.getMonth();
        var year = dt.getFullYear();
        var daysInMonth = new Date(year, month + 1, 0).getDate();
        var day = new Date(year, month, 1);
        setMonth({
            date: dt,
            days: daysInMonth,
            firstDay: day.getDay(),
        });
    };
    React.useEffect(() => {
        currentMonth();
    }, []);
    return month !== null ? (
        <View className="flex-1">
            <View className="flex-row h-10 items-center">
                <View className="flex-1">
                    <Font weight={700}>
                        {moment(month.date).format("MMMM YYYY")}
                    </Font>
                </View>
                <View className="ml-auto flex-row">
                    {min.getMonth() !== month.date.getMonth() ? (
                        <Pressable
                            className=" w-10 items-center justify-center"
                            onPress={prevMonth}
                        >
                            <Font className="Font-app-e" weight={700}>
                                &lt;
                            </Font>
                        </Pressable>
                    ) : (
                        <View className=" w-10 items-center justify-center"></View>
                    )}
                    {max.getMonth() !== month.date.getMonth() ? (
                        <Pressable
                            className=" w-10 items-center justify-center"
                            onPress={nextMonth}
                        >
                            <Font className="Font-app-e" weight={700}>
                                &gt;
                            </Font>
                        </Pressable>
                    ) : (
                        <View className=" w-10 items-center justify-center"></View>
                    )}
                </View>
            </View>
            <View className="flex-row flex-wrap border border-transparent ">
                {days.map((day, i) => (
                    <View
                        className="w-[14.285%] h-12 md:h-20 lg:h-32 items-center justify-center"
                        key={i}
                    >
                        <Font weight={600}>{day}</Font>
                    </View>
                ))}
            </View>
            <View className="flex-1 h-full flex-row flex-wrap border border-gray-100 ">
                {[...Array(month.firstDay).keys()].map((e, i) => (
                    <View
                        className="w-[14.285%] h-[16.67%] shrink-0 items-center justify-center border border-gray-100 box-border"
                        key={i}
                    >
                        <Font>&nbsp;</Font>
                    </View>
                ))}
                {[...Array(month.days).keys()]
                    .map((e) => e + 1)
                    .map((e, i) => (
                        <Pressable
                            className={`w-[14.285%] h-[16.67%] shrink-0 items-center justify-center border border-gray-100 box-border ${
                                new Date(
                                    month.date.getFullYear(),
                                    month.date.getMonth(),
                                    e
                                ).getTime() < min.getTime() ||
                                new Date(
                                    month.date.getFullYear(),
                                    month.date.getMonth(),
                                    e
                                ).getTime() > max.getTime()
                                    ? ""
                                    : "hover:bg-green-100"
                            }`}
                            key={i}
                            onPress={() => {
                                new Date(
                                    month.date.getFullYear(),
                                    month.date.getMonth(),
                                    e
                                ).getTime() < min.getTime() ||
                                new Date(
                                    month.date.getFullYear(),
                                    month.date.getMonth(),
                                    e
                                ).getTime() > max.getTime()
                                    ? ""
                                    : "hover:bg-green-100";
                            }}
                        >
                            <Font
                                weight={700}
                                className={
                                    new Date(
                                        month.date.getFullYear(),
                                        month.date.getMonth(),
                                        e
                                    ).getTime() < min.getTime() ||
                                    new Date(
                                        month.date.getFullYear(),
                                        month.date.getMonth(),
                                        e
                                    ).getTime() > max.getTime()
                                        ? "text-gray-300"
                                        : "text-black"
                                }
                            >
                                {e}
                            </Font>
                        </Pressable>
                    ))}
                {[
                    ...Array(
                        month.firstDay + month.days < 42
                            ? 42 - (month.firstDay + month.days)
                            : 0
                    ).keys(),
                ].map((e, i) => (
                    <View
                        className="w-[14.285%] h-[16.67%] shrink-0 items-center justify-center border border-gray-100 box-border"
                        key={i}
                    >
                        <Font>&nbsp;</Font>
                    </View>
                ))}
            </View>
        </View>
    ) : null;
}
