import React from "react";

const INITIAL_STATE = {
  tabStatus: { curr: undefined, prev: undefined },
  setTabStatus: () => ({ curr: undefined, prev: undefined }),
  reviews: null,
  setReviews: () => [],
  reviewsPagination: { from: null, to: null, total: null },
  setReviewsPagination: () => ({ from: null, to: null, total: null }),
  reviewDetails: null,
  setReviewDetails: () => null,
  posts: null,
  setPosts: () => null,
  postsPagination: { from: null, to: null, total: null },
  setPostsPagination: () => ({ from: null, to: null, total: null }),
  postDetails: null,
  setPostDetails: () => null,
  vendorFeedComments: null,
  setVendorFeedComments: () => null,
  resetVendorFeed: () => null,
};

export const VendorFeed = React.createContext(INITIAL_STATE);

const ReviewsReducer = (state, action) => {
  switch (action.type) {
    case "SET_TAB_STATUS":
      return { ...state, tabStatus: action.payload };
    case "SET_REVIEW_DETAILS":
      return { ...state, reviewDetails: action.payload };
    case "ADD_REVIEWS":
      return { ...state, reviews: action.payload };
    case "SET_REVIEWS_PAGINATION":
      return { ...state, reviewsPagination: action.payload };
    case "SET_POST_DETAILS":
      return { ...state, postDetails: action.payload };
    case "SET_FEED_COMMENTS":
      return { ...state, vendorFeedComments: action.payload };
    case "ADD_POSTS":
      return { ...state, posts: action.payload };
    case "SET_POSTS_PAGINATION":
      return { ...state, postsPagination: action.payload };
    case "RESET_REVIEWS_STATE":
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default function VendorFeedProvider({ children }) {
  const [state, dispatch] = React.useReducer(ReviewsReducer, INITIAL_STATE);

  return (
    <VendorFeed.Provider
      value={{
        tabStatus: state.tabStatus,
        setTabStatus: ({ curr, prev }) => {
          dispatch({
            type: "SET_TAB_STATUS",
            payload: { curr: curr, prev: prev },
          });
        },
        reviews: state.reviews,
        setReviews: ({ data, id, push = false }) => {
          dispatch({
            type: "ADD_REVIEWS",
            payload: {
              ...(state.reviews || {}),
              [id]: push ? { ...data, ...(state.reviews?.[id] || {}) } : data,
            },
          });
        },
        reviewsPagination: state.reviewsPagination,
        setReviewsPagination: ({ id, from, to, total }) => {
          dispatch({
            type: "SET_REVIEWS_PAGINATION",
            payload: {
              ...(state.reviewsPagination || {}),
              [id]: { from, to, total },
            },
          });
        },
        reviewDetails: state.reviewDetails,
        setReviewDetails: ({ data, id }) => {
          dispatch({
            type: "SET_REVIEW_DETAILS",
            payload: { ...state.reviewDetails, [id]: data || {} },
          });
        },
        vendorFeedComments: state.vendorFeedComments,
        setVendorFeedComments: ({ data, id }) => {
          dispatch({
            type: "SET_FEED_COMMENTS",
            payload: { ...state.vendorFeedComments, [id]: data || {} },
          });
        },
        posts: state.posts,
        setPosts: ({ data, push = false }) => {
          dispatch({
            type: "ADD_POSTS",
            payload: push ? [...(data || []), ...(state.posts || [])] : data,
          });
        },
        postsPagination: state.postsPagination,
        setPostsPagination: ({ from, to, total }) => {
          dispatch({
            type: "SET_POSTS_PAGINATION",
            payload: { from, to, total },
          });
        },
        postDetails: state.postDetails,
        setPostDetails: ({ data, pid }) => {
          dispatch({
            type: "SET_POST_DETAILS",
            payload: { ...state.postDetails, [pid]: data },
          });
        },
        resetVendorFeed: ({ data } = {}) => {
          dispatch({ type: "RESET_REVIEWS_STATE", payload: data || [] });
        },
      }}
    >
      {children}
    </VendorFeed.Provider>
  );
}

export const useVendorFeedContext = () => React.useContext(VendorFeed);
