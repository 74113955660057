import React from "react";
import { VendorRouting } from "./screens/vendor/routing";
import { SharedContextProvider } from "./contexts/shared-context";

export const Main = () => (
  <SharedContextProvider>
    <VendorRouting></VendorRouting>
  </SharedContextProvider>
);

