import React from "react";
import { Image, Pressable, View } from "react-native";
import { Font } from "./font";
import { Input } from "./input";

export const CartItem = () => {
    const [addNote, setAddNote] = React.useState(false);
    return (
        <View className="border-b border-gray-200 ">
            <View className="flex-row py-2 px-4">
                <View className="justify-center flex-1 flex-row">
                    <View>
                        <Image
                            source={require("../../assets/bip3.jpg")}
                            className="w-20 h-20 rounded"
                        ></Image>
                    </View>
                    <View className="flex-1 pl-2">
                        <Font
                            className="text-app-d"
                            weight={500}
                            numberOfLines={1}
                        >
                            Product name
                        </Font>
                        <View
                            className="flex-row items-center"
                            style={{
                                marginVertical: 2,
                            }}
                        >
                            <Font
                                className="text-app-d"
                                numberOfLines={1}
                                weight={700}
                            >
                                {" "}
                                $ 99.00{" "}
                            </Font>
                            <Font
                                className="text-app-e1 line-through ml-2"
                                numberOfLines={1}
                                weight={500}
                            >
                                {" "}
                                $ 150.00
                            </Font>
                        </View>
                        <View className="flex-row items-center">
                            <Input
                                type="smallcounter"
                                className="w-1/2"
                            ></Input>
                            <Pressable
                                className="flex-1 w-1/2 items-center"
                                onPress={() => setAddNote((e) => !e)}
                            >
                                <Font className="text-app-g">
                                    {!addNote ? "Add note" : "Remove note"}
                                </Font>
                            </Pressable>
                        </View>
                    </View>
                </View>
            </View>
            {addNote && (
                <View className="px-1 pb-1">
                    <Input type="text"></Input>
                </View>
            )}
        </View>
    );
};
