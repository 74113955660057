import React from "react";
import { Image, Pressable, ScrollView, Text, View } from "react-native";
import RemixIcon from "react-native-remix-icon";
import colors from "../../../colors";
import { Card } from "../../components/general/cards/card";
import { Font } from "../../components/font";
import { Button, Switcher } from "../../components/input";
import { Unauthorized } from "./unauthorized";
import { AuthContext } from "../../contexts/auth-context";
import { useBookingsContext } from "../../contexts/bookings-context";
import { apiURIV2, mediaURI } from "../../constants";
import axiosInstance from "../../constants/axios-config";
import { isSignedIn } from "../../actions/auth-actions";
import { RefreshControl } from "react-native";
import { FlatList } from "react-native";
import ResponseModal from "../../components/general/modals/ResponseModal";
import { LogBox } from "react-native";
import { Platform } from "react-native";
import { getFullDate } from "../../utils/date-time-util";
import { bookingStatusBadgeData } from "../../utils/statusBadgeValues";
import { ActivityIndicator } from "react-native";
import { DefaultImages } from "../../constants/predefined-constants";
import { catchAPIErrorText } from "../../utils/error-handler";

const initial_from = 1,
  load_records = 20;

export default function Bookings({ navigation, route }) {
  const [isBookingsLoading, setIsBookingsLoading] = React.useState();
  const [showResponseModal, setShowResponseModal] = React.useState({
    visible: false,
    onRequestClose: () => null,
    bodyText: "",
  });

  const [isBookingsFailed, setIsBookingsFailed] = React.useState();
  const [bookingsData, setBookingsData] = React.useState();

  const [isFirstCame, setIsFirstCame] = React.useState(true);

  const { user } = React.useContext(AuthContext);
  const {
    tabStatus,
    setTabStatus,
    bookings,
    setBookings,
    pagination,
    setPagination,
  } = useBookingsContext();

  React.useEffect(() => {
    if (isFirstCame) {
      setIsFirstCame((isFirstCame) => !isFirstCame);
      setPagination({
        from: 1,
        to: load_records,
        total: pagination?.total,
      });
    }
  }, [isFirstCame, setPagination, pagination?.total]);

  const getVendorBookings = async (offset, limit, tab_status, push) => {
    const statuses =
      tab_status === "created"
        ? ["created"]
        : tab_status === "completed"
        ? ["completed"]
        : tab_status === "cancelled"
        ? ["cancelled_by_customer", "cancelled_by_vendor", "failed", "rejected"]
        : undefined;
    setIsBookingsLoading(true);
    let data,
      total_count = 0;
    try {
      const token = await isSignedIn();
      const res = await axiosInstance.get(apiURIV2 + "/bookings/service", {
        params: {
          offset,
          limit: limit || load_records,
          statuses: statuses,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      // console.log("bookings res.status: ", res.status, res.data);
      if (res.status === 200) {
        total_count = res.data.total_count;
        data = res.data.data;
      }
    } catch (e) {
      console.log("bookings e.res", e?.response, e);
      if (!offset && !limit) {
        setTabStatus({ curr: tabStatus.curr, prev: undefined });
      }
      setShowResponseModal({
        visible: true,
        onRequestClose: () => {
          setShowResponseModal({
            visible: false,
            onRequestClose: () => null,
            bodyText: "",
          });
        },
        bodyText: catchAPIErrorText(e, "Unknown Error while Getting Bookings"),
      });
      setIsBookingsFailed(true);
    } finally {
      if (data) {
        const pagObj = {
          from: (offset || -1) + 1 || initial_from,
          to:
            (offset || -1) + 1
              ? offset + (data?.length || load_records)
              : data?.length || load_records,
          total: total_count,
        };

        console.log("finally", "pagObj:", pagObj);
        setPagination(pagObj);
        setBookings({ data: data, push });
        setBookingsData(data);
      }
      setIsBookingsLoading(false);
    }
  };

  if (!bookings && !isBookingsLoading && !isBookingsFailed) {
    getVendorBookings();
  } else if (bookings && !bookingsData) {
    setBookingsData(bookings.slice(0, load_records));
  }

  React.useEffect(() => {
    LogBox.ignoreLogs(["VirtualizedLists should never be nested"]);
  }, []);

  if (!user) {
    return <Unauthorized />;
  } else if (isBookingsFailed) {
    return (
      <View className="flex-1 justify-center items-center">
        {showResponseModal.visible && (
          <ResponseModal
            visible={showResponseModal.visible}
            onRequestClose={showResponseModal.onRequestClose}
            bodyText={showResponseModal.bodyText}
          />
        )}
        <View className="p-4">
          <Font className="text-app-d font-semibold text-base" weight={700}>
            Failed to Load the Data
          </Font>
          <Font className="text-app-d1 py-2">Unable to Connect the Server</Font>
          <Button
            label="Retry"
            type="primary"
            onPress={() => {
              if (!isBookingsLoading) {
                getVendorBookings();
                setIsBookingsFailed(false);
              }
            }}
          />
        </View>
      </View>
    );
  } else if (!bookings || !bookingsData) {
    return (
      <View className="flex-1 justify-center items-center">
        {showResponseModal.visible && (
          <ResponseModal
            visible={showResponseModal.visible}
            onRequestClose={showResponseModal.onRequestClose}
            bodyText={showResponseModal.bodyText}
          />
        )}
        <ActivityIndicator
          animating={!bookings && !bookingsData}
          size={Platform.OS === "web" ? "small" : "large"}
          color={colors.app.e}
          className="mb-4 shadow-md rounded-full p-2"
        />
      </View>
    );
  }

  const statusBadgeData = (status) => {
    let bookingStatus = bookingStatusBadgeData(status);
    return (
      <Font
        className={`px-2 py-1 text-xs rounded-full uppercase border ${bookingStatus.bgcolor} ${bookingStatus.textColor} ${bookingStatus.borderColor}`}
      >
        {bookingStatus.text}
      </Font>
    );
  };

  return user ? (
    <View className="flex-1 bg-app-e lg:bg-gray-50">
      {showResponseModal.visible && (
        <ResponseModal
          visible={showResponseModal.visible}
          onRequestClose={showResponseModal.onRequestClose}
          bodyText={showResponseModal.bodyText}
        />
      )}
      <ScrollView
        className="px-4 pt-1 sm:pt-2 lg:pt-4 pb-4 rounded-t-2xl bg-gray-50"
        contentContainerStyle={{
          flexGrow: 1,
          justifyContent: "center",
          alignItems: "center",
        }}
        nestedScrollEnabled={true}
        refreshControl={
          Platform.OS === "web" ? null : (
            <RefreshControl
              refreshing={isBookingsLoading}
              onRefresh={() => {
                getVendorBookings(undefined, undefined, tabStatus.curr);
              }}
            />
          )
        }
      >
        <View className=" max-w-screen-lg w-full mx-auto h-full">
          <View className="mt-3 mb-4 lg:my-4 p-0 lg:px-4 flex-col w-full">
            <View className="flex flex-row items-center">
              <View className="hidden lg:flex mr-4 flex-1">
                <Font className="text-lg font-semibold text-app-d">
                  Bookings
                </Font>
              </View>
              {/* <View className="w-40 lg:ml-4 ">
                    <Select
                      options={[{ label: "All Listings", value: "All Listings" }]}
                      value={"All Listings"}
                    />
                  </View> */}
              <View className="self-center justify-self-center flex-1">
                <Switcher
                  options={[
                    { label: "All", value: undefined },
                    // { label: "Pending", value: "created" },
                    { label: "Completed", value: "completed" },
                    {
                      label: "Canceled",
                      value: "cancelled",
                    },
                  ]}
                  value={tabStatus.curr}
                  setValue={(e) => {
                    if (e !== tabStatus.curr) {
                      getVendorBookings(undefined, undefined, e);
                      setTabStatus({ curr: e, prev: tabStatus.curr });
                    }
                  }}
                />
              </View>
            </View>
          </View>
          <View className="p-0 lg:mx-4 flex-1">
            <Card title={`${tabStatus.curr || "All"} Bookings`}>
              {
                <FlatList
                  className="w-full"
                  initialNumToRender={load_records}
                  data={bookingsData}
                  ListEmptyComponent={() => (
                    <View className="w-full justify-center items-center pt-4 pb-8">
                      <Font>No Bookings Found</Font>
                    </View>
                  )}
                  renderItem={({ item: e }) => (
                    <Pressable
                      onPress={() =>
                        navigation.navigate("booking-details", { bid: e?.id })
                      }
                      className="p-4 border-b border-gray-200 flex-row w-full"
                      key={e?.id}
                    >
                      <View className="justify-center items-center w-20 xs:w-24 lg:w-32">
                        <Image
                          source={
                            e?.user?.profile_image_url
                              ? { uri: mediaURI + e?.user?.profile_image_url }
                              : DefaultImages.DP_PLACEHOLDER
                          }
                          className="w-14 h-14 lg:w-22 lg:h-22 rounded-full"
                        ></Image>
                        <Font className="mt-2 text-app-d1 text-center">
                          {`${e?.user?.first_name ? e?.user?.first_name : ""}${
                            e?.user?.last_name ? " " + e?.user?.last_name : ""
                          }`}
                        </Font>
                      </View>
                      <View className="justify-center ml-4 flex-1">
                        <Font className="text-app-f text-sm">
                          {e?.listing
                            ? e?.listing?.listing_category?.title
                            : "Unknown Category"}
                        </Font>
                        <Font className="text-md text-app-d lg:text-lg font-semibold mb-1">
                          {e?.listing ? e?.listing?.title : "Unknown Listing"}
                        </Font>
                        <View className="flex-row items-center mb-1">
                          <RemixIcon
                            name="ri-calendar-fill"
                            size={14}
                            color={colors.app.d1}
                          ></RemixIcon>
                          <Font className="text-app-d1 text-md leading-4 ml-2">
                            {(() => {
                              const d = e?.sessions
                                ? e?.sessions?.length > 1
                                  ? `${getFullDate(
                                      e?.sessions[0].date
                                    )} to ${getFullDate(
                                      e?.sessions[e?.sessions?.length - 1].date
                                    )}`
                                  : getFullDate(e?.sessions[0].date)
                                : "Unknown Date";
                              return d;
                            })()}
                          </Font>
                        </View>
                        <View className="flex-row items-center mb-2">
                          <RemixIcon
                            name="ri-user-fill"
                            size={14}
                            color={colors.app.d1}
                          ></RemixIcon>
                          <Font className="text-app-d1 text-md ml-2 leading-4">
                            {`${
                              e?.sessions
                                ? (() => {
                                    let count = 0;
                                    e?.sessions.forEach(
                                      (v) => (count += v.service_unit_quantity)
                                    );
                                    return count;
                                  })()
                                : "Unknown"
                            } ${e?.service?.service_unit?.name || "unit"}(s)`}
                          </Font>
                        </View>
                        <View className="flex-1 flex-row">
                          {statusBadgeData(e?.status)}
                        </View>
                      </View>
                    </Pressable>
                  )}
                />
              }

              {load_records < pagination?.total && (
                <View className="flex-row justify-between w-full">
                  {pagination.from > initial_from && (
                    <Pressable
                      className="p-4 "
                      onPress={() => {
                        if (pagination.from > initial_from) {
                          const from =
                            pagination.from === 1
                              ? 1
                              : pagination.from - load_records;
                          const toModulus = pagination.to % load_records;
                          const to =
                            pagination.to -
                            (toModulus > 0
                              ? toModulus
                              : pagination.to > load_records
                              ? load_records
                              : 0);
                          const pagObj = {
                            from: from,
                            to: to,
                            total: pagination.total,
                          };
                          setPagination(pagObj);
                          // getListingData(from - 1, load_records, true);
                          setBookingsData(bookings.slice(from - 1, to));
                        }
                      }}
                    >
                      <Font className="text-app-e"> &lt; Newer</Font>
                    </Pressable>
                  )}
                  <View className="flex-1" />
                  {pagination.to < pagination.total && (
                    <Pressable
                      className="p-4"
                      onPress={() => {
                        if (pagination.to < pagination.total) {
                          const remList = pagination.total - pagination.to;
                          const from =
                            pagination.from + (remList > 0 ? load_records : 0);
                          const to =
                            pagination.to +
                            (remList < load_records ? remList : load_records);
                          const pagObj = {
                            from: from,
                            to: to,
                            total: pagination.total,
                          };
                          const listData = bookings.slice(from - 1, to);
                          if (listData?.length) {
                            setPagination(pagObj);
                            setBookingsData(listData);
                          } else {
                            getVendorBookings(
                              from - 1,
                              load_records,
                              tabStatus.curr,
                              true
                            );
                          }
                        }
                      }}
                    >
                      <Font className="text-app-e"> Older &gt;</Font>
                    </Pressable>
                  )}
                </View>
              )}
            </Card>
          </View>
        </View>
      </ScrollView>
      {Platform.OS === "web" && isBookingsLoading && (
        <ActivityIndicator
          animating={isBookingsLoading}
          size="small"
          color={colors.app.d}
          className={`mb-4 shadow-md rounded-full p-2 absolute self-center bg-white top-8 transform transition-all ease-in-out delay-100 duration-1000 opacity-0 ${
            isBookingsLoading && "opacity-100"
          }`}
        />
      )}
    </View>
  ) : (
    <Unauthorized />
  );
}
