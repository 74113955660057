import { StyleSheet, View } from "react-native";
import React from "react";
import Html5QrcodePlugin from "./general/scanners/html5QrcodePlugin.web";
import { Modal, Pressable } from "react-native-web";
import { Card } from "./general/cards/card";
import RemixIcon from "react-native-remix-icon";
import colors from "../../colors";
import { ActivityIndicator } from "react-native";

const QRCodeScanner = ({
  visible,
  onRequestClose,
  onScannedBarcodeInfo,
  showLoadingIndicator,
}) => {
  const onNewScanResult = (decodedText /*, decodedResult*/) => {
    // handle decoded results here
    // console.log("decodedText:", decodedText);
    // console.log("\n\ndecodedResult:", decodedResult);
    onScannedBarcodeInfo({ data: decodedText });
  };

  return (
    <Modal transparent={true} visible={visible} onRequestClose={onRequestClose}>
      <View style={styles.container} className="bg-black/50">
        <Card
          title="Verify Booking QR"
          className="self-center justify-self-center w-full px-4 md:max-w-[50%] lg:max-w-[40%] md:max-h-[70%]"
          Widget={() => (
            <Pressable onPress={onRequestClose}>
              <RemixIcon
                name="ri-close-fill"
                className=""
                size={25}
                color={colors.app.e}
              />
            </Pressable>
          )}
        >
          <View className="bg-white rounded-lg w-full h-2/3 md:h-1/3 lg:w-96 lg:h-[410px]">
            <View className="h-full flex-1 px-4 pb-4 relative">
              <Html5QrcodePlugin qrCodeSuccessCallback={onNewScanResult} />
              {showLoadingIndicator && (
                <ActivityIndicator
                  className="self-center justify-self-center absolute flex-1 w-full h-full -top-6"
                  animating={showLoadingIndicator}
                  size="large"
                  color={colors.app.e}
                />
              )}
            </View>
          </View>
        </Card>
      </View>
    </Modal>
  );
};

export default QRCodeScanner;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "column",
    justifyContent: "center",
  },
});
