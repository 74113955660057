import React, { useEffect, useState } from "react";
import { View, TouchableOpacity, Pressable } from "react-native";
import {
  getStats,
  getRecentActivites,
  clearAllPackages,
} from "../../services/api";
import { Font } from "../../components/font";
import { Footer } from "../../components/footer";
import { Card } from "../../components/general/cards/card";

import { ScrollView } from "react-native";
import RemixIcon from "react-native-remix-icon";
import colors from "../../../colors";
import {
  AcceptRequest,
  GetGroupJoinRequest,
  RejectRequest,
} from "../../actions/auth-actions";

export default function GroupJoinRequests({ navigation, route }) {
  React.useEffect(() => {
    if (route?.params) {
      const { id, groupId, userToken, groupMembersLists } = route?.params;
      setGroupMemberList(groupMembersLists);
      setGroupId(groupId);
      setId(id);
    }
  }, [route?.params]);
  const [groupId, setGroupId] = useState();
  const [id, setId] = useState();

  const [groupMembersList, setGroupMemberList] = useState([]);

  const [isVisible, setIsVisible] = useState(false);

  const [stats, setStats] = React.useState({
    total_listings: 0,
    total_products: 0,
    bookings_completed: 0,
    orders_fulfilled: 0,
  });
  const [activities, setActivities] = React.useState([]);
  const [visibleIndex, setVisibleIndex] = React.useState(0);

  const itemsPerPage = 2;
  const totalItems = activities.length;

  useEffect(() => {
    getActivities();
    GetRequests();
  }, []);

  const getActivities = async () => {
    try {
      let res = await getStats();
      if (res.status === 200) setStats(res.data.data);
      else
        setStats({
          total_listings: 0,
          total_products: 0,
          bookings_completed: 0,
          orders_fulfilled: 0,
        });
      let activities = await getRecentActivites();
      if (activities.status === 200) setActivities(activities.data.data || []);
    } catch (error) {
      console.log("Error Getting Stats : ", error.response);
    }
  };

  const GetRequests = async () => {
    try {
      const res = await GetGroupJoinRequest(groupId);
      console.log(res.status, "get req status");

      if (res.status == 200) {
        setGroupMemberList(res.data.data);
        // console.log(res.data.data, "get req data");
      } else {
        console.log(res, "status");
      }
    } catch (error) {}
  };
  const RejectRequestOfUser = async (request_id) => {
    try {
      console.log(request_id, "req id");

      const res = await RejectRequest(groupId, request_id);
      console.log(res.status, "status");
      console.log(res.data, "reject res data");
      if (res.status == 200) {
        setGroupMemberList((prevList) =>
          prevList.filter((member) => member.id !== request_id)
        );
      } else {
        console.log(res, "status");
      }
    } catch (error) {}
  };
  const AcceptRequestOfUser = async (request_id) => {
    try {
      console.log(request_id, "req id");
      const res = await AcceptRequest(groupId, request_id);
      console.log(res.status, "status");
      console.log(res.data, "accept res data");

      if (res.status == 200) {
        setGroupMemberList((prevList) =>
          prevList.filter((member) => member.id !== request_id)
        );
      } else {
        console.log(res, "status");
      }
    } catch (error) {}
  };

  return (
    <ScrollView
      className="p-4 rounded-t-2xl bg-gray-50"
      contentContainerStyle={{
        flexGrow: 1,
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <View className="max-w-screen-lg w-full h-full">
        <View className="max-w-screen-lg w-full flex-col justify-center items-center">
          {/* ------------------------Join requests - section----------------------------------- */}
          <>
            <View className="w-full flex-col item-center">
              {groupMembersList?.length == 0 ? (
                <View className=" w-full flex justify-center items-center">
                  <Font
                    className="text-app-d1 text-xs items-center"
                    weight={500}
                  >
                    No Requests
                  </Font>
                </View>
              ) : (
                groupMembersList?.map((e, i) => (
                  <Card className="mb-6 lg:mb-0 lg:mr-2 lg:flex-1" key={i}>
                    <TouchableOpacity
                      className="px-4 py-2 flex-row justify-between w-full"
                      // onPress={() => navigation.navigate("add-member")}
                    >
                      <View className="flex-row justify-start items-center">
                        <View className="flex justify-center items-center">
                          <RemixIcon
                            name="ri-user-fill"
                            size={16}
                            color={colors.app.g4}
                            className="mr-1 lg:mr-4 -ml-2.5 w-4 h-4 lg:w-6 lg:h-6 flex-shrink-0"
                          />
                        </View>
                        <Font className="font-medium w-[190]">
                          {/* James (james123@gmail.com) */}
                          {e?.requester.first_name +
                            " " +
                            e?.requester.last_name}
                          <Font className="font-medium">
                            {/* James (james123@gmail.com) */}
                            {" " + e?.requester.email + " "}
                          </Font>
                        </Font>
                      </View>
                      <View className="-mr-2.5 lg:mr-0 flex-row justify-center items-center">
                        <Pressable
                          onPress={() => AcceptRequestOfUser(e?.id)}
                          className="p-1 mr-1 lg:mr-2 flex justify-center items-center bg-green-200 border border-green-600 rounded-lg"
                        >
                          <Font className="text-xs text-green-600">Accept</Font>
                        </Pressable>
                        <Pressable
                          onPress={() => RejectRequestOfUser(e?.id)}
                          className="p-1 flex justify-center items-center bg-red-200 border border-app-e rounded-lg"
                        >
                          <Font className="text-xs text-app-e">Decline</Font>
                        </Pressable>
                      </View>
                    </TouchableOpacity>
                  </Card>
                ))
              )}
            </View>
          </>
        </View>
        <Footer navigation={navigation} />
      </View>
    </ScrollView>
  );
}
