import moment from "moment";

export const getFullDateTime = (date, moment_format) => {
  if (!date) {
    return "";
  }
  return moment(date).format(moment_format || "MMMM D, YYYY \\at h:mm A");
};

export const getFullDate = (date, moment_format) => {
  if (!date) {
    return "";
  }
  return moment(date).format(moment_format || "MMMM D, YYYY");
};

export const getFullDateWithWeekday = (date, moment_format) => {
  if (!date) {
    return "";
  }
  return moment(date).format(moment_format || "MMMM D, YYYY");
};

export const getTime = (date, moment_format) => {
  if (!date) {
    return "";
  }
  return moment(date).format(moment_format || "h:mm A");
};

export const fromNow = ({ date, format, fromNow } = {}) => {
  if (!date) {
    return "";
  }
  console.log("new Date(date).getDate():", new Date(date).getDate());
  console.log("new Date().getDate():", new Date().getDate());
  return moment(date)[
    fromNow && new Date(date).getDate() === new Date().getDate()
      ? "fromNow"
      : "format"
  ](format || "MMMM D, YYYY \\at h:mm A");
};
