import React from "react";
import { View } from "react-native";
import { Font } from "../../font";

const ListingPlaceholder = ({ name, style }) => {
  name = name
    .split(" ")
    .map((v, i, a) => v.substr(0, a?.length === 1 ? 2 : 1))
    .join("")
    .substr(0, 2);
  return (
    <View style={style} className="overflow-hidden border border-app-e2">
      <View className="bg-app-e2/20 flex-1 justify-center items-center">
        <View className="w-max h-max rounded-full w-[64px] h-[64px] justify-center items-center bg-app-e/80 overflow-visible">
          <Font className="text-app-a text-lg leading-tight pb-[2px] uppercase">
            {name}
          </Font>
        </View>
      </View>
    </View>
  );
};

export default ListingPlaceholder;
