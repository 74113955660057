import moment from "moment";
import React from "react";
import { Image, Pressable, ScrollView, Text, View } from "react-native";
import RemixIcon from "react-native-remix-icon";
import colors from "../../../colors";
import { Card } from "../../components/general/cards/card";
import { Font } from "../../components/font";
import { Footer } from "../../components/footer";
import { Widget } from "../../components/widget";
import { DEV_API, getPublicData } from "../../services/api";

export default function Blog({ navigation, route }) {
  const postsLimit = 10;
  const [posts, setPosts] = React.useState([]);
  const [page, setPage] = React.useState(0);

  const goToPost = (slug) => {
    navigation.navigate({ name: "post", params: { slug: slug } });
  };
  return (
    <View className="flex-1 bg-app-e lg:bg-gray-50">
      <ScrollView
        className="p-4 rounded-t-2xl bg-gray-50"
        contentContainerStyle={{
          flexGrow: 1,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <View className=" max-w-screen-lg w-full mx-auto">
          <View className="my-5 hidden lg:flex">
            <Font className="text-lg font-semibold text-app-d">Blog</Font>
          </View>
          <View className="flex-col lg:flex-row max-w-screen-lg w-full">
            <View className=" w-full lg:w-4/6 p-0 lg:pr-4">
              <Card title="Latest posts">
                {[...Array(10).keys()].map((e, i) => (
                  <Pressable
                    className="w-full px-4 py-2 border-b border-gray-200 flex-col lg:flex-row"
                    key={i}
                    onPress={() => goToPost(e.slug)}
                  >
                    <Image
                      source={require("../../../assets/banner.jpg")}
                      className="w-full lg:w-60 h-32 rounded-lg"
                    ></Image>
                    <View className="flex-1 mt-4 lg:ml-4">
                      <Font
                        className="font-semibold text-[15px] text-app-d"
                        numberOfLines={3}
                      >
                        The post title is here
                      </Font>
                      <Font className="text-app-d1 my-2" weight={500}>
                        {moment(new Date()).format("llll")}
                      </Font>
                      <View
                        className="
                                            "
                      >
                        <Font
                          className="text-app-d"
                          weight={500}
                          numberOfLines={3}
                        >
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry&rsquo;s standard dummy text ever since the
                          1500s, when an unknown printer took a galley of type
                          and scrambled it to make a type specimen book. It has
                          survived not only five centuries, but also the leap
                          into electronic typesetting, remaining essentially
                          unchanged. It was popularised in the 1960s with the
                          release of Letraset sheets containing Lorem Ipsum
                          passages, and more recently with desktop publishing
                          software like Aldus PageMaker including versions of
                          Lorem Ipsum.
                        </Font>
                      </View>
                    </View>
                  </Pressable>
                ))}
                <View className="flex-row w-full">
                  {/* {page > 0 && ( */}
                  <Pressable
                    className="p-4"
                    onPress={() => setPage((e) => e - 1)}
                  >
                    <Font className="text-app-e" weight={600}>
                      &lt; Newer
                    </Font>
                  </Pressable>
                  {/* )} */}
                  <View className="flex-1"></View>
                  {/* {postsLimit <= posts.length && ( */}
                  <Pressable
                    className="p-4"
                    onPress={() => setPage((e) => e + 1)}
                  >
                    <Font className="text-app-e" weight={600}>
                      Older &gt;
                    </Font>
                  </Pressable>
                  {/* )} */}
                </View>
              </Card>
            </View>
            <View className=" w-full lg:w-2/6 p-0 lg:pl-4">
              <Widget></Widget>
            </View>
          </View>
          <Footer navigation={navigation}></Footer>
        </View>
      </ScrollView>
    </View>
  );
}
