import moment from "moment";
import React from "react";
import { Image, Pressable, ScrollView, Text, View } from "react-native";
import RemixIcon from "react-native-remix-icon";
import colors from "../../../colors";
import { Card } from "../../components/general/cards/card";
import { Font } from "../../components/font";
import { Footer } from "../../components/footer";
import { Button, Input } from "../../components/input";
import { CartItem } from "../../components/shop";
import { DEV_API, getPublicData } from "../../services/api";

export default function Checkout({ navigation, route }) {
  const postsLimit = 10;
  const [posts, setPosts] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const getPosts = async () => {
    let data = await getPublicData(
      `/posts?postType=blog&_sort=createdAt:DESC&_limit=${postsLimit}&_start=${
        page * postsLimit
      }`
    );
    if (data) {
      setPosts(data.data);
    }
  };
  React.useEffect(() => {
    getPosts();
  }, [page]);
  const goToPost = (slug) => {
    console.log(slug);
    navigation.navigate({ name: "post", params: { slug: slug } });
  };
  const extractContent = (s, space) => {
    var span = document.createElement("span");
    span.innerHTML = s;
    if (space) {
      var children = span.querySelectorAll("*");
      for (var i = 0; i < children.length; i++) {
        if (children[i].textContent) children[i].textContent += " ";
        else children[i].innerText += " ";
      }
    }
    return [span.textContent || span.innerText].toString().replace(/ +/g, " ");
  };
  return (
    <View className="flex-1 bg-app-e lg:bg-gray-50">
      <ScrollView
        className="p-4 rounded-t-2xl bg-gray-50"
        contentContainerStyle={{
          flexGrow: 1,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <View className=" max-w-screen-lg w-full mx-auto">
          <View className="my-5 hidden lg:flex">
            <Font className="text-lg font-semibold text-app-d">Checkout</Font>
          </View>
          <View className="flex-col lg:flex-row max-w-screen-lg w-full">
            <View className=" w-full lg:w-4/6 p-0 lg:pr-4">
              <Card title="Select shipping address">
                <View className="p-4 pt-0">
                  <Font className="text-app-d" weight={500}>
                    <Font className="text-app-d" weight={700}>
                      Recepient Name
                    </Font>{" "}
                    (Home)
                  </Font>
                  <Font className="text-app-d my-2" weight={600}>
                    +62123123123
                  </Font>
                  <Font className="text-app-d" weight={500}>
                    We collaboratively administrate turnkey channels where
                    kingdom businesses can become virtual retailers. We provide
                    scalable metrics, proactive e-services and objective metrics
                    for small businesses to thrive in any economic environment.
                  </Font>
                </View>
                <View className="p-2 w-full flex-row">
                  <Button label="Switch Address" className="w-1/2"></Button>
                  <View className="w-1/2"></View>
                </View>
              </Card>
              <Card title="Order Item">
                <View className="flex-1 w-full bg-white rounded-2xl lg:rounded-none p-4 pt-0">
                  <View className="pb-4 border-b border-gray-200 flex-row items-center">
                    <View>
                      <Font className="text-app-d" weight={600}>
                        Store name
                      </Font>
                      <Font className="text-app-d1 text-xs -mt-1" weight={600}>
                        Region
                      </Font>
                    </View>
                    <View className="flex-1"></View>
                  </View>
                  {[...Array(3).keys()].map((e, i) => (
                    <View key={i}>
                      <CartItem></CartItem>
                    </View>
                  ))}
                  <View className="flex-row pt-4">
                    <Font>Subtotal</Font>
                    <View className="w-1/2"></View>
                    <Font>$ 120.00</Font>
                  </View>
                </View>
                <View className="p-4 w-full flex-row">
                  <Button label="Select Shipping" className="w-1/2"></Button>
                  <View className="w-1/2"></View>
                </View>
              </Card>
              <Card title="Message">
                <View className="p-4 w-full">
                  <Input type="longtext" className="w-full"></Input>
                </View>
              </Card>
            </View>
            <View className=" w-full lg:w-2/6 p-0 lg:pl-4">
              <Card title="Payment">
                <View className="flex-1 w-full p-4 pt-0">
                  <View className="w-full flex-row">
                    <Input
                      type="coupon"
                      label="Have a coupon?"
                      className="w-1/2"
                    ></Input>
                  </View>

                  <View className="flex-row w-full py-4">
                    <Font className="flex-1">Subtotal</Font>
                    <Font>$ 240.00</Font>
                  </View>
                  <View className="flex-row w-full pb-4">
                    <Font className="flex-1">Reward</Font>
                    <Font>$ 7.20</Font>
                  </View>
                  <View className="w-full flex-row  my-2">
                    <Input type="checkbox" label="Use reward balance"></Input>
                  </View>

                  <View className="flex-row w-full py-4">
                    <Font className="flex-1">Pay with reward</Font>
                    <Font>$ -40.00</Font>
                  </View>
                  <View className="flex-row w-full pb-4">
                    <Font className="flex-1">Total</Font>
                    <Font>$ 200.00</Font>
                  </View>
                  <View className="w-full flex-row mt-4">
                    <Button
                      label="Select Payment Method"
                      className="w-1/2"
                    ></Button>
                  </View>
                  <View className="w-full flex-row mt-4">
                    <Button
                      type="primary"
                      label="Pay"
                      className="w-1/2"
                    ></Button>
                    <View className="w-1/2"></View>
                  </View>
                </View>
              </Card>
            </View>
          </View>
          <Footer navigation={navigation}></Footer>
        </View>
      </ScrollView>
    </View>
  );
}
