import React from "react";
import { Pressable } from "react-native";
import { View } from "react-native";
import { Platform } from "react-native";
import { FlatList } from "react-native";
import RemixIcon from "react-native-remix-icon";
import colors from "../../../../colors";
import { Font } from "../../font";

const ServicesWidget = (
  {
    categoryWiseServicesData,
    deleteCategoryHandler,
    editServiceHandler,
    deleteServiceHandler,
  } = {
    categoryWiseServicesData: [],
    deleteCategoryHandler: () => null,
    editServiceHandler: () => null,
    deleteServiceHandler: () => null,
  }
) => {
  return (
    <FlatList
      data={Object.keys(categoryWiseServicesData || {})}
      renderItem={({ item }) => (
        <>
          <View className="w-full bg-app-e/80 rounded relative mb-4 items-center flex-row w-full py-3">
            <View className="h-6 absolute w-2 bg-app-b rounded-r"></View>
            <Font
              className="px-4 w-full text-app-a leading-4 text-[15px] flex-1"
              numberOfLines={2}
            >
              {item}
            </Font>
            {!!deleteCategoryHandler && (
              <Pressable
                className="mr-4"
                onPress={() => deleteCategoryHandler(item)}
              >
                <RemixIcon
                  name="ri-delete-bin-5-line"
                  className="self-start"
                  size={20}
                  color={colors.app.a}
                />
              </Pressable>
            )}
          </View>
          <View className="w-full">
            <FlatList
              ItemSeparatorComponent={() => {
                return (
                  <View
                    style={{
                      marginHorizontal: 10,
                    }}
                  />
                );
              }}
              data={categoryWiseServicesData?.[item]}
              horizontal
              showsHorizontalScrollIndicator={false}
              renderItem={({
                item: { id, title, subtitle, service_unit } = {},
                index,
              }) => (
                <View
                  key={"bs" + id}
                  className={`rounded-md bg-app-c3 border border-app-c1 p-4 mb-4 ${
                    Platform.OS === "web"
                      ? "min-w-[208px] max-w-[328px]"
                      : "min-w-[60vw] w-fit max-w-[83vw]"
                  }`}
                >
                  <View className="flex-row items-start relative">
                    {/* <View className="h-6 absolute w-1 bg-app-e  rounded-r"></View> */}
                    <Font
                      weight={700}
                      className="font-semibold text-base flex-1"
                      numberOfLines={2}
                    >
                      {title}
                    </Font>
                    {!!editServiceHandler && (
                      <Pressable
                        className="mr-2"
                        onPress={() =>
                          editServiceHandler(
                            item,
                            categoryWiseServicesData[item],
                            index
                          )
                        }
                      >
                        <RemixIcon
                          name="ri-pencil-fill"
                          className="self-start mt-1 ml-2"
                          size={20}
                          color={colors.app.d2}
                        />
                      </Pressable>
                    )}
                    {!!deleteServiceHandler && (
                      <Pressable
                        onPress={() =>
                          deleteServiceHandler(
                            item,
                            categoryWiseServicesData[item],
                            index
                          )
                        }
                      >
                        <RemixIcon
                          name="ri-delete-bin-5-fill"
                          className="self-start mt-1 ml-1"
                          size={20}
                          color={colors.app.d2}
                        />
                      </Pressable>
                    )}
                  </View>
                  <Font className="py-1">{subtitle}</Font>
                  <Font weight={600} className="font-semibold">
                    {`${service_unit.currency_symbol} ${
                      service_unit.price / 100
                    } / ${service_unit.name}`}
                  </Font>
                </View>
              )}
            />
          </View>
        </>
      )}
    />
  );
};

export default ServicesWidget;
