import React from "react";
import { Platform, Text } from "react-native";
import { Link } from "@react-navigation/native";

export const Font = ({ selectable = false, numberOfLines, ...props }) => {
  let weight = {
    300: "Quicksand_300Light",
    400: "Quicksand_400Regular",
    500: "Quicksand_500Medium",
    600: "Quicksand_600SemiBold",
    700: "Quicksand_700Bold",
  };
  let styl = props.style
    ? props.style.reduce(function (acc, x) {
        for (var key in x) acc[key] = x[key];
        return acc;
      }, {})
    : [];
  return (
    <Text
      {...props}
      style={{
        fontFamily: weight[props.weight ? props.weight : 500],
        ...styl,
      }}
      selectable={selectable}
      // allowFontScaling={true}
      numberOfLines={numberOfLines}
    ></Text>
  );
};

export const A = (props) => {
  let weight = {
    300: "Quicksand_300Light",
    400: "Quicksand_400Regular",
    500: "Quicksand_500Medium",
    600: "Quicksand_600SemiBold",
    700: "Quicksand_700Bold",
  };
  let styl = props.style
    ? props.style.reduce(function (acc, x) {
        for (var key in x) acc[key] = x[key];
        return acc;
      }, {})
    : [];
  let h =
    Platform.OS === "web" ? { lineHeight: "auto", textDecoration: "none" } : {};
  return (
    <Link
      to={{ screen: props.href, params: props.params }}
      {...props}
      style={{
        fontFamily: weight[props.weight ? props.weight : 400],
        ...styl,
        ...h,
      }}
    >
      {props.label}
    </Link>
  );
};
