import { Platform, Dimensions } from "react-native";

export const parseProfileName = (fn, ln, noNameText) =>
  (fn || "") + (ln ? (fn ? " " : "") + ln : "") || noNameText || "Hello, User";

const _dimentions = () => Dimensions.get("screen");

export const pageTitle = (text) =>
  Platform.select({
    web: _dimentions().width > 1023 ? `SMBEx | ${text}` : text,
    default: text,
  });
