import { cloneDeep } from "lodash";

const getMinutes = (time_in_min) => {
  let m = time_in_min % 60;
  if (m < 10) {
    m = "0" + m;
  }
  return m.toString();
};

const getHours = (time_in_min) => {
  let h = parseInt(time_in_min / 60);
  if (h < 10) {
    h = "0" + h;
  }
  return h.toString();
};

export const parseSlot = (v) => {
  if (typeof v.start_in_min === "number")
    v.start_in_min = {
      h: getHours(v.start_in_min),
      m: getMinutes(v.start_in_min),
      tim: v.start_in_min, // required, has dependency while calling API for Opening Hours/ Availability Slots
    };

  if (typeof v.end_in_min === "number")
    v.end_in_min = {
      h: getHours(v.end_in_min),
      m: getMinutes(v.end_in_min),
      tim: v.end_in_min, // required, has dependency while calling API for Opening Hours/ Availability Slots
    };
  return v;
};

export const parseSlotsArray = (data) => {
  return data.map(parseSlot);
};

export const parseSlotsObject = (parseObj) => {
  let data = cloneDeep(parseObj);
  for (const key in data) {
    data[key] = parseSlotsArray(data[key]);
  }
  return data;
};
