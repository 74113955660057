import React from "react";

const INITIAL_STATE = {
  orders: null,
  setOrders: () => [],
  pagination: null,
  setPagination: () => ({ from: null, to: null, total: null }),
  tabStatus: { curr: undefined, prev: undefined },
  setTabStatus: () => ({ curr: undefined, prev: undefined }),
  orderDetailsData: null,
  setOrderDetailsData: () => ({}),
  resetOrdersContext: () => null,
};

export const OrdersContext = React.createContext(INITIAL_STATE);

const OrdersReducer = (state, action) => {
  switch (action.type) {
    case "ADD_ORDERS":
      return { ...state, orders: action.payload };
    case "PAGINATION":
      return { ...state, pagination: action.payload };
    case "SET_ORDER_DATA":
      return { ...state, orderDetailsData: action.payload };
    case "SET_TAB_STATUS":
      return { ...state, tabStatus: action.payload };
    case "RESET_ORDERS_STATE":
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default function OrdersProvider({ children }) {
  const [state, dispatch] = React.useReducer(OrdersReducer, INITIAL_STATE);

  return (
    <OrdersContext.Provider
      value={{
        orders: state.orders,
        setOrders: ({ data, push = false }) => {
          dispatch({
            type: "ADD_ORDERS",
            payload: push ? [...(state.orders || []), ...data] : data,
          });
        },
        tabStatus: state.tabStatus,
        setTabStatus: ({ curr, prev }) => {
          dispatch({
            type: "SET_TAB_STATUS",
            payload: { curr: curr, prev: prev },
          });
        },
        pagination: state.pagination,
        setPagination: ({ from, to, total }) => {
          dispatch({ type: "PAGINATION", payload: { from, to, total } });
        },
        orderDetailsData: state.orderDetailsData,
        setOrderDetailsData: ({ data, id, push = true }) => {
          dispatch({
            type: "SET_ORDER_DATA",
            payload: push
              ? { ...state.orderDetailsData, [id]: data || {} }
              : { [id]: data || {} },
          });
        },
        resetOrdersContext: ({ data } = {}) => {
          dispatch({ type: "RESET_ORDERS_STATE", payload: data || [] });
        },
      }}
    >
      {children}
    </OrdersContext.Provider>
  );
}

export const useOrdersContext = () => React.useContext(OrdersContext);
