import { View, Text, Platform } from "react-native";
import React from "react";
import { Font } from "../../font";
import RemixIcon from "react-native-remix-icon";
import colors from "../../../../colors";

const PlanStatusChip = ({ status }) => {
  const [backgroundColor, setBackgroundColor] = React.useState();
  const [textColor, setTextColor] = React.useState();
  const [iconNColor, setIconNColor] = React.useState();

  React.useMemo(() => {
    setBackgroundColor(
      status === "active"
        ? "bg-app-d2"
        : status === "scheduled"
        ? "bg-app-i/80"
        : "bg-app-c"
    );
    setTextColor(
      status === "active"
        ? "text-app-d"
        : status === "scheduled"
        ? "text-app-a"
        : "text-app-d1"
    );
    setIconNColor(
      status === "active"
        ? {
            icon: "ri-checkbox-circle-fill",
            colorClass: "fill-app-a",
            color: colors.app.a,
          }
        : status === "scheduled"
        ? {
            icon: "ri-time-fill",
            colorClass: "fill-app-a",
            color: colors.app.a,
          }
        : ""
    );
  }, [status]);

  return (
    <View
      className={`flex px-2 py-1 flex-row items-center justify-center rounded-full ${backgroundColor}`}
    >
      {iconNColor ? (
        <RemixIcon
          className={`${iconNColor.colorClass}`}
          name={iconNColor.icon}
          color={Platform.OS === "web" ? "" : iconNColor.color}
          size="18"
        />
      ) : (
        <></>
      )}
      <Font
        className={`text-xs font-bold uppercase ${
          Platform.OS === "web" ? "" : "pb-px"
        } ${textColor}`}
      >
        &nbsp;{status}&nbsp;
      </Font>
    </View>
  );
};

export default PlanStatusChip;
