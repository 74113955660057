import React from "react";
import { ScrollView, View } from "react-native";
//import { Card } from "../../components/general/cards/card";
import { FaqItem } from "../../components/faq";
import { Font } from "../../components/font";
import { Footer } from "../../components/footer";
import { Widget } from "../../components/widget";
//import { getPublicData } from "../../services/api";

export default function Faq({ navigation, route }) {
  //const [faqs, setFaqs] = React.useState([]);
  return (
    <View className="flex-1 bg-app-e lg:bg-gray-50">
      <ScrollView
        className="p-4 rounded-t-2xl bg-gray-50"
        contentContainerStyle={{
          flexGrow: 1,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <View className=" max-w-screen-lg w-full mx-auto">
          <View className="my-5 hidden lg:flex">
            <Font className="text-lg font-semibold text-app-d">
              Frequently Asked Questions
            </Font>
          </View>
          <View className="flex-col lg:flex-row max-w-screen-lg w-full">
            <View className=" w-full lg:w-4/6 p-0 lg:pr-4 pt-0">
              {[...Array(5).keys()].map((e, i) => (
                <FaqItem key={i}></FaqItem>
              ))}
            </View>
            <View className=" w-full lg:w-2/6 p-0 lg:pl-4">
              <Widget></Widget>
            </View>
          </View>
          <Footer navigation={navigation}></Footer>
        </View>
      </ScrollView>
    </View>
  );
}
