import { Platform } from "react-native";

export const b64toBlob = (b64Data, contentType = "", sliceSize = 512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

export const fileURLtoBlob = (url) =>
  fetch(url)
    .then((res) => res.blob())
    .catch((err) => console.log("fetch URL into blob err:", err));

export const fileURLtoArrayBuffer = (url) =>
  fetch(url)
    .then((res) => res.arrayBuffer())
    .catch((err) => console.log("fetch URL into blob err:", err));

export const fileURLtoBase64NBlob = (url) =>
  fileURLtoBlob(url)
    .then((blob) => {
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.readAsDataURL(blob);
      }).then((result) => ({ blob, base64: result }));
    })
    .catch((err) => console.log("fetch URL into blob err:", err));

    export const createCrossPlatformFormData = ({
      blobDataKeys = [],
      data = {},
    }) => {
      if (!Array.isArray(blobDataKeys)) {
        if (!blobDataKeys) {
          blobDataKeys = [];
        }
        blobDataKeys = [blobDataKeys];
      }
      const isWeb = Platform.OS === "web";
      const formData = new FormData();
      const addFileInFormData = (key, fileObject, isWeb) => {
        const data = isWeb
          ? fileObject.blob
          : {
              uri: fileObject?.uri,
              name: key,
              type: fileObject?.blob?._data?.type || "image/jpeg",
            };
        formData.append(key, data);
      };
      const addDataInFormData = (data, lastKey = "") => {
        if (!lastKey && lastKey !== "") {
          return;
        }
        if (typeof data === "object") {
          if (Array.isArray(data)) {
            data.forEach((v, i) => {
              if (typeof v === "object") {
                if (blobDataKeys.includes(lastKey)) {
                  if (data) addFileInFormData(lastKey ? `${lastKey}` : i, v, isWeb);
                  // addFileInFormData(lastKey ? `${lastKey}[${i}]` : i, v, isWeb);
                  delete data[i];
                } else {
                  // addDataInFormData(v, `${lastKey ? `${lastKey}[${i}]` : i}`);
                  addDataInFormData(v, `${lastKey ? `${lastKey}` : i}`);
                }
              } else {
                if (v !== undefined) formData.append(lastKey ? `${lastKey}` : i, v);
                // formData.append(lastKey ? `${lastKey}[${i}]` : i, v);
              }
            });
          } else {
            if (blobDataKeys.includes(lastKey)) {
              if (data) addFileInFormData(lastKey, data, isWeb);
              // delete data;
            } else {
              for (let key in data) {
                addDataInFormData(
                  data[key],
                  `${lastKey ? `${lastKey}[${key}]` : key}`
                );
              }
            }
          }
        } else {
          if (blobDataKeys.includes(lastKey)) {
            if (data) addFileInFormData(lastKey, data, isWeb);
            // delete data;
          } else {
            if (data !== undefined) formData.append(lastKey, data);
          }
        }
      };
      addDataInFormData(data);
      return formData;
    };

export const logFormData = (formData) => {
  for (let [name, value] of formData) {
    console.log(`\n\n${name} = `, value, "\n\n");
  }
};
