export const catchAPIErrorText = (e, unknownErrorMessage) =>
  e?.response
    ? e.response?.data?.message
      ? e.response.data.message
      : e?.response?.data?.pathParams?.[0]
      ? `${e.response.data.pathParams[0]?.instancePath} ${e.response.data.pathParams[0]?.message}`
      : e?.response?.data?.body?.[0]
      ? `${e.response.data.body[0]?.keyword} ${e.response.data.body[0]?.instancePath} ${e.response.data.body[0]?.message}`
      : e?.message
    : e?.code === "ERR_NETWORK"
    ? e.message
    : unknownErrorMessage || "Unknown error occured";
