import React, { useEffect, useState } from "react";
import {
  Pressable,
  View,
  Image,
  TouchableOpacity,
  Alert,
  Text,
} from "react-native";
import { getStats, getRecentActivites } from "../../services/api";
import { Font } from "../../components/font";
import { Footer } from "../../components/footer";
import * as ImagePicker from "expo-image-picker";
import * as DocumentPicker from "expo-document-picker";
import { ScrollView } from "react-native";
import { TextInput } from "react-native-gesture-handler";
import RemixIcon from "react-native-remix-icon";
import colors from "../../../colors";
import CreateGroupPostModal from "./create-post-modal";
import { createAPost } from "../../actions/auth-actions";
import { createCrossPlatformFormData, fileURLtoBlob } from "../../utils/encDec";
import { PleaseWaitModal } from "../../components/modal";
import ResponseModal from "../../components/general/modals/ResponseModal";
import { Camera } from "expo-camera";

export default function CreateGroupPost({
  navigation,
  route,
  groupId,
  checkRefreshListforPosts,
}) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [postDescription, setIsPostDescription] = useState("");

  const [stats, setStats] = React.useState({
    total_listings: 0,
    total_products: 0,
    bookings_completed: 0,
    orders_fulfilled: 0,
  });
  const [activities, setActivities] = React.useState([]);

  useEffect(() => {
    getActivities();
  }, []);

  const getActivities = async () => {
    try {
      let res = await getStats();
      if (res.status === 200) setStats(res.data.data);
      else
        setStats({
          total_listings: 0,
          total_products: 0,
          bookings_completed: 0,
          orders_fulfilled: 0,
        });
      let activities = await getRecentActivites();
      if (activities.status === 200) setActivities(activities.data.data || []);
    } catch (error) {
      console.log("Error Getting Stats : ", error.response);
    }
  };

  const [selectedImage, setSelectedImage] = useState(null);

  const [postExternalURL, setPostExternalURL] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFileName, setSelectedFileName] = useState(null);
  const [selectedFileObj, setSelectedFileObj] = useState(null);
  const [selectedImageObj, setSelectedImageObj] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isResponseModal, setIsResponseModal] = useState({
    visible: false,
    message: "",
  });

  const closeResponseModal = () => {
    setIsResponseModal((prev) => ({ ...prev, visible: false }));
  };
  const [status, requestPermission] = ImagePicker.useCameraPermissions();

  const [hasPermission, setHasPermission] = useState(null);
  const [camera, setCamera] = useState(null);
  const [photo, setPhoto] = useState(null);

  // Request camera permissions on component mount
  useEffect(() => {
    (async () => {
      const { status } = await Camera.requestCameraPermissionsAsync();
      setHasPermission(status === "granted");
    })();
  }, []);

  const takePicture = async () => {
    if (Camera) {
      const data = await Camera.takePictureAsync({ quality: 1 });
      setPhoto(data.uri);
      console.log("Picture taken:", data.uri);
    }
  };

  // if (hasPermission === null) {
  //   return <Text>Requesting for camera permission</Text>;
  // }
  // if (hasPermission === false) {
  //   return <Text>No access to camera</Text>;
  // }

  const openCamera = async () => {
    try {
      // Ensure permissions are granted before proceeding
      const cameraPermission =
        await ImagePicker.requestCameraPermissionsAsync();
      const data = await requestPermission();
      if (cameraPermission.granted === false) {
        return;
      }
      console.log(
        "Camera permission granted, launching camera",
        cameraPermission,
        "data:",
        data,
        "status:",
        status
      );
      // Launch camera if permission is granted
      // const result = await ImagePicker.launchCameraAsync({
      //   mediaTypes: ImagePicker.MediaTypeOptions.Images,
      //   allowsEditing: true,
      //   aspect: [4, 3],
      //   quality: 1,
      // });
      const result = await ImagePicker.launchCameraAsync({});

      // if (!result.canceled) {
      //   console.log("Image captured:", result.assets[0].uri);
      //   let imageObject = result?.assets[0];
      //   let path = imageObject?.uri?.replace("file:///", "file:/");
      //   setSelectedImage(result.assets[0].uri);

      //   if (result.assets[0].uri !== null) {
      //     fileURLtoBlob(path)
      //       .then((data) => {
      //         imageObject.blob = data;
      //         setSelectedImageObj(imageObject);
      //       })
      //       .catch((error) => {
      //         console.log("fileURLtoBlob-error", error);
      //       });
      //   }
      // }
    } catch (error) {
      // Handle any unexpected errors
      console.log("Error launching camera", error);
      Alert.alert(
        "Camera Error",
        "There was an error trying to access the camera."
      );
    }
  };

  const openFilePicker = async () => {
    const result = await DocumentPicker.getDocumentAsync({
      type: "*/*",
      copyToCacheDirectory: true,
    });
    if (result.type !== "cancel") {
      let path = result.uri.replace("file:///", "file:/");
      console.log(result.uri, "result.uri");
      setSelectedFileName(result.name);
      setSelectedFile(result.uri);
      // console.log(selectedFile, result, "selectedFile");
      fileURLtoBlob(path)
        .then((data) => {
          setSelectedFileObj({
            uri: result.uri,
            name: result.name,
            type: result.mimeType,
            blob: data,
          });
          
        })
        .catch((error) => {
          console.log("fileURLtoBlob-error", error);
        });
    }
  };

  const openGallery = async () => {
    const result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      quality: 1,
      allowsEditing: true,
    });

    if (!result.canceled) {
      let imageObject = result?.assets[0];
      let path = imageObject?.uri?.replace("file:///", "file:/");
      console.log(result.assets[0].uri, "result.assets[0].uri");
      console.log(selectedImage, "selectedImage");

      setSelectedImage(result.assets[0].uri);
      if (result.assets[0].uri !== null) {
        fileURLtoBlob(path)
          .then((data) => {
            imageObject.blob = data;
            setSelectedImageObj(imageObject);
          })
          .catch((error) => {
            console.log("fileURLtoBlob-error", error);
          });
      }
    }
  };

  console.log(selectedImageObj, "selectedImageObj Img");
  const CreatePostForGroup = async () => {
    try {
      setIsLoading(true);
      // const formData = new FormData();
      const dataObj = {};
      console.log(selectedFileObj, "selectedFileObj");
      console.log(selectedImage, "selected Img");

      dataObj.title = "";
      dataObj.external_link_url = postExternalURL;
      dataObj.description = postDescription;
      dataObj.cover_image = selectedImageObj;
      dataObj.attached_file = selectedFileObj;

      const formData = createCrossPlatformFormData({
        blobDataKeys: ["cover_image", "attached_file"],
        data: dataObj,
      });
      if (Object.keys(dataObj)?.length) {
        const res = await createAPost(groupId, formData);
        console.log(res.status, "res.status for creating post");
        console.log(res.data, "res.data for creating post");
        console.log("post Creation ", res);
        if (res.status == 201) {
          setSelectedImage(null);
          setSelectedFile(null);
          setIsPostDescription("");
          setIsLoading(false);
          setIsResponseModal((prev) => ({
            ...prev,
            visible: true,
            message: "Post Created Successfully !!!",
          }));
          checkRefreshListforPosts();
        } else if (res.status == 413) {
          // console.log("post Creation not 200", res);
          console.log("post Creation not 200 data", res.data);
          setIsLoading(false);
          setIsResponseModal((prev) => ({
            ...prev,
            visible: true,
            message: "File Size too large !!!",
          }));
        } else {
          console.log("post Creation not 200 data", res.data, res.status);
          setIsLoading(false);
          setIsResponseModal((prev) => ({
            ...prev,
            visible: true,
            message: "Post Creation Failed !!!",
          }));
        }
      }
    } catch (error) {
      console.log("post Creation Failed", error);

      setIsLoading(false);
      setIsResponseModal((prev) => ({
        ...prev,
        visible: true,
        message: "Post Creation Failed !!!",
      }));
    }
  };

  // console.log(selectedImage, "selectedImage");
  // console.log(postDescription, "postDescription");

  return (
    <ScrollView
      className="p-4 rounded-t-2xl bg-gray-50"
      contentContainerStyle={{
        flexGrow: 1,
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Camera style={{ flex: 1 }} ref={(ref) => setCamera(ref)} />

      {photo && <Text>Photo taken at: {photo}</Text>}
      {isLoading && <PleaseWaitModal showLoader={true} />}
      <ResponseModal
        visible={isResponseModal.visible}
        onRequestClose={closeResponseModal}
        bodyText={isResponseModal.message}
      ></ResponseModal>

      <View className="max-w-screen-lg w-full h-full">
        <View className="flex max-w-screen-lg w-full flex-col justify-center items-center">
          {/* ------------------------Create Group-Tab----------------------------------- */}
          <>
            <TouchableOpacity
              className="w-full flex"
              onPress={() => {
                setIsModalVisible(true);
              }}
            >
              <View className=" w-full flex p-4 item-center bg-app-a">
                <TouchableOpacity className="flex-row justify-end items-center">
                  {/* {selectedImage != "" && selectedImage != null && (
                    <View className="mr-10">
                      <Pressable
                        onPress={() => setSelectedImage(null)}
                        style={{
                          position: "absolute",
                          right: 0,
                          top: 0,
                          zIndex: 9999,
                          backgroundColor: "white",
                        }}
                        className={`w-3 rounded-full justify-center items-center`}
                      >
                        <Text style={{ fontSize: 10, fontWeight: "600" }}>
                          X
                        </Text>
                      </Pressable>
                      <Image
                        // source={require("../../../assets/user.jpg")}
                        source={{ uri: selectedImage }}
                        className="w-8 h-8 rounded-lg"
                      />
                    </View>
                  )} */}
                  <Pressable
                    onPress={() =>
                      postDescription == "" ? null : CreatePostForGroup()
                    }
                    className={`px-2 py-1 flex-center items-center ${
                      postDescription == ""
                        ? "bg-gray-100 border border-gray-600"
                        : "bg-green-100 border border-green-600"
                    } rounded-lg`}
                  >
                    <Font
                      className={`text-sm ${
                        postDescription == ""
                          ? "text-gray-600"
                          : "text-green-600"
                      }`}
                    >
                      Post
                    </Font>
                  </Pressable>
                </TouchableOpacity>
                <View className="p-2 flex-row">
                  <View className="flex-row mr-2.5">
                    <Image
                      source={require("../../../assets/user.jpg")}
                      className="w-8 h-8 rounded-full"
                    />
                  </View>
                  <View className="flex-col flex-1">
                    <TouchableOpacity className="w-full">
                      <TextInput
                        className="w-full p-2 border border-gray-300 rounded-2xl"
                        placeholder="Write something..."
                        placeholderTextColor="#94a3b8"
                        multiline={true}
                        numberOfLines={4}
                        value={postDescription}
                        onChangeText={(e) => setIsPostDescription(e)}
                      />
                    </TouchableOpacity>
                    <View className="border border-gray-300 rounded-xl p-2 w-full mt-2">
                      <TextInput
                        className="w-full"
                        placeholder="https link to external url"
                        placeholderTextColor="#94a3b8"
                        value={postExternalURL}
                        onChangeText={(e) => setPostExternalURL(e)}
                      />
                    </View>
                  </View>
                </View>
              </View>
              {/* {selectedImage == "" ||
                (selectedImage == null && ( */}
              <View className=" flex-row p-2 w-full justify-center item-center border-t border-t-gray-300">
                <Pressable
                  onPress={openFilePicker}
                  className="py-1 px-2 lg:px-10 flex-row justify-center item-center bg-app-a rounded-full border border-gray-300"
                >
                  <View className="lg:w-full flex-row lg:justify-between lg:items-center">
                    <View className="flex justify-center items-center">
                      <RemixIcon
                        name="ri-file-text-fill"
                        size={16}
                        color={colors.app.a4}
                        className="mr-2 lg:mr-4 w-4 h-4 lg:w-6 lg:h-6 flex-shrink-0"
                      />
                    </View>
                    <Font className="lg:ml-4 text-sm">File</Font>
                  </View>
                </Pressable>
                <Pressable
                  onPress={openGallery}
                  className="py-1 px-2 ml-2 mr-2 lg:px-10 flex-row justify-center item-center bg-app-a rounded-full border border-gray-300"
                >
                  <View className="lg:w-full flex-row lg:justify-between lg:items-center">
                    <View className="mr-2 lg:mr-4 flex justify-center items-center">
                      <RemixIcon
                        name="ri-image-fill"
                        size={16}
                        color={colors.app.b4}
                        className="mr-1 w-4 h-4 lg:w-6 lg:h-6 flex-shrink-0"
                      />
                    </View>
                    <Font className="lg:ml-4 text-sm">Gallery</Font>
                  </View>
                </Pressable>
                <Pressable
                  // onPress={takePicture}
                  onPress={openCamera}
                  className="py-1 px-2 lg:px-10 flex-row justify-center item-center bg-app-a rounded-full border border-gray-300"
                >
                  <View className="lg:w-full flex-row lg:justify-between lg:items-center">
                    <View className="mr-2 lg:mr-4 flex justify-center items-center">
                      <RemixIcon
                        name="ri-camera-fill"
                        size={16}
                        color={colors.app.c4}
                        className="mr-1 w-4 h-4 lg:w-6 lg:h-6 flex-shrink-0"
                      />
                    </View>
                    <Font className="lg:ml-4 text-sm">Camera</Font>
                  </View>
                </Pressable>
              </View>
              {/* ))} */}
              {selectedFile != "" && selectedFile != null && (
                <View className="w-full  h-16 mb-5 bg-gray-200 rounded-md">
                  <Pressable
                    onPress={() => {
                      setSelectedFile(null);
                      setSelectedFileObj(null);
                    }}
                    style={{
                      position: "absolute",
                      right: 0,
                      top: 0,
                      zIndex: 9999,
                      backgroundColor: "white",
                    }}
                    className={`w-5 h-5 rounded-full justify-center items-center`}
                  >
                    <Text style={{ fontSize: 14, fontWeight: "600" }}>X</Text>
                  </Pressable>
                  <View className="flex-col h-full items-center justify-center">
                  <RemixIcon
                        name="ri-file-text-fill"
                        size={18}
                        color={colors.app.a4}
                        className=" w-5 h-5 lg:w-8  flex-shrink-0 mb-2"
                      />
                    <Text className="flex items-center justify-center" style={{ fontSize: 14, fontWeight: "600" }}>
                      {selectedFileName}
                    </Text>
                  </View>
                  {/* <Image
                    source={{ uri: selectedImage }}
                    className="w-full h-full rounded-lg"
                    // style={{ resizeMode: "contain" }}
                  /> */}
                </View>
              )}
              {selectedImage != "" && selectedImage != null && (
                <View className="w-full h-56">
                  <Pressable
                    onPress={() => {
                      setSelectedImage(null);
                      setSelectedImageObj(null);
                    }}
                    style={{
                      position: "absolute",
                      right: 0,
                      top: 0,
                      zIndex: 9999,
                      backgroundColor: "white",
                    }}
                    className={`w-5 h-5 rounded-full justify-center items-center`}
                  >
                    <Text style={{ fontSize: 14, fontWeight: "600" }}>X</Text>
                  </Pressable>
                  <Image
                    source={{ uri: selectedImage }}
                    className="w-full h-full rounded-lg"
                    // style={{ resizeMode: "contain" }}
                  />
                </View>
              )}
            </TouchableOpacity>
          </>

          <>
            {/* <View className="flex-row p-12 w-full item-center bg-app-a">
              <View className="mr-2 -ml-8">
                <Image
                  source={require("../../../assets/user.jpg")}
                  className="w-10 h-10 rounded-full"
                />
              </View>

              <View className="flex-1 w-full">
                <View className="w-full flex-row justify-between items-center">
                  <Font className="text-wrap mr-2">
                    Description lorem ipsum dolor sit amet bla slo are asli whey
                    lorem dolor siu
                  </Font>
                  <View className="p-2 flex-row justify-end items-center">
                    <Pressable className="px-2 py-1 flex-center items-center bg-green-100 border border-green-600 rounded-lg">
                      <Font className="text-sm text-green-600">Post</Font>
                    </Pressable>
                  </View>
                </View>
                <View className="w-full h-56">
                  <Image
                    source={require("../../../assets/wallPost.jpg")}
                    className="w-full h-full rounded-lg"
                    // style={{ resizeMode: 'cover',}}
                  />
                </View>
              </View>
            </View> */}
          </>
        </View>

        {/* {isModalVisible && <CreateGroupPostModal />} */}

        <Footer navigation={navigation} />
      </View>
    </ScrollView>
  );
}
