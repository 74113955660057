import { Platform, Pressable, TextInput, View } from "react-native";
import React from "react";
import { Font } from "../../font";
import RemixIcon from "react-native-remix-icon";
import colors from "../../../../colors";
import { Input, Select } from "../../input";

const AvailabilitySlotCard = ({
  slotStartHour,
  slotStartHourPlaceholder,
  onSlotStartHourChange = () => null,
  slotStartMinute,
  slotStartMinutePlaceholder,
  onSlotStartMinuteChange = () => null,
  slotEndHour,
  slotEndHourPlaceholder,
  onSlotEndHourChange = () => null,
  slotEndMinute,
  slotEndMinutePlaceholder,
  onSlotEndMinuteChange = () => null,
  onRemoveSlot = () => null,
  services,
  serviceValue,
  servicePlaceholder,
  servicesEnabled = true,
  onServiceSelect = () => null,
  counterValue,
  counterPlaceholder,
  onCounterValueChange = () => null,
}) => {
  return (
    <View className="border border-app-d3 bg-app-b3 w-72 rounded-lg p-4">
      <View
        className={`flex-row w-full justify-around items-center border border-app-c rounded py-2 ${
          onSlotStartHourChange ||
          onSlotStartMinuteChange ||
          onSlotEndHourChange ||
          onSlotEndMinuteChange
            ? ""
            : "bg-app-c3"
        }`}
      >
        <View className="flex-1 flex-row justify-center items-center border-r border-app-c px-4">
          <View className="flex-1 flex-row justify-around items-center">
            <TextInput
              className={`font-medium flex-1 w-full text-center ${
                onSlotStartHourChange ? "" : "text-app-d/60"
              }`}
              inputMode="numeric"
              keyboardType="numeric"
              style={
                Platform.OS === "web" && {
                  outlineColor: "transparent",
                }
              }
              value={slotStartHour}
              placeholder={slotStartHourPlaceholder}
              onChangeText={onSlotStartHourChange}
              editable={!!onSlotStartHourChange}
            />
            <Font
              weight={800}
              className={`font-bold pb-[2px] px-1 ${
                onSlotStartHourChange || onSlotStartMinuteChange
                  ? ""
                  : "text-app-d/60"
              }`}
            >
              :
            </Font>
            <TextInput
              className={`font-medium flex-1 w-full text-center ${
                onSlotStartMinuteChange ? "" : "text-app-d/60"
              }`}
              inputMode="numeric"
              keyboardType="numeric"
              style={
                Platform.OS === "web" && {
                  outlineColor: "transparent",
                }
              }
              value={slotStartMinute}
              placeholder={slotStartMinutePlaceholder}
              onChangeText={onSlotStartMinuteChange}
              editable={!!onSlotStartMinuteChange}
            />
          </View>
          <Font
            className={`font-bold px-4 ${
              onSlotStartHourChange ||
              onSlotStartMinuteChange ||
              onSlotEndHourChange ||
              onSlotEndMinuteChange
                ? ""
                : "text-app-d/60"
            }`}
          >
            -
          </Font>
          <View className="flex-1 flex-row justify-around items-center">
            <TextInput
              className={`font-medium flex-1 w-full text-center ${
                onSlotEndHourChange ? "" : "text-app-d/60"
              }`}
              inputMode="numeric"
              keyboardType="numeric"
              style={
                Platform.OS === "web" && {
                  outlineColor: "transparent",
                }
              }
              value={slotEndHour}
              placeholder={slotEndHourPlaceholder}
              onChangeText={onSlotEndHourChange}
              editable={!!onSlotEndHourChange}
            />
            <Font
              weight={800}
              className={`font-bold pb-[2px] px-1 ${
                onSlotEndHourChange || onSlotEndMinuteChange
                  ? ""
                  : "text-app-d/60"
              }`}
            >
              :
            </Font>
            <TextInput
              className={`font-medium flex-1 w-full text-center ${
                onSlotEndMinuteChange ? "" : "text-app-d/60"
              }`}
              inputMode="numeric"
              keyboardType="numeric"
              style={
                Platform.OS === "web" && {
                  outlineColor: "transparent",
                }
              }
              value={slotEndMinute}
              placeholder={slotEndMinutePlaceholder}
              onChangeText={onSlotEndMinuteChange}
              editable={!!onSlotEndMinuteChange}
            />
          </View>
        </View>
        {onRemoveSlot && (
          <Pressable
            className={`px-2 active:bg-app-e3 rounded py-2 -my-2 ${
              Platform.OS === "web" ? "" : "mx-px"
            }`}
            onPress={onRemoveSlot}
          >
            <RemixIcon name="ri-close-fill" size={25} color={colors.app.e} />
          </Pressable>
        )}
      </View>
      <View className="flex-row w-full mt-2">
        <Select
          placeholder={servicePlaceholder || "Choose service"}
          options={services}
          value={serviceValue}
          setValue={onServiceSelect}
          enabled={!!onServiceSelect || servicesEnabled}
        />
      </View>
      <View className="flex-row w-full mt-2">
        <Input
          type="counter"
          setValue={onCounterValueChange}
          value={counterValue}
          placeholder={counterPlaceholder || "Slots"}
          min={1}
          max={1000}
          editable={!!onCounterValueChange}
        />
      </View>
    </View>
  );
};

export default AvailabilitySlotCard;
