import React from "react";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { apiURIV2, stripeAPIURIV2 } from "../constants";
import axiosInstance from "../constants/axios-config";
import { replace } from "../utils/navigation-util";
import { socket } from "../services/socket-io";
import { Platform } from "react-native";
import { method } from "lodash";

export const logIn = async ({
  email,
  password,
  role = "vendor",
  remember,
  user_device_token,
}) => {
  try {
    const res = await axiosInstance.post(apiURIV2 + "/auth/login", {
      email,
      password,
      role,
      remember,
      user_device_token,
    });
    if (res.status === 200) {
      if (!res?.data?.token) {
        throw new Error("jwt not received");
      }

      await AsyncStorage.clear();
      await AsyncStorage.setItem("ut", res.data.token || "");
      socket.connect();
      return getUserProfile(res.data.token);
    }
  } catch (error) {
    console.log("login api error : ", error);
    if (error?.response) return error.response;
    throw error;
  }
};

export const logOut = async () => {
  try {
    const token = await AsyncStorage.getItem("ut");
    const res = await axiosInstance.post(apiURIV2 + "/auth/logout", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    console.log("logout res", res);
    if (res.status === 204) {
      await AsyncStorage.removeItem("ut");
      socket.disconnect();
      await AsyncStorage.clear();
    }
    return res;
  } catch (error) {
    console.log("log out error : ", error);
    if (error?.response) return error.response;
  }
};

export const signUp = async ({
  email,
  password,
  phoneNumber,
  role = "vendor",
  is_non_profit = "true",
}) => {
  try {
    const res = await axiosInstance.post(apiURIV2 + "/auth/register", {
      email,
      password,
      phone: phoneNumber,
      role,
      is_non_profit,
    });
    console.log("sign up res", res);
    return res;
  } catch (error) {
    console.log("sign up error : ", error);
    if (error?.response) return error.response;
  }
};

export const isSignedIn = async () => {
  const token = await AsyncStorage.getItem("ut");
  if (!token) {
    replace("signin");
    return null;
  }
  return token;
};

export const getUserProfile = async (token_data) => {
  try {
    const token = token_data || (await isSignedIn());
    if (!token) {
      return null;
    }
    const res = await axiosInstance.get(apiURIV2 + "/users/me", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (res.status === 200) {
      return res;
    }
    return res;
  } catch (error) {
    console.log("Get User Profile error : ", error);
    if (error?.response) {
      console.log("error.response.status:", error.response.status);
      if (error.response.status === 401) await AsyncStorage.removeItem("ut");
      return error.response;
    } else {
      throw error;
    }
  }
};
export const sendGroupRequest = async (userId, token_data) => {
  try {
    const token = token_data || (await isSignedIn());
    if (!token) {
      return null;
    }
    const res = await axiosInstance.get(
      apiURIV2 + `/users/${userId}/groups/permission-requests/send-request`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (res.status === 200) {
      return res;
    }
    return res;
  } catch (error) {
    console.log("Get request send error : ", error);
    if (error?.response) {
      console.log("error.response.status:", error.response.status);
      // if (error.response.status === 401) await AsyncStorage.removeItem("ut");
      return error.response;
    } else {
      throw error;
    }
  }
};
export const CreateAGroup = async (
  userId,
  name,
  desc,
  isPvt,
  formData,
  token_data
) => {
  console.log(formData, "formData check");

  try {
    const token = token_data || (await isSignedIn());

    if (!token) {
      return null;
    }
    const res = await axiosInstance.post(
      apiURIV2 + `/users/${userId}/groups`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );
    if (res.status === 200) {
      return res;
    }
    return res;
  } catch (error) {
    // console.log("Get request send error creating group: ", error);
    if (error?.response) {
      // console.log(
      //   "error.response.status creating group::",
      //   error.response.data
      // );
      // if (error.response.status === 401) await AsyncStorage.removeItem("ut");
      return error.response;
    } else {
      throw error;
    }
  }
};
export const loadGroups = async (userId, token_data) => {
  try {
    const token = token_data || (await isSignedIn());
    if (!token) {
      return null;
    }
    const res = await axiosInstance.get(apiURIV2 + `/users/${userId}/groups`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (res.status === 200) {
      return res;
    }
    return res;
  } catch (error) {
    // console.log("Get request send error load : ", error);
    if (error?.response) {
      // console.log("error.response.status load:", error.response.status);
      // if (error.response.status === 401) await AsyncStorage.removeItem("ut");
      return error.response;
    } else {
      throw error;
    }
  }
};
export const getGroupMembers = async (group_id, token_data) => {
  try {
    const token = token_data || (await isSignedIn());
    if (!token) {
      return null;
    }
    const res = await axiosInstance.get(
      apiURIV2 + `/groups/${group_id}/members`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (res.status === 200) {
      return res;
    }
    return res;
  } catch (error) {
    if (error?.response) {
      return error.response;
    } else {
      throw error;
    }
  }
};

// Remove member api

export const RemoveMemberApi = async (group_id, member_id, token_data) => {
  try {
    const token = token_data || (await isSignedIn());
    if (!token) {
      return null;
    }
    const res = await axiosInstance.delete(
      apiURIV2 + `/groups/${group_id}/members/${member_id}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
      return res;
    
  } catch (error) {
    if (error?.response) return error.response;
    throw error;
  }
};
export const getGroupNonMembersInvites = async (
  user_id,
  group_id,
  token_data
) => {
  try {
    // console.log(user_id, group_id, "getGroupNonMembers");

    const token = token_data || (await isSignedIn());
    if (!token) {
      return null;
    }
    const res = await axiosInstance.get(
      apiURIV2 + `/users/${user_id}/groups/${group_id}/non-member-list`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          group_joining_preference: "invites_only",
        },
      }
    );
    if (res.status === 200) {
      return res;
    }
    return res;
  } catch (error) {
    if (error?.response) {
      return error.response;
    } else {
      throw error;
    }
  }
};
export const getGroupNonMembers = async (user_id, group_id, token_data) => {
  try {
    // console.log(user_id, group_id, "getGroupNonMembers");

    const token = token_data || (await isSignedIn());
    console.log(token, "token");

    if (!token) {
      return null;
    }
    const res = await axiosInstance.get(
      apiURIV2 + `/users/${user_id}/groups/${group_id}/non-member-list`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          group_joining_preference: "no_restriction",
        },
      }
    );
    if (res.status === 200) {
      return res;
    }
    return res;
  } catch (error) {
    if (error?.response) {
      return error.response;
    } else {
      throw error;
    }
  }
};
export const deleteGroup = async (group_id, token_data) => {
  try {
    console.log(group_id, "deleteGroup");

    const token = token_data || (await isSignedIn());
    if (!token) {
      return null;
    }
    const res = await axiosInstance.delete(apiURIV2 + `/groups/${group_id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (res.status === 200) {
      return res;
    }
    return res;
  } catch (error) {
    if (error?.response) {
      return error.response;
    } else {
      throw error;
    }
  }
};
export const EditGroup = async (user_id, group_id, formData, token_data) => {
  try {
    console.log(user_id, group_id, "getGroupNonMembers");
    const token = token_data || (await isSignedIn());
    if (!token) {
      return null;
    }
    const res = await axiosInstance.patch(
      apiURIV2 + `/users/${user_id}/groups/${group_id}`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );
    if (res.status === 200) {
      return res;
    }
    return res;
  } catch (error) {
    if (error?.response) {
      return error.response;
    } else {
      throw error;
    }
  }
};
export const AddToGroup = async (group_id, arrId, token_data) => {
  try {
    console.log(arrId, "AddToGroup");
    const token = token_data || (await isSignedIn());
    if (!token) {
      return null;
    }
    const res = await axiosInstance.post(
      // apiURIV2 + `/groups/${group_id}/invites/send-invitation`, //for invitation 
      apiURIV2 + `/groups/${group_id}/members`, // for direct add 
      // apiURIV2 + `/groups/${group_id}/members`,
      { user_ids: arrId },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    if (res.status === 200) {
      return res;
    }
    return res;
  } catch (error) {
    if (error?.response) {
      return error.response;
    } else {
      throw error;
    }
  }
};
export const sendInvite = async (group_id, arrId, token_data) => {
  try {
    console.log(arrId, "sendInvite");
    const token = token_data || (await isSignedIn());
    if (!token) {
      return null;
    }
    const res = await axiosInstance.post(
      apiURIV2 + `/groups/${group_id}/invites/send-invitation`, //for invitation 
      // apiURIV2 + `/groups/${group_id}/members`, // for direct add 
      // apiURIV2 + `/groups/${group_id}/members`,
      { user_ids: arrId },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    if (res.status === 200) {
      return res;
    }
    return res;
  } catch (error) {
    if (error?.response) {
      return error.response;
    } else {
      throw error;
    }
  }
};
export const sendInviteToEmail = async (email, token_data) => {
  try {
    console.log(email, "email");
    const token = token_data || (await isSignedIn());
    console.log(token, "token");

    if (!token) {
      return null;
    }
    const res = await axiosInstance.post(
      apiURIV2 + `/invites`,
      {
        contacts: [
          {
            email: email,
          },
        ],
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    if (res.status === 200) {
      return res;
    }
    console.log(res, "failed res check");
    return res;
  } catch (error) {
    if (error?.response) {
      return error.response;
    } else {
      throw error;
    }
  }
};

export const getAllCustomers = async (query = "", token_data) => {
  console.log(query, "query");

  try {
    const token = token_data || (await isSignedIn());
    if (!token) {
      return null;
    }
    const res = await axiosInstance.get(apiURIV2 + `/users/customers`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      params: {
        search_text: query,
      },
    });
    if (res.status === 200) {
      return res;
    }
    return res;
  } catch (error) {
    if (error?.response) {
      return error.response;
    } else {
      throw error;
    }
  }
};
export const GetGroupJoinRequest = async (group_id, token_data) => {
  try {
    const token = token_data || (await isSignedIn());
    if (!token) {
      return null;
    }
    const res = await axiosInstance.get(
      apiURIV2 + `/groups/${group_id}/join/requests`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    if (res.status === 200) {
      return res;
    }
    return res;
  } catch (error) {
    if (error?.response) {
      return error.response;
    } else {
      throw error;
    }
  }
};
export const RejectRequest = async (group_id, request_id, token_data) => {
  try {
    const token = token_data || (await isSignedIn());
    if (!token) {
      return null;
    }
    const res = await axiosInstance.get(
      apiURIV2 + `/groups/${group_id}/join/requests/${request_id}/reject`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    if (res.status === 200) {
      return res;
    }
    return res;
  } catch (error) {
    if (error?.response) {
      return error.response;
    } else {
      throw error;
    }
  }
};
export const AcceptRequest = async (group_id, request_id, token_data) => {
  try {
    const token = token_data || (await isSignedIn());
    if (!token) {
      return null;
    }
    const res = await axiosInstance.get(
      apiURIV2 + `/groups/${group_id}/join/requests/${request_id}/accept`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    if (res.status === 200) {
      return res;
    }
    return res;
  } catch (error) {
    if (error?.response) {
      return error.response;
    } else {
      throw error;
    }
  }
};
export const createAPost = async (group_id, formData, token_data) => {
  try {
    console.log(formData, "formData for create post");

    const token = token_data || (await isSignedIn());
    if (!token) {
      return null;
    }
    const res = await axiosInstance.post(
      apiURIV2 + `/groups/${group_id}/posts`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );
    if (res.status === 200) {
      return res;
    }
    return res;
  } catch (error) {
    if (error?.response) {
      console.log(error, "error in auth for crete post");

      return error.response;
    } else {
      throw error;
    }
  }
};
export const EditAPostApi = async (group_id, post_id, formData, token_data) => {
  try {
    console.log(formData, "formData for create post");

    const token = token_data || (await isSignedIn());
    if (!token) {
      return null;
    }
    const res = await axiosInstance.patch(
      apiURIV2 + `/groups/${group_id}/posts/${post_id}`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );
    if (res.status === 200) {
      return res;
    }
    return res;
  } catch (error) {
    if (error?.response) {
      console.log(error, "error in auth for crete post");

      return error.response;
    } else {
      throw error;
    }
  }
};
export const getAPost = async (group_id,page, token_data) => {
  const pageCheck = page-1
  try {
    const token = token_data || (await isSignedIn());
    if (!token) {
      return null;
    }
    const res = await axiosInstance.get(
      apiURIV2 + `/groups/${group_id}/posts?offset=${pageCheck*10}&limit=10`,

      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );
    if (res.status === 200) {
      return res;
    }
    return res;
  } catch (error) {
    if (error?.response) {
      return error.response;
    } else {
      throw error;
    }
  }
};
export const deleteAPost = async (group_id, post_id, token_data) => {
  try {
    const token = token_data || (await isSignedIn());
    if (!token) {
      return null;
    }
    const res = await axiosInstance.delete(
      apiURIV2 + `/groups/${group_id}/posts/${post_id}`,

      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );
    if (res.status === 200) {
      return res;
    }
    return res;
  } catch (error) {
    if (error?.response) {
      return error.response;
    } else {
      throw error;
    }
  }
};
export const ShareAPost = async (group_id, post_id, token_data) => {
  try {
    const token = token_data || (await isSignedIn());
    if (!token) {
      return null;
    }
    const res = await axiosInstance.post(
      apiURIV2 + `/groups/${group_id}/posts/${post_id}/share`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (res.status === 200) {
      return res;
    }
    return res;
  } catch (error) {
    if (error?.response) {
      return error.response;
    } else {
      throw error;
    }
  }
};
export const MakeAdminApi = async (group_id, member_id, token_data) => {
  try {
    const token = token_data || (await isSignedIn());
    if (!token) {
      return null;
    }
    const res = await axiosInstance.get(
      apiURIV2 + `/groups/${group_id}/members/${member_id}/promote-as-admin`,

      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (res.status === 200) {
      return res;
    }
    return res;
  } catch (error) {
    if (error?.response) {
      return error.response;
    } else {
      throw error;
    }
  }
};
export const MakeMemberApi = async (group_id, member_id, token_data) => {
  try {
    const token = token_data || (await isSignedIn());
    if (!token) {
      return null;
    }
    const res = await axiosInstance.get(
      apiURIV2 + `/groups/${group_id}/members/${member_id}/demote-as-admin`,

      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (res.status === 200) {
      return res;
    }
    return res;
  } catch (error) {
    if (error?.response) {
      return error.response;
    } else {
      throw error;
    }
  }
};
export const LikePostApi = async (group_id, post_id, isLiked, token_data) => {
  try {
    const token = token_data || (await isSignedIn());
    if (!token) {
      return null;
    }
    const res = await axiosInstance.post(
      apiURIV2 +
        `/groups/${group_id}/posts/${post_id}` +
        (isLiked ? `/unlike` : `/like`),
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (res.status === 200) {
      return res;
    }
    return res;
  } catch (error) {
    if (error?.response) {
      return error.response;
    } else {
      throw error;
    }
  }
};

export const validateStripeStatus = async () => {
  try {
    const token = await AsyncStorage.getItem("ut");
    if (!token) {
      return;
    }
    const res = await axiosInstance.post(
      stripeAPIURIV2 + "/stripe/onboard/check",
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (res && res.status === 200 && res.data) {
      return res;
    } else throw res;
  } catch (error) {
    if (error?.response) {
      return error?.response;
    }
    console.log("Error in Initiate stripe", error.response);
  }
};

export const initiateStripe = async () => {
  try {
    const token = await AsyncStorage.getItem("ut");
    return axiosInstance.post(
      stripeAPIURIV2 + "/stripe/onboard/initiate",
      {
        platform: Platform.OS, // ios or android or web
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  } catch (error) {
    console.log("initiateStripe   =>>>", error);
    if (error?.response) {
      return error.response;
    }
  }
};
