import React from "react";
import { socket } from "../services/socket-io";
import { isSignedIn } from "../actions/auth-actions";
import ResponseModal from "../components/general/modals/ResponseModal";

export const emitEventHandler = async ({ event, payload }) => {
  return socket
    .timeout(10000)
    .emitWithAck(event, payload)
    .then((response) => {
      console.log(`emitEventHandler ${event} response:`, response);
      // if (response.event === "start-typing") {
      //   console.log("emitEventHandler start-typing response: ", response);
      // }
      // if (response.event === "stop-typing") {
      //   console.log("emitEventHandler stop-typing response: ", response);
      // }
      // if (response.event === "record-last-read") {
      //   console.log("emitEventHandler record-last-read response: ", response);
      // }
      // if (response.event === "delete-message") {
      //   console.log("emitEventHandler delete-message response: ", response);
      // }
      // if (response.event === "send-message") {
      //   console.log("emitEventHandler send-message response: ", response);
      // }
      return response;
    })
    .catch((err) => {
      console.log("timeout err:", err);
      if (["send-message", "delete-message"].includes(event)) {
        throw err;
      }
    });
};

const INITIAL_STATE = {
  socketConnectStatus: "disconnected",
  currentChatData: {
    data: null,
    currIndex: undefined,
    prevIndex: undefined,
    self_user_id: null,
  },
  scrollToEnd: false,
  setScrollToEnd: () => null,
  setCurrentChatData: () => null,
  channels: null,
  setChannels: () => [],
  // channelMessages: null,
  // setChannelMessages: () => {},
  currentChannelMessages: null,
  setCurrentChannelMessages: () => [],
  updateCurrentChannelMessages: () => [],
  deleteCurrentChannelMessages: () => [],
  currentChannelMessagesPagination: { from: null, to: null, total: null },
  setCurrentChannelMessagesPagination: () => ({
    from: null,
    to: null,
    total: null,
  }),
  redirectChatUserID: null,
  setRedirectChatUserID: () => null,
  dispatchMessageEvents: {
    startedTypingMessage: ({ channel_id }) => {
      emitEventHandler({
        event: "start-typing",
        payload: { channel_id },
      });
    },
    stoppedTypingMessage: ({ channel_id }) => {
      emitEventHandler({ event: "stop-typing", payload: { channel_id } });
    },
    recordLastMessageRead: ({ channel_id, message_id }) => {
      emitEventHandler({
        event: "record-last-read",
        payload: { channel_id, message_id },
      });
    },
    deleteMessage: ({ channel_id, message_id }) => {
      return emitEventHandler({
        event: "delete-message",
        payload: { channel_id, message_id },
      });
    },
    sendMessage: ({ channel_id, message }) => {
      return emitEventHandler({
        event: "send-message",
        payload: { channel_id, message },
      });
    },
  },
  receivedMessageHandler: () => null,
  resetMessagesContext: () => null,
};

let isModalShown = false,
  errorMessages = [];

export const MessagesContext = React.createContext(INITIAL_STATE);

const MessagesReducer = (state, action) => {
  switch (action.type) {
    case "SET_RDR_UID": {
      if (action.callback) {
        setTimeout(action.callback, 1000);
      }
      return { ...state, redirectChatUserID: action.payload };
    }
    case "SET_CURR_CH":
      return { ...state, currentChatData: action.payload };
    case "SET_SCROLL_TO_END":
      return { ...state, scrollToEnd: !!action.payload };
    case "SET_CHS":
      return { ...state, channels: action.payload };
    case "SET_CH_MESGS":
      return { ...state, channelMessages: action.payload };
    case "SET_CURR_CH_MESGS":
      return { ...state, currentChannelMessages: action.payload };
    case "SET_CURR_CH_MESGS_PAGINATION":
      return { ...state, currentChannelMessagesPagination: action.payload };
    case "NEW_MESSAGE": {
      let currentChatData = state.currentChatData;
      if (currentChatData.data) {
        let currChMsgs = state.currentChannelMessages || [];
        let data = action.payload;
        if (data.channel_id === currentChatData.data.id) {
          const currChMesgsLen = currChMsgs.length;
          if (data.sender.id === currentChatData.self_user_id) {
            let messageIndex, indexForExternalSelfMesgID;
            for (let i = currChMesgsLen - 1; i > -1; i--) {
              if ((currChMsgs[i].id + "").includes("local")) {
                if (currChMsgs[i].message === data.message) {
                  messageIndex = i;
                }
              } else {
                indexForExternalSelfMesgID = i;
                break;
              }
            }
            data.sender_id = data.sender.id;
            if (messageIndex) {
              currChMsgs[messageIndex] = data;
            } else if (indexForExternalSelfMesgID) {
              currChMsgs = [
                ...currChMsgs.splice(0, indexForExternalSelfMesgID + 1),
                data,
                ...currChMsgs,
              ];
            } else {
              currChMsgs.push(data);
            }
          } else {
            currentChatData.isUserOnline = true;
            let messageIndex;
            for (let i = currChMesgsLen - 1; i > -1; i--) {
              if (data.timestamp >= currChMsgs[i].timestamp) {
                messageIndex = i;
              } else {
                break;
              }
            }
            data.sender_id = data.sender.id;
            if (messageIndex) {
              currChMsgs = [
                ...currChMsgs.splice(0, messageIndex + 1),
                data,
                ...currChMsgs,
              ];
            } else {
              currChMsgs.push(data);
            }
            state.dispatchMessageEvents.recordLastMessageRead({
              channel_id: currentChatData.data.id,
              message_id: data.id,
            });
          }
        }
        return {
          ...state,
          currentChatData,
          currentChannelMessages: currChMsgs,
        };
      } else {
        return state;
      }
    }
    case "DELETE_MESSAGE": {
      let currentChatData = state.currentChatData;
      if (currentChatData.data) {
        let currChMsgs = state.currentChannelMessages;
        if (action.payload.user.id != currentChatData.self_user_id) {
          currentChatData.isUserOnline = true;
        }
        if (action.payload.channel_id === currentChatData.data.id) {
          if (action.payload?.messageIndex) {
            currChMsgs.splice(action.payload?.messageIndex, 1);
          } else {
            const currChMesgsLen = currChMsgs.length;
            let msgIndex;
            for (let i = currChMesgsLen - 1; i > -1; i--) {
              if (currChMsgs[i].id === action.payload.message_id) {
                msgIndex = i;
                break;
              }
            }
            currChMsgs.splice(msgIndex, 1);
          }
        }
        return {
          ...state,
          currentChatData: { ...currentChatData },
          currentChannelMessages: currChMsgs,
        };
      } else {
        return state;
      }
    }
    case "TYPING_STARTED": {
      const copiedState = state;
      if (copiedState.currentChatData?.data?.id === action.payload.channel_id) {
        copiedState.currentChatData.data.typingUserInfo = action.payload.user;
      } else if (copiedState.channels) {
        const i = copiedState.channels.findIndex(
          (c) => c.id == action.payload.channel_id
        );
        if (i > -1) {
          copiedState.channels[i].typingUserInfo = action.payload.user;
        }
      }
      return { ...copiedState };
    }
    case "TYPING_STOPPED": {
      const copiedState = state;
      if (copiedState.currentChatData?.data?.id === action.payload.channel_id) {
        copiedState.currentChatData.data.typingUserInfo = null;
      } else if (copiedState.channels) {
        const i = copiedState.channels.findIndex(
          (c) => c.id === action.payload.channel_id
        );
        if (i > -1) {
          copiedState.channels[i].typingUserInfo = null;
        }
      }
      return { ...copiedState };
    }
    case "RESET_MESSAGES_STATE":
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default function MessagesProvider({ children }) {
  const [state, dispatch] = React.useReducer(MessagesReducer, INITIAL_STATE);
  const [socketConnectStatus, setSocketConnectStatus] = React.useState(
    socket.connected ? "connected" : "disconnected"
  ); // 'connected', 'authenticated', 'disconnected'
  // const [currentChatData, setCurrentChatData] = React.useState({
  //   data: null,
  //   currIndex: undefined,
  //   prevIndex: undefined,
  // });
  const [showResponseModal, setShowResponseModal] = React.useState({
    visible: false,
    onRequestClose: () => null,
    bodyText: "",
  });

  React.useLayoutEffect(() => {
    if (!socket.connected) {
      (async () => {
        let auth_token = await isSignedIn();
        if (auth_token) {
          socket.connect();
        }
      })();
    }

    function errorHandler(errorText) {
      errorMessages.push(errorText);
      const showModal = () => {
        isModalShown = true;
        setShowResponseModal({
          visible: true,
          onRequestClose: () => {
            if (errorMessages.length > 1) {
              errorMessages.pop();
              setTimeout(showModal, 300);
            } else {
              isModalShown = false;
            }
            setShowResponseModal({
              visible: false,
              onRequestClose: () => null,
              bodyText: "",
            });
          },
          bodyText: errorMessages[errorMessages.length - 1] || "",
        });
      };
      if (!isModalShown) {
        showModal();
      }
    }

    function onConnect() {
      setSocketConnectStatus("connected");
      console.log("isConnected to Socket.io:", socket.connected);
      (async () =>
        ((auth_token) => {
          if (auth_token) {
            socket.emit(
              "auth",
              {
                token: auth_token,
              },
              (response) => {
                if (response.status === "error") {
                  console.log(
                    `${response.error.event}: ${response.error.message}`
                  );
                  return errorHandler(
                    `${response.error.event}: ${response.error.message}`
                  );
                } else {
                  setSocketConnectStatus("authenticated");
                  // console.log("ws server auth response : ", response);
                }
              }
            );
          }
        })(await isSignedIn()))();
    }

    function onMessageDeleted(data) {
      console.log("Message Deleted data:", data);
      dispatch({
        type: "DELETE_MESSAGE",
        payload: {
          channel_id: data.channel_id,
          message_id: data.message_id,
          user: data.user,
        },
      });
    }

    function onMessageArrived(data) {
      console.log("Message Arrived data:", data);
      dispatch({ type: "NEW_MESSAGE", payload: data });
    }

    // function onMessage(data) {
    //   console.log("Message : ", data);
    // }

    function onTypingStarted(data) {
      console.log("Typing Started data:", data);
      dispatch({ type: "TYPING_STARTED", payload: data });
    }

    function onTypingStopped(data) {
      console.log("Typing Stopped data:", data);
      dispatch({ type: "TYPING_STOPPED", payload: data });
    }

    function onResponse(data) {
      console.log("Response data:", data);
    }

    function onConnectionError() {
      console.log("Impossible to establish the socket.io connection");
      setSocketConnectStatus("disconnected");
    }

    function onDisconnect() {
      setSocketConnectStatus("disconnected");
    }

    socket.on("connect", onConnect);
    // socket.on("message", onMessage);
    socket.on("message-deleted", onMessageDeleted);
    socket.on("new-message", onMessageArrived);
    socket.on("typing-started", onTypingStarted);
    socket.on("typing-stopped", onTypingStopped);
    socket.on("response", onResponse);
    socket.on("connect_error", onConnectionError);
    socket.on("disconnect", onDisconnect);

    return () => {
      socket.off("connect", onConnect);
      // socket.off("message", onMessage);
      socket.off("message-deleted", onMessageDeleted);
      socket.off("new-message", onMessageArrived);
      socket.off("typing-started", onTypingStarted);
      socket.off("typing-stopped", onTypingStopped);
      socket.off("response", onResponse);
      socket.off("connect_error", onConnectionError);
      socket.off("disconnect", onDisconnect);
    };
  }, []);

  const LatestContextState = () => {
    const { currentChatData } = React.useContext(MessagesContext);

    React.useEffect(() => {
      let onlineTimeout;
      if (currentChatData.isUserOnline) {
        onlineTimeout = setTimeout(() => {
          dispatch({
            type: "SET_CURR_CH",
            payload: { ...currentChatData, isUserOnline: false },
          });
        }, 10000);
      }
      return () => {
        clearTimeout(onlineTimeout);
      };
    }, [currentChatData.isUserOnline]);
  };

  const moveChatAtTopOfListAndGiveCallback = ({
    cb,
    lastMesgId,
    lastIndex,
  }) => {
    let chatsList = state.channels;
    let removedChat = chatsList.splice(state.currentChatData.currIndex, 1);
    removedChat.prevIndexes = {
      ...removedChat.prevIndexes,
      [lastMesgId]: lastIndex,
    };
    chatsList.unshift(...removedChat);
    dispatch({
      type: "SET_CHS",
      payload: chatsList,
    });
    cb(removedChat.prevIndexes);
  };

  return (
    <MessagesContext.Provider
      value={{
        dispatchMessageEvents: state.dispatchMessageEvents,
        socketConnectStatus: socketConnectStatus,
        currentChatData: state.currentChatData,
        scrollToEnd: state.scrollToEnd,
        setScrollToEnd: (scroll) => {
          dispatch({ type: "SET_SCROLL_TO_END", payload: scroll });
        },
        setCurrentChatData: (receivedData) => {
          // const prevChatData = state.currentChatData;
          if (receivedData.data?.unread) {
            receivedData.data.unread = 0;
          }
          dispatch({
            type: "SET_CURR_CH",
            payload: {
              // key: receivedData.data ? receivedData.key : Date.now(),
              data: receivedData.data,
              currIndex: receivedData.currIndex,
              prevIndexes: receivedData.data?.prevIndexes || [],
              self_user_id: receivedData.self_user_id,
              ...receivedData,
            },
          });
          // if (prevChatData.data) {
          //   const prevChatID = prevChatData.data.id;
          //   if (prevChatID) {
          //     dispatch({
          //       type: "SET_CH_MESGS",
          //       payload: {
          //         ...(state.channels || {}),
          //         [prevChatID]: {
          //           messages: [
          //             ...(state.channels[prevChatID] || []),
          //             ...state.currentChannelMessages,
          //           ],
          //           pagination: state.currentChannelMessagesPagination,
          //           prevIndexes: prevChatData.prevIndexes,
          //         },
          //       },
          //     });
          //   }
          // }
          const new_ch_msgs = state.channelMessages?.[receivedData.data.id];
          const len = new_ch_msgs?.messages ? new_ch_msgs?.messages?.length : 0;
          dispatch({
            type: "SET_CURR_CH_MESGS",
            payload: len ? new_ch_msgs.messages : null,
          });
          dispatch({
            type: "SET_CURR_CH_MESGS_PAGINATION",
            payload: new_ch_msgs?.pagination || {
              from: 1,
              to: len,
              total: len,
            },
          });
        },
        currentChannelMessages: state.currentChannelMessages,
        setCurrentChannelMessages: ({
          data,
          push = false,
          unshift = false,
        }) => {
          if (data) {
            data = Array.isArray(data) ? data : [data];
          }
          dispatch({
            type: "SET_CURR_CH_MESGS",
            payload: push
              ? [...(state.currentChannelMessages || {}), ...data]
              : unshift
              ? [...data, ...(state.currentChannelMessages || {})]
              : data,
          });
          if (state.currentChatData?.data?.id && data?.length)
            state.dispatchMessageEvents.recordLastMessageRead({
              channel_id: state.currentChatData.data.id,
              message_id: data[data.length - 1].id,
            });
          const currChatData = state.currentChatData;
          if (push && currChatData.currIndex && currChatData.currIndex !== 0) {
            const currMsgsLen = state.currentChannelMessages?.length;
            moveChatAtTopOfListAndGiveCallback({
              lastMesgId: currMsgsLen
                ? state.currentChannelMessages[currMsgsLen - 2].id
                : -1,
              lastIndex: currMsgsLen ? currMsgsLen - 2 : -1,
              cb: (prevIndexes) =>
                dispatch({
                  type: "SET_CURR_CH",
                  payload: {
                    data: currChatData.data,
                    currIndex: 0,
                    prevIndex: prevIndexes,
                  },
                }),
            });
          }
        },
        updateCurrentChannelMessages: ({
          channel_id,
          message_id,
          messageIndex,
          data,
        }) => {
          let currChMsgs = [...(state.currentChannelMessages || [])];
          if (messageIndex) {
            currChMsgs[messageIndex] = data;
          } else {
            const currChMesgsLen = currChMsgs.length;
            let msgIndex;
            for (let i = currChMesgsLen - 1; i > -1; i--) {
              if (currChMsgs[i].id === message_id) {
                msgIndex = i;
                break;
              }
            }
            currChMsgs[msgIndex] = data;
          }

          dispatch({
            type: "SET_CURR_CH_MESGS",
            payload: currChMsgs,
          });
        },
        deleteCurrentChannelMessages: ({
          channel_id,
          message_id,
          sender_id,
        }) => {
          dispatch({
            type: "DELETE_MESSAGE",
            payload: { channel_id, message_id, user: { id: sender_id } },
          });
        },
        currentChannelMessagesPagination:
          state.currentChannelMessagesPagination,
        setCurrentChannelMessagesPagination: ({ from, to, total }) => {
          dispatch({
            type: "SET_CURR_CH_MESGS_PAGINATION",
            payload: { from, to, total },
          });
        },
        channels: state.channels,
        setChannels: ({ data, unshift = false }) => {
          data = Array.isArray(data) ? data : [data];
          dispatch({
            type: "SET_CHS",
            payload: unshift ? [...data, ...(state.channels || [])] : data,
          });
        },
        // channelMessages: state.channelMessages,
        // setChannelMessages: ({
        //   addMesg: { channel_id, messages, push = false, to },
        //   delMesg = { mesg_id, channel_id },
        // }) => {
        //   let channels = state.channelMessages;
        //   let settingChannel = channels[channel_id];
        //   if (delMesg.mesg_id) {
        //     channels[channel_id].messages = channels[channel_id].messages.filter((m) => m.id !== delMesg.mesg_id);
        //     // channels[channel_id].pagination = {...settingChannel.pagination, to: settingChannel.pagination.to }
        //     dispatch({
        //       type: "SET_CH_MESGS",
        //       payload: {
        //         ...(channels || {}),
        //         [channel_id]: push
        //           ? {
        //               messages: setngChMesgs,
        //               pagination: {
        //                 ...(settingChannel?.pagination || {
        //                   from: null,
        //                   to: null,
        //                   total: null,
        //                 }),
        //                 from: 1,
        //                 to: to || setngMesgsLen,
        //                 total: setngMesgsLen,
        //               },
        //             }
        //           : data,
        //       },
        //     });
        //   } else if (addMesg.channel_id) {
        //     messages = Array.isArray(messages) ? messages : [messages];
        //     const setngChMesgs = [
        //       ...(settingChannel?.messages || []),
        //       ...messages,
        //     ];
        //     const setngMesgsLen = setngChMesgs.length;
        //     dispatch({
        //       type: "SET_CH_MESGS",
        //       payload: {
        //         ...(channels || {}),
        //         [channel_id]: push
        //           ? {
        //               messages: setngChMesgs,
        //               pagination: {
        //                 ...(settingChannel?.pagination || {
        //                   from: null,
        //                   to: null,
        //                   total: null,
        //                 }),
        //                 from: 1,
        //                 to: to || setngMesgsLen,
        //                 total: setngMesgsLen,
        //               },
        //             }
        //           : data,
        //       },
        //     });
        //     const currChatData = state.currentChatData;
        //     if (
        //       currChatData.data.id !== channel_id &&
        //       currChatData.currIndex !== 0
        //     ) {
        //        const currMsgsLen = state.currentChannelMessages?.length;
        //        moveChatAtTopOfListAndGiveCallback({
        //          lastMesgId: currMsgsLen
        //            ? currChatData[currMsgsLen - 2].id
        //            : -1,
        //        lastIndex: currMsgsLen ? currMsgsLen - 2 : -1,
        //        if(currChatData.currIndex) {
        //         const prevIndexesLen = currChatData.prevIndex.length;
        //         dispatch({
        //           type: "SET_CURR_CH",
        //           payload: {
        //             data: currChatData.data,
        //             currIndex: currChatData.currIndex + 1,
        //             prevIndex: prevIndexesLen ? currChatData.prevIndex[prevIndexesLen-1] + 1 : [],
        //           },
        //         })
        //        }
        //       }});
        //     }
        //   }
        // },
        redirectChatUserID: state.redirectChatUserID,
        setRedirectChatUserID: ({ uid, callback = () => null }) => {
          dispatch({ type: "SET_RDR_UID", payload: uid, callback });
        },
        resetMessagesContext: ({ data } = {}) => {
          dispatch({ type: "RESET_MESSAGES_STATE", payload: data || [] });
        },
      }}
    >
      {showResponseModal.visible && (
        <ResponseModal
          visible={showResponseModal.visible}
          onRequestClose={showResponseModal.onRequestClose}
          bodyText={showResponseModal.bodyText}
        />
      )}
      {children}
      <LatestContextState />
    </MessagesContext.Provider>
  );
}

export const useMessagesContext = () => React.useContext(MessagesContext);
