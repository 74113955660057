export const DEV_API = "http://172.105.52.113:1337/api";
import React from "react";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { StackActions } from "@react-navigation/routers";
import axios from "axios";
import { isSignedIn } from "../actions/auth-actions";
import axiosInstance from "../constants/axios-config";
import { apiURIV2 } from "../constants";

let token = null;
export const register = async ({ email, password, isNonProfit }) => {
  try {
    const reg = await axios.post(DEV_API + "/auth/local/register", {
      username: email,
      email: email,
      password: password,
      first_name: "user_" + reg?.data?.user?.id,
      non_profit: isNonProfit,
    });
    if (reg !== null) {
      token = reg?.data?.jwt;
      await AsyncStorage.setItem("user", JSON.stringify(reg?.data?.jwt));
      const me = await axios.get(DEV_API + "/user/me?populate=picture", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return me?.data;
    }
    return false;
  } catch (error) {
    return false;
  }
};
export const googleRegister = async ({
  access_token,
  id_token,
  isNonProfit,
}) => {
  try {
    const reg = await axios.get(
      DEV_API +
        "/auth/google/callback?id_token=" +
        id_token +
        "&access_token=" +
        access_token
    );

    if (reg !== null) {
      token = reg?.data?.jwt;
      const upd = await axios.put(
        DEV_API + "/users/" + reg?.data?.user?.id,
        {
          isNonProfit,
        },
        {
          headers: {
            Authorization: `Bearer ${reg?.data?.jwt}`,
          },
        }
      );
      await AsyncStorage.setItem("user", JSON.stringify(reg?.data?.jwt));
      const me = await axios.get(DEV_API + "/user/me?populate=picture", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return me?.data;
    }
    return reg;
  } catch (error) {
    return error;
  }
};
export const login = async ({ email, password, isNonProfit }) => {
  try {
    const reg = await axios.post(DEV_API + "/auth/local", {
      identifier: email,
      password: password,
    });
    if (reg?.data?.user?.non_profit !== isNonProfit) {
      return false;
    }
    if (reg !== null) {
      token = reg?.data?.jwt;
      await AsyncStorage.setItem("user", JSON.stringify(reg?.data?.jwt));
      const me = await axios.get(DEV_API + "/user/me?populate=picture", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return me?.data;
    }
    return reg?.data;
  } catch (error) {
    return false;
  }
};

export const googleLogin = async ({ access_token, id_token, isNonProfit }) => {
  try {
    const reg = await axios.get(
      DEV_API +
        "/auth/google/callback?id_token=" +
        id_token +
        "&access_token=" +
        access_token
    );
    if (reg?.data?.user?.isNonProfit !== isNonProfit) {
      throw "invalid login";
    }
    if (reg !== null) {
      token = reg?.data?.jwt;
      await AsyncStorage.setItem("user", JSON.stringify(reg?.data?.jwt));
      const me = await axios.get(DEV_API + "/user/me?populate=picture", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return me?.data;
    }
    return reg;
  } catch (error) {
    return false;
  }
};
export const isLoggedin = async () => {
  try {
    const value = await AsyncStorage.getItem("user");
    if (value !== null) {
      token = JSON.parse(value);

      const me = await axios.get(DEV_API + "/user/me?populate=picture", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return me?.data;
    }
    return false;
  } catch (e) {
    return false;
  }
};
export const logout = async () => {
  try {
    await AsyncStorage.removeItem("user");
    return true;
  } catch (e) {
    return false;
  }
};

export const getPublicData = async (url) => {
  try {
    const data = await axios.get(DEV_API + url);
    return data;
  } catch (error) {
    return false;
  }
};

export const getInviteContact = async () => {
  try {
    const token = await isSignedIn();
    if (!token) {
      return null;
    }
    const res = await axiosInstance.get(apiURIV2 + "/invites", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return res;
  } catch (error) {
    console.log("get invites error : ", error);
    if (error?.response) {
      console.log("error.response.status:", error.response.status);
      if (error.response.status === 401) await AsyncStorage.removeItem("ut");
      return error.response;
    } else {
      throw error;
    }
  }
};

export const sendInviteContact = async () => {
  const raw = {
    contacts: [
      {
        mobile: "9527729803",
        name: "Samuel Shinde",
      },
      {
        name: "Mom",
        mobile: "7040366410",
      },
    ],
  };
  try {
    const token = await isSignedIn();
    if (!token) {
      return null;
    }
    const res = await axiosInstance.post(apiURIV2 + "/invites", raw, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return res;
  } catch (error) {
    console.log("send invites error : ", error);
    if (error?.response) {
      console.log("error.response.status:", error.response.status);
      if (error.response.status === 401) await AsyncStorage.removeItem("ut");
      return error.response;
    } else {
      throw error;
    }
  }
};

export const getStats = async () => {
  try {
    const token = await isSignedIn();
    if (!token) {
      return null;
    }
    const res = await axiosInstance.get(apiURIV2 + "/stats", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return res;
  } catch (error) {
    console.log("get stats error : ", error);
    if (error?.response) {
      console.log("error.response.status:", error.response.status);
      if (error.response.status === 401) await AsyncStorage.removeItem("ut");
      return error.response;
    } else {
      throw error;
    }
  }
};

export const getRecentActivites = async () => {
  try {
    const token = await isSignedIn();
    if (!token) {
      return null;
    }
    const res = await axiosInstance.get(apiURIV2 + "/activities", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return res;
  } catch (error) {
    console.log("get activities error : ", error);
    if (error?.response) {
      console.log("error.response.status:", error.response.status);
      if (error.response.status === 401) await AsyncStorage.removeItem("ut");
      return error.response;
    } else {
      throw error;
    }
  }
};

export const getlistingPackages = async () => {
  try {
    const token = await isSignedIn();
    if (!token) {
      return null;
    }
    const res = await axiosInstance.get(apiURIV2 + "/packages", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return res;
  } catch (error) {
    console.log("get packages error : ", error);
    if (error?.response) {
      console.log("error.response.status:", error.response.status);
      if (error.response.status === 401) await AsyncStorage.removeItem("ut");
      return error.response;
    } else {
      throw error;
    }
  }
};

export const clearAllPackages = async () => {
  try {
    const token = await isSignedIn();
    if (!token) {
      return null;
    }
    const res = await axiosInstance.post(apiURIV2 + "/activities/clear", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return res;
  } catch (error) {
    if (error?.response) {
      console.log("error.response.status:", error.response.status);
      if (error.response.status === 401) await AsyncStorage.removeItem("ut");
      return error.response;
    } else {
      throw error;
    }
  }
};
