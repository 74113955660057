import React, { useEffect, useState } from "react";
import { Pressable, Text, View } from "react-native";
import { ScrollView } from "react-native";
import RemixIcon from "react-native-remix-icon";
import colors from "../../../colors";
import { Font } from "../../components/font";
import moment from "moment";
import { apiURIV2 } from "../../constants";
import axiosInstance from "../../constants/axios-config";
import { isSignedIn } from "../../actions/auth-actions";
import { useMessagesContext } from "../../contexts/messages-context";

export const VendorNotifications = ({ navigation }) => {
  const { setRedirectChatUserID } = useMessagesContext();
  const back = navigation.canGoBack();

  const [notifications, setNotifications] = useState([]);
  const [totalLength, setTotalLength] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const pageSize = 20;
  const dismiss = () => {
    if (back) {
      navigation.goBack();
    } else {
      navigation.navigate("home");
    }
  };

  useEffect(() => {
    getNotifications({
      limit: pageSize,
      offset: 0,
    });
  }, []);

  const loadMoreHandler = () => {
    try {
      getNotifications({
        limit: pageSize,
        offset: currentPage * pageSize,
      });
    } catch (e) {
      console.error(e);
    }
  };

  const navigateToScreen = (data) => {
    if (data.screen === "chat") {
      setRedirectChatUserID({
        uid: parseInt(data.channel_details.customer_id),
        callback: () => navigation.navigate("messages"),
      });
    }
    if (data.screen === "booking_details") {
      navigation.navigate("booking-details", {
        bid: parseInt(data.booking_id),
      });
    }
    if (data.screen === "order_details") {
      navigation.navigate("order-details", {
        oid: parseInt(data.product_order_id),
      });
    }
  };

  const getNotifications = ({ limit, offset }) => {
    isSignedIn()
      .then((token) => {
        axiosInstance
          .get(apiURIV2 + "/notifications", {
            params: {
              limit,
              offset,
            },
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((response) => {
            console.log("data", response.data);
            setCurrentPage(currentPage + 1);
            setNotifications([
              ...notifications,
              ...response.data["notifications"],
            ]);
            setTotalLength(response.data["total_count"]);
          })
          .catch((err) => {
            console.log("notifications error: ", err);
          });
      })
      .catch((err) => {
        console.log("isSignedIn error: ", err);
      });
  };

  return (
    <View className="flex-1 bg-transparent">
      <Pressable
        className="w-full h-full absolute top-0 left-0 bg-transparent"
        onPress={dismiss}
      ></Pressable>
      <View className="absolute w-full h-full lg:w-96 lg:h-1/2 top-0 right-0 lg:top-16 lg:right-40 bg-app-e lg:bg-white rounded-none lg:rounded-lg lg:border lg:border-gray-200 rounded-none overflow-hidden lg:rounded-lg">
        <View className="w-full h-16 bg-app-e lg:bg-app-a border-b border-b-transparent lg:border-b-gray-200 items-center flex-row px-1 lg:px-4">
          <Pressable
            className="p-3 rounded-full active:bg-app-c2 active:bg-opacity-10 flex lg:hidden"
            onPress={dismiss}
          >
            <RemixIcon
              name="arrow-left-line"
              className="flex lg:hidden"
              color={colors.app.a}
              size="22"
            ></RemixIcon>
            <RemixIcon
              name="arrow-left-line"
              className="hidden lg:flex"
              color={colors.app.d1}
              size="22"
            ></RemixIcon>
          </Pressable>
          <Font className="text-app-a lg:text-app-d font-semibold text-[15px]  px-2">
            Notifications
          </Font>
          <View className="flex-1"></View>
          {/* <Pressable className=" px-4">
            <Font className="text-app-a lg:text-app-d font-semibold text-xs">
              Read all
            </Font>
          </Pressable> */}
        </View>
        <View className="rounded-t-lg lg:rounded-none overflow-hidden flex-1">
          <ScrollView
            className="rounded-t-lg lg:rounded-none bg-gray-100 lg:bg-white w-full min-h-full p-4 lg:p-0 mb-6"
            contentContainerStyle={{
              flexGrow: 1,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <View className="flex-1 w-full bg-white rounded-2xl">
              {!notifications?.length && (
                <View className="w-full flex-1 justify-center items-center pt-4 pb-8">
                  <Font>No Notifications Found</Font>
                </View>
              )}
              {notifications.map((notification) => (
                <Pressable
                  className="flex-row border-b border-gray-200 p-4"
                  key={notification.id}
                  onPress={() => navigateToScreen(notification.data)}
                >
                  <View className="justify-center flex-1">
                    <View className="flex-row">
                      <RemixIcon
                        name="calendar-line"
                        color={colors.app.d1}
                        size="18"
                      ></RemixIcon>
                      <Font
                        className="text-app-d1 flex-1 ml-2 uppercase"
                        weight={700}
                        numberOfLines={2}
                      >
                        {notification.title}
                      </Font>
                      <Font className="text-xs text-app-d1" weight={600}>
                        {moment(notification.created_at).fromNow()}
                      </Font>
                    </View>
                    <View>
                      <Font
                        className="text-app-d mt-2 leading-4"
                        weight={500}
                        numberOfLines={4}
                      >
                        {notification.subtitle}
                      </Font>
                    </View>
                  </View>
                </Pressable>
              ))}
              {notifications.length < totalLength && (
                <Pressable
                  onPress={loadMoreHandler}
                  className="w-full p-4 items-center justify-center mb-6"
                >
                  <Font className="text-app-e" weight={600}>
                    Load more
                  </Font>
                </Pressable>
              )}
            </View>
          </ScrollView>
        </View>
      </View>
    </View>
  );
};
