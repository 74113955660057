import React from "react";
import { ScrollView, Image, Pressable, View, Text } from "react-native";
import RemixIcon from "react-native-remix-icon";
import Icon from "react-native-remix-icon";
import colors from "../../colors";
import { Font } from "./font";
import { navigate } from "../utils/navigation-util";
export function Honeycomb(props) {
  return (
    <View className="mb-4 items-center" {...props}>
      <View className="flex-row">
        {[...Array(12).keys()].map((e, i) => (
          <View key={i}>
            <Image
              source={require("../../assets/hex.png")}
              style={{
                opacity: 0.6,
                height: 90,
                width: 80,
                margin: 1,
              }}
            ></Image>
            <Pressable
              className="absolute rounded-full items-center"
              style={{
                width: 82,
                height: 86,
                marginTop: 4,
              }}
            >
              <RemixIcon
                name="home-fill"
                size="28"
                color={colors.app.h}
                style={{
                  marginTop: 16,
                }}
              ></RemixIcon>
              <View
                className="flex-row justify-center items-center h-6"
                style={{
                  wordBreak: "break-word",
                }}
              >
                <Font
                  className="text-center text-app-d1 flex-1 mt-[1px] text-xs leading-3"
                  weight={600}
                >
                  Stay
                </Font>
              </View>
            </Pressable>
          </View>
        ))}
      </View>
      <View
        className="flex-row"
        style={{
          marginTop: -20,
        }}
      >
        {[...Array(11).keys()].map((e, i) => (
          <View key={i}>
            <Image
              source={require("../../assets/hex.png")}
              style={{
                opacity: 0.6,
                height: 90,
                width: 80,
                margin: 1,
              }}
            ></Image>
            <Pressable
              className="absolute rounded-full items-center"
              style={{
                width: 82,
                height: 86,
                marginTop: 4,
              }}
            >
              <RemixIcon
                name="home-fill"
                size="28"
                color={colors.app.h}
                style={{
                  marginTop: 16,
                }}
              ></RemixIcon>
              <View
                className="flex-row justify-center items-center h-6"
                style={{
                  wordBreak: "break-word",
                }}
              >
                <Font
                  className="text-center text-app-d1 flex-1 mt-[1px] text-xs leading-3"
                  weight={500}
                >
                  Stay
                </Font>
              </View>
            </Pressable>
          </View>
        ))}
      </View>
      <View
        className="flex-row"
        style={{
          marginTop: -20,
        }}
      >
        {[...Array(12).keys()].map((e, i) => (
          <View key={i}>
            <Image
              source={require("../../assets/hex.png")}
              style={{
                opacity: 0.6,
                height: 90,
                width: 80,
                margin: 1,
              }}
            ></Image>
            <Pressable
              className="absolute rounded-full items-center"
              style={{
                width: 82,
                height: 86,
                marginTop: 4,
              }}
            >
              <RemixIcon
                name="home-fill"
                size="28"
                color={colors.app.h}
                style={{
                  marginTop: 16,
                }}
              ></RemixIcon>
              <View
                className="flex-row justify-center items-center h-6"
                style={{
                  wordBreak: "break-word",
                }}
              >
                <Font
                  className="text-center text-app-d1 flex-1 mt-[1px] text-xs leading-3"
                  weight={500}
                >
                  Stay
                </Font>
              </View>
            </Pressable>
          </View>
        ))}
      </View>
    </View>
  );
}

export function HoneycombSmall(props) {
  // let data = props.data.slice(0, 11);
  let data = [
    { title: "Stay", slug: "" },
    { title: "Stay", slug: "" },
    { title: "Stay", slug: "" },
    { title: "Stay", slug: "" },
    { title: "Stay", slug: "" },
    { title: "Stay", slug: "" },
    { title: "Stay", slug: "" },
    { title: "Stay", slug: "" },
    { title: "Stay", slug: "" },
    { title: "Stay", slug: "" },
    { title: "Stay", slug: "" },
  ];
  let categories = [];
  let longest = Math.ceil(data.length / 3);
  let chunkSizes = [longest, longest - 1];
  let x = 0;
  if (data.length > 0) {
    for (let i = 0; i < data.length; i += chunkSizes[x === 1 ? 0 : 1]) {
      categories.push(data.slice(i, i + chunkSizes[x]));
      x = x === 1 ? 0 : 1;
    }
  }
  return (
    <View className="mb-4 items-center mt-5" {...props}>
      {categories.map((ee, ii) => (
        <View className="flex-row -mt-5" key={ii}>
          {ee.map((e, i) => (
            <View key={i}>
              <Image
                source={require("../../assets/hex.png")}
                style={{
                  opacity: 0.6,
                  height: 90,
                  width: 80,
                  margin: 1,
                }}
              ></Image>
              <Pressable
                className="absolute rounded-full items-center"
                style={{
                  width: 82,
                  height: 86,
                  marginTop: 4,
                }}
                onPress={() =>
                  navigate({
                    name: "search",
                    params: {
                      itemType: "Listing",
                      category: e.slug,
                    },
                  })
                }
              >
                <RemixIcon
                  name="home-fill"
                  size="28"
                  color={colors.app.h}
                  style={{
                    marginTop: 16,
                  }}
                ></RemixIcon>
                <View
                  className="flex-row justify-center items-center h-6"
                  style={{
                    wordBreak: "break-word",
                  }}
                >
                  <Font
                    className="text-center text-app-d1 flex-1 mt-[1px] text-xs leading-3"
                    weight={600}
                  >
                    {e?.title}
                  </Font>
                </View>
              </Pressable>
            </View>
          ))}
        </View>
      ))}
    </View>
  );
}
