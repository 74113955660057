import React, { useState } from "react";
import {
  Pressable,
  View,
  ScrollView,
  FlatList,
  TextInput,
  Platform,
} from "react-native";
import RemixIcon from "react-native-remix-icon";
import { Font } from "../../components/font";
import { Card } from "../../components/general/cards/card";
import {
  Button,
  Input,
  OpeningHoursSlot,
  Select,
} from "../../components/input";
import colors from "../../../colors";
import { Maps } from "../../components/maps";
import { Gallery } from "../../components/gallery";
import { Galleryupload } from "../../components/galleryupload";
import PrevListingSvg from "../../../assets/prev-listing.svg";
import SVGLoader from "../../utils/SVGLoader";
import { apiURIV2 } from "../../constants";
import axiosInstance from "../../constants/axios-config";
import MediaUpload from "../../components/general/uploads/media-upload";
import { useListingContext } from "../../contexts/listing-context";
import { isSignedIn } from "../../actions/auth-actions";
import useInputHandler from "../../utils/useInputHandler";
import { Modal } from "react-native";
import ConfirmModal from "../../components/general/modals/ConfirmModal";
import ServicesWidget from "../../components/general/widgets/services-widget";
import ResponseModal from "../../components/general/modals/ResponseModal";
import VideoPlayer from "../../components/general/Players/VideoPlayer";
import { useSharedContext } from "../../contexts/shared-context";
import { createCrossPlatformFormData } from "../../utils/encDec";
import { ActivityIndicator } from "react-native";
import { useFocusEffect } from "@react-navigation/native";
import { catchAPIErrorText } from "../../utils/error-handler";

const currencyList = [{ label: "USD ($)", value: "USD", symbol: "$" }];

export default function AddListing({ navigation }) {
  const [step, setStep] = React.useState(2);
  // const [priceID, setPriceID] = useState();
  const [showContactWidget, setShowContactWidget] = useState(false);
  const [descriptionReadMore, toggleDescriptionReadMore] = useState(false);
  const [openingHours, setOpeningHours] = useState([]);
  const [availabilitySlots, setAvailabilitySlots] = useState();
  const [getItemDetails, setGetItemDetails] = useState(false);
  const [categoryDetails, setCategoryDetails] = useState();
  const [selectedCoupons, setSelectedCoupons] = React.useState([]);
  const [listingCategories, setListingCategories] = useState();
  const [lCIsLoading, setLCIsLoading] = useState(false);
  const [listingFeatures, setListingFeatures] = useState();
  const [lFIsLoading, setLFIsLoading] = useState(false);
  const [isLoadingSubscription, setIsLoadingSubscription] = useState(true);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [showConfirmModal, setShowConfirmModal] = useState({
    visible: false,
    bodyText: "",
    BodyComponent: "",
    cancelText: "Cancel",
    confirmText: "Confirm",
    onCancel: () => null,
    onConfirm: () => null,
  });
  const [showEditServiceModal, setShowEditServiceModal] = useState({
    visible: false,
    onRequestClose: () =>
      setShowEditServiceModal((d) => {
        d.visible = false;
        d.data.category = null;
        d.data.index = null;
        return { ...d };
      }),
    data: {
      isModal: true,
      isEditMode: true,
      category: null,
      index: null,
    },
  });
  const [showResponseModal, setShowResponseModal] = useState({
    visible: false,
    onRequestClose: () => null,
    bodyText: "",
  });

  const [lPIsLoading, setLPIsLoading] = useState(false);
  const {
    listingPackages,
    setListingPackages,
    listings,
    setListings,
    listingCoupons,
    setListingCoupons,
    pagination,
    setPagination,
  } = useListingContext();

  const { screen } = useSharedContext();

  const [mapAddress, setMapAddress] = useState("");
  const [latLng, setLatLng] = useState();
  const [pickedImage, setPickedImage] = useState();
  const [galleryImages, setGalleryImages] = useState([]);
  const [serviceCategories, setServiceCategories] = useState();
  const [isSCLoading, setIsSCLoading] = useState();

  const [addedServices, setAddedServices] = useState([]);
  const [currencySymbol, setCurrencySymbol] = useState();

  React.useEffect(() => {
    getListingCoupons();
  }, []);

  const getListingCoupons = async () => {
    let data = [];
    try {
      const token = await isSignedIn();
      const res = await axiosInstance.get(apiURIV2 + "/coupons", {
        headers: {
          Authorization: "Bearer " + token,
        },
      });
      console.log("coupon res", res.status);

      if (res.status === 200) {
        data = res.data.data.coupons;
        console.log("coupon res", data);
        const couponList = data.map((CouponsListMap) => ({
          label: CouponsListMap.code,
          value: CouponsListMap.id,
        }));
        // console.log("couponList", couponList);
        setListingCoupons(couponList);
      }
      // if (initiallySelectedCouponID !== null) {
      //   onChangeProductCouponIDText(initiallySelectedCouponID);
      // }
    } catch (e) {
      console.log("e.response", e?.response, e);
    } finally {
    }
  };

  const arrayOfCouponsValue = selectedCoupons.map((obj) => obj.value);

  const getListingFeatures = async (listing_category_id) => {
    setLFIsLoading(true);
    let data;
    try {
      console.log("called!!! LC");
      const res = await axiosInstance.get(apiURIV2 + "/listing-features", {
        params: {
          listing_category_id: listing_category_id || undefined,
        },
      });
      // console.log("res.status: ", res.status, res.data);
      if (res.status === 200) {
        data = res.data.data;
      }
    } catch (e) {
      console.log("lc e.res", e?.response, e);
    } finally {
      console.log("came at setListingFeatures:", data);
      setListingFeatures(data || []);
      setLFIsLoading(false);
    }
  };

  // if (step == 0) {
  //   if (!listingPackages && !lPIsLoading) {
  //     const getListingPackages = async () => {
  //       setLPIsLoading(true);
  //       let data;
  //       try {
  //         console.log("called!!! LP");
  //         const token = await isSignedIn();
  //         const res = await axiosInstance.get(apiURIV2 + "/packages", {
  //           params: { package_item_type: "listing", currency: "USD" },
  //           headers: {
  //             Authorization: `Bearer ${token}`,
  //           },
  //         });
  //         console.log("lp res.status: ", res.status, res.data);
  //         if (res.status === 200) {
  //           data = res.data.data;
  //         }
  //       } catch (e) {
  //         console.log("lp e.res", e?.response, e);
  //       } finally {
  //         console.log("came pkgs", data);
  //         setListingPackages({ data });
  //         setLPIsLoading(false);
  //       }
  //     };
  //     getListingPackages();
  //   }
  // } else
  if (step == 2) {
    if (!listingCategories && !lCIsLoading) {
      const getListingCategories = async () => {
        setLCIsLoading(true);
        let data;
        try {
          console.log("called!!! LC");
          const res = await axiosInstance.get(apiURIV2 + "/listing-categories");
          // console.log("res.status: ", res.status, res.data);
          if (res.status === 200) {
            data = res.data.data;
          }
        } catch (e) {
          console.log("lc e.res", e?.response, e);
        } finally {
          console.log("came");
          setListingCategories(
            data?.map((v) => ({ label: v.title, value: v.id }))
          );
          setLCIsLoading(false);
        }
      };
      getListingCategories();
    }
  } else if (step == 3) {
    if (!serviceCategories && !isSCLoading) {
      const getListingCategories = async () => {
        setIsSCLoading(true);
        let data = [];
        try {
          const res = await axiosInstance.get(apiURIV2 + "/service-categories");
          // console.log("res.status: ", res.status, res.data);
          if (res.status === 200) {
            data = res.data.data;
          }
        } catch (e) {
          console.log("e.res", e?.response, e);
        } finally {
          console.log("came finally");
          setServiceCategories((d) => {
            d = data.map((d1) => ({
              value: d1.id,
              label: d1.title,
            }));
            return [...d];
          });
          setIsSCLoading(false);
        }
      };
      getListingCategories();
    }
  } else if (step == 4) {
    if (categoryDetails && !addedServices?.length) {
      for (const key in categoryDetails) {
        if (Array.isArray(categoryDetails[key])) {
          setAddedServices((addedServ) => {
            categoryDetails[key].forEach((d) => {
              addedServ = [
                ...(addedServ || []),
                { label: d.title, value: d.title, data: d },
              ];
            });
            return [...addedServ];
          });
        }
      }
    }
  }

  const getAddrDetails = async (latitude, longitude) =>
    await axiosInstance.get(
      `https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${latitude}&lon=${longitude}`
    );

  const {
    inputValue: titleInputValue,
    currentValue: titleValue,
    onValueChangeHandler: onChangeTitleText,
    onBlurHandler: onBlurTitle,
    hasError: titleHasError,
    reset: resetTitle,
  } = useInputHandler({ required: true });

  const {
    inputValue: listingCategoryInputValue,
    currentValue: listingCategoryValue,
    onValueChangeHandler: onListingCategoryChange,
    onBlurHandler: onBlurListingCategory,
    hasError: listingCategoryHasError,
    reset: resetListingCategory,
  } = useInputHandler({ required: true });

  const {
    inputValue: listingCouponIDInputValue,
    currentValue: listingCouponIDValue,
    onValueChangeHandler: onChangeListingCouponIDTextFromInputHandler,
    onBlurHandler: onBlurListingCouponID,
    hasError: listingCouponIDHasError,
    reset: resetListingCouponID,
  } = useInputHandler();

  const {
    inputValue: keywordsInputValue,
    currentValue: keywordsValue,
    onValueChangeHandler: onChangeKeywordsText,
    onBlurHandler: onBlurKeywords,
    hasError: keywordsHasError,
    reset: resetKeywords,
  } = useInputHandler();

  const {
    inputValue: latitudeInputValue,
    currentValue: latitudeValue,
    onValueChangeHandler: onChangeLatitudeText,
    onBlurHandler: onBlurLatitude,
    hasError: latitudeHasError,
    reset: resetLatitude,
  } = useInputHandler({ required: true });

  const {
    inputValue: longitudeInputValue,
    currentValue: longitudeValue,
    onValueChangeHandler: onChangeLongitudeText,
    onBlurHandler: onBlurLongitude,
    hasError: longitudeHasError,
    reset: resetLongitude,
  } = useInputHandler({ required: true });

  const {
    inputValue: addressLine1InputValue,
    currentValue: addressLine1Value,
    onValueChangeHandler: onChangeAddressLine1,
    onBlurHandler: onBlurAddressLine1,
    hasError: addressLine1HasError,
    reset: resetAddressLine1,
  } = useInputHandler();

  const {
    inputValue: addressLine2InputValue,
    currentValue: addressLine2Value,
    onValueChangeHandler: onChangeAddressLine2,
    onBlurHandler: onBlurAddressLine2,
    hasError: addressLine2HasError,
    reset: resetAddressLine2,
  } = useInputHandler();

  const {
    inputValue: landmarkInputValue,
    currentValue: landmarkValue,
    onValueChangeHandler: onChangeLandmark,
    onBlurHandler: onBlurLandmark,
    hasError: landmarkHasError,
    reset: resetLandmark,
  } = useInputHandler();

  const {
    inputValue: localityInputValue,
    currentValue: localityValue,
    onValueChangeHandler: onChangeLocality,
    onBlurHandler: onBlurLocality,
    hasError: localityHasError,
    reset: resetLocality,
  } = useInputHandler();

  const {
    inputValue: cityInputValue,
    currentValue: cityValue,
    onValueChangeHandler: onChangeCity,
    onBlurHandler: onBlurCity,
    hasError: cityHasError,
    reset: resetCity,
  } = useInputHandler();

  const {
    inputValue: stateInputValue,
    currentValue: stateValue,
    onValueChangeHandler: onChangeState,
    onBlurHandler: onBlurState,
    hasError: stateHasError,
    reset: resetState,
  } = useInputHandler();

  const {
    inputValue: countryInputValue,
    currentValue: countryValue,
    onValueChangeHandler: onChangeCountry,
    onBlurHandler: onBlurCountry,
    hasError: countryHasError,
    reset: resetCountry,
  } = useInputHandler();

  const {
    inputValue: postalCodeInputValue,
    currentValue: postalCodeValue,
    onValueChangeHandler: onChangePostalCode,
    onBlurHandler: onBlurPostalCode,
    hasError: postalCodeHasError,
    reset: resetPostalCode,
  } = useInputHandler();

  const {
    inputValue: descriptionInputValue,
    currentValue: descriptionValue,
    onValueChangeHandler: onChangeDescription,
    onBlurHandler: onBlurDescription,
    hasError: descriptionHasError,
    reset: resetDescription,
  } = useInputHandler();

  const {
    inputValue: videoLinkInputValue,
    currentValue: videoLinkValue,
    onValueChangeHandler: onChangeVideoLink,
    onBlurHandler: onBlurVideoLink,
    hasError: videoLinkHasError,
    reset: resetVideoLink,
  } = useInputHandler();

  const {
    inputValue: contactPhoneInputValue,
    currentValue: contactPhoneValue,
    onValueChangeHandler: onChangeContactPhone,
    onBlurHandler: onBlurContactPhone,
    hasError: contactPhoneHasError,
    reset: resetContactPhone,
  } = useInputHandler();

  const {
    inputValue: websiteLinkInputValue,
    currentValue: websiteLinkValue,
    onValueChangeHandler: onChangeWebsiteLink,
    onBlurHandler: onBlurWebsiteLink,
    hasError: websiteLinkHasError,
    reset: resetWebsiteLink,
  } = useInputHandler();

  const {
    inputValue: contactEmailInputValue,
    currentValue: contactEmailValue,
    onValueChangeHandler: onChangeContactEmail,
    onBlurHandler: onBlurContactEmail,
    hasError: contactEmailHasError,
    reset: resetContactEmail,
  } = useInputHandler();

  const {
    inputValue: facebookLinkInputValue,
    currentValue: facebookLinkValue,
    onValueChangeHandler: onChangeFacebookLink,
    onBlurHandler: onBlurFacebookLink,
    hasError: facebookLinkHasError,
    reset: resetFacebookLink,
  } = useInputHandler();

  const {
    inputValue: twitterLinkInputValue,
    currentValue: twitterLinkValue,
    onValueChangeHandler: onChangeTwitterLink,
    onBlurHandler: onBlurTwitterLink,
    hasError: twitterLinkHasError,
    reset: resetTwitterLink,
  } = useInputHandler();

  const {
    inputValue: youtubeLinkInputValue,
    currentValue: youtubeLinkValue,
    onValueChangeHandler: onChangeYoutubeLink,
    onBlurHandler: onBlurYoutubeLink,
    hasError: youtubeLinkHasError,
    reset: resetYoutubeLink,
  } = useInputHandler();

  const {
    inputValue: instaLinkInputValue,
    currentValue: instaLinkValue,
    onValueChangeHandler: onChangeInstaLink,
    onBlurHandler: onBlurInstaLink,
    hasError: instaLinkHasError,
    reset: resetInstaLink,
  } = useInputHandler();

  const {
    inputValue: whatsappLinkInputValue,
    currentValue: whatsappLinkValue,
    onValueChangeHandler: onChangeWhatsappLink,
    onBlurHandler: onBlurWhatsappLink,
    hasError: whatsappLinkHasError,
    reset: resetWhatsappLink,
  } = useInputHandler();

  const {
    inputValue: skypeLinkInputValue,
    currentValue: skypeLinkValue,
    onValueChangeHandler: onChangeSkypeLink,
    onBlurHandler: onBlurSkypeLink,
    hasError: skypeLinkHasError,
    reset: resetSkypeLink,
  } = useInputHandler();

  const {
    inputValue: currencyInputValue,
    currentValue: currencyValue,
    onValueChangeHandler: onChangeCurrency,
    onBlurHandler: onBlurCurrency,
    hasError: currencyHasError,
    reset: resetCurrency,
  } = useInputHandler({ defaultValue: "USD" });

  React.useEffect(() => {
    if (!currencySymbol) {
      setCurrencySymbol(
        currencyValue
          ? currencyList.find((v) => v.value === currencyValue)?.label ||
              currencyValue
          : "$"
      );
    }
  }, [currencySymbol, currencyValue]);

  const {
    inputValue: minPriceInputValue,
    currentValue: minPriceValue,
    onValueChangeHandler: onChangeMinPrice,
    onBlurHandler: onBlurMinPrice,
    hasError: minPriceHasError,
    reset: resetMinPrice,
  } = useInputHandler();

  const {
    inputValue: maxPriceInputValue,
    currentValue: maxPriceValue,
    onValueChangeHandler: onChangeMaxPrice,
    onBlurHandler: onBlurMaxPrice,
    hasError: maxPriceHasError,
    reset: resetMaxPrice,
  } = useInputHandler();

  const {
    inputValue: googleMapURLInputValue,
    currentValue: googleMapURLValue,
    onValueChangeHandler: onChangeGoogleMapURL,
    onBlurHandler: onBlurGoogleMapURL,
    hasError: googleMapURLHasError,
    reset: resetGoogleMapURL,
  } = useInputHandler();

  // const {
  //   inputValue: facebookPageURLInputValue,
  //   currentValue: facebookPageURLValue,
  //   onValueChangeHandler: onChangeFacebookPageURL,
  //   onBlurHandler: onBlurFacebookPageURL,
  //   hasError: facebookPageURLHasError,
  //   reset: resetFacebookPageURL,
  // } = useInputHandler();

  const getCurrentSubscription = React.useCallback(async () => {
    try {
      const token = await isSignedIn();
      const res = await axiosInstance.get(
        apiURIV2 + "/package-subscriptions/current",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (res.status === 200) {
        // if (res.data?.data?.package_subscription != null) {
        //   // setPriceID(res.data.data.package_subscription.price_id);
        // }
      }
    } catch (e) {
      console.log("current-subscription error : ", e);
      if (e?.response?.status) {
        setShowResponseModal({
          visible: true,
          onRequestClose: () => {
            setShowResponseModal({
              visible: false,
              onRequestClose: () => null,
              bodyText: "",
              dismissText: undefined,
            });
            navigation.replace("manage-subscription");
          },
          bodyText: "Requires package subscription to proceed",
          dismissText: "Proceed",
        });
      }
    } finally {
      setIsLoadingSubscription(false);
    }
  });

  useFocusEffect(React.useCallback(() => getCurrentSubscription(), []));

  // ===========================================================================================
  // GetItemDetailsComponent
  // ===========================================================================================

  const GetItemDetailsComponent = (props) => {
    const {
      id,
      title,
      subtitle,
      cancellation_penalty,
      // "gap_between_booking_and_appointment_in_hours": 0,
      // "allow_reschedule": true,
      listing_service_category: { id: service_category_id } = {},
      service_unit: {
        name: service_unit_name,
        price,
        weekend_price,
        special_price,
        currency,
        allow_multiple_per_booking,
        max_per_booking,
      } = {},
    } = categoryDetails?.[props.category]?.[props.index] || {};

    const [selectedCategoryID, setSelectedCategoryID] = useState();
    const [allowMultipleUnitsBooking, setAllowMultipleUnitsBooking] =
      useState(false);

    if (!selectedCategoryID && service_category_id) {
      setSelectedCategoryID(service_category_id);
    }
    if (!allowMultipleUnitsBooking && allow_multiple_per_booking) {
      setAllowMultipleUnitsBooking(allow_multiple_per_booking);
    }

    const {
      inputValue: titleInputValue,
      currentValue: titleValue,
      onValueChangeHandler: onChangeTitleText,
      onBlurHandler: onBlurTitle,
      hasError: titleHasError,
      reset: resetTitle,
    } = useInputHandler({ defaultValue: title, required: true });

    const {
      inputValue: descriptionInputValue,
      currentValue: descriptionValue,
      onValueChangeHandler: onChangeDescriptionText,
      onBlurHandler: onBlurDescription,
      hasError: descriptionHasError,
      reset: resetDescription,
    } = useInputHandler({ defaultValue: subtitle, required: true });

    const {
      inputValue: serviceUnitInputValue,
      currentValue: serviceUnitValue,
      onValueChangeHandler: onChangeServiceUnitText,
      onBlurHandler: onBlurServiceUnit,
      hasError: serviceUnitHasError,
      reset: resetServiceUnit,
    } = useInputHandler({ defaultValue: service_unit_name, required: true });

    const {
      inputValue: regularPriceInputValue,
      currentValue: regularPriceValue,
      onValueChangeHandler: onChangeRegularPriceText,
      onBlurHandler: onBlurRegularPrice,
      hasError: regularPriceHasError,
      reset: resetRegularPrice,
    } = useInputHandler({
      defaultValue: price ? price / 100 : undefined,
      required: true,
    });

    const {
      inputValue: weekendPriceInputValue,
      currentValue: weekendPriceValue,
      onValueChangeHandler: onChangeWeekendPriceText,
      onBlurHandler: onBlurWeekendPrice,
      hasError: weekendPriceHasError,
      reset: resetWeekendPrice,
    } = useInputHandler({
      defaultValue: weekend_price ? weekend_price / 100 : undefined,
      required: true,
    });

    const {
      inputValue: specialPriceInputValue,
      currentValue: specialPriceValue,
      onValueChangeHandler: onChangeSpecialPriceText,
      onBlurHandler: onBlurSpecialPrice,
      hasError: specialPriceHasError,
      reset: resetSpecialPrice,
    } = useInputHandler({
      defaultValue: special_price ? special_price / 100 : undefined,
      required: true,
    });

    const {
      inputValue: maxServiceUnitInputValue,
      currentValue: maxServiceUnitValue,
      onValueChangeHandler: onChangeMaxServiceUnitText,
      onBlurHandler: onBlurMaxServiceUnit,
      hasError: maxServiceUnitHasError,
      reset: resetMaxServiceUnit,
    } = useInputHandler({ defaultValue: max_per_booking });

    const {
      inputValue: cancelationPenaltyInputValue,
      currentValue: cancelationPenaltyValue,
      onValueChangeHandler: onChangeCancelationPenaltyText,
      onBlurHandler: onBlurCancelationPenalty,
      hasError: cancelationPenaltyHasError,
      reset: resetCancelationPenalty,
    } = useInputHandler({ defaultValue: cancellation_penalty, required: true });

    const onAddServiceItem = () => {
      if (props.isEditMode) {
        let updatedServiceDetails =
          categoryDetails?.[props.category]?.[props.index] || {};
        if (selectedCategoryID) {
          updatedServiceDetails.service_category_id =
            parseInt(selectedCategoryID);
          updatedServiceDetails.listing_service_category = {
            ...updatedServiceDetails.listing_service_category,
            id: parseInt(selectedCategoryID),
          };
        }
        if (titleInputValue) {
          updatedServiceDetails = {
            ...updatedServiceDetails,
            title: titleInputValue,
          };
        }
        if (descriptionInputValue) {
          updatedServiceDetails = {
            ...updatedServiceDetails,
            subtitle: descriptionInputValue,
          };
        }
        if (cancelationPenaltyInputValue) {
          updatedServiceDetails = {
            ...updatedServiceDetails,
            cancellation_penalty:
              parseInt(cancelationPenaltyInputValue || 0) / 100,
          };
        }
        if (serviceUnitInputValue) {
          updatedServiceDetails.service_unit = {
            ...updatedServiceDetails.service_unit,
            name: serviceUnitInputValue,
          };
        }
        if (regularPriceInputValue) {
          updatedServiceDetails.service_unit = {
            ...updatedServiceDetails.service_unit,
            price: parseInt(regularPriceInputValue || 0) * 100,
          };
        }
        if (weekendPriceInputValue) {
          updatedServiceDetails.service_unit = {
            ...updatedServiceDetails.service_unit,
            weekend_price: parseInt(weekendPriceInputValue || 0) * 100,
          };
        }
        if (specialPriceInputValue) {
          updatedServiceDetails.service_unit = {
            ...updatedServiceDetails.service_unit,
            special_price: parseInt(specialPriceInputValue || 0) * 100,
          };
        }
        if (allowMultipleUnitsBooking) {
          updatedServiceDetails.service_unit = {
            ...updatedServiceDetails.service_unit,
            allow_multiple_per_booking: allowMultipleUnitsBooking,
          };
          if (maxServiceUnitInputValue) {
            updatedServiceDetails.service_unit = {
              ...updatedServiceDetails.service_unit,
              max_per_booking: parseInt(maxServiceUnitInputValue || 1),
            };
          }
        }
        setCategoryDetails((cD) => {
          const i = cD[props.category].findIndex((v) => v.id === id);
          if (i > -1) {
            cD[props.category][i] = {
              ...(cD[props.category][i] || {}),
              ...updatedServiceDetails,
            };
          }
          return { ...cD };
        });
      } else {
        const selectedCategoryTitle = serviceCategories.find(
          (v) => v.value === parseInt(selectedCategoryID)
        ).label;
        const addedServiceDetails = {
          service_category_id: parseInt(selectedCategoryID),
          title: titleInputValue,
          subtitle: descriptionInputValue,
          cancellation_penalty:
            parseInt(cancelationPenaltyInputValue || 0) / 100,
          // "gap_between_booking_and_appointment_in_hours": 0,
          // "allow_reschedule": true,
          service_unit: {
            name: serviceUnitInputValue,
            price: parseInt(regularPriceInputValue || 0) * 100,
            weekend_price: parseInt(weekendPriceInputValue || 0) * 100,
            special_price: parseInt(specialPriceInputValue || 0) * 100,
            currency: "USD",
            allow_multiple_per_booking: allowMultipleUnitsBooking,
            max_per_booking: parseInt(maxServiceUnitInputValue || 0),
            // "allow_multiple_per_availability_slot": true
          },
        };
        addedServiceDetails.service_unit.currency_symbol = "$";
        setCategoryDetails((cD) => {
          if (!cD) {
            cD = {};
          }
          cD[selectedCategoryTitle] = [
            ...(cD[selectedCategoryTitle] || []),
            {
              ...addedServiceDetails,
              id: Date.now() + "-local",
              listing_service_category: { id: parseInt(selectedCategoryID) },
            },
          ];
          console.log("while adding service locally cD: ", cD);
          return { ...cD };
        });
      }
    };

    return (
      <Card
        title="Item Details"
        Widget={() => (
          <Pressable
            onPress={() => {
              if (props.isModal) {
                props.onRequestClose();
              } else {
                setGetItemDetails(false);
              }
            }}
          >
            <RemixIcon
              name="ri-close-fill"
              className=""
              size={25}
              color={colors.app.e}
            />
          </Pressable>
        )}
        className={`${props.isModal ? "w-82 lg:w-2/3" : "mt-4"}`}
      >
        <ScrollView
          className={`mb-4 mx-4 ${
            props.isModal
              ? `${Platform.OS === "web" ? "h-96 lg:h-[368px]" : "h-[75%]"}`
              : "w-full"
          }`}
        >
          <View className="flex-row flex-wrap">
            <View className="px-4 w-full lg:w-1/3 pb-4">
              <Input
                type="text"
                label="Title"
                value={titleValue}
                onChangeText={onChangeTitleText}
                onBlur={onBlurTitle}
                note={titleHasError}
              />
            </View>
            <View className="px-4 w-full lg:w-1/3 pb-4">
              <Select
                mode=""
                label="Category"
                options={serviceCategories}
                value={selectedCategoryID}
                setValue={(e) => setSelectedCategoryID(e)}
                placeholder="Select Category"
              />
            </View>
            <View className="px-4 w-full lg:w-1/3 pb-4">
              <Input
                type="text"
                label="Description"
                value={descriptionValue}
                onChangeText={onChangeDescriptionText}
                onBlur={onBlurDescription}
                note={descriptionHasError}
              />
            </View>
            <View className="px-4 w-full lg:w-1/3 pb-4">
              <Input
                type="text"
                label="Service Unit Alias"
                value={serviceUnitValue}
                onChangeText={onChangeServiceUnitText}
                onBlur={onBlurServiceUnit}
                note={serviceUnitHasError}
              />
            </View>
            <View className="px-4 w-full lg:w-1/3 pb-4">
              <Select
                label="Currency"
                options={currencyList}
                value={currencyValue}
                setValue={(v, i) => {
                  onChangeCurrency(v);
                  setCurrencySymbol(currencyList[i - 1]?.label);
                }}
                placeholder="Select Currency"
                enabled={false}
                note={currencyHasError}
              />
            </View>
            <View className="px-4 w-full lg:w-1/3 pb-4">
              <Input
                type="number"
                label="Regular Price"
                leftText={currencySymbol}
                rightText={`/ ${serviceUnitValue || "-"}`}
                keyboardType="numeric"
                inputMode="numeric"
                value={regularPriceValue}
                onChangeText={onChangeRegularPriceText}
                onBlur={onBlurRegularPrice}
                note={regularPriceHasError}
              />
            </View>
            <View className="px-4 w-full lg:w-1/3 pb-4">
              <Input
                type="number"
                label="Weekend Price"
                leftText={currencySymbol}
                rightText={`/ ${serviceUnitValue || "-"}`}
                keyboardType="numeric"
                inputMode="numeric"
                value={weekendPriceValue}
                onChangeText={onChangeWeekendPriceText}
                onBlur={onBlurWeekendPrice}
                note={weekendPriceHasError}
              />
            </View>
            <View className="px-4 w-full lg:w-1/3 pb-4">
              <Input
                type="number"
                label="Special Price"
                leftText={currencySymbol}
                rightText={`/ ${serviceUnitValue || "-"}`}
                keyboardType="numeric"
                inputMode="numeric"
                value={specialPriceValue}
                onChangeText={onChangeSpecialPriceText}
                onBlur={onBlurSpecialPrice}
                note={specialPriceHasError}
              />
            </View>
            <View className="px-4 w-full lg:w-1/3 pb-4">
              <Input
                type="number"
                label="Cancellation Penalty in Percentage"
                rightText="%"
                keyboardType="numeric"
                inputMode="numeric"
                value={cancelationPenaltyValue}
                onChangeText={onChangeCancelationPenaltyText}
                onBlur={onBlurCancelationPenalty}
                note={cancelationPenaltyHasError}
                placeholder="e.g. 30 (without % sign)"
              />
            </View>
            <View className="px-4 w-full pb-4">
              <Card
                title="Allow Multiple Unit per Booking"
                Widget={() => (
                  <View className="w-8">
                    <Input
                      type="toggle"
                      value={allowMultipleUnitsBooking}
                      setValue={setAllowMultipleUnitsBooking}
                    />
                  </View>
                )}
                className="mb-0"
              >
                {allowMultipleUnitsBooking && (
                  <View className="px-4 w-full pb-4 lg:w-1/3 lg:self-start">
                    <Input
                      type="number"
                      label="Max Service Units per Booking"
                      keyboardType="numeric"
                      inputMode="numeric"
                      value={maxServiceUnitValue}
                      onChangeText={onChangeMaxServiceUnitText}
                      onBlur={onBlurMaxServiceUnit}
                      note={maxServiceUnitHasError}
                    />
                  </View>
                )}
              </Card>
            </View>
            <View className="px-4 w-full pt-4">
              <View className="lg:self-end w-full lg:w-60">
                <Button
                  label={props.isEditMode ? "Update Service" : "Add Service"}
                  type="primary"
                  onPress={() => {
                    if (props.isModal) {
                      props.onRequestClose();
                    } else {
                      setGetItemDetails(false);
                    }
                    return onAddServiceItem();
                  }}
                ></Button>
              </View>
            </View>
          </View>
        </ScrollView>
      </Card>
    );
  };

  const weekDays = [
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
    "sunday",
  ];

  const onContinuePress = () => {
    if (step == 2) {
      onBlurTitle();
      onBlurListingCategory();
      onBlurLatitude();
      onBlurLongitude();
      if (
        !titleInputValue ||
        titleHasError ||
        !listingCategoryInputValue ||
        listingCategoryHasError ||
        !latitudeInputValue ||
        !longitudeInputValue // ||
        // !priceID
      ) {
        return;
      }
      if (step < 5) setStep(step + 1);
    }
  };

  // const resetFormData = React.useCallback(
  //   ({
  //     resetAddedGalleryImages,
  //     resetCurrSelectedFeatures,
  //     resetOtherFormData,
  //     resetAddedServices,
  //     resetAddedOpeningHours,
  //     resetAddedAvailableSlots,
  //   }) => {
  //     if (resetOtherFormData) {
  //       resetTitle();
  //       resetListingCategory();
  //       resetKeywords();
  //     }
  //     if (resetCurrSelectedFeatures) {
  //       setListingFeatures((data) => {
  //         data = data.map((d) => delete d.selected);
  //         return [...data];
  //       });
  //       // (data) => ({
  //       //   ...data,
  //       //   listingFeatures: data.listingFeatures?.filter((d) =>
  //       //     data.currSelectedIDs.includes(d.id) ? false : true
  //       //   ),
  //       //   currSelectedIDs: [],
  //       // });
  //     }
  //     if (resetOtherFormData) {
  //       setLatLng({ lat: undefined, lng: undefined });
  //       setMapAddress("");
  //       resetAddressLine1();
  //       resetAddressLine2();
  //       resetLandmark();
  //       resetLocality();
  //       resetCity();
  //       resetState();
  //       resetCountry();
  //       resetPostalCode();
  //       setPickedImage();
  //     }
  //     if (resetAddedGalleryImages) {
  //       setGalleryImages([]);
  //     }
  //     if (resetOtherFormData) {
  //       resetDescription();
  //       resetContactPhone();
  //       resetContactEmail();
  //       resetVideoLink();
  //       resetWebsiteLink();
  //       resetFacebookLink();
  //       resetTwitterLink();
  //       resetYoutubeLink();
  //       resetInstaLink();
  //       resetWhatsappLink();
  //       resetSkypeLink();
  //       resetMaxPrice();
  //       resetMinPrice();
  //       resetGoogleMapURL();
  //       // resetFacebookPageURL();
  //     }
  //     if (resetAddedServices) {
  //       setCategoryDetails();
  //       setAddedServices([]);
  //     }
  //     if (resetAddedOpeningHours) {
  //       setOpeningHours([]);
  //     }
  //     if (resetAddedAvailableSlots) {
  //       setAvailabilitySlots();
  //     }
  //   },
  //   [
  //     resetTitle,
  //     resetListingCategory,
  //     resetKeywords,
  //     setLatLng,
  //     resetAddressLine1,
  //     resetAddressLine2,
  //     resetLandmark,
  //     resetLocality,
  //     resetCity,
  //     resetState,
  //     resetCountry,
  //     resetPostalCode,
  //     setPickedImage,
  //     resetDescription,
  //     resetContactPhone,
  //     resetContactEmail,
  //     resetVideoLink,
  //     resetWebsiteLink,
  //     resetFacebookLink,
  //     resetTwitterLink,
  //     resetYoutubeLink,
  //     resetInstaLink,
  //     resetWhatsappLink,
  //     resetSkypeLink,
  //     resetMaxPrice,
  //     resetMinPrice,
  //     resetGoogleMapURL,
  //     // resetFacebookPageURL,
  //   ]
  // );

  const onSubmitListingData = () => {
    let updateObj = {};
    updateObj.category_id = parseInt(listingCategoryInputValue);
    updateObj.coupons = arrayOfCouponsValue;
    updateObj.title = titleInputValue;
    updateObj.keywords = JSON.stringify(
      keywordsInputValue?.split(",")?.map((v) => v.trim())
    );
    updateObj.lat = latitudeInputValue || 0;
    updateObj.lng = longitudeInputValue || 0;
    updateObj.address_line_1 = addressLine1InputValue;
    updateObj.address_line_2 = addressLine2InputValue;
    updateObj.landmark = landmarkInputValue;
    updateObj.locality = localityInputValue;
    updateObj.city = cityInputValue;
    updateObj.state = stateInputValue;
    updateObj.country = countryInputValue;
    updateObj.postal_code = postalCodeInputValue;
    updateObj.description = descriptionInputValue;
    updateObj.contact_phone = contactPhoneInputValue;
    updateObj.contact_email = contactEmailInputValue;
    updateObj.video_link = videoLinkInputValue;
    updateObj.website_link = websiteLinkInputValue;
    updateObj.facebook_link = facebookLinkInputValue;
    updateObj.twitter_link = twitterLinkInputValue;
    updateObj.youtube_link = youtubeLinkInputValue;
    updateObj.instagram_link = instaLinkInputValue;
    updateObj.whatsapp_link = whatsappLinkInputValue;
    updateObj.skype_link = skypeLinkInputValue;
    updateObj.max_price = maxPriceInputValue * 100;
    updateObj.min_price = minPriceInputValue * 100;
    updateObj.google_maps_url = googleMapURLInputValue;
    const selectedFeatures = [];
    listingFeatures.forEach((d) => {
      if (d?.selected) {
        selectedFeatures.push(d.id);
      }
    });
    updateObj.features = JSON.stringify(
      selectedFeatures?.length ? selectedFeatures : null
    );
    let openingHoursObj = {};
    if (Object.keys(openingHours || {})?.length) {
      weekDays.forEach((v) => {
        if (openingHours?.[v]) {
          openingHoursObj[v] = openingHours[v]
            ?.filter((d) => {
              if (d?.start_in_min?.tim) {
                return true;
              } else false;
            })
            ?.map((d) => {
              return {
                ...d,
                start_in_min: d.start_in_min.tim,
                end_in_min: d.end_in_min.tim,
              };
            });
        } else {
          openingHoursObj[v] = [];
        }
      });
    }

    updateObj.opening_hours = JSON.stringify(
      openingHoursObj ? openingHoursObj : null
    );
    let aval_sl = availabilitySlots;

    const services = addedServices.map((d) => {
      let data = { ...d.data };
      data.availability = {};
      for (const key in aval_sl) {
        aval_sl[key].filter((v) => {
          if (v.service_id === data.title) {
            v.start_in_min = v.start_in_min.tim;
            v.end_in_min = v.end_in_min.tim;
            delete v.service_id;
            data.availability[key] = [...(data.availability[key] || []), v];
            return false;
          }
          return true;
        });
      }
      return data;
    });
    updateObj.services = JSON.stringify(services?.length ? services : null);
    // updateObj.cover_image = pickedImage?.blob;
    updateObj.cover_image = pickedImage;
    // updateObj.gallery_images = galleryImages?.length
    //   ? galleryImages.map((d) => d?.blob)
    //   : null;
    updateObj.gallery_images = galleryImages;

    if (Object.keys(updateObj)?.length) {
      const addListing = async () => {
        for (const key in updateObj) {
          if (!updateObj[key]) {
            delete updateObj[key];
          }
        }
        const formData = createCrossPlatformFormData({
          blobDataKeys: ["cover_image", "gallery_images"],
          data: updateObj,
        });
        // for (let [name, value] of formData) {
        //   console.log(`\n\n${name} = `, value, "\n\n");
        // }
        try {
          setIsSubmitting(true);
          const token = await isSignedIn();
          const res = await axiosInstance.post(
            apiURIV2 + "/listings/",
            formData,
            {
              headers: {
                Authorization: "Bearer " + token,
                "Content-Type": "multipart/form-data",
              },
            }
          );
          // console.log("res.status: ", res.status, res.data);
          if (res.status === 201) {
            if (listings?.length) {
              setListings({ data: res.data.data, push: true });
              setPagination({
                from: pagination.from,
                to: pagination.to + 1,
                total: pagination.total,
              });
            }
            setShowResponseModal({
              visible: true,
              onRequestClose: () => {
                setShowResponseModal({
                  visible: false,
                  onRequestClose: () => null,
                  bodyText: "",
                });
                navigation.replace("listings");
              },
              bodyText: `“${(
                updateObj.title + ""
              ).trim()}” Listing has been Successfully Submitted for Approval`,
            });
          }
        } catch (e) {
          console.log(
            "e:",
            e,
            "e.res",
            e?.response,
            "\n\n",
            e?.response?.status,
            "\n\n",
            e?.response?.data
          );

          setShowResponseModal({
            visible: true,
            onRequestClose: () => {
              setShowResponseModal({
                visible: false,
                onRequestClose: () => null,
                bodyText: "",
              });
            },
            bodyText: catchAPIErrorText(e, "Unknown Error while Submitting Listing Details"),
          });
        } finally {
          setIsSubmitting(false);
          // resetFormData({
          //   resetAddedGalleryImages: true,
          //   resetCurrSelectedFeatures: true,
          //   resetOtherFormData: true,
          //   resetAddedServices: true,
          //   resetAddedOpeningHours: true,
          //   resetAddedAvailableSlots: true,
          // });
        }
      };
      addListing();
    }
  };

  // ==================================== Coupons ====================================

  const handleCouponSelection = (couponId) => {
    const selectedCouponObj = listingCoupons.find(
      (coupon) => coupon.value === couponId
    );

    if (selectedCouponObj) {
      setSelectedCoupons([...selectedCoupons, selectedCouponObj]);
      setListingCoupons(
        listingCoupons.filter((coupon) => coupon.value !== couponId)
      );
    }
  };

  const handleCancel = (couponId) => {
    const canceledCouponObj = selectedCoupons.find(
      (coupon) => coupon.value === couponId
    );

    if (canceledCouponObj) {
      setListingCoupons([...listingCoupons, canceledCouponObj]);
      setSelectedCoupons(
        selectedCoupons.filter((coupon) => coupon.value !== couponId)
      );
    }
  };

  if (lPIsLoading || lCIsLoading || isSCLoading || isLoadingSubscription) {
    if (showResponseModal.visible)
      return (
        <ResponseModal
          visible={showResponseModal.visible}
          onRequestClose={showResponseModal.onRequestClose}
          bodyText={showResponseModal.bodyText}
          dismissText={showResponseModal?.dismissText}
        />
      );

    return (
      <View style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
        <ActivityIndicator color={colors.app.e} size="large" />
      </View>
    );
  }

  return (
    <View className="flex-1 bg-app-e lg:bg-gray-50">
      {showResponseModal.visible && (
        <ResponseModal
          visible={showResponseModal.visible}
          onRequestClose={showResponseModal.onRequestClose}
          bodyText={showResponseModal.bodyText}
          dismissText={showResponseModal?.dismissText}
        />
      )}
      <View className="rounded-t-2xl h-full max-h-max bg-gray-50">
        <View className="flex-1 max-w-screen-lg w-full content-around h-full grow justify-center mx-auto">
          <View className="w-full my-0 lg:my-5 p-0 lg:px-4 flex-row items-center justify-between">
            <View className="flex-row items-center hidden w-full lg:w-3/12">
              <Font className="text-lg font-semibold text-app-d hidden lg:flex">
                Add Listings
              </Font>
            </View>
            {step > 0 ? (
              <View className="flex-row px-4 py-4 lg:pt-1 lg:pb-3 items-center w-full justify-center">
                <View className="relative w-full">
                  <View className="h-[2px] w-full absolute bottom-[5px] px-5">
                    <View className="h-[2px] w-full bg-gray-300"></View>
                  </View>
                  <View className="flex-row justify-between w-full">
                    {/* <View className="items-center">
                      <Font
                        className={`${
                          1 < step
                            ? "text-app-g"
                            : step == 1
                            ? "text-app-e"
                            : "text-app-d"
                        }`}
                      >
                        Type
                      </Font>
                      <View
                        className={`w-3 h-3 rounded-full mt-2 ${
                          1 < step
                            ? "bg-app-g"
                            : step == 1
                            ? "bg-app-e"
                            : "bg-app-d"
                        }`}
                      ></View>
                    </View> */}
                    <View className="items-center">
                      <Font
                        className={`${
                          2 < step
                            ? "text-app-g"
                            : step == 2
                            ? "text-app-e"
                            : "text-app-d"
                        }`}
                      >
                        Details
                      </Font>
                      <View
                        className={`w-3 h-3 rounded-full mt-2 ${
                          2 < step
                            ? "bg-app-g"
                            : step == 2
                            ? "bg-app-e"
                            : "bg-app-d"
                        }`}
                      ></View>
                    </View>
                    <View className="items-center">
                      <Font
                        className={`${
                          3 < step
                            ? "text-app-g"
                            : step == 3
                            ? "text-app-e"
                            : "text-app-d"
                        }`}
                      >
                        Services
                      </Font>
                      <View
                        className={`w-3 h-3 rounded-full mt-2 ${
                          3 < step
                            ? "bg-app-g"
                            : step == 3
                            ? "bg-app-e"
                            : "bg-app-d"
                        }`}
                      ></View>
                    </View>
                    <View className="items-center">
                      <Font
                        className={`${
                          4 < step
                            ? "text-app-g"
                            : step == 4
                            ? "text-app-e"
                            : "text-app-d"
                        }`}
                      >
                        Schedule
                      </Font>
                      <View
                        className={`w-3 h-3 rounded-full mt-2 ${
                          4 < step
                            ? "bg-app-g"
                            : step == 4
                            ? "bg-app-e"
                            : "bg-app-d"
                        }`}
                      ></View>
                    </View>
                    <View className="items-center">
                      <Font
                        className={`${
                          5 < step
                            ? "text-app-e"
                            : step == 5
                            ? "text-app-e"
                            : "text-app-d"
                        }`}
                      >
                        Submit
                      </Font>
                      <View
                        className={`w-3 h-3 rounded-full mt-2 ${
                          step >= 5 ? "bg-app-e" : "bg-app-d"
                        }`}
                      ></View>
                    </View>
                  </View>
                </View>
              </View>
            ) : null}
          </View>
          {showConfirmModal.visible && <ConfirmModal {...showConfirmModal} />}
          {showEditServiceModal.visible && (
            <Modal
              animationType="fade"
              transparent={true}
              visible={showEditServiceModal.visible}
              onRequestClose={showEditServiceModal.onRequestClose}
            >
              <View className="flex-1 justify-center items-center bg-black/50 px-8">
                <GetItemDetailsComponent
                  onRequestClose={showEditServiceModal.onRequestClose}
                  {...showEditServiceModal.data}
                />
              </View>
            </Modal>
          )}
          {/* {step == 0 ? (
            listingPackages ? (
              <ScrollView
                className="rounded-t-2xl"
                overScrollMode="never"
                contentContainerStyle={{
                  flexGrow: 1,
                  padding: 16,
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <Card title="Choose Package" className="mt-4">
                  <View className="w-full flex-row flex-wrap px-4">
                    <FlatList
                      data={listingPackages}
                      key={screen?.width}
                      numColumns={screen?.width >= 768 ? 2 : 1}
                      renderItem={({
                        item: {
                          id,
                          title,
                          subTitle,
                          is_featured,
                          features,
                          prices,
                        },
                      }) => (
                        <View className="w-full md:w-1/2 mb-4 md:m-0 md:p-4">
                          <PlanCard
                            planTitle={title}
                            planPrice={
                              prices[0].currency_symbol +
                              " " +
                              prices[0].discounted_price / 100
                            }
                            planSubtitle={subTitle}
                            planFeatures={features}
                            onPress={() => {
                              setPriceID(id);
                              setStep(2);
                            }}
                            isFeatured={is_featured}
                          />
                        </View>
                      )}
                      // numColumns={2}
                    />
                  </View>
                </Card>
              </ScrollView>
            ) : (
              <View className="flex-1 justify-center items-center">
                <Font>Loading...</Font>
              </View>
            )
          ) : step == 1 ? (
            <ScrollView
              className="rounded-t-2xl"
              overScrollMode="never"
              contentContainerStyle={{
                flexGrow: 1,
                paddingHorizontal: 16,
                paddingBottom: 16,
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Card title="Listing Type">
                <View className="w-full flex-row flex-wrap px-4 py-2">
                  <Pressable
                    onPress={() => setListing((e) => ({ ...e, type: "rent" }))}
                    className="w-full lg:w-1/3 lg:pr-2 rounded-lg mb-4"
                  >
                    <View
                      className={`h-24 border flex-row overflow-hidden rounded-lg ${
                        listing.type == "rent"
                          ? "border-app-f"
                          : "border-gray-300"
                      }`}
                    >
                      <View className="w-24 h-full bg-app-f items-center justify-center">
                        <RemixIcon
                          name="ri-home-6-fill"
                          size={50}
                          color={colors.app.a}
                        ></RemixIcon>
                      </View>
                      <View
                        className={`justify-center p-3 flex-1 ${
                          listing.type == "rent" ? "bg-app-f3" : ""
                        }`}
                      >
                        <Font className="font-semibold text-lg leading-5 text-app-d my-1">
                          Rent
                        </Font>
                        <Font className="text-app-d1">
                          Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet
                        </Font>
                      </View>
                    </View>
                  </Pressable>
                  <Pressable
                    onPress={() =>
                      setListing((e) => ({ ...e, type: "service" }))
                    }
                    className="w-full lg:w-1/3 lg:pr-2 rounded-lg mb-4"
                  >
                    <View
                      className={`h-24 border flex-row overflow-hidden rounded-lg ${
                        listing.type == "service"
                          ? "border-app-g"
                          : "border-gray-300"
                      }`}
                    >
                      <View className="w-24 h-full bg-app-g items-center justify-center">
                        <RemixIcon
                          name="ri-map-pin-3-fill"
                          size={50}
                          color={colors.app.a}
                        ></RemixIcon>
                      </View>
                      <View
                        className={`justify-center p-3 flex-1 ${
                          listing.type == "service" ? "bg-app-g3" : ""
                        }`}
                      >
                        <Font className="font-semibold text-lg leading-5 text-app-d my-1">
                          Service
                        </Font>
                        <Font className="text-app-d1">
                          Lorem ipsum dolor sit amet
                        </Font>
                      </View>
                    </View>
                  </Pressable>
                  <Pressable
                    onPress={() => setListing((e) => ({ ...e, type: "event" }))}
                    className="w-full lg:w-1/3 lg:pr-2 rounded-lg mb-4"
                  >
                    <View
                      className={`h-24 border flex-row overflow-hidden rounded-lg ${
                        listing.type == "event"
                          ? "border-app-h"
                          : "border-gray-300"
                      }`}
                    >
                      <View className="w-24 h-full bg-app-h items-center justify-center">
                        <RemixIcon
                          name="ri-coupon-3-fill"
                          size={50}
                          color={colors.app.a}
                        ></RemixIcon>
                      </View>
                      <View
                        className={`justify-center p-3 flex-1 ${
                          listing.type == "event" ? "bg-app-h3" : ""
                        }`}
                      >
                        <Font className="font-semibold text-lg leading-5 text-app-d my-1">
                          Event
                        </Font>
                        <Font className="text-app-d1">
                          Lorem ipsum dolor sit amet
                        </Font>
                      </View>
                    </View>
                  </Pressable>
                </View>
              </Card>
              <View className="flex-1 grow w-full h-full flex-col min-h-[16px]"></View>
              <View className="w-full flex-row justify-center lg:justify-end y-4">
                <View className="w-full lg:w-60">
                  <Button
                    label={[5, 6].includes(step) ? "Submit" : "Continue"}
                    type="primary"
                    onPress={() => step < 5 && setStep(step + 1)}
                  ></Button>
                </View>
              </View>
            </ScrollView>
          ) */}
          {step == 2 ? (
            <ScrollView
              className="rounded-t-2xl"
              overScrollMode="never"
              contentContainerStyle={{
                flexGrow: 1,
                paddingHorizontal: 16,
                paddingBottom: 16,
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <>
                <Card title="Basic Information">
                  <View className="flex-row flex-wrap px-2 pb-4 w-full">
                    <View className="px-2 w-full lg:w-1/3 pb-4">
                      <Input
                        type="text"
                        label="Title"
                        value={titleValue}
                        onChangeText={onChangeTitleText}
                        onBlur={onBlurTitle}
                        note={titleHasError}
                      />
                    </View>
                    <View className="px-2 w-full lg:w-1/3 pb-4">
                      <Select
                        label="Category"
                        options={listingCategories}
                        placeholder="Select category"
                        value={listingCategoryValue}
                        setValue={(e) => {
                          onListingCategoryChange(e);
                          getListingFeatures(e);
                        }}
                        note={listingCategoryHasError}
                      />
                    </View>
                    <View className="px-2 w-full lg:w-1/3 pb-4">
                      <Input
                        type="text"
                        label="Keywords"
                        value={keywordsValue}
                        onChangeText={onChangeKeywordsText}
                        onBlur={onBlurKeywords}
                        note={keywordsHasError}
                        placeholder="e.g. hotel, store, grocery"
                      />
                    </View>
                    <View className="px-2 w-full mb-4">
                      <View className="w-full h-8 items-center flex-row">
                        <Font className="flex-1  text-app-d1" weight={600}>
                          Other Features
                        </Font>
                      </View>
                      <View className="w-full">
                        <FlatList
                          className="flex-1 w-full pb-4 pt-2"
                          horizontal={!!listingFeatures?.length}
                          data={listingFeatures}
                          ItemSeparatorComponent={() => (
                            <View className="w-2"></View>
                          )}
                          ListEmptyComponent={() => (
                            <View className="flex-1 justify-center items-center">
                              <Font
                                className={lFIsLoading ? "" : "text-app-d1/80"}
                              >
                                {lFIsLoading
                                  ? "Loading..."
                                  : listingCategoryValue
                                  ? "No Features Found"
                                  : "Select category to show available features"}
                              </Font>
                            </View>
                          )}
                          renderItem={({ item, index }) => (
                            <Pressable
                              className={`border p-3 mt-1 rounded-lg ${
                                item?.selected
                                  ? "border-app-g bg-app-g3"
                                  : "border-gray-200"
                              } overflow-hidden`}
                              onPress={() => {
                                setListingFeatures((data) => {
                                  data[index].selected = !data[index]?.selected;
                                  return [...data];
                                });
                              }}
                            >
                              <Font>{item.title}</Font>
                              {item?.selected && (
                                <View className="rounded-full bg-app-g p-5 absolute -right-4 -bottom-6">
                                  <RemixIcon
                                    name="ri-check-fill"
                                    className="-my-5 -ml-4 -mr-1 -pb-8"
                                    size={20}
                                    color={colors.app.a}
                                  />
                                </View>
                              )}
                            </Pressable>
                          )}
                        />
                      </View>
                    </View>
                  </View>
                </Card>
                <Card title="Location">
                  <View className="flex-row flex-wrap px-2 pb-4">
                    <View className="w-full h-80 pb-4 px-2">
                      <Maps
                        url={websiteLinkInputValue}
                        image={pickedImage?.uri}
                        title={titleInputValue}
                        location={
                          `${cityInputValue ? cityInputValue : ""}${
                            stateInputValue ? ", " + stateInputValue : ""
                          }${
                            countryInputValue ? ", " + countryInputValue : ""
                          }${
                            postalCodeInputValue
                              ? " - " + postalCodeInputValue
                              : ""
                          }` || mapAddress
                        }
                        latitude={latitudeValue}
                        longitude={longitudeValue}
                        rating={"0.00"}
                        priceMin={minPriceInputValue}
                        priceMax={maxPriceInputValue}
                        onLocate={async ({ data: { latitude, longitude } }) => {
                          console.log("lat:", latitude, "lon:", longitude);
                          onChangeLatitudeText(latitude);
                          onChangeLongitudeText(longitude);
                          const addrDetails = await getAddrDetails(
                            latitude,
                            longitude
                          );
                          setMapAddress(addrDetails.data?.display_name);
                        }}
                      ></Maps>
                    </View>
                    <View className="px-2 w-full lg:w-1/3 pb-4">
                      <Input
                        type="text"
                        label="Map's Address"
                        value={mapAddress}
                        editable={false}
                      />
                    </View>
                    <View className="px-2 w-full lg:w-1/3 pb-4">
                      <Input
                        type="number"
                        label="Latitude"
                        value={latitudeValue}
                        keyboardType="numeric"
                        inputMode="numeric"
                        editable={false}
                        note={latitudeHasError}
                      />
                    </View>
                    <View className="px-2 w-full lg:w-1/3 pb-4">
                      <Input
                        type="number"
                        label="Longitude"
                        value={longitudeValue}
                        keyboardType="numeric"
                        inputMode="numeric"
                        editable={false}
                        note={longitudeHasError}
                      />
                    </View>
                    <View className="px-2 w-full lg:w-1/3 pb-4">
                      <Input
                        type="text"
                        label="Address Line 1"
                        value={addressLine1Value}
                        onChangeText={onChangeAddressLine1}
                        onBlur={onBlurAddressLine1}
                        note={addressLine1HasError}
                      />
                    </View>
                    <View className="px-2 w-full lg:w-1/3 pb-4">
                      <Input
                        type="text"
                        label="Address Line 2"
                        value={addressLine2Value}
                        onChangeText={onChangeAddressLine2}
                        onBlur={onBlurAddressLine2}
                        note={addressLine2HasError}
                      />
                    </View>
                    <View className="px-2 w-full lg:w-1/3 pb-4">
                      <Input
                        type="text"
                        label="Landmark"
                        value={landmarkValue}
                        onChangeText={onChangeLandmark}
                        onBlur={onBlurLandmark}
                        note={landmarkHasError}
                      />
                    </View>
                    <View className="px-2 w-full lg:w-1/3 pb-4">
                      <Input
                        type="text"
                        label="Locality"
                        value={localityValue}
                        onChangeText={onChangeLocality}
                        onBlur={onBlurLocality}
                        note={localityHasError}
                      />
                    </View>
                    <View className="px-2 w-full lg:w-1/3 pb-4">
                      <Input
                        type="text"
                        label="City"
                        value={cityValue}
                        onChangeText={onChangeCity}
                        onBlur={onBlurCity}
                        note={cityHasError}
                      />
                    </View>
                    <View className="px-2 w-full lg:w-1/3 pb-4">
                      <Input
                        type="text"
                        label="State"
                        value={stateValue}
                        onChangeText={onChangeState}
                        onBlur={onBlurState}
                        note={stateHasError}
                      />
                    </View>
                    <View className="px-2 w-full lg:w-1/3 pb-4">
                      <Input
                        type="text"
                        label="Country"
                        value={countryValue}
                        onChangeText={onChangeCountry}
                        onBlur={onBlurCountry}
                        note={countryHasError}
                      />
                    </View>
                    <View className="px-2 w-full lg:w-1/3 pb-4">
                      <Input
                        type="number"
                        label="Postal Code"
                        value={postalCodeValue}
                        onChangeText={onChangePostalCode}
                        onBlur={onBlurPostalCode}
                        note={postalCodeHasError}
                        keyboardType="numeric"
                        inputMode="numeric"
                      />
                    </View>
                  </View>
                </Card>
                <Card title="Cover Image">
                  <View className="px-4 pt-4 pb-8 w-full">
                    <View className="w-full lg:w-96 h-max flex-row flex-wrap">
                      <MediaUpload
                        pickedImage={pickedImage}
                        onImagePick={(data) => setPickedImage(data)}
                        // placeholderImage={
                        //   cover_image_url ? { uri: mediaURI + cover_image_url } : null
                        // }
                      />
                    </View>
                  </View>
                </Card>
                <Card title="Gallery">
                  <View
                    className={`px-2 pt-4 pb-8 flex-row basis-0 items-start w-full ${
                      Platform.OS === "web" ? "flex-1" : ""
                    }`}
                  >
                    <View className="w-full h-full flex-row flex-1">
                      <Galleryupload
                        images={galleryImages}
                        onAddImage={(addedImage) =>
                          setGalleryImages((d) => {
                            d = [...(d || []), addedImage];
                            return [...d];
                          })
                        }
                        onRemoveImage={(removedImage) =>
                          setGalleryImages((d) => {
                            const i = d.findIndex(
                              (v) => v.id === removedImage.id
                            );
                            if (i > -1) {
                              d.splice(i, 1);
                            }
                            return [...d];
                          })
                        }
                      />
                    </View>
                  </View>
                </Card>
                <Card title="Details">
                  <View className="flex-row flex-wrap px-2">
                    {/* <View className="px-2 w-full lg:w-2/3 pb-4"> */}
                    <View className="px-2 w-full pb-4">
                      <Input
                        type="longtext"
                        label="Description"
                        value={descriptionValue}
                        onChangeText={onChangeDescription}
                        onBlur={onBlurDescription}
                        note={descriptionHasError}
                        // height="h-40 lg:h-[184px]"
                      />
                    </View>
                    {/* <View className="px-2 w-full lg:w-1/3 pb-4 pt-4 lg:pt-0 justify-center"> */}
                    <View className="px-2 w-full lg:w-1/3 pb-4">
                      {/* <View className="w-[327px] h-[184px] lg:w-[256px] lg:h-[144px] rounded-lg bg-black/90 items-center justify-center flex-row">
                        {videoLinkInputValue ? (
                          <VideoPlayer videoURI={videoLinkInputValue} />
                        ) : (
                          <Font className="p-2 text-white text-center">
                            No Media URL
                          </Font>
                        )}
                      </View> */}
                      <Input
                        type="text"
                        label="Video"
                        value={videoLinkValue}
                        onChangeText={onChangeVideoLink}
                        onBlur={onBlurVideoLink}
                        note={videoLinkHasError}
                      />
                    </View>
                    <View className="px-2 w-full lg:w-1/3 pb-4">
                      <Input
                        type="number"
                        label="Phone"
                        value={contactPhoneValue}
                        onChangeText={onChangeContactPhone}
                        onBlur={onBlurContactPhone}
                        note={contactPhoneHasError}
                      />
                    </View>
                    <View className="px-2 w-full lg:w-1/3 pb-4">
                      <Input
                        type="text"
                        label="Website"
                        value={websiteLinkValue}
                        onChangeText={onChangeWebsiteLink}
                        onBlur={onBlurWebsiteLink}
                        note={websiteLinkHasError}
                      />
                    </View>
                    <View className="px-2 w-full lg:w-1/3 pb-4">
                      <Input
                        type="text"
                        label="Email"
                        value={contactEmailValue}
                        onChangeText={onChangeContactEmail}
                        onBlur={onBlurContactEmail}
                        note={contactEmailHasError}
                      />
                    </View>
                    <View className="px-2 w-full pb-4">
                      <Input
                        type="toggle"
                        label="Enable Contact Widget"
                        setValue={() =>
                          setShowContactWidget(!showContactWidget)
                        }
                        value={showContactWidget}
                      />
                    </View>
                    <View className="px-2 w-full lg:w-1/3 pb-4">
                      <Input
                        type="text"
                        label="Facebook"
                        value={facebookLinkValue}
                        onChangeText={onChangeFacebookLink}
                        onBlur={onBlurFacebookLink}
                        note={facebookLinkHasError}
                      />
                    </View>
                    <View className="px-2 w-full lg:w-1/3 pb-4">
                      <Input
                        type="text"
                        label="Twitter"
                        value={twitterLinkValue}
                        onChangeText={onChangeTwitterLink}
                        onBlur={onBlurTwitterLink}
                        note={twitterLinkHasError}
                      />
                    </View>
                    <View className="px-2 w-full lg:w-1/3 pb-4">
                      <Input
                        type="text"
                        label="Youtube"
                        value={youtubeLinkValue}
                        onChangeText={onChangeYoutubeLink}
                        onBlur={onBlurYoutubeLink}
                        note={youtubeLinkHasError}
                      />
                    </View>
                    <View className="px-2 w-full lg:w-1/3 pb-4">
                      <Input
                        type="text"
                        label="Instagram"
                        value={instaLinkValue}
                        onChangeText={onChangeInstaLink}
                        onBlur={onBlurInstaLink}
                        note={instaLinkHasError}
                      />
                    </View>
                    <View className="px-2 w-full lg:w-1/3 pb-4">
                      <Input
                        type="text"
                        label="Whatsapp"
                        value={whatsappLinkValue}
                        onChangeText={onChangeWhatsappLink}
                        onBlur={onBlurWhatsappLink}
                        note={whatsappLinkHasError}
                      />
                    </View>
                    <View className="px-2 w-full lg:w-1/3 pb-4">
                      <Input
                        type="text"
                        label="Skype"
                        value={skypeLinkValue}
                        onChangeText={onChangeSkypeLink}
                        onBlur={onBlurSkypeLink}
                        note={skypeLinkHasError}
                      />
                    </View>
                    <View className="px-2 w-full lg:w-1/3 pb-4">
                      <Select
                        label="Currency"
                        options={currencyList}
                        value={currencyValue}
                        setValue={(e) => {
                          onChangeCurrency(e);
                          setCurrencySymbol(
                            currencyList.find((v) => v.value === e)
                          );
                        }}
                        placeholder={
                          currencyValue === "USD"
                            ? "Select Currency"
                            : currencyValue
                        }
                        enabled={false}
                        note={currencyHasError}
                      />
                    </View>
                    <View className="px-2 w-full lg:w-1/3 pb-4">
                      <Input
                        type="number"
                        label="Minimum Price"
                        keyboardType="numeric"
                        inputMode="numeric"
                        value={minPriceValue}
                        onChangeText={onChangeMinPrice}
                        onBlur={onBlurMinPrice}
                        note={minPriceHasError}
                      />
                    </View>
                    <View className="px-2 w-full lg:w-1/3 pb-4">
                      <Input
                        type="number"
                        label="Maximum Price"
                        keyboardType="numeric"
                        inputMode="numeric"
                        value={maxPriceValue}
                        onChangeText={onChangeMaxPrice}
                        onBlur={onBlurMaxPrice}
                        note={maxPriceHasError}
                      />
                    </View>
                  </View>
                </Card>
                {/* <Card title="Coupons">
                  <View className="flex-row flex-wrap px-2 w-full">
                    <View className="px-2 w-full lg:w-1/3 pb-4">
                      <Select
                        // label="Select Coupons From List"
                        options={listingCoupons}
                        placeholder="Select Coupons From List"
                        value={null}
                        onValueChange={handleCouponSelection}
                        note={listingCouponIDHasError}
                      />
                    </View>
                  </View>
                  <View className="px-2 w-full lg:w-1/3 pb-4">
                    <View className="">
                      {selectedCoupons.map((coupon) => (
                        <View
                          key={coupon.value}
                          className="bg-gray-200 rounded-full px-4 py-2 m-2"
                        >
                          <Font className="mr-2">{coupon.label}</Font>
                          <Pressable
                            className=" p-2 cursor-pointer"
                            onPress={() => handleCancel(coupon.value)}
                          >
                            <MaterialIcons
                              name="cancel"
                              size={24}
                              color={colors.app.e1}
                            />
                          </Pressable>
                        </View>
                      ))}
                    </View>
                  </View>
                </Card> */}
                <Card title="Third-party reviews">
                  <View className="flex-row flex-wrap px-2 pb-4 w-full">
                    <View className="px-2 w-full lg:w-1/3 pb-4">
                      <Input
                        type="text"
                        label="Google Map’s URL"
                        value={googleMapURLValue}
                        onChangeText={onChangeGoogleMapURL}
                        onBlur={onBlurGoogleMapURL}
                        note={googleMapURLHasError}
                      />
                    </View>
                    {/* <View className="px-2 w-full lg:w-1/3 pb-4">
                      <Input
                        type="text"
                        label="Facebook Page URL"
                        value={facebookPageURLValue}
                        onChangeText={onChangeFacebookPageURL}
                        onBlur={onBlurFacebookPageURL}
                        note={facebookPageURLHasError}
                      />
                    </View> */}
                  </View>
                </Card>
                <View className="flex-1 grow w-full h-full flex-col min-h-[16px]"></View>
                <View className="w-full flex-row justify-center lg:justify-end y-4">
                  <View className="w-full lg:w-60">
                    <Button
                      label={[5, 6].includes(step) ? "Submit" : "Continue"}
                      type="primary"
                      onPress={onContinuePress}
                    ></Button>
                  </View>
                </View>
              </>
            </ScrollView>
          ) : step == 3 ? (
            <ScrollView
              className="rounded-t-2xl"
              overScrollMode="never"
              contentContainerStyle={{
                flexGrow: 1,
                paddingHorizontal: 16,
                paddingBottom: 16,
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <View className="w-full">
                <View className="w-full">
                  <Card
                    title="Pricing & Bookable Services"
                    Widget={() => (
                      <View className="w-8">
                        <Input
                          type="toggle"
                          value={1}
                          setValue={() => null}
                        ></Input>
                      </View>
                    )}
                  >
                    <View className="w-full px-4 mb-4">
                      <ServicesWidget
                        categoryWiseServicesData={categoryDetails}
                        deleteCategoryHandler={(category) => {
                          setShowConfirmModal((d) => ({
                            ...d,
                            visible: true,
                            BodyComponent: () => (
                              <Font>
                                Do you want to delete All the{" "}
                                <Font className="text-app-f capitalize">
                                  {category}
                                </Font>{" "}
                                Category Services?
                              </Font>
                            ),
                            onConfirm: () => {
                              setCategoryDetails((cD) => {
                                delete cD[category];
                                return { ...cD };
                              });
                              setShowConfirmModal((d1) => {
                                d1.visible = false;
                                d1.onConfirm = () => null;
                                d1.onCancel = () => null;
                                d1.cancelText = "Cancel";
                                d1.confirmText = "Confirm";
                                d1.bodyText = "";
                                return { ...d1 };
                              });
                            },
                            onCancel: () =>
                              setShowConfirmModal((d1) => {
                                d1.visible = false;
                                d1.onConfirm = () => null;
                                d1.onCancel = () => null;
                                d1.cancelText = "Cancel";
                                d1.confirmText = "Confirm";
                                d1.bodyText = "";
                                return { ...d1 };
                              }),
                          }));
                        }}
                        editServiceHandler={(category, service, index) => {
                          setShowEditServiceModal((d) => {
                            d.visible = true;
                            d.data.category = category;
                            d.data.index = index;
                            return { ...d };
                          });
                        }}
                        deleteServiceHandler={(category, service, index) => {
                          setShowConfirmModal((d) => ({
                            ...d,
                            visible: true,
                            BodyComponent: () => (
                              <Font>
                                Do you want to delete{" "}
                                <Font className="text-app-f capitalize">
                                  {service.title}
                                </Font>{" "}
                                Service?
                              </Font>
                            ),
                            onConfirm: () => {
                              setCategoryDetails((cD) => {
                                if (cD[category]?.length === 1) {
                                  delete cD[category];
                                } else {
                                  cD[category].splice(index, 1);
                                }
                                return { ...cD };
                              });
                              setShowConfirmModal((d1) => {
                                d1.visible = false;
                                d1.onConfirm = () => null;
                                d1.onCancel = () => null;
                                d1.cancelText = "Cancel";
                                d1.confirmText = "Confirm";
                                d1.bodyText = "";
                                return { ...d1 };
                              });
                            },
                            onCancel: () =>
                              setShowConfirmModal((d1) => {
                                d1.visible = false;
                                d1.onConfirm = () => null;
                                d1.onCancel = () => null;
                                d1.cancelText = "Cancel";
                                d1.confirmText = "Confirm";
                                d1.bodyText = "";
                                return { ...d1 };
                              }),
                          }));
                        }}
                      />
                      <View className="flex-row w-full">
                        <Pressable
                          className="bg-app-a flex-1 lg:flex-none px-2 py-1 rounded-full border border-app-g flex-row justify-start items-center mx-1"
                          onPress={() => setGetItemDetails(true)}
                        >
                          <RemixIcon
                            name="ri-add-circle-line"
                            className="mr-2"
                            color={colors.app.g}
                          ></RemixIcon>
                          <Font className="text-app-g leading-4">
                            Add Service
                          </Font>
                        </Pressable>
                      </View>
                      {!!getItemDetails ? <GetItemDetailsComponent /> : <></>}
                    </View>
                  </Card>
                </View>
                {/* <Card
                    title="Enable Booking"
                    Widget={() => (
                      <View className="w-8">
                        <Input type="toggle"></Input>
                      </View>
                    )}
                  >
                    <View></View>
                  </Card> */}
                {/* <Card
                    title="Booking Prices & Settings"
                    Widget={() => (
                      <View className="w-8">
                        <Input type="toggle"></Input>
                      </View>
                    )}
                  >
                    <View className="flex-row flex-wrap px-2">
                      <View className="px-2 w-full lg:w-1/3 mb-4">
                        <Input type="text" label="Regular Price"></Input>
                      </View>
                      <View className="px-2 w-full lg:w-1/3 mb-4">
                        <Input type="text" label="Weekend Price"></Input>
                      </View>
                      <View className="px-2 w-full lg:w-1/3 mb-4">
                        <Input type="text" label="Special Price"></Input>
                      </View>
                      <View className="px-2 w-full lg:w-1/3 mb-4">
                        <Input type="text" label="Reservation Fee"></Input>
                      </View>
                      <View className="px-2 w-full lg:w-1/3 mb-4">
                        <Input
                          type="text"
                          label="Reservation Expire After"
                        ></Input>
                      </View>

                      <View className="px-2 w-full lg:w-1/3 mb-4">
                        <Input
                          type="toggle"
                          label="Enable Instant Booking"
                        ></Input>
                      </View>
                      <View className="px-2 w-full lg:w-1/3 mb-4">
                        <Input
                          type="toggle"
                          label="Enable Price Per Guest"
                        ></Input>
                      </View>
                      <View className="px-2 w-full lg:w-1/3 mb-4">
                        <Input
                          type="toggle"
                          label="Enable End Hour Time-picker"
                        ></Input>
                      </View>
                      <View className="px-2 w-full lg:w-1/3 mb-6">
                        <Input
                          type="text"
                          label="Maximum Number Of Guest"
                        ></Input>
                      </View>
                    </View>
                  </Card> */}
                {/* <View className="w-full lg:w-1/2 lg:ml-4"> */}
                {/* <Card
                  title="Enable Coupon Widget"
                  Widget={() => (
                    <View className="w-8">
                      <Input
                        type="toggle"
                        value={1}
                        setValue={() => null}
                      ></Input>
                    </View>
                  )}
                  className="mb-4"
                >
                  <View className="px-4 w-full">
                    <View className="mb-4 mx-auto w-full lg:w-3/4 flex-row flex-wrap">
                      <View className="flex-row justify-between items-center w-full pb-2">
                        <Font weight="600" className="capitalize">
                          Coupons List
                        </Font>
                        <Pressable
                          onPress={() =>
                            addCoupons((coupons) => [
                              ...coupons,
                              coupons.length + 1,
                            ])
                          }
                        >
                          <RemixIcon
                            name="ri-add-circle-line"
                            className="px-2"
                            color={colors.app.g}
                            size={25}
                          />
                        </Pressable>
                      </View>
                      <View className="flex-row flex-wrap w-full lg:justify-start items-center gap-x-2">
                        {!coupons.length ? (
                          <View className="w-full flex-row justify-center items-center border border-app-d3 rounded-md py-2 pl-4 pr-8 mb-2">
                            <Font>No Coupons</Font>
                          </View>
                        ) : (
                          coupons.map((v1, i1) => (
                            <View
                              key={"coupons" + i1}
                              className={`flex-row justify-center lg:justify-start items-center border border-app-c rounded-md py-1.5 pl-4 pr-2 mb-2 ${
                                Platform.OS === "web" ? "md:w-max" : "w-full"
                              }`}
                            >
                              <View className="flex-row justify-center items-center flex-1">
                                <TextInput
                                  value={`Coupon ${v1}`}
                                  className="w-full"
                                />
                              </View>
                              <Pressable
                                onPress={() =>
                                  addCoupons((coupons) => {
                                    coupons.splice(i1, 1);
                                    return [...coupons];
                                  })
                                }
                              >
                                <RemixIcon
                                  name="ri-close-fill"
                                  className=""
                                  size={25}
                                  color={colors.app.e}
                                />
                              </Pressable>
                            </View>
                          ))
                        )}
                      </View>
                    </View>
                  </View>
                </Card> */}
                {/* </View> */}
              </View>
              <View className="flex-1 grow w-full h-full flex-col min-h-[16px]"></View>
              <View className="w-full flex-row justify-center lg:justify-end y-4">
                <View className="w-full lg:w-60">
                  <Button
                    label={[5, 6].includes(step) ? "Submit" : "Continue"}
                    type="primary"
                    onPress={() => step < 5 && setStep(step + 1)}
                  ></Button>
                </View>
              </View>
            </ScrollView>
          ) : step == 4 ? (
            <ScrollView
              className="rounded-t-2xl"
              overScrollMode="never"
              contentContainerStyle={{
                flexGrow: 1,
                paddingHorizontal: 16,
                paddingBottom: 16,
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <>
                <Card
                  title="Opening Hours"
                  Widget={() => (
                    <View className="w-8">
                      <Input
                        type="toggle"
                        value={1}
                        setValue={() => null}
                      ></Input>
                    </View>
                  )}
                >
                  <View className="px-4 w-full">
                    <View className="mb-4 w-full flex-row flex-wrap">
                      <FlatList
                        data={weekDays}
                        renderItem={({ item, index }) => (
                          <>
                            <View
                              className={`flex-row justify-between items-center w-full p-2 ${
                                index > 0 ? "mt-4" : ""
                              }`}
                            >
                              <Font weight="600" className="capitalize">
                                {item}
                              </Font>
                              <Pressable
                                onPress={() => {
                                  setOpeningHours((oH) => {
                                    oH[item] = [
                                      ...(oH[item] || []),
                                      {
                                        id: Date.now(),
                                        start_in_min: {
                                          h: "09",
                                          m: "00",
                                          tim: 540,
                                        },
                                        end_in_min: {
                                          h: "21",
                                          m: "00",
                                          tim: 1260,
                                        },
                                      },
                                    ];
                                    return { ...oH };
                                  });
                                }}
                              >
                                <RemixIcon
                                  name="ri-add-circle-line"
                                  color={colors.app.g}
                                  size={25}
                                />
                              </Pressable>
                            </View>
                            {openingHours?.[item]?.length ? (
                              openingHours[item].map((d, i) => (
                                <View
                                  key={d.id}
                                  className={`w-full flex-row justify-center items-center border border-app-d3 rounded-md py-1.5 px-4 ${
                                    i > 0 ? "mt-4" : ""
                                  }`}
                                >
                                  <View className="flex-1 flex-row justify-center items-center">
                                    <TextInput
                                      className="font-medium px-2 w-max"
                                      inputMode="numeric"
                                      keyboardType="numeric"
                                      style={
                                        Platform.OS === "web" && {
                                          outlineColor: "transparent",
                                        }
                                      }
                                      value={d.start_in_min.h}
                                      onChangeText={(e) => {
                                        let intValue = parseInt(e || 0);
                                        if (intValue < 0) {
                                          e = "00";
                                          intValue = 0;
                                        } else if (intValue > 23) {
                                          e = "23";
                                          intValue = 23;
                                        } else if (isNaN(e)) {
                                          e = e !== "" ? "00" : e;
                                          intValue = 0;
                                        }
                                        const exstMins = parseInt(
                                          d.start_in_min.m || 0
                                        );
                                        setOpeningHours((oH) => {
                                          oH[item][i].start_in_min = {
                                            h: e,
                                            m:
                                              exstMins < 10
                                                ? "0" + exstMins
                                                : exstMins,
                                            tim: intValue * 60 + exstMins,
                                          };
                                          return { ...oH };
                                        });
                                      }}
                                    />
                                    <Font
                                      weight={800}
                                      className="font-bold pb-[2px] px-1"
                                    >
                                      :
                                    </Font>
                                    <TextInput
                                      className="font-medium px-2 w-max"
                                      inputMode="numeric"
                                      keyboardType="numeric"
                                      style={
                                        Platform.OS === "web" && {
                                          outlineColor: "transparent",
                                        }
                                      }
                                      value={d.start_in_min.m}
                                      onChangeText={(e) => {
                                        let intValue = parseInt(e || 0);
                                        if (intValue < 0) {
                                          e = "00";
                                          intValue = 0;
                                        } else if (intValue > 59) {
                                          e = "59";
                                          intValue = parseInt(59);
                                        } else if (isNaN(e)) {
                                          e = e !== "" ? "00" : e;
                                          intValue = 0;
                                        }
                                        const exstHrs = parseInt(
                                          d.start_in_min.h || 0
                                        );
                                        setOpeningHours((oH) => {
                                          oH[item][i].start_in_min = {
                                            h:
                                              exstHrs < 10
                                                ? "0" + exstHrs
                                                : exstHrs,
                                            m: e,
                                            tim: intValue + exstHrs * 60,
                                          };
                                          return { ...oH };
                                        });
                                      }}
                                    />
                                  </View>
                                  <Font className="font-bold px-1">-</Font>
                                  <View className="flex-1 flex-row justify-center items-center">
                                    <TextInput
                                      className="font-medium px-2"
                                      inputMode="numeric"
                                      keyboardType="numeric"
                                      style={
                                        Platform.OS === "web" && {
                                          outlineColor: "transparent",
                                        }
                                      }
                                      value={d.end_in_min.h}
                                      onChangeText={(e) => {
                                        let intValue = parseInt(e || 0);
                                        if (intValue < 0) {
                                          e = "00";
                                          intValue = 0;
                                        } else if (intValue > 23) {
                                          e = "23";
                                          intValue = 23;
                                        } else if (isNaN(e)) {
                                          e = e !== "" ? "00" : e;
                                          intValue = 0;
                                        }
                                        const exstMins = parseInt(
                                          d.end_in_min.m || 0
                                        );
                                        setOpeningHours((oH) => {
                                          oH[item][i].end_in_min = {
                                            h: e,
                                            m:
                                              exstMins < 10
                                                ? "0" + exstMins
                                                : exstMins,
                                            tim: intValue * 60 + exstMins,
                                          };
                                          return { ...oH };
                                        });
                                      }}
                                    />
                                    <Font
                                      weight={800}
                                      className="font-bold pb-[2px] px-1"
                                    >
                                      :
                                    </Font>
                                    <TextInput
                                      className="font-medium px-2"
                                      inputMode="numeric"
                                      keyboardType="numeric"
                                      style={
                                        Platform.OS === "web" && {
                                          outlineColor: "transparent",
                                        }
                                      }
                                      value={d.end_in_min.m}
                                      onChangeText={(e) => {
                                        let intValue = parseInt(e || 0);
                                        if (intValue < 0) {
                                          e = "00";
                                          intValue = 0;
                                        } else if (intValue > 59) {
                                          e = "59";
                                          intValue = parseInt(59);
                                        } else if (isNaN(e)) {
                                          e = e !== "" ? "00" : e;
                                          intValue = 0;
                                        }
                                        const exstHrs = parseInt(
                                          d.end_in_min.h || 0
                                        );
                                        setOpeningHours((oH) => {
                                          oH[item][i].end_in_min = {
                                            h:
                                              exstHrs < 10
                                                ? "0" + exstHrs
                                                : exstHrs,
                                            m: e,
                                            tim: intValue + exstHrs * 60,
                                          };
                                          return { ...oH };
                                        });
                                      }}
                                    />
                                  </View>
                                  <Pressable
                                    onPress={() => {
                                      setOpeningHours((oHD) => {
                                        oHD[item].splice(i, 1);
                                        return { ...oHD };
                                      });
                                    }}
                                  >
                                    <RemixIcon
                                      name="ri-close-fill"
                                      className="-mr-1"
                                      size={25}
                                      color={colors.app.e}
                                    />
                                  </Pressable>
                                </View>
                              ))
                            ) : (
                              <View className="w-full flex-row justify-center items-center border border-app-d3 rounded-md py-2">
                                <Font>Not Available</Font>
                              </View>
                            )}
                          </>
                        )}
                      />
                    </View>
                  </View>
                </Card>
                <Card
                  title="Availability"
                  Widget={() => (
                    <View className="w-8">
                      <Input
                        type="toggle"
                        value={addedServices?.length ? 1 : 0}
                        setValue={() => null}
                      ></Input>
                    </View>
                  )}
                >
                  <View className="px-4 w-full">
                    <View className="mb-4 w-full flex-row">
                      {addedServices?.length ? (
                        <FlatList
                          data={weekDays}
                          renderItem={({ item, index }) => (
                            <>
                              <View
                                className={`flex-row justify-between items-center w-full p-2 ${
                                  index > 0 ? "mt-4" : ""
                                }`}
                              >
                                <Font weight="600" className="capitalize">
                                  {item}
                                </Font>
                                <Pressable
                                  onPress={() => {
                                    if (addedServices?.length) {
                                      const id = Date.now() + "-local",
                                        data = {
                                          id: id,
                                          start_in_min: {
                                            h: "09",
                                            m: "00",
                                            tim: 540,
                                          },
                                          end_in_min: {
                                            h: "21",
                                            m: "00",
                                            tim: 1260,
                                          },
                                          service_unit_capacity: 1,
                                          service_id: "",
                                        };
                                      setAvailabilitySlots((aS) => {
                                        if (!aS) {
                                          aS = {};
                                        }
                                        if (!aS?.[item]) {
                                          aS[item] = {};
                                        }
                                        if (aS[item]?.length) {
                                          aS[item].push(data);
                                        } else {
                                          aS[item] = [data];
                                        }
                                        return { ...aS };
                                      });
                                    }
                                  }}
                                >
                                  <RemixIcon
                                    name="ri-add-circle-line"
                                    className=""
                                    size={25}
                                    color={colors.app.g}
                                  />
                                </Pressable>
                              </View>
                              {availabilitySlots?.[item]?.length ? (
                                availabilitySlots[item].map((d1, i) => (
                                  <View
                                    key={d1.id}
                                    className={`border border-app-d3 p-4 rounded-lg bg-app-b3 ${
                                      i > 0 ? "mt-6" : ""
                                    }`}
                                  >
                                    <View className="w-full flex-row justify-center items-center border border-app-c rounded-md py-1.5 px-4">
                                      <View className="flex-1 flex-row justify-center items-center">
                                        <TextInput
                                          className="font-medium"
                                          inputMode="numeric"
                                          keyboardType="numeric"
                                          style={
                                            Platform.OS === "web" && {
                                              outlineColor: "transparent",
                                            }
                                          }
                                          value={d1.start_in_min.h}
                                          onChangeText={(e) => {
                                            let intValue = parseInt(e || 0);
                                            if (intValue < 0) {
                                              e = "00";
                                              intValue = 0;
                                            } else if (intValue > 23) {
                                              e = "23";
                                              intValue = 23;
                                            } else if (isNaN(e)) {
                                              e = e !== "" ? "00" : e;
                                              intValue = 0;
                                            }
                                            const exstMins = parseInt(
                                              d1.start_in_min.m || 0
                                            );
                                            setAvailabilitySlots((aS) => {
                                              aS[item][i].start_in_min = {
                                                h: e,
                                                m:
                                                  exstMins < 10
                                                    ? "0" + exstMins
                                                    : exstMins,
                                                tim: intValue * 60 + exstMins,
                                              };
                                              return { ...aS };
                                            });
                                          }}
                                        />
                                        <Font
                                          weight={800}
                                          className="font-bold pb-[2px] px-1"
                                        >
                                          :
                                        </Font>
                                        <TextInput
                                          className="font-medium"
                                          inputMode="numeric"
                                          keyboardType="numeric"
                                          style={
                                            Platform.OS === "web" && {
                                              outlineColor: "transparent",
                                            }
                                          }
                                          value={d1.start_in_min.m}
                                          onChangeText={(e) => {
                                            let intValue = parseInt(e || 0);
                                            if (intValue < 0) {
                                              e = "00";
                                              intValue = 0;
                                            } else if (intValue > 59) {
                                              e = "59";
                                              intValue = parseInt(59);
                                            } else if (isNaN(e)) {
                                              e = e !== "" ? "00" : e;
                                              intValue = 0;
                                            }
                                            const exstHrs = parseInt(
                                              d1.start_in_min.h || 0
                                            );
                                            setAvailabilitySlots((aS) => {
                                              aS[item][i].start_in_min = {
                                                h:
                                                  exstHrs < 10
                                                    ? "0" + exstHrs
                                                    : exstHrs,
                                                m: e,
                                                tim: intValue + exstHrs * 60,
                                              };
                                              return { ...aS };
                                            });
                                          }}
                                        />
                                      </View>
                                      <Font className="font-bold px-1">-</Font>
                                      <View className="flex-1 flex-row justify-center items-center">
                                        <TextInput
                                          className="font-medium"
                                          inputMode="numeric"
                                          keyboardType="numeric"
                                          style={
                                            Platform.OS === "web" && {
                                              outlineColor: "transparent",
                                            }
                                          }
                                          value={d1.end_in_min.h}
                                          onChangeText={(e) => {
                                            let intValue = parseInt(e || 0);
                                            if (intValue < 0) {
                                              e = "00";
                                              intValue = 0;
                                            } else if (intValue > 23) {
                                              e = "23";
                                              intValue = 23;
                                            } else if (isNaN(e)) {
                                              e = e !== "" ? "00" : e;
                                              intValue = 0;
                                            }
                                            const exstMins = parseInt(
                                              d1.end_in_min.m || 0
                                            );
                                            setAvailabilitySlots((aS) => {
                                              aS[item][i].end_in_min = {
                                                h: e,
                                                m:
                                                  exstMins < 10
                                                    ? "0" + exstMins
                                                    : exstMins,
                                                tim: intValue * 60 + exstMins,
                                              };
                                              return { ...aS };
                                            });
                                          }}
                                        />
                                        <Font
                                          weight={800}
                                          className="font-bold pb-[2px] px-1"
                                        >
                                          :
                                        </Font>
                                        <TextInput
                                          className="font-medium"
                                          inputMode="numeric"
                                          keyboardType="numeric"
                                          style={
                                            Platform.OS === "web" && {
                                              outlineColor: "transparent",
                                            }
                                          }
                                          value={d1.end_in_min.m}
                                          onChangeText={(e) => {
                                            let intValue = parseInt(e || 0);
                                            if (intValue < 0) {
                                              e = "00";
                                              intValue = 0;
                                            } else if (intValue > 59) {
                                              e = "59";
                                              intValue = parseInt(59);
                                            } else if (isNaN(e)) {
                                              e = e !== "" ? "00" : e;
                                              intValue = 0;
                                            }
                                            const exstHrs = parseInt(
                                              d1.end_in_min.h || 0
                                            );
                                            setAvailabilitySlots((aS) => {
                                              aS[item][i].end_in_min = {
                                                h:
                                                  exstHrs < 10
                                                    ? "0" + exstHrs
                                                    : exstHrs,
                                                m: e,
                                                tim: intValue + exstHrs * 60,
                                              };
                                              return { ...aS };
                                            });
                                          }}
                                        />
                                      </View>
                                      <Pressable
                                        onPress={() => {
                                          setAvailabilitySlots((aS) => {
                                            if (aS[item]?.length === 1) {
                                              delete aS[item];
                                            } else {
                                              aS[item].splice(i, 1);
                                            }
                                            return { ...aS };
                                          });
                                        }}
                                      >
                                        <RemixIcon
                                          name="ri-close-fill"
                                          className="-mr-1"
                                          size={25}
                                          color={colors.app.e}
                                        />
                                      </Pressable>
                                    </View>
                                    <View className="flex-row w-full mt-2">
                                      <Select
                                        mode=""
                                        placeholder="Choose service"
                                        options={addedServices}
                                        value={d1.service_id}
                                        setValue={(e) => {
                                          setAvailabilitySlots((aSD) => {
                                            aSD[item][i].service_id = e;
                                            return { ...aSD };
                                          });
                                        }}
                                      />
                                    </View>
                                    <View className="flex-row w-full mt-2">
                                      <Input
                                        type="counter"
                                        setValue={(e) => {
                                          const intValue = parseInt(
                                            e(d1.service_unit_capacity)
                                          );
                                          setAvailabilitySlots((aSD) => {
                                            aSD[item][i].service_unit_capacity =
                                              intValue;
                                            return { ...aSD };
                                          });
                                        }}
                                        value={d1.service_unit_capacity}
                                        placeholder="Available slots"
                                        min={1}
                                        max={1000}
                                      />
                                    </View>
                                  </View>
                                ))
                              ) : (
                                <View className="w-full flex-auto justify-center items-center border border-app-d3 rounded-md py-2 px-8">
                                  <Font>Not Available</Font>
                                </View>
                              )}
                            </>
                          )}
                        />
                      ) : (
                        <View className="w-full flex-1 justify-center items-center border border-app-d3 rounded-md py-2 px-8">
                          <Font>No Services Added</Font>
                        </View>
                      )}
                    </View>
                  </View>
                </Card>
              </>
              <View className="flex-1 grow w-full h-full flex-col min-h-[16px]"></View>
              <View className="w-full flex-row justify-center lg:justify-end y-4">
                <View className="w-full lg:w-60">
                  <Button
                    label={[5, 6].includes(step) ? "Submit" : "Continue"}
                    type="primary"
                    onPress={() => step < 5 && setStep(step + 1)}
                  ></Button>
                </View>
              </View>
            </ScrollView>
          ) : step == 5 ? (
            <ScrollView
              className="rounded-t-2xl"
              overScrollMode="never"
              contentContainerStyle={{
                flexGrow: 1,
                paddingHorizontal: 16,
                paddingBottom: 16,
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Card>
                <View className="flex-col justify-center items-center w-full mt-12 mb-6">
                  <View className="justify-center items-center w-full mx-1.5 lg:mt-3">
                    <SVGLoader src={PrevListingSvg} />
                    <Font weight={700} className="text-2xl">
                      Completed!
                    </Font>
                    <Font numberOfLines={2} className="text-lg">
                      Listing&nbsp;
                      <Font className="text-app-f">
                        “{(titleValue + "").trim()}”
                      </Font>
                      &nbsp;Successfully Created!!!
                    </Font>
                  </View>
                  <View className="w-40 my-8">
                    <Button
                      label="Preview"
                      type="info-outline"
                      shape="rounded-rect"
                      onPress={() => setStep(6)}
                    ></Button>
                  </View>
                </View>
                <View className="p-2 border border-app-d2 rounded mx-4 mb-4">
                  <Font numberOfLines={3} className="text-app-d/80 text-center">
                    Now, you can Proceed to Preview and Submit your Application
                    for SMBEx Approval
                  </Font>
                </View>
              </Card>
              <View className="flex-1 grow w-full h-full flex-col"></View>
              {/* <View className="flex-1 grow w-full h-full flex-col min-h-[16px]"></View> */}
              <View className="w-full flex-row justify-center lg:justify-end y-4">
                <View className="w-full lg:w-60">
                  <Button
                    label={isSubmitting ? "Please Wait" : "Submit"}
                    type="primary"
                    onPress={() => onSubmitListingData()}
                  ></Button>
                </View>
              </View>
            </ScrollView>
          ) : (
            step === 6 && (
              <ScrollView
                className="rounded-t-2xl"
                overScrollMode="never"
                contentContainerStyle={{
                  flexGrow: 1,
                  paddingHorizontal: 16,
                  paddingBottom: 16,
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <>
                  <View className="flex-row flex-wrap items-center w-full">
                    <View className=" w-full p-0 ">
                      <View className="h-80 ">
                        <Gallery
                          images={galleryImages}
                          className="h-full"
                        ></Gallery>
                      </View>
                      {/* <View className="w-full p-4 bg-app-g flex-row rounded-lg items-center flex lg:hidden">
                              <RemixIcon
                                name="checkbox-circle-line"
                                color={colors.app.a}
                                size={24}
                                className="mr-2"
                              ></RemixIcon>
                              <Font className="text-app-a text-md">Verified Listing</Font>
                            </View>
                            <Pressable className="w-full p-4 bg-app-a border border-gray-200 flex-row rounded-lg items-center mt-4 flex lg:hidden">
                              <RemixIcon
                                name="bookmark-line"
                                color={colors.app.d}
                                size={24}
                                className="mr-2"
                              ></RemixIcon>
                              <Font className="text-app-d text-md">
                                Save this listing
                              </Font>
                            </Pressable>
                            <View className="h-8">
                          </View> */}
                      <Card className="mt-4">
                        <View className="w-full pt-6 px-4 mb-4">
                          <Font
                            className="text-xl lg:text-2xl text-app-d font-semibold leading-5 mb-2"
                            numberOfLines={2}
                          >
                            {titleInputValue || "Unknown Name"}
                          </Font>
                          {/* <View
                            className="flex-row items-center  mb-4 "
                            style={{ marginVertical: 2 }}
                          >
                            <Font
                              className="text-sm text-app-d1 font-medium"
                              numberOfLines={2}
                            >
                              {descriptionInputValue || "Unknown Description"}
                            </Font>
                          </View> */}
                          <View
                            className="flex-row items-center"
                            style={{ marginVertical: 2 }}
                          >
                            <RemixIcon
                              name="star-fill"
                              size={16}
                              color={colors.app.i}
                              className="mr-1 mt-[2px] flex-shrink-0"
                            ></RemixIcon>
                            <Font
                              className="text-md text-app-d1 font-semibold"
                              numberOfLines={1}
                            >
                              0.0 | (0) reviews
                            </Font>
                          </View>
                          <View className="mt-2">
                            {descriptionInputValue ? (
                              <>
                                <Font
                                  className="text-app-d"
                                  numberOfLines={descriptionReadMore ? 0 : 4}
                                >
                                  {/* {`**Our historic bed and breakfast** near Washington DC offers guests a warm and tranquil alternative to area hotel lodging in a warm and welcoming atmosphere here in the Sweetest Place On Earth. Built as a private residence farmhouse more than 180 years ago, the B&B has been providing relaxing accommodations for many years. We are just ten miles from downtown, our location provides a truly relaxing getaway. With mature landscaping, serene gardens, and quiet neighborhood, you will feel as though you have found a new, favorite retreat. Each of our eight guest rooms features a unique character, from grand, to romantic, to rustic, and everything in between. With private bathrooms, cable TV, and high-speed wireless Internet, you will have every convenience in the midst of the tranquil luxury of elegant country decor. Each roomoffers amenities such as individually zonedcentral air conditioning, Showtime and ESPNTV options, use of our common areas andhouse computer, evening snacks and drinks,and of course a delicious homemade breakfaston each morning of your stay in ourbeautiful enclosed porch. Also there is anew covered deck to sit and enjoy even on arainy day. Escape from the stresses ofeveryday life here at Hope Bed & BreakfastInn, and enjoy a country setting with allthe conveniences of everyone’s favoritesweet city!`} */}
                                  {descriptionInputValue}
                                </Font>
                                {descriptionInputValue?.length > 1350 && (
                                  <Pressable
                                    className="my-2"
                                    onPress={() =>
                                      toggleDescriptionReadMore(
                                        !descriptionReadMore
                                      )
                                    }
                                  >
                                    <Font className="text-app-e">
                                      {descriptionReadMore
                                        ? "See less"
                                        : "See more"}
                                    </Font>
                                  </Pressable>
                                )}
                              </>
                            ) : (
                              <Font className="p-2 text-app-d2 text-center">
                                No Description
                              </Font>
                            )}
                          </View>
                        </View>
                      </Card>
                      <Card title="Features">
                        <View className="w-full pb-4">
                          <View className="w-full justify-start flex-row flex-wrap px-4">
                            <FlatList
                              key={screen?.width}
                              data={listingFeatures}
                              renderItem={({ item, index }) => (
                                <View
                                  className="w-1/2 p-2 lg:w-1/4 flex-row justify-start items-start"
                                  key={"features" + index}
                                >
                                  <RemixIcon
                                    key={Date.now()}
                                    name="checkbox-fill"
                                    color={colors.app.e}
                                    size={20}
                                    className="mr-2"
                                  ></RemixIcon>
                                  <Font className="leading-4 break-all mr-4">
                                    {item.title}
                                  </Font>
                                </View>
                              )}
                              numColumns={screen?.width >= 768 ? 4 : 2}
                              ListEmptyComponent={() => {
                                return (
                                  <Font className="p-2 text-app-d2 text-center">
                                    No Features
                                  </Font>
                                );
                              }}
                            />
                          </View>
                        </View>
                      </Card>
                      <Card title="Services & Pricing">
                        {categoryDetails ? (
                          <View className="flex-row flex-wrap p-4 justify-between pt-0 lg:w-full">
                            <FlatList
                              data={Object.keys(categoryDetails || {})}
                              renderItem={({ item }) => (
                                <>
                                  <View className="w-full bg-gray-100 rounded mb-4">
                                    <View className="min-h-12 items-center flex-row w-full py-4">
                                      <View className="h-6 absolute w-1 bg-app-e rounded-r"></View>
                                      <Font
                                        className="px-4 w-full leading-4 text-[15px] flex-1 font-semibold"
                                        numberOfLines={2}
                                        weight={700}
                                      >
                                        {item}
                                      </Font>
                                    </View>
                                  </View>
                                  <View className="w-full">
                                    <FlatList
                                      ItemSeparatorComponent={() => {
                                        return (
                                          <View
                                            style={{
                                              marginHorizontal: 10,
                                            }}
                                          />
                                        );
                                      }}
                                      data={categoryDetails[item]}
                                      horizontal
                                      renderItem={({
                                        item: {
                                          id,
                                          title,
                                          subtitle,
                                          service_unit,
                                        } = {},
                                        index,
                                      }) => (
                                        <View
                                          className="md:min-w-[140rem] md:max-w-[160rem] border border-gray-200 rounded-lg mb-4 py-4 pr-4"
                                          key={"prevbs" + index}
                                        >
                                          <View className="items-center w-full flex-row">
                                            <View className="h-full absolute w-1 bg-app-e rounded-r"></View>
                                            <Font
                                              className="ml-4 font-semibold leading-5 text-base mb-px"
                                              numberOfLines={2}
                                              weight={800}
                                            >
                                              {title}
                                            </Font>
                                          </View>
                                          <View className="items-center flex-row py-1">
                                            <Font
                                              className="ml-4 text-app-h font-semibold"
                                              weight={700}
                                            >
                                              {`${
                                                service_unit.currency_symbol
                                              } ${service_unit.price / 100} / ${
                                                service_unit.name
                                              }`}
                                            </Font>
                                          </View>
                                          <View className="items-center flex-row my-1">
                                            <Font className="px-4 text-app-d1">
                                              {subtitle}
                                            </Font>
                                          </View>
                                        </View>
                                      )}
                                    />
                                  </View>
                                </>
                              )}
                            />
                          </View>
                        ) : (
                          <View className="mb-4 justify-center items-center">
                            <Font className="p-2 text-app-d2 text-center">
                              No Services
                            </Font>
                          </View>
                        )}
                      </Card>
                      <Card title="Video">
                        {videoLinkInputValue ? (
                          <View className="w-[327px] h-[184px] lg:w-[426px] lg:h-[240px] mb-4">
                            <VideoPlayer videoURI={videoLinkInputValue} />
                          </View>
                        ) : (
                          <View className="mb-4 justify-center items-center">
                            <Font className="p-2 text-app-d2 text-center">
                              No Media URL
                            </Font>
                          </View>
                        )}
                      </Card>
                      <Card title="Location">
                        <View className="w-full h-80 mb-4 md:px-4">
                          <Maps
                            url={websiteLinkInputValue}
                            image={pickedImage?.uri}
                            title={titleInputValue}
                            location={
                              `${cityInputValue ? cityInputValue : ""}${
                                stateInputValue ? ", " + stateInputValue : ""
                              }${
                                countryInputValue
                                  ? ", " + countryInputValue
                                  : ""
                              }${
                                postalCodeInputValue
                                  ? " - " + postalCodeInputValue
                                  : ""
                              }` || mapAddress
                            }
                            latitude={latLng?.lat}
                            longitude={latLng?.lng}
                            rating={"0.00"}
                            priceMin={minPriceInputValue}
                            priceMax={maxPriceInputValue}
                            disabled={true}
                            className="w-full h-80 "
                          ></Maps>
                        </View>
                      </Card>
                      <Card title="Opening Hours" className="mb-4">
                        <View className="flex-1 flex-row w-full flex-wrap pb-4">
                          <FlatList
                            data={weekDays}
                            renderItem={({ item, index }) => (
                              <View className="flex-row px-8 w-full py-2">
                                <View className="flex-1 flex-row">
                                  <Font className="text-app-d capitalize w-full">
                                    {item}
                                  </Font>
                                </View>
                                <View>
                                  <FlatList
                                    data={openingHours?.[item]}
                                    renderItem={({ item, index }) => (
                                      <Font className="text-app-d">
                                        {`${
                                          item.start_in_min.h > 12
                                            ? (() => {
                                                let actTm =
                                                  item.end_in_min.h - 12;
                                                return actTm < 10
                                                  ? "0" + actTm
                                                  : actTm;
                                              })()
                                            : item.start_in_min.h
                                        }:${item.start_in_min.m} ${
                                          item.start_in_min.h < 12 ? "AM" : "PM"
                                        } - ${
                                          item.end_in_min.h > 12
                                            ? (() => {
                                                let actTm =
                                                  item.end_in_min.h - 12;
                                                return actTm < 10
                                                  ? "0" + actTm
                                                  : actTm;
                                              })()
                                            : item.end_in_min.h
                                        }:${item.end_in_min.m} ${
                                          item.end_in_min.h < 12 ? "AM" : "PM"
                                        }`}
                                      </Font>
                                    )}
                                    ItemSeparatorComponent={() => {
                                      return (
                                        <View
                                          style={{
                                            marginBottom: 5,
                                          }}
                                        />
                                      );
                                    }}
                                    ListEmptyComponent={() => {
                                      return (
                                        <Font className="text-app-d">
                                          Closed
                                        </Font>
                                      );
                                    }}
                                  />
                                </View>
                              </View>
                            )}
                            ItemSeparatorComponent={() => {
                              return (
                                <View className="border-t border-dashed my-0 mx-4 border-app-d3" />
                              );
                            }}
                          />
                          {/* <View className="border-t border-dashed my-0 mx-4 border-app-d3" />
                          <View className="flex-row w-full px-4 py-2">
                            <Font className="flex-1 text-app-d">Tuesday</Font>
                            <View>
                              <FlatList
                                data={["09:00 AM - 08:00 PM"]}
                                renderItem={({ item, i }) => (
                                  <Font key={"tue" + i} className="text-app-d">
                                    {item}
                                  </Font>
                                )}
                                ItemSeparatorComponent={() => {
                                  return (
                                    <View
                                      style={{
                                        marginBottom: 5,
                                      }}
                                    />
                                  );
                                }}
                                ListEmptyComponent={() => {
                                  return (
                                    <Font className="text-app-d">Closed</Font>
                                  );
                                }}
                              />
                            </View>
                          </View> */}
                        </View>
                      </Card>
                    </View>
                  </View>
                </>
                <View className="flex-1 grow w-full h-full flex-col min-h-[16px]"></View>
                <View className="w-full flex-row justify-center lg:justify-end y-4">
                  <View className="w-full lg:w-60">
                    <Button
                      label={isSubmitting ? "Please Wait" : "Submit"}
                      type="primary"
                      onPress={() => onSubmitListingData()}
                    ></Button>
                  </View>
                </View>
              </ScrollView>
            )
          )}
          {/* {step > 0 && (
            <>
              <View className="flex-1 grow w-full h-full flex-col"></View>
              <View className="w-full flex-row justify-center lg:justify-end">
                <View className="w-full lg:w-60">
                  <Button
                    label={[5, 6].includes(step) ? "Submit" : "Continue"}
                    type="primary"
                    onPress={() => step < 5 && setStep(step + 1)}
                  ></Button>
                </View>
              </View>
            </>
          )} */}
        </View>
      </View>
    </View>
  );
}
