import React from "react";
import { ScrollView, View } from "react-native";
//import RemixIcon from "react-native-remix-icon";
//import colors from "../../../colors";
import { Card } from "../../components/general/cards/card";
import { Font } from "../../components/font";
import { Footer } from "../../components/footer";
import { Widget } from "../../components/widget";
//import { getPublicData } from "../../services/api";

export default function PrivacyPolicy({ navigation, route }) {
  return (
    <View className="flex-1 bg-app-e lg:bg-gray-50">
      <ScrollView
        className="p-4 rounded-t-2xl bg-gray-50"
        contentContainerStyle={{
          flexGrow: 1,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <View className=" max-w-screen-lg w-full mx-auto">
          <View className="my-5 hidden lg:flex">
            <Font className="text-lg font-semibold text-app-d">
              Privacy Policy
            </Font>
            <Font className="text-app-d1 my-2" weight={500}>
              Effective Date : March 24 ,2023
            </Font>
          </View>
          <View className="flex-col lg:flex-row max-w-screen-lg w-full">
            <View className=" w-full lg:w-4/6 p-0 lg:pr-4">
              <Card title="what does this policy cover ?">
                <View className="w-full p-4 pt-0">
                  <Font className="text-app-d">
                    SMBEx is providing Terms Of Service document for
                    conservative business owners. It brings businesses under one
                    roof where customers can search deals, make appointments and
                    book online. SMBEx brings the demand and supply together for
                    local kingdom businesses to build a stronger community to
                    serve local neighborhoods. SMBEx encourages businesses to
                    adhere to good stewardship within local communities. SMBEx
                    is about facilitating a thriving small business economy in a
                    post-COVID era.
                    <Font>
                      SMBEx is providing Terms And conditions document for
                      conservative business owners. It brings businesses under
                      one roof where customers can search deals, make
                      appointments and book online. SMBEx brings the demand and
                      supply together for local kingdom businesses to build a
                      stronger community to serve local neighborhoods. SMBEx
                      encourages businesses to adhere to good stewardship within
                      local communities SMBEx is providing Terms And conditions
                      document for conservative business owners. It brings
                      businesses under one roof where customers can search
                      deals, make appointments and book online. SMBEx brings the
                      demand and supply together for local kingdom businesses to
                      build a stronger community to serve local neighborhoods.
                      SMBEx encourages businesses to adhere to good stewardship
                      within local communities
                    </Font>
                    <Font>
                      SMBEx is providing Terms And conditions document for
                      conservative business owners. It brings businesses under
                      one roof where customers can search deals, make
                      appointments and book online. SMBEx brings the demand and
                      supply together for local kingdom businesses to build a
                      stronger community to serve local neighborhoods. SMBEx
                      encourages businesses to adhere to good stewardship within
                      local communities
                    </Font>
                  </Font>
                </View>
              </Card>
            </View>
            <View className=" w-full lg:w-2/6 p-0 lg:pl-4">
              <Widget></Widget>
            </View>
          </View>
          <Footer navigation={navigation}></Footer>
        </View>
      </ScrollView>
    </View>
  );
}
