import React from "react";
import {
  Image,
  Pressable,
  View,
  Text,
  ScrollView,
  Animated,
  Platform,
} from "react-native";
import { WebView } from "react-native-webview";
import Icon from "react-native-remix-icon";
import RemixIcon from "react-native-remix-icon";
import { Font } from "../../components/font";
import { Card } from "../../components/general/cards/card";
import { Button, Input } from "../../components/input";
import colors from "../../../colors";
import { Maps } from "../../components/maps";
import { Footer } from "../../components/footer";
import { Gallery } from "../../components/gallery";

export default function ListingDetails({navigation}) {
  let text = `**Our historic bed and breakfast** near Washington DC offers guests a warm and tranquil alternative to area hotel lodging in a warm and welcoming atmosphere here in the Sweetest Place On Earth. Built as a private residence farmhouse more than 180 years ago, the B&B has been providing relaxing accommodations for many years. We are just ten miles from downtown, our location provides a truly relaxing getaway. With mature landscaping, serene gardens, and quiet neighborhood, you will feel as though you have found a new, favorite retreat. Each of our eight guest rooms features a unique character, from grand, to romantic, to rustic, and everything in between. With private bathrooms, cable TV, and high-speed wireless Internet, you will have every convenience in the midst of the tranquil luxury of elegant country decor. Each room offers amenities such as individually zoned central air conditioning, Showtime and ESPN TV options, use of our common areas and house computer, evening snacks and drinks, and of course a delicious homemade breakfast on each morning of your stay in our beautiful enclosed porch. Also there is a new covered deck to sit and enjoy even on a rainy day. Escape from the stresses of everyday life here at Hope Bed & Breakfast Inn, and enjoy a country setting with all the conveniences of everyone’s favorite sweet city!
    `;
  return (
    <View className="flex-1 bg-app-e">
      <View className="flex-1">
        <View className="flex-1 rounded-t-2xl lg:rounded-none overflow-hidden bg-app-b">
          <ScrollView
            className="flex-1 h-4 "
            contentContainerStyle={{
              flexGrow: 1,
              alignItems: "center",
            }}
          >
            <View className="flex-1 h-full w-full p-4 items-center">
              <View className="flex-col lg:flex-row max-w-screen-lg w-full">
                <View className=" w-full lg:w-4/6 p-0 lg:pr-4">
                  <View className="h-96 ">
                    <Gallery className="h-full"></Gallery>
                  </View>
                  <View className="w-full p-4 bg-app-g flex-row rounded-lg items-center flex lg:hidden">
                    <RemixIcon
                      name="checkbox-circle-line"
                      color={colors.app.a}
                      size={24}
                      className="mr-2"
                    ></RemixIcon>
                    <Font className="text-app-a text-md">Verified Listing</Font>
                  </View>
                  <Pressable className="w-full p-4 bg-app-a border border-gray-200 flex-row rounded-lg items-center mt-4 flex lg:hidden">
                    <RemixIcon
                      name="bookmark-line"
                      color={colors.app.d}
                      size={24}
                      className="mr-2"
                    ></RemixIcon>
                    <Font className="text-app-d text-md">
                      Save this listing
                    </Font>
                  </Pressable>
                  <View className="h-8"></View>
                  <Card title="Details">
                    <View className="w-full p-4 pt-0">
                      <Font
                        className="text-xl lg:text-2xl text-app-d  leading-5 mb-2"
                        numberOfLines={2}
                      >
                        Tommy’s Italian Restaurant
                      </Font>
                      <View
                        className="flex-row items-center  mb-4 "
                        style={{ marginVertical: 2 }}
                      >
                        <Font
                          className="text-sm text-app-d1 leading-4"
                          numberOfLines={2}
                        >
                          Washington, DC 20008, United States
                        </Font>
                      </View>
                      <View
                        className="flex-row items-center"
                        style={{ marginVertical: 4 }}
                      >
                        <RemixIcon
                          name="star-fill"
                          size={16}
                          color={colors.app.i}
                          className="mr-1 flex-shrink-0"
                        ></RemixIcon>
                        <Font className="text-md text-app-d1" numberOfLines={1}>
                          {" "}
                          4.8 | 100 reviews
                        </Font>
                      </View>
                      <View className="mt-4">
                        <Font className="text-app-d leading-5">{text}</Font>
                      </View>
                    </View>
                  </Card>
                  <Card title="Features">
                    <View className="w-full p-4 pt-0 flex-row flex-wrap">
                      {[...Array(5).keys()].map((e, i) => (
                        <View
                          className="w-full lg:w-1/2 flex-row items-center h-8"
                          key={i}
                        >
                          <RemixIcon
                            name="checkbox-fill"
                            color={colors.app.e}
                            size={20}
                            className="mr-2"
                          ></RemixIcon>
                          <Font>Feature1</Font>
                        </View>
                      ))}
                    </View>
                  </Card>
                  <Card title="Pricing">
                    <View className="flex-row flex-wrap p-4 justify-between  pt-0">
                      <View className="w-full bg-gray-100  rounded mb-4">
                        <View className="min-h-12 items-center flex-row w-full py-4">
                          <View className="h-1/2 absolute w-1 bg-app-e rounded-r"></View>
                          <Font
                            className="px-4 w-full leading-4 text-[15px] flex-1"
                            numberOfLines={2}
                          >
                            Bed Setup
                          </Font>
                        </View>
                      </View>
                      {[...Array(2).keys()].map((e, i) => (
                        <View
                          className="w-full lg:w-[48.5%] border border-gray-200  rounded-lg mb-4"
                          key={i}
                        >
                          <View className="items-center flex-row w-full pt-4 pb-2">
                            {/* <View className="h-1/2 absolute w-1 bg-app-e1 rounded-r"></View> */}
                            <Font
                              className="ml-4 w-full leading-4"
                              numberOfLines={2}
                            >
                              Extra King Double Bed Super Premium
                            </Font>
                            <View className="items-center flex-row px-4">
                              <Font className="ml-4 w-full ">$ 200.00</Font>
                            </View>
                          </View>
                          <View className="items-center flex-row w-full mb-4">
                            <Font className="px-4 w-full text-app-d1">
                              King Bed Air Conditioning Electric Fireplace 22″
                              TV with Blue Ray
                            </Font>
                          </View>
                        </View>
                      ))}
                    </View>
                    <View className="flex-row flex-wrap p-4 justify-between  pt-0">
                      <View className="w-full bg-gray-100  rounded mb-4">
                        <View className="min-h-12 items-center flex-row w-full py-4">
                          <View className="h-1/2 absolute w-1 bg-app-e rounded-r"></View>
                          <Font
                            className="px-4 w-full leading-4  text-[15px]  flex-1"
                            numberOfLines={2}
                          >
                            Service
                          </Font>
                        </View>
                      </View>
                      {[...Array(2).keys()].map((e, i) => (
                        <View
                          className="w-full lg:w-[48.5%] border border-gray-200  rounded-lg mb-4"
                          key={i}
                        >
                          <View className="items-center flex-row w-full pt-4 pb-2">
                            {/* <View className="h-1/2 absolute w-1 bg-app-e1 rounded-r"></View> */}
                            <Font
                              className="ml-4 w-full leading-4"
                              numberOfLines={2}
                            >
                              Special Breakfast
                            </Font>
                            <View className="items-center flex-row px-4">
                              <Font className="ml-4 w-full ">$ 200.00</Font>
                            </View>
                          </View>
                          <View className="items-center flex-row w-full mb-4">
                            <Font className="px-4 w-full text-app-d1">
                              King Bed Air Conditioning Electric Fireplace 22″
                              TV with Blue Ray
                            </Font>
                          </View>
                        </View>
                      ))}
                    </View>
                  </Card>
                  <Card title="Video">
                    <View className="flex-1 w-full h-96">
                      <WebView
                        style={{ flex: 1 }}
                        javaScriptEnabled={true}
                        className="rounded-b-lg"
                        source={{
                          uri: "https://www.youtube.com/embed/ZZ5LpwO-An4?rel=0&autoplay=0&showinfo=0&controls=0",
                        }}
                      />
                    </View>
                  </Card>
                  <Card title="Location">
                    <View className="flex-1 w-full h-96">
                      <Maps className="rounded-b-lg"></Maps>
                    </View>
                  </Card>
                  <Card title="Reviews (100)">
                    <ScrollView
                      className="flex-1 w-full p-4 pt-0"
                      // showsHorizontalScrollIndicator={false}
                    >
                      {[...Array(5).keys()].map((e, i) => (
                        <View
                          className="w-full py-4 flex-col lg:flex-row border-b border-b-gray-200"
                          key={i}
                        >
                          <View className="w-full lg:w-44 mb-4 mr-4">
                            <View className="items-center justify-center flex-row ">
                              <View className="w-10 h-10 rounded-full overflow-hidden">
                                <Image
                                  source={require("../../../assets/bg.jpg")}
                                  className="flex-1"
                                ></Image>
                              </View>
                              <View className="flex-1 ml-2">
                                <Font className="text-app-d" numberOfLines={1}>
                                  John Doe I got amazing assistance from Apple
                                  Union Square! They were very nice to me
                                </Font>
                                <Font className="text-xs text-app-d1">
                                  2 days ago
                                </Font>
                              </View>
                            </View>
                          </View>
                          <View className="flex-1">
                            <View className="items-center flex-row w-full pt-1 pb-2 ">
                              <RemixIcon
                                name="star-fill"
                                size={16}
                                color={colors.app.i}
                                className="flex-shrink-0"
                              ></RemixIcon>
                              <RemixIcon
                                name="star-fill"
                                size={16}
                                color={colors.app.i}
                                className="ml-1 flex-shrink-0"
                              ></RemixIcon>
                              <RemixIcon
                                name="star-fill"
                                size={16}
                                color={colors.app.i}
                                className="ml-1 flex-shrink-0"
                              ></RemixIcon>
                              <RemixIcon
                                name="star-fill"
                                size={16}
                                color={colors.app.i}
                                className="ml-1 flex-shrink-0"
                              ></RemixIcon>
                              <RemixIcon
                                name="star-fill"
                                size={16}
                                color={colors.app.c}
                                className="ml-1 flex-shrink-0"
                              ></RemixIcon>
                            </View>
                            <View>
                              <Font className="w-full text-app-d">
                                I got amazing assistance from Apple Union
                                Square! They were very nice to me, and they
                                explained each and everything in detail. but at
                                the entrance it was long wait.
                              </Font>
                            </View>
                            <View className="p-4 bg-gray-100 mt-4 rounded-lg">
                              <View className="items-center justify-center flex-row mb-2">
                                <View className="w-8 h-8 rounded-full overflow-hidden">
                                  <Image
                                    source={require("../../../assets/bg.jpg")}
                                    className="flex-1"
                                  ></Image>
                                </View>
                                <View className="flex-1 ml-2">
                                  <View className="flex-row items-center">
                                    <Font
                                      numberOfLines={1}
                                      className="text-app-d"
                                    >
                                      John Doe
                                    </Font>
                                    <View className="px-1 bg-app-g rounded ml-2">
                                      <Font className="text-app-a text-xs">
                                        Vendor
                                      </Font>
                                    </View>
                                  </View>
                                  <Font className="text-xs text-app-d1">
                                    2 days ago
                                  </Font>
                                </View>
                              </View>
                              <Font className="w-full text-app-d">
                                Thank you very much for using our service, don't
                                forget to share our link
                                <Font className="text-app-f">
                                  {" "}
                                  https://this.link.com{" "}
                                </Font>
                                to your friends
                              </Font>
                            </View>
                          </View>
                        </View>
                      ))}
                    </ScrollView>
                    <View className="w-full p-4 pt-0 flex-row justify-end">
                      <View className="ml-auto flex-row">
                        <Pressable className="py-1 px-2 mx-2 ">
                          <Font className="text-app-d">&lt;</Font>
                        </Pressable>
                        <Pressable className="py-1 px-2 mx-2  border-b-2 border-b-app-g">
                          <Font className="text-app-g">1</Font>
                        </Pressable>
                        <Pressable className="py-1 px-2 mx-2 ">
                          <Font className="text-app-d">2</Font>
                        </Pressable>
                        <Pressable className="py-1 px-2 mx-2 ">
                          <Font className="text-app-d">3</Font>
                        </Pressable>
                        <Pressable className="py-1 px-2 mx-2 ">
                          <Font className="text-app-d">&gt;</Font>
                        </Pressable>
                      </View>
                    </View>
                  </Card>
                </View>
                <View className=" w-full lg:w-2/6 p-0 lg:pl-4">
                  <View className="w-full p-4 bg-app-g flex-row rounded-lg items-center hidden lg:flex">
                    <RemixIcon
                      name="checkbox-circle-line"
                      color={colors.app.a}
                      size={24}
                      className="mr-2"
                    ></RemixIcon>
                    <Font className="text-app-a text-md">Verified Listing</Font>
                  </View>
                  <Pressable className="w-full p-4 bg-app-a border border-gray-200 flex-row rounded-lg items-center mt-8 hidden lg:flex">
                    <RemixIcon
                      name="bookmark-line"
                      color={colors.app.d}
                      size={24}
                      className="mr-2"
                    ></RemixIcon>
                    <Font className="text-app-d text-md">
                      Save this listing
                    </Font>
                  </Pressable>
                  <View className="h-8"></View>
                  <Card title="Opeing Hours">
                    <View className="flex-1 w-full pb-4">
                      <View className="flex-row w-full px-4 py-2">
                        <Font className="flex-1 text-app-d">Monday</Font>
                        <View className="items-end">
                          <Font className="text-app-d">
                            09:00 AM - 12:00 PM
                          </Font>
                          <Font className="text-app-d">
                            02:00 AM - 08:00 PM
                          </Font>
                        </View>
                      </View>
                      <View className="flex-row w-full px-4 py-2">
                        <Font className="flex-1 text-app-d">Tuesday</Font>
                        <Font className="text-app-d">09:00 AM - 08:00 PM</Font>
                      </View>
                      <View className="flex-row w-full px-4 py-2">
                        <Font className="flex-1 text-app-d">Wednesday</Font>
                        <Font className="text-app-d">09:00 AM - 08:00 PM</Font>
                      </View>
                      <View className="flex-row w-full px-4 py-2">
                        <Font className="flex-1 text-app-d">Thursday</Font>
                        <Font className="text-app-d">09:00 AM - 08:00 PM</Font>
                      </View>
                      <View className="flex-row w-full px-4 py-2">
                        <Font className="flex-1 text-app-d">Friday</Font>
                        <Font className="text-app-d">09:00 AM - 08:00 PM</Font>
                      </View>
                      <View className="flex-row w-full px-4 py-2">
                        <Font className="flex-1 text-app-d">Saturday</Font>
                        <Font className="text-app-d">Closed</Font>
                      </View>
                      <View className="flex-row w-full px-4 py-2">
                        <Font className="flex-1 text-app-d">Sunday</Font>
                        <Font className="text-app-d">Closed</Font>
                      </View>
                    </View>
                  </Card>
                  <Card title="Booking">
                    <View className="flex-1 w-full p-4 pt-0">
                      {/* <AISplitNumber
                                                minLabel={"Check In"}
                                                maxLabel={"Check out"}
                                            ></AISplitNumber> */}

                      <Input type="counter" label={"Guests"}></Input>
                    </View>
                    <View className="flex-row h-10 items-center w-full px-4">
                      <Font className="text-app-d">Extra Services</Font>
                    </View>
                    <ScrollView
                      className="flex-1 w-full p-2 pb-4 pt-0 gap-4"
                      horizontal
                      // showsHorizontalScrollIndicator={false}
                    >
                      {[...Array(5).keys()].map((e, i) => (
                        <View
                          className="w-44 h-20 border border-gray-200 bg-app-b rounded-lg"
                          key={i}
                        >
                          <View className="h-12 items-center flex-row w-full">
                            <View className="h-6 absolute w-1 bg-app-e"></View>
                            <Font className="ml-4 w-full">King Bed</Font>
                          </View>
                          <View className="items-center flex-row w-full">
                            <Font className="ml-4 w-full text-app-h">
                              $ 200
                            </Font>
                          </View>
                        </View>
                      ))}
                    </ScrollView>

                    <View className="flex-1 w-full p-4 pt-4">
                      <Input
                        type="coupon"
                        label={"Have a coupon?"}
                        placeholder="Coupon code"
                        submitText="Apply"
                      ></Input>
                    </View>
                    <View className="flex-row w-full px-4">
                      <Font className="flex-1">Total</Font>
                      <Font>$ 240.00</Font>
                    </View>
                    <View className="flex-row w-full px-4">
                      <Font className="flex-1">Reward</Font>
                      <Font>$ 7.20</Font>
                    </View>
                    <View className="p-4 rounded-lg flex-row w-full items-center">
                      <Button
                        type="primary"
                        label="Book Now"
                        className="w-full"
                      ></Button>
                    </View>
                  </Card>

                  <Card title="Owner">
                    <View className="flex-1 w-full pt-0 ">
                      <View className="h-12 items-center justify-center flex-row px-4">
                        <View className="w-10 h-10 rounded-full overflow-hidden">
                          <Image
                            source={require("../../../assets/bg.jpg")}
                            className="flex-1"
                          ></Image>
                        </View>
                        <View className="flex-1 ml-2">
                          <Font>John Doe</Font>
                          <Font className="text-xs">3 Listings</Font>
                        </View>
                      </View>
                      <View className="items-center w-full p-4">
                        <Font className="w-full text-app-d1 mb-4">
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the 1500s.
                        </Font>
                        <Button
                          leftIcon={{
                            name: "message-2-fill",
                            color: colors.app.d,
                          }}
                          label="Contact owner"
                        ></Button>
                      </View>
                    </View>
                  </Card>
                </View>
              </View>
            </View>
            <Footer navigation={navigation}></Footer>
          </ScrollView>
        </View>
      </View>
    </View>
  );
}
