import React from "react";
import { Image, View, Pressable, Platform } from "react-native";
import { Font, A } from "../../components/font";
import colors from "../../../colors";
import { ScrollView } from "react-native";
import RemixIcon from "react-native-remix-icon";
import { ImageBackground } from "react-native";

export default function EmailSentSuccessfullyPage({ navigation }) {
  return (
    <View className="w-full h-full bg-gray-50 flex-row">
      <View className="h-full w-full lg:w-1/2 items-center justify-center">
        <ScrollView
          className="w-full"
          contentContainerStyle={{
            flexGrow: 1,
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <View
            className={
              "w-80 items-center py-16 " +
              (Platform.OS === "web"
                ? "py-8 px-10 md:px-0 w-full md:w-80"
                : "basis-0")
            }
          >
            <Pressable
              onPress={() => navigation.replace("app")}
              className="mb-8 items-center"
            >
              <Image
                source={require("../../../assets/smbex-lo.png")}
                className="w-36 h-12 my-2"
              ></Image>
              <Font className="text-app-e">Vendor</Font>
            </Pressable>
            <View className="h-[600px] justify-center items-center">
              <View className="rounded-full w-10 h-10 bg-app-g items-center justify-center">
                <RemixIcon
                  name="check-line"
                  color={colors.app.a}
                  size="24"
                ></RemixIcon>
              </View>
              <Font className="text-lg my-4 text-center">
                Rest Password Link Sent Successfully to Your Email Address!
              </Font>
              {/* <View className="w-full my-2">
                <A
                  href="signin"
                  label="Sign in"
                  className="font-semibold text-app-e text-sm text-center"
                />
              </View> */}
            </View>
          </View>
        </ScrollView>
      </View>
      <View className="h-full w-1/2 hidden lg:flex bg-app-d">
        <ImageBackground
          className="flex-1"
          source={require("../../../assets/side-background-image.jpg")}
          resizeMode="stretch"
          alt="side-background-home"
        />
      </View>
    </View>
  );
}
