import React, { useState, useEffect } from "react";
import { Button, Image, View, Pressable } from "react-native";
import * as ImagePicker from "expo-image-picker";
import { Font } from "./font";

export default function UploadPicture({ source,onImagePick }) {
  const [image, setImage] = useState(require("../../assets/userImage.jpg"));

  const pickImage = async () => {
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.All,
      allowsEditing: true,
      aspect: [1, 1],
      quality: 1,
      base64: true,
    });

  

    if (!result.canceled) {
      setImage({ uri: result.assets[0].uri });
      onImagePick(result.assets[0])
    }
  };

  useEffect(() => {
    setImage(source || require("../../assets/userImage.jpg"));
  }, []);

  return (
    <View className="w-40 h-44 rounded-lg border border-gray-200 overflow-hidden">
      <Image source={image} className="w-full h-36 object-cover flex-1" />
      <Pressable
        onPress={pickImage}
        className="justify-center items-center p-2"
      >
        <Font weight={500}>Upload Picture</Font>
      </Pressable>
    </View>
  );
}
