import { LogBox, Platform } from "react-native";
if (Platform.OS === "web") {
  global._frameTimestamp = null;
}
import "expo-dev-client";
import "react-native-gesture-handler";
import "./main.css";
import { StatusBar } from "expo-status-bar";
import {
  useFonts,
  Quicksand_300Light,
  Quicksand_400Regular,
  Quicksand_500Medium,
  Quicksand_600SemiBold,
  Quicksand_700Bold,
} from "@expo-google-fonts/quicksand";
import { Main } from "./src/main";
import { SafeAreaProvider } from "react-native-safe-area-context";
import React from "react";
import { NativeWindStyleSheet } from "nativewind";
import Toast from "react-native-toast-message";

LogBox.ignoreAllLogs();
NativeWindStyleSheet.setOutput({
  default: "native",
});

export default function App() {
  let [fontsLoaded] = useFonts({
    Quicksand_300Light,
    Quicksand_400Regular,
    Quicksand_500Medium,
    Quicksand_600SemiBold,
    Quicksand_700Bold,
  });

  return !fontsLoaded ? null : (
    <SafeAreaProvider>
      <Main />
      <StatusBar style="light"></StatusBar>
      <Toast />
    </SafeAreaProvider>
  );
}
