import React from "react";
import { View, ScrollView, Platform, ActivityIndicator } from "react-native";
import { Font } from "../../components/font";
import { Card } from "../../components/general/cards/card";
import { Button, Input, Select } from "../../components/input";
import colors from "../../../colors";
import { Galleryupload } from "../../components/galleryupload";
import useInputHandler from "../../utils/useInputHandler";
import { useProductsContext } from "../../contexts/product-context";
import { isSignedIn } from "../../actions/auth-actions";
import axiosInstance from "../../constants/axios-config";
import { apiURIV2 } from "../../constants";
import MediaUpload from "../../components/general/uploads/media-upload";
import { cloneDeep } from "lodash";
import { RefreshControl } from "react-native";
import ResponseModal from "../../components/general/modals/ResponseModal";
import { createCrossPlatformFormData } from "../../utils/encDec";
import { getCouponsData } from "./coupons-list";
import { useCouponsContext } from "../../contexts/coupons-context";
import { useFocusEffect } from "@react-navigation/native";
import { catchAPIErrorText } from "../../utils/error-handler";

const currencyList = [{ label: "USD ($)", value: "USD", symbol: "$" }];

export default function AddProduct({ navigation }) {
  const [step, setStep] = React.useState(1);
  const [showResponseModal, setShowResponseModal] = React.useState({
    visible: false,
    onRequestClose: () => null,
    bodyText: "",
  });

  // console.log("print coupon", getCouponsData);

  // const [pPIsLoading, setpPIsLoading] = React.useState();
  const [pCIsLoading, setpCIsLoading] = React.useState();
  const [resetCoverImage, setResetCoverImage] = React.useState();
  const [resetGallery, setResetGallery] = React.useState();
  // const [priceID, setPriceID] = React.useState();
  const [selectedCoupons, setSelectedCoupons] = React.useState([]);
  // const [couponIDValue, setCouponIDValue] = React.useState(null);
  // const [couponOptions, setCouponOptions] = React.useState([]);
  const [currencySymbol, setCurrencySymbol] = React.useState();
  const [isLoading, setIsLoading] = React.useState(true);

  const {
    setProducts,
    productCategories,
    setProductCategories,
    productCoupons,
    setProductCoupons,
    // productPackages,
    // setProductPackages,
  } = useProductsContext();

  // const { screen } = useSharedContext();

  React.useEffect(() => {
    getProductCoupons();
  }, []);

  const {
    inputValue: productTitleInputValue,
    currentValue: productTitleValue,
    onValueChangeHandler: onChangeProductTitleText,
    onBlurHandler: onBlurProductTitle,
    hasError: productTitleHasError,
    reset: resetProductTitle,
  } = useInputHandler();
  const {
    inputValue: productKeywordsInputValue,
    currentValue: productKeywordsValue,
    onValueChangeHandler: onChangeProductKeywordsText,
    onBlurHandler: onBlurProductKeywords,
    hasError: productKeywordsHasError,
    reset: resetProductKeywords,
  } = useInputHandler();
  const {
    inputValue: productCategoryIDInputValue,
    currentValue: productCategoryIDValue,
    onValueChangeHandler: onChangeProductCategoryIDText,
    onBlurHandler: onBlurProductCategoryID,
    hasError: productCategoryIDHasError,
    reset: resetProductCategoryID,
  } = useInputHandler();
  const {
    inputValue: productCurrencyInputValue,
    currentValue: productCurrencyValue,
    onValueChangeHandler: onChangeProductCurrencyText,
    onBlurHandler: onBlurProductCurrency,
    hasError: productCurrencyHasError,
    reset: resetProductCurrency,
  } = useInputHandler({ defaultValue: "USD" });

  React.useEffect(() => {
    if (!currencySymbol) {
      setCurrencySymbol(
        productCurrencyValue
          ? currencyList.find((v) => v.value === productCurrencyValue)?.label ||
              productCurrencyValue
          : "$"
      );
    }
  }, [currencySymbol, productCurrencyValue]);

  const {
    inputValue: productRegularPriceInputValue,
    currentValue: productRegularPriceValue,
    onValueChangeHandler: onChangeProductRegularPriceText,
    onBlurHandler: onBlurProductRegularPrice,
    hasError: productRegularPriceHasError,
    reset: resetProductRegularPrice,
  } = useInputHandler();

  const {
    inputValue: productSalePriceInputValue,
    currentValue: productSalePriceValue,
    onValueChangeHandler: onChangeProductSalePriceText,
    onBlurHandler: onBlurProductSalePrice,
    hasError: productSalePriceHasError,
    reset: resetProductSalePrice,
  } = useInputHandler();

  const {
    inputValue: productDescriptionInputValue,
    currentValue: productDescriptionValue,
    onValueChangeHandler: onChangeProductDescriptionText,
    onBlurHandler: onBlurProductDescription,
    hasError: productDescriptionHasError,
    reset: resetProductDescription,
  } = useInputHandler();

  const {
    inputValue: productVideoLinkInputValue,
    currentValue: productVideoLinkValue,
    onValueChangeHandler: onChangeProductVideoLinkText,
    onBlurHandler: onBlurProductVideoLink,
    hasError: productVideoLinkHasError,
    reset: resetProductVideoLink,
  } = useInputHandler();

  const {
    inputValue: productWeightInputValue,
    currentValue: productWeightValue,
    onValueChangeHandler: onChangeProductWeightText,
    onBlurHandler: onBlurProductWeight,
    hasError: productWeightHasError,
    reset: resetProductWeight,
  } = useInputHandler();

  const {
    inputValue: productLengthInputValue,
    currentValue: productLengthValue,
    onValueChangeHandler: onChangeProductLengthText,
    onBlurHandler: onBlurProductLength,
    hasError: productLengthHasError,
    reset: resetProductLength,
  } = useInputHandler();

  const {
    inputValue: productWidthInputValue,
    currentValue: productWidthValue,
    onValueChangeHandler: onChangeProductWidthText,
    onBlurHandler: onBlurProductWidth,
    hasError: productWidthHasError,
    reset: resetProductWidth,
  } = useInputHandler();

  const {
    inputValue: productHeightInputValue,
    currentValue: productHeightValue,
    onValueChangeHandler: onChangeProductHeightText,
    onBlurHandler: onBlurProductHeight,
    hasError: productHeightHasError,
    reset: resetProductHeight,
  } = useInputHandler();

  const {
    inputValue: productSKUInputValue,
    currentValue: productSKUValue,
    onValueChangeHandler: onChangeProductSKUText,
    onBlurHandler: onBlurProductSKU,
    hasError: productSKUHasError,
    reset: resetProductSKU,
  } = useInputHandler();

  const {
    inputValue: productStockQTYInputValue,
    currentValue: productStockQTYValue,
    onValueChangeHandler: onChangeProductStockQTYText,
    onBlurHandler: onBlurProductStockQTY,
    hasError: productStockQTYHasError,
    reset: resetProductStockQTY,
  } = useInputHandler();

  // const {
  //   inputValue: productCouponIDInputValue,
  //   currentValue: productCouponIDValue,
  //   onValueChangeHandler: onChangeProductCouponIDTextFromInputHandler,
  //   onBlurHandler: onBlurProductCouponID,
  //   hasError: productCouponIDHasError,
  //   reset: resetProductCouponID,
  // } = useInputHandler();

  const {
    // inputValue: productCoverImgInputValue,
    currentValue: productCoverImgValue,
    onValueChangeHandler: onChangeProductCoverImg,
    // onBlurHandler: onBlurProductCoverImg,
    hasError: productCoverImgHasError,
    reset: resetProductCoverImg,
  } = useInputHandler();

  const {
    // inputValue: productGalleryInputValue,
    currentValue: productGalleryValue,
    onValueChangeHandler: onChangeProductGallery,
    // onBlurHandler: onBlurProductGallery,
    hasError: productGalleryHasError,
    reset: resetProductGallery,
  } = useInputHandler({ defaultValue: [] });

  // const getProductPackages = async (currency) => {
  //   setpPIsLoading(true);
  //   let data;
  //   try {
  //     const token = await isSignedIn();
  //     const res = await axiosInstance.get(apiURIV2 + "/packages", {
  //       params: {
  //         package_item_type: "product",
  //         currency: currency || "USD",
  //       },
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //       },
  //     });
  //     // console.log("res.status: ", res.status, res.data);
  //     if (res.status === 200) {
  //       data = res.data.data;
  //       return res;
  //     }
  //   } catch (e) {
  //     console.log("e.res", e?.response, e);
  //     if (e?.response) {
  //       return e.response;
  //     }
  //   } finally {
  //     setProductPackages({ data });
  //     setpPIsLoading(false);
  //   }
  // };

  // if (step == 0 && !productPackages && !pPIsLoading) {
  //   getProductPackages();
  // }

  const getProductCategories = async () => {
    setpCIsLoading(true);
    let data;
    try {
      const token = await isSignedIn();
      const res = await axiosInstance.get(apiURIV2 + "/product-categories", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      // console.log("res.status: ", res.status, res.data);
      if (res.status === 200) {
        data = res.data.data;
      }
    } catch (e) {
      console.log("e.res", e?.response, e);
    } finally {
      setProductCategories({
        data: data?.map((v) => ({ label: v.title, value: v.id })),
      });
      setpCIsLoading(false);
    }
  };

  if (!productCategories && !pCIsLoading) {
    getProductCategories();
  }

  // const onPackageSelect = (id, price) => {
  //   setPriceID(id);
  //   postCreateSubscription(id, price);
  //   // setStep(1);
  // };
  // const [confirmModal, setConfirmModal] = React.useState({
  //   show: false,
  //   message: "Test Message",
  // });

  // const postCreateSubscription = async (id, price) => {
  //   // console.log('idprice',id,price)
  //   // setPostSubscription({ isLoading: true, isCancelLoading: false });
  //   let data;
  //   try {
  //     const useToken = await isSignedIn();
  //     console.log("token ----------------- : ", useToken);
  //     const res = await axiosInstance.post(
  //       apiURIV2 + `/packages/${id}/prices/${price}/subscribe`,
  //       {},
  //       {
  //         headers: {
  //           Authorization: `Bearer ${useToken}`,
  //         },
  //       }
  //     );
  //     console.log(
  //       "manage-subscription, postCreateSubscription res : ",
  //       res.data
  //     );
  //     if (res.status === 200) {
  //       setConfirmModal({ show: true, message: "Test Message" });
  //       if (res.data.data.payment_intent) {
  //         const initPayment = await initializePaymentSheet({
  //           customer: res.data.data.customer_id,
  //           ephemeralKey: res.data.data.ephemeral_key,
  //           paymentIntent: res.data.data.payment_intent,
  //           token: useToken,
  //         });
  //       } else if (res.data.data.setup_intent) {
  //         const initPayment = await initializePaymentSheet({
  //           customer: res.data.data.customer_id,
  //           ephemeralKey: res.data.data.ephemeral_key,
  //           paymentIntent: res.data.data.setup_intent,
  //           token: useToken,
  //         });
  //       } else {
  //         setConfirmModal({
  //           show: true,
  //           message: res.data.message,
  //         });
  //       }
  //     }
  //   } catch (e) {
  //     console.log("manage-subscription, postCreateSubscription error", e);
  //     setConfirmModal({
  //       show: true,
  //       message: "manage-subscription, postCreateSubscription error",
  //     });
  //   } finally {
  //     // setPostSubscription({ isLoading: false, isCancelLoading: false });
  //   }
  // };

  const getCurrentSubscription = React.useCallback(async () => {
    try {
      const token = await isSignedIn();
      const res = await axiosInstance.get(
        apiURIV2 + "/package-subscriptions/current",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // console.log(
      //   "package-subscription, getCurrentSubscription res : ",
      //   res.data.data
      // );
      if (res.status === 200) {
        // if (res.data?.data?.package_subscription != null) {
        //   // setPriceID(res.data.data.package_subscription.price_id);
        // }
      }
    } catch (e) {
      console.log("current-subscription error : ", e);
      if (e?.response?.status) {
        setShowResponseModal({
          visible: true,
          onRequestClose: () => {
            setShowResponseModal({
              visible: false,
              onRequestClose: () => null,
              bodyText: "",
              dismissText: undefined,
            });
            navigation.replace("manage-subscription");
          },
          bodyText: "Requires package subscription to proceed",
          dismissText: "Proceed",
        });
      }
    } finally {
      setIsLoading(false);
    }
  });

  useFocusEffect(React.useCallback(() => getCurrentSubscription(), []));

  const addProductDetails = async (dataObj) => {
    let data;
    const formData = createCrossPlatformFormData({
      blobDataKeys: ["cover_image", "gallery_images"],
      data: dataObj,
    });
    // for (let [name, value] of formData) {
    //   console.log(`\n\n${name} = `, value, "\n\n");
    // }
    try {
      const token = await isSignedIn();
      const res = await axiosInstance.post(apiURIV2 + "/products", formData, {
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "multipart/form-data",
        },
      });
      if (res.status === 201) {
        data = res.data.data;
        setShowResponseModal({
          visible: true,
          onRequestClose: () => {
            setShowResponseModal({
              visible: false,
              onRequestClose: () => null,
              bodyText: "",
            });
          },
          bodyText: res.data.message,
        });
      }
    } catch (e) {
      console.log("e.res", e?.response, e);
      if (bodyText) {
        setShowResponseModal({
          visible: true,
          onRequestClose: () => {
            setShowResponseModal({
              visible: false,
              onRequestClose: () => null,
              bodyText: "",
            });
          },
          bodyText: catchAPIErrorText(
            e,
            "Unknown Error while Getting Bookings"
          ),
        });
      }
      return e?.response;
    } finally {
      if (data) {
        setProducts({ data, push: true });
        navigation.replace("products");
      }
    }
  };

  // ==================================== Coupons ====================================

  // const handleSelectCoupon = (coupon) => {
  //   // Check if the coupon is already selected
  //   if (
  //     !selectedCoupons.some(
  //       (selectedCoupon) => selectedCoupon.value === coupon.value
  //     )
  //   ) {
  //     setSelectedCoupons([...selectedCoupons, coupon]);
  //   }
  // };

  // const handleUnAllotCoupon = async (coupon) => {
  //   // Perform the un-allot API call here
  //   try {
  //     // Replace with your un-allot API call logic
  //     console.log(`Un-allot coupon with ID ${coupon.value}`);
  //   } catch (error) {
  //     console.error(`Failed to un-allot coupon with ID ${coupon.value}`);
  //   }

  //   // Remove the coupon from the selectedCoupons array
  //   setSelectedCoupons(
  //     selectedCoupons.filter(
  //       (selectedCoupon) => selectedCoupon.value !== coupon.value
  //     )
  //   );
  // };

  const handleCouponSelection = (couponId) => {
    const selectedCouponObj = productCoupons.find(
      (coupon) => coupon.value === couponId
    );

    if (selectedCouponObj) {
      setSelectedCoupons([...selectedCoupons, selectedCouponObj]);
      setProductCoupons(
        productCoupons.filter((coupon) => coupon.value !== couponId)
      );
    }
  };

  const handleCancel = (couponId) => {
    const canceledCouponObj = selectedCoupons.find(
      (coupon) => coupon.value === couponId
    );

    if (canceledCouponObj) {
      setProductCoupons([...productCoupons, canceledCouponObj]);
      setSelectedCoupons(
        selectedCoupons.filter((coupon) => coupon.value !== couponId)
      );
    }
  };

  // let initiallySelectedCouponID = null;

  const getProductCoupons = async () => {
    let data = [];
    try {
      const token = await isSignedIn();
      const res = await axiosInstance.get(apiURIV2 + "/coupons", {
        headers: {
          Authorization: "Bearer " + token,
        },
      });
      console.log("coupon res", res.status);

      if (res.status === 200) {
        data = res.data.data.coupons;
        console.log("coupon res", data);
        const couponList = data.map((CouponsListMap) => ({
          label: CouponsListMap.code,
          value: CouponsListMap.id,
        }));
        // console.log("couponList", couponList);
        setProductCoupons(couponList);
      }
      // if (initiallySelectedCouponID !== null) {
      //   onChangeProductCouponIDText(initiallySelectedCouponID);
      // }
    } catch (e) {
      console.log("e.response", e?.response, e);
    } finally {
    }
  };

  const arrayOfCouponsValue = selectedCoupons.map((obj) => obj.value);

  // console.log(arrayOfCouponsValue);

  // const onChangeProductCouponIDText = async (selectedValue) => {
  //   if (selectedValue !== null) {
  //     await allotCoupon(selectedValue);
  //   }
  //   setCouponIDValue(selectedValue);
  //   onChangeProductCouponIDTextFromInputHandler(selectedValue);
  // };

  // const allotCoupon = async (couponID) => {
  //   try {
  //     const token = await isSignedIn();
  //     const requestData = {
  //       product_id: 0,
  //     };
  //     const res = await axiosInstance.post(
  //       apiURIV2 + `/coupons/${couponID}/allot`,
  //       requestData,
  //       {
  //         headers: {
  //           Authorization: "Bearer " + token,
  //         },
  //       }
  //     );
  //     if (res.status === 200) {
  //       console.log(`Coupon ${couponID} allotted successfully.`);
  //       handleSelectCoupon({ label: couponID, value: couponID });
  //     }
  //   } catch (e) {
  //     console.error(`Failed to allot coupon ${couponID}.`, e);
  //   }
  // };

  // const unAllotCoupon = async (couponID) => {
  //   try {
  //     const token = await isSignedIn();
  //     const requestData = {
  //       product_id: 0,
  //     };
  //     const res = await axiosInstance.post(
  //       apiURIV2 + `/coupons/${couponID}/un-allot`,
  //       requestData,
  //       {
  //         headers: {
  //           Authorization: "Bearer " + token,
  //         },
  //       }
  //     );
  //     if (res.status === 200) {
  //       console.log(`Coupon ${couponID} un-allotted successfully.`);
  //       handleUnAllotCoupon({ label: couponID, value: couponID });
  //     }
  //   } catch (e) {
  //     console.error(`Failed to un-allot coupon ${couponID}.`, e);
  //   }
  // };

  // ================================ submit form handler ==================================

  const submitFormHandler = () => {
    let data = {};
    data.category_id = productCategoryIDInputValue;
    data.coupons = arrayOfCouponsValue;
    data.title = productTitleInputValue;
    data.sku = productSKUInputValue;
    // temporary commented out for presentation 20 oct
    data.keywords = JSON.stringify(
      productKeywordsInputValue?.split(",")?.map((v) => v.trim())
    );
    // .split(/\,[ ]|\,/)
    // .filter((v) => v.trim());
    data.description = productDescriptionInputValue;
    data.video_link = productVideoLinkInputValue;
    data.price = parseFloat(productRegularPriceInputValue || 0) * 100;
    data.sale_price = parseFloat(productSalePriceInputValue || 0) * 100;
    // data.currency = productCurrencyInputValue;
    data.weight_in_gm = productWeightInputValue;
    data.length_in_mm = productLengthInputValue;
    data.width_in_mm = productWidthInputValue;
    data.height_in_mm = productHeightInputValue;
    data.stock = productStockQTYInputValue;
    // data.cover_image = productCoverImgValue.blob;
    data.cover_image = productCoverImgValue;
    // data.gallery_images = productGalleryValue.map((v) => v.blob);
    console.log("productGalleryValue:", productGalleryValue);
    data.gallery_images = productGalleryValue;
    if (Object.keys(data)?.length) addProductDetails(data);
  };

  if (/*pPIsLoading || */ isLoading) {
    if (showResponseModal.visible)
      return (
        <ResponseModal
          visible={showResponseModal.visible}
          onRequestClose={showResponseModal.onRequestClose}
          bodyText={showResponseModal.bodyText}
          dismissText={showResponseModal?.dismissText}
        />
      );

    return (
      <View style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
        <ActivityIndicator color={colors.app.e} size="large" />
      </View>
    );
  }

  return (
    <View className="flex-1 bg-app-e lg:bg-gray-50">
      {showResponseModal.visible && (
        <ResponseModal
          visible={showResponseModal.visible}
          onRequestClose={showResponseModal.onRequestClose}
          bodyText={showResponseModal.bodyText}
          dismissText={showResponseModal?.dismissText}
        />
      )}
      <ScrollView
        className="p-4 rounded-t-2xl bg-gray-50"
        contentContainerStyle={{
          flexGrow: 1,
          justifyContent: "center",
          alignItems: "center",
        }}
        refreshControl={
          <RefreshControl
            refreshing={/*step == 0 ? pPIsLoading :*/ pCIsLoading}
            onRefresh={() => {
              if (step == 1 && productCategories?.length) {
                getProductCategories();
              }
              // else if (step == 0 && !productPackages?.length) {
              //   getProductPackages();
              // }
            }}
          />
        }
      >
        <View className=" max-w-screen-lg w-full mx-auto h-full">
          <View className="my-0 mb-2 lg:my-5 p-0 lg:px-4 flex-row items-center justify-between">
            <View className="flex-row items-center w-3/12">
              <Font className="text-lg font-semibold text-app-d hidden lg:flex">
                Add Product
              </Font>
            </View>
            <View className="flex-row items-center w-6/12 justify-center"></View>
            <View className="flex-row items-center w-3/12"></View>
          </View>
          <View className="p-0 lg:px-4">
            {/* {step == 0 ? (
              <Card title="Select Package">
                <View className="w-full flex-row flex-wrap px-4 pb-4">
                  <FlatList
                    data={productPackages}
                    key={screen?.width}
                    numColumns={screen?.width >= 768 ? 2 : 1}
                    ListEmptyComponent={() => (
                      <View className="flex-1 justify-center items-center h-14">
                        <Font>No Packages Found</Font>
                      </View>
                    )}
                    renderItem={({
                      item: {
                        id,
                        title,
                        subTitle,
                        is_featured,
                        features,
                        prices,
                      },
                    }) => (
                      <View className="w-full md:w-1/2 mb-4 md:m-0 md:p-4">
                        <PlanCard
                          planTitle={title}
                          planPrice={
                            prices[0].currency_symbol +
                            " " +
                            prices[0].discounted_price / 100
                          }
                          planSubtitle={subTitle}
                          planFeatures={features}
                          onPress={() => onPackageSelect(id, prices[0].id)}
                          isFeatured={is_featured}
                        />
                      </View>
                    )}
                  />
                </View>
              </Card>
            ) :  */}
            {/* {step == 1 ? (
              <> */}
            <Card title="Basic Information">
              <View className="flex-row flex-wrap px-2 pb-4 w-full">
                <View className="px-2 w-full lg:w-1/3 pb-4">
                  <Input
                    type="text"
                    label="Title"
                    value={productTitleValue}
                    onChangeText={onChangeProductTitleText}
                    onBlur={onBlurProductTitle}
                    note={productTitleHasError}
                  />
                </View>
                <View className="px-2 w-full lg:w-1/3 pb-4">
                  <Select
                    label="Category"
                    options={productCategories}
                    placeholder={"Select Category"}
                    value={productCategoryIDValue}
                    setValue={onChangeProductCategoryIDText}
                    note={productCategoryIDHasError}
                  />
                </View>
                <View className="px-2 w-full lg:w-1/3 pb-4">
                  <Input
                    type="text"
                    label="Keywords"
                    placeholder="Office Use, Household"
                    value={productKeywordsValue}
                    onChangeText={onChangeProductKeywordsText}
                    onBlur={onBlurProductKeywords}
                    note={productKeywordsHasError}
                  ></Input>
                </View>
                <View className="px-2 w-full lg:w-1/3 pb-4">
                  <Select
                    label="Currency"
                    options={currencyList}
                    placeholder="Select Currency"
                    enabled={false}
                    value={productCurrencyValue}
                    setValue={(v, i) => {
                      onChangeProductCurrencyText(v);
                      setCurrencySymbol(currencyList[i - 1]?.label);
                    }}
                  />
                </View>
                <View className="px-2 w-full lg:w-1/3 pb-4">
                  <Input
                    type="number"
                    label="Regular Price"
                    leftText={currencySymbol}
                    placeholder="0"
                    value={productRegularPriceValue}
                    onChangeText={onChangeProductRegularPriceText}
                    onBlur={onBlurProductRegularPrice}
                    note={productRegularPriceHasError}
                  ></Input>
                </View>
                <View className="px-2 w-full lg:w-1/3 pb-4">
                  <Input
                    type="number"
                    label="Sale Price"
                    placeholder="0"
                    leftText={currencySymbol}
                    value={productSalePriceValue}
                    onChangeText={onChangeProductSalePriceText}
                    onBlur={onBlurProductSalePrice}
                    note={productSalePriceHasError}
                  ></Input>
                </View>
                <View className="px-2 w-full lg:w-1/3 pb-4">
                  <Input
                    type="number"
                    label="Weight"
                    placeholder="0"
                    rightText="gm"
                    value={productWeightValue}
                    onChangeText={onChangeProductWeightText}
                    onBlur={onBlurProductWeight}
                    note={productWeightHasError}
                  ></Input>
                </View>
                <View className="px-2 w-full lg:w-2/3 pb-4 flex-row flex-wrap">
                  <Input
                    type="number"
                    label="Length"
                    placeholder="0"
                    rightText="mm"
                    value={productLengthValue}
                    onChangeText={onChangeProductLengthText}
                    onBlur={onBlurProductLength}
                    note={productLengthHasError}
                  ></Input>
                  <Font
                    className={`p-2 self-start ${
                      Platform.OS === "web" ? "mt-9" : "mt-8"
                    }`}
                  >
                    X
                  </Font>
                  <Input
                    type="number"
                    label="Width"
                    placeholder="0"
                    rightText="mm"
                    value={productWidthValue}
                    onChangeText={onChangeProductWidthText}
                    onBlur={onBlurProductWidth}
                    note={productWidthHasError}
                  ></Input>
                  <Font
                    className={`p-2 self-start ${
                      Platform.OS === "web" ? "mt-9" : "mt-8"
                    }`}
                  >
                    X
                  </Font>
                  <Input
                    type="number"
                    label="Height"
                    placeholder="0"
                    rightText="mm"
                    value={productHeightValue}
                    onChangeText={onChangeProductHeightText}
                    onBlur={onBlurProductHeight}
                    note={productHeightHasError}
                  ></Input>
                </View>
                <View className="px-2 w-full pb-4">
                  <Input
                    type="longtext"
                    label="Description"
                    placeholder="No Description"
                    value={productDescriptionValue}
                    onChangeText={onChangeProductDescriptionText}
                    onBlur={onBlurProductDescription}
                    note={productDescriptionHasError}
                  ></Input>
                </View>
                <View className="px-2 w-full lg:w-1/3 pb-4">
                  <Input
                    type="text"
                    label="Video Link"
                    placeholder={"https://"}
                    value={productVideoLinkValue}
                    onChangeText={onChangeProductVideoLinkText}
                    onBlur={onBlurProductVideoLink}
                    note={productVideoLinkHasError}
                  ></Input>
                </View>
              </View>
            </Card>
            <Card title="Stock">
              <View className="flex-row flex-wrap px-2 pb-4 w-full">
                <View className="px-2 w-full lg:w-1/3 pb-4">
                  <Input
                    type="text"
                    label="SKU"
                    placeholder={"SKU Number"}
                    value={productSKUValue}
                    onChangeText={onChangeProductSKUText}
                    onBlur={onBlurProductSKU}
                    note={productSKUHasError}
                  ></Input>
                </View>
                <View className="px-2 w-full lg:w-1/3 pb-4">
                  <Input
                    type="number"
                    label="Quantity"
                    placeholder="0"
                    rightText="nos."
                    value={productStockQTYValue}
                    onChangeText={onChangeProductStockQTYText}
                    onBlur={onBlurProductStockQTY}
                    note={productStockQTYHasError}
                  ></Input>
                </View>
              </View>
            </Card>
            {/* <Card title="Coupons"> */}
            {/* <View className="flex-row flex-wrap px-2 w-full">
                    <View className="px-2 w-full lg:w-1/3 pb-4">
                      <Select
                        // label="Select Coupons From List"
                        options={productCoupons}
                        placeholder="Select Coupons From List"
                        value={null}
                        onValueChange={handleCouponSelection}
                        note={productCouponIDHasError}
                      />
                    </View>
                  </View>
                  <View className="px-2 w-full lg:w-1/3 pb-4"> */}
            {/* {selectedCoupons.value !== 0 && (
                      <Font className="text-lg font-semibold mb-4">
                        Selected Coupons:
                      </Font>
                    )} */}
            {/* <View className=""> */}
            {/* {selectedCoupons.map((coupon) => (
                        <View
                          key={coupon.value}
                          className="bg-gray-200 rounded-full px-4 py-2 m-2"
                        >
                          <Font className="mr-2">{coupon.label}</Font>
                          <Pressable
                            className=" p-2 cursor-pointer"
                            onPress={() => handleCancel(coupon.value)}
                          >
                            <MaterialIcons
                              name="cancel"
                              size={24}
                              color={colors.app.e1}
                            />
                          </Pressable>
                        </View>
                      ))} */}
            {/* </View>
                  </View> */}
            {/* </Card> */}

            <Card title="Cover Image">
              <View className="px-4 pt-4 pb-8 w-full inline-block">
                <View className="w-full lg:w-96">
                  <MediaUpload
                    onImagePick={onChangeProductCoverImg}
                    note={productCoverImgHasError}
                    reset={resetCoverImage}
                    onReset={() => setResetCoverImage(false)}
                  ></MediaUpload>
                </View>
              </View>
            </Card>
            <Card title="Gallery">
              <View className="px-4 pt-4 pb-8 w-full">
                <View className="w-full">
                  <Galleryupload
                    onAddImage={(img) => {
                      let imgData = cloneDeep(productGalleryValue);
                      imgData.push(img);
                      onChangeProductGallery(imgData);
                    }}
                    onRemoveImage={(img) => {
                      let imgData = cloneDeep(productGalleryValue);
                      if (img?.id?.includes("-local")) {
                        const i = imgData.findIndex((v) => v.id === img.id);
                        if (i > -1) {
                          imgData.splice(i, 1);
                        }
                      }
                      onChangeProductGallery(imgData);
                    }}
                    reset={resetGallery}
                    onReset={() => setResetGallery(false)}
                  ></Galleryupload>
                </View>
              </View>
            </Card>
            {/* <View className="flex-row md:justify-end">
                  <View className="w-full md:w-40">
                    <Button
                      label="Submit"
                      type="primary"
                      onPress={submitFormHandler}
                    ></Button>
                  </View>
                </View> */}
            <View className="flex-1 grow w-full h-full flex-col min-h-[16px]"></View>
            <View className="w-full flex-row justify-center lg:justify-end y-4">
              <View className="w-full lg:w-60">
                <Button
                  label="Submit"
                  type="primary"
                  onPress={submitFormHandler}
                ></Button>
              </View>
            </View>
            {/* </>
            ) : (
              <></>
            )} */}
          </View>
          {/* <ConfirmModal
            BodyComponent={""}
            bodyText={confirmModal.message}
            visible={confirmModal.show}
            onConfirm={() => {
              setConfirmModal({ show: false, message: "" }),
                getCurrentSubscription();
            }}
            confirmText="Ok"
            onCancel={() => setConfirmModal({ show: false, message: "" })}
          /> */}
        </View>
      </ScrollView>
    </View>
  );
}
